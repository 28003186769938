import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  Button,
  Divider,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  moveTeacher,
  getSchools,
  getPositions,
} from "../../../../store/posts/actions";
import { showError } from "../../../toastify";

const MoveStaffDialog = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    teacher,
    sectors,
    schools,
    getSchools,
    getPositions,
    positions,
    moveTeacher,
    getTreeUnits,
  } = props;

  const onClose = () => {
    setSector(null);
    setSchool(null);
    setPosition(null);
    setShowDialog(false);
  };

  const [sector, setSector] = useState(null);
  const [school, setSchool] = useState(null);
  const [position, setPosition] = useState(null);

  const [exitComments, setExitComments] = useState("");

  // console.log(teacher);

  // getShoolPositions("SCHOOL", placementReport.schoolId);

  useEffect(() => {
    setPosition(
      positions.filter(
        ({ positionId }) => !!school && positionId === teacher.positionId
      )[0] || null
    );
    // if (!!selectedSector?.id) getCells(selectedSector.id);
  }, [positions]);

  const onSave = () => {
    moveTeacher(
      {
        ...teacher,
        toSchoolPositionId: position?.id,
        exitComments,
      },
      () => {
        onClose();
        getTreeUnits();
      }
    );
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-dark font-weight-bold">Move teacher</span> |{" "}
            {teacher.lastName + " " + teacher.firstName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="mt-2">
            <TextField
              sx={{ backgroundColor: "#eee" }}
              fullWidth
              size="small"
              name="currentPosition"
              label="Current Position"
              variant="outlined"
              placeholder="Current Position"
              value={teacher.positionName}
              InputProps={{
                readOnly: true,
              }}
            />
          </div>
          <div className="mt-3">
            <TextField
              sx={{ backgroundColor: "#eee" }}
              fullWidth
              size="small"
              name="currentSchoolName"
              label="Current School"
              variant="outlined"
              placeholder="Current School"
              value={
                teacher.schoolName +
                "/" +
                teacher.sectorName +
                "/" +
                teacher.districtName
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </div>

          <div className="mt-3">
            <Autocomplete
              size="small"
              defaultValue={null}
              value={sector || null}
              options={sectors}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, sector) => {
                setSector(sector || null);
                setSchool(null);
                setPosition(null);

                if (!!sector?.id) getSchools(sector?.id || null);
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, sector) => (
                <Box component="li" {...props}>
                  {sector.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select sector"
                  name="sector"
                  placeholder="Select sector"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </div>

          <div className="mt-3">
            <Autocomplete
              size="small"
              defaultValue={null}
              value={school || null}
              options={schools.filter(
                ({ id }) => !!sector && id !== teacher.schoolId
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, school) => {
                setSchool(school || null);
                setPosition(null);

                if (!!school?.id) getPositions("SCHOOL", school?.id || null);
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, school) => (
                <Box component="li" {...props}>
                  {school.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select school"
                  name="school"
                  placeholder="Select school"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </div>

          <div className="mt-3">
            <Autocomplete
              size="small"
              defaultValue={null}
              value={position || null}
              options={positions.filter(
                ({ positionId }) =>
                  !!school && positionId === teacher.positionId
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, position) => {
                setPosition(position || null);
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <div>
                    <span className="d-block text-primary">{option.name}</span>

                    <small className="d-block mb-1 ">
                      <span
                        className={`badge badge badge-${
                          +option.budgetPosts > +option.numberOfEmployees
                            ? "success"
                            : "danger"
                        } mr-1`}
                      >
                        {+option.budgetPosts - +option.numberOfEmployees} Vacant
                        {+option.budgetPosts - +option.numberOfEmployees > 1
                          ? "s"
                          : ""}
                      </span>
                      /
                      <span className="badge badge-secondary ml-1">
                        {option.budgetPosts} Budget post
                        {option.budgetPosts > 1 ? "s" : ""}
                      </span>
                    </small>
                    <Divider />
                    <Divider />
                  </div>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Position"
                  name="position"
                  placeholder="Position"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />

            {!loading && !!school && !position && (
              <small className="text-danger">No similar position found</small>
            )}
          </div>

          <div className="mt-3">
            <TextField
              multiline
              rows={2}
              fullWidth
              size="small"
              name="exitComments"
              label="Comments"
              variant="outlined"
              placeholder="Comments"
              value={exitComments}
              onChange={(e) => {
                setExitComments(e.target.value || "");
              }}
            />
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            variant="contained"
            onClick={onSave}
            type="button"
            className="px-5"
            disabled={loading || !position || !exitComments}
          >
            {loading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  unitTypes,
  // units,
  user,
  cells,
  villages,
  schoolLevels,
  sectors,
  schools,
  positions,
}) => {
  return {
    loading,
    onWait,
    unitTypes,
    // units,
    user,
    sectors,
    cells,
    villages,
    schoolLevels,
    schools,
    positions,
  };
};
export default connect(mapStateToProps, {
  moveTeacher,
  getSchools,
  getPositions,
})(MoveStaffDialog);
