export const currencyFormat = (num, currency = "") => {
  return (
    (+num * 1.0).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
    " " +
    currency
  );
};

export const idNumberPattern = [
  /[1-3]/,
  " ",
  /[1-2]/,
  /[0|9]/,
  /\d/,
  /\d/,
  " ",
  /[7-8]/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
];
