import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import REB_Logo from "../../../assets/REB_Logo.png";

import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import DrawerContent from "./DrawerContent";

const Drawer = (props) => {
  const {
    lgDrawerState,
    smDrawerState,

    setLGDrawerState,
    setSMDrawerState,
  } = props;

  const onDrowerItemClicked = () => {
    setSMDrawerState(false);
    setLGDrawerState(true);
  };

  return (
    <React.Fragment>
      <div className="drawer-sm d-block d-lg-none">
        <DrawerContent
          drawerState={smDrawerState}
          setDrawerState={setSMDrawerState}
          onDrowerItemClicked={onDrowerItemClicked}
        />
      </div>
      <div className="drawer-lg d-none d-lg-block">
        <DrawerContent
          drawerState={lgDrawerState}
          setDrawerState={setLGDrawerState}
          onDrowerItemClicked={onDrowerItemClicked}
        />
      </div>
    </React.Fragment>
  );
};

export default Drawer;
