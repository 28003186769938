import React, { useState, useEffect, useCallback } from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { alpha, styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";

import {
  getUnits,
  deleteSchool,
  getShoolPositions,
  getSchoolLevels,
  getSectors,
  deleteSchoolPosition,
  getJobs,
  getQualificationLevels,
  lockDistrict,
  setAsTVETWing,
} from "../../../../store/posts/actions";

import { connect, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import {
  IconButton,
  Tooltip,
  Chip,
  Menu,
  MenuItem,
  Divider,
  Badge,
  Skeleton,
  Button,
} from "@mui/material";
import SearchBox from "../../common/components/SearchBox";
import AppBar from "@mui/material/AppBar";
import { Typography, Box, Toolbar } from "@mui/material";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ReactPaginate from "react-paginate";
import AddOrUpdateSchoolDialog from "../components/AddOrUpdateSchoolDialog";
import arrayToTree from "array-to-tree";
import AddOrUpdatePositionDialog from "../components/AddOrUpdatePositionDialog";
import PositionEmployeesDialog from "../components/PositionBudgetPostsDialog";
import BudgetPostRequestDialog from "../components/BudgetPostRequestDialog";
import AddNewTeacherDialog from "../components/AddNewTeacherDialog";
import { CSVLink } from "react-csv";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    // right: -3,
    // top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const StyledTreeItem = styled((props) => <TreeItem {...props} />)(
  ({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  })
);

const Schools = (props) => {
  useEffect(() => {
    document.title = "TMIS - School management | Schools";
  });

  const {
    loading,
    user,
    units,
    getUnits,
    deleteSchool,
    setAsTVETWing,
    lockDistrict,

    setSearchUnit,

    getShoolPositions,
    schoolPositions,
    selectedUnit,
    getSchoolLevels,
    getSectors,
    deleteSchoolPosition,
    getJobs,
    jobs,
    getQualificationLevels,
  } = props;
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [selectedNode, setSelectedNode] = useState("");
  const [parentUnit, setParentUnit] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [showAddOrUpdateSchoolDialog, setShowAddOrUpdateSchoolDialog] =
    useState(false);

  const [confirmLocking, setConfirmLocking] = useState(false);

  const handleToggleNode = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
  };

  const handleSelectNode = (event, nodeId) => {
    setSelectedNode(nodeId);
  };
  const handleAddUnitDialog = (event, node) => {
    setParentUnit(node);

    setSelectedTreeItem(node);

    setShowAddOrUpdateSchoolDialog(true);
    // setSelectedUnit("");
    setIsEditing(false);
    event.stopPropagation();
  };

  const handleLockDistrict = (event, node) => {
    setSelectedTreeItem(node);
    setConfirmLocking(true);
    event.stopPropagation();
  };

  //handleEditUnitDialog
  const handleEditUnitDialog = (event, node) => {
    // setSelectedUnit(node);
    setSchoolFormData(node);
    setIsEditing(true);
    // setParentUnit("");
    setShowAddOrUpdateSchoolDialog(true);

    event.stopPropagation();
  };

  useEffect(
    () => {
      // if (
      //   !(
      //     !!units.length &&
      //     units.find(({ parentId }) => parentId === null)?.id ===
      //       user?.selectedEntity?.id
      //   )
      // )

      if (user.selectedEntity.role !== "HEAD_TEACHER")
        getUnits(setExpandedNodes);
      // else {
      //   setExpandedNodes([units.find(({ parentId }) => parentId === null).id]);
      // }

      if (!!user.selectedEntity && user.selectedEntity.type === "DISTRICT") {
        getSectors(user.selectedEntity.id);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.selectedEntity, user.selectedAcademicYear]
  );

  useEffect(() => {
    getSchoolLevels();

    getQualificationLevels();
    // if (!jobs.length)
    getJobs();
  }, []);

  const [unitSearchTerm, setUnitSearchTerm] = useState("");
  const [filteredUnitItems, setFilteredUnitItems] = useState([...units]);

  //====UNIT SEARCH====//
  useEffect(() => {
    const filtered = [...units].filter(
      ({ districtName, sectorName, schoolName }) =>
        ((districtName || "") + (sectorName || "") + (schoolName || ""))
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(unitSearchTerm.toLowerCase().replace(/\s/g, ""))
    );
    setFilteredUnitItems(filtered);
  }, [unitSearchTerm, units]);

  const onUnitSearch = (term) => {
    setUnitSearchTerm(term);
  };

  //====POSITION SEARCH====//
  const [positionSearchTerm, setPositionSearchTerm] = useState("");
  const [filteredPositionItems, setFilteredPositionItems] = useState([
    ...schoolPositions,
  ]);

  useEffect(() => {
    const filtered = [...schoolPositions].filter(
      ({ name, schoolName, sectorName }) =>
        ((name || "") + (schoolName || "") + (sectorName || ""))
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(positionSearchTerm.toLowerCase().replace(/\s/g, ""))
    );
    setFilteredPositionItems(filtered);

    paginate(filtered);
  }, [positionSearchTerm, schoolPositions]);

  const onPositionSearch = (term) => {
    setPositionSearchTerm(term);
  };

  const renderTree = (node) => (
    <StyledTreeItem
      style={{ padding: "0" }}
      key={node.id}
      nodeId={node.id}
      label={
        <TreeListItem
          loading={loading}
          user={user}
          // setHoveredNode={setHoveredNode}
          labelClicked={labelClicked}
          setSelectedTreeItem={setSelectedTreeItem}
          deleteSchool={deleteSchool}
          selectedNode={selectedNode}
          setAsTVETWing={setAsTVETWing}
          node={node}
          handleAddUnitDialog={handleAddUnitDialog}
          handleEditUnitDialog={handleEditUnitDialog}
          handleLockDistrict={handleLockDistrict}
          // setSchollToBeRemoved={setSchollToBeRemoved}
          // setConfirmRemoveSchool={setConfirmRemoveSchool}
          // handleUnitAllowanceDialog={handleUnitAllowanceDialog}
          // handleUnitDeductionDialog={handleUnitDeductionDialog}
        />
      }
    >
      {Array.isArray(node.children)
        ? node.children.map((childNode) => renderTree(childNode))
        : null}
    </StyledTreeItem>
  );

  const [selectedTreeItem, setSelectedTreeItem] = useState(null);

  const dispatch = useDispatch();

  const labelClicked = useCallback((event, node) => {
    setSelectedNode(node.id);

    dispatch({
      type: "SET_SELECTED_UNIT",
      data: node,
    });

    setSelectedTreeItem(node);

    if (!!node && user.selectedEntity.role !== "HEAD_TEACHER" && !!node.id)
      getShoolPositions(node.type, node.id);

    event.stopPropagation();

    // console.log(node);
  }, []);

  // useEffect(() => {
  //   if (
  //     !!selectedUnit &&
  //     !loading &&
  //     user.selectedEntity.role !== "HEAD_TEACHER" &&
  //     !!selectedUnit.id
  //   )
  //     getShoolPositions(selectedUnit.type, selectedUnit.id);
  // }, [selectedUnit]);

  useEffect(() => {
    dispatch({
      type: "SET_SCHOOL_POSITIONS",
      data: [],
    });

    if (user.selectedEntity.role === "HEAD_TEACHER")
      getShoolPositions("SCHOOL", user.selectedEntity.id);
  }, []);

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredPositionItems);
  }, [itemOffset, itemsPerPage, filteredPositionItems]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredPositionItems.length || 0;

    setItemOffset(newOffset);
  };

  // useEffect(() => {
  //   console.log(paginatedItems);
  // }, [paginatedItems]);

  const [schoolFormData, setSchoolFormData] = useState({
    id: null,
    name: "",
    schoolLevelId: "",
    schoolCategoryId: 1,
    sectorId: "",
    cellId: "",
    villageId: "",
    sdmsSchoolCode: "",
    ippisUnitId: "",
    // isLikeTTC: false,
  });

  const getTreeData = (data) => {
    const treeData = arrayToTree(data, {
      parentProperty: "parentId",
      childrenProperty: "children",
      customID: "id",
    });
    return treeData;
  };

  const [showPositionForm, setShowPositionForm] = useState(false);

  const [positionFormData, setPositionFormData] = useState({
    id: null,
    schoolId: "",
    positionId: "",
    budgetPosts: 0,
    teachingLevelId: "",
  });

  return (
    <div className="">
      <div className="row mx-0 no-gutters">
        {user.selectedEntity.role !== "HEAD_TEACHER" && (
          <div className={`col-4`}>
            <div className="border rounded">
              <AppBar position="static" elevation={0} className="app-bar w-100">
                <Toolbar
                  style={{
                    minHeight: "43px",
                    borderBottom: "3px solid #e9ecef",
                  }}
                  className=" my-0 py-1"
                >
                  <Typography
                    variant="p"
                    noWrap
                    component="div"
                    className="d-flex ilign-items-center text-uppercase text-primary font-weight-bold"
                  >
                    School management
                  </Typography>

                  <Box sx={{ flexGrow: 1 }} />
                  {user.selectedEntity.id !== "00000000" && (
                    <Box
                      sx={{
                        display: { xs: "flex", md: "flex", width: "150px" },
                      }}
                    >
                      <SearchBox
                        onSearch={onUnitSearch}
                        placeholder="Search…"
                      />
                    </Box>
                  )}
                </Toolbar>
              </AppBar>

              {!!filteredUnitItems.length && (
                <div
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "0.5rem",
                    boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                    MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                    height: "69vh",
                    overflowY: "auto",
                  }}
                >
                  {getTreeData(filteredUnitItems).map((treeItem) => (
                    <TreeView
                      key={treeItem.id}
                      style={{
                        padding: "0.5em 1em",
                      }}
                      defaultCollapseIcon={<MinusSquare />}
                      defaultExpandIcon={<PlusSquare />}
                      defaultEndIcon={<CloseSquare />}
                      expanded={expandedNodes}
                      selected={selectedNode}
                      onNodeToggle={handleToggleNode}
                      onNodeSelect={handleSelectNode}
                    >
                      {renderTree(treeItem)}
                    </TreeView>
                  ))}

                  {showAddOrUpdateSchoolDialog && (
                    <AddOrUpdateSchoolDialog
                      parentUnit={parentUnit}
                      selectedTreeItem={selectedTreeItem}
                      setIsEditing={setIsEditing}
                      showDialog={showAddOrUpdateSchoolDialog}
                      setShowDialog={setShowAddOrUpdateSchoolDialog}
                      expandedNodes={expandedNodes}
                      setExpandedNodes={setExpandedNodes}
                      isEditing={isEditing}
                      formData={schoolFormData}
                      setFormData={setSchoolFormData}
                    />
                  )}

                  {confirmLocking && (
                    <ConfirmationDialog
                      btnMessage={
                        <>
                          {!!selectedTreeItem[
                            `is${user.selectedScope.id}Locked`
                          ] && <>un</>}
                          lock
                        </>
                      }
                      confirmationDialog={confirmLocking}
                      message={
                        <>
                          <strong>Are you sure,</strong> <br />
                          You want to{" "}
                          {!!selectedTreeItem[
                            `is${user.selectedScope.id}Locked`
                          ] && <>un</>}
                          lock {selectedTreeItem.name}?
                        </>
                      }
                      setConfirmationDialog={setConfirmLocking}
                      onYes={() => {
                        lockDistrict(
                          selectedTreeItem,
                          setConfirmLocking,
                          user.selectedScope.id
                        );
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        <div
          className={`${
            user.selectedEntity.role === "HEAD_TEACHER" ? "col-12" : "col-8"
          } `}
        >
          <div className="border rounded">
            <AppBar position="static" elevation={0} className="app-bar w-100">
              <Toolbar
                style={{
                  minHeight: "0px",
                  borderBottom: "3px solid #e9ecef",
                }}
                className=" my-0 py-1"
              >
                <Typography
                  variant="p"
                  noWrap
                  component="div"
                  className="d-flex  align-items-center text-uppercase text-primary font-weight-bold"
                >
                  <span className="text-dark ">
                    ({filteredPositionItems.length}) Positions{" "}
                  </span>
                  <span className="material-icons" style={{ color: "#cecece" }}>
                    chevron_right
                  </span>
                  {/* <DragIndicatorIcon  /> */}
                  <span
                    className="d-block text-truncate"
                    style={{ maxWidth: "70%" }}
                  >
                    {user.selectedEntity.role !== "HEAD_TEACHER" && (
                      <span>
                        {selectedUnit?.name}{" "}
                        {!!selectedUnit?.type &&
                        !(selectedUnit?.name)
                          .toUpperCase()
                          .includes(selectedUnit?.type)
                          ? selectedUnit?.type
                          : ""}
                      </span>
                    )}
                    {user.selectedEntity.role === "HEAD_TEACHER" && (
                      <span>
                        {user.selectedEntity?.name}{" "}
                        {!!user.selectedEntity?.type &&
                        !(user.selectedEntity?.name)
                          .toUpperCase()
                          .includes(user.selectedEntity?.type)
                          ? user.selectedEntity?.type
                          : ""}
                      </span>
                    )}
                  </span>
                  <CSVLink
                    data={schoolPositions}
                    filename={`Positions list - ${
                      selectedUnit?.name || user.selectedEntity?.name
                    }.csv`}
                    className="hidden"
                    id="downloadBTN"
                    target="_blank"
                  />
                  <Tooltip title="Download">
                    <span className="ml-2">
                      <IconButton
                        size="small"
                        disabled={loading || !schoolPositions.length}
                        className=" mr-1"
                        color="primary"
                        onClick={() =>
                          document.getElementById("downloadBTN").click()
                        }
                      >
                        <span className="material-icons">file_download</span>
                      </IconButton>
                    </span>
                  </Tooltip>
                </Typography>

                <Box sx={{ flexGrow: 1 }} />
                {(selectedUnit?.type === "SCHOOL" ||
                  user.selectedEntity.type === "SCHOOL") && (
                  <Button
                    onClick={() => {
                      setShowPositionForm(true);
                    }}
                    variant="contained"
                    size="small"
                    disableElevation
                    className="mr-2"
                    color="primary"
                    style={{
                      borderRadius: "8px",
                      height: "32px",
                      // border: `1px solid rgb(7, 142, 206)`,
                      // color: "#fff",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                      // backgroundColor: "rgb(7, 142, 206)",
                    }}
                  >
                    <span className="material-icons">add</span>
                    New position
                  </Button>
                )}
                <Box
                  sx={{
                    display: { xs: "flex", md: "flex" },
                    width: "150px",
                  }}
                >
                  <SearchBox
                    onSearch={onPositionSearch}
                    placeholder="Search…"
                  />
                </Box>
              </Toolbar>
            </AppBar>

            <div
              style={{
                backgroundColor: "#f3f4f6",
                borderRadius: "0.5rem",
                boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                height: "60vh",
                overflowY: "auto",
              }}
              className="px-3"
            >
              <table className="table table-striped table-hover fixTableHead">
                {!!filteredPositionItems.length && (
                  <thead>
                    <tr style={{ backgroundColor: "#f0f2f5" }}>
                      <th scope="col">#</th>

                      <th scope="col" style={{ width: "128px" }}>
                        PositionId
                      </th>
                      {/* <th scope="col">District</th> */}
                      {selectedUnit.type === "DISTRICT" && (
                        <th scope="col">Sector</th>
                      )}

                      {selectedUnit.type !== "SCHOOL" && (
                        <th scope="col">School</th>
                      )}

                      {/* {selectedUnit.type !== "SCHOOL" && (
                        <th scope="col">School Level</th>
                      )} */}

                      <th scope="col">Position</th>
                      <th scope="col" className="text-left">
                        Teaching Level
                      </th>
                      <th scope="col" className="text-center">
                        Qualification
                      </th>
                      <th scope="col" className="text-center">
                        #Staff
                      </th>
                      <th scope="col" className="text-right">
                        Actions
                      </th>
                    </tr>
                  </thead>
                )}

                {!!paginatedItems && (
                  <tbody>
                    {paginatedItems.map((position) => (
                      <PositionCard
                        user={user}
                        key={position.id}
                        index={getNo(filteredPositionItems, "id", position.id)}
                        position={position}
                        selectedUnit={selectedUnit}
                        setPositionFormData={setPositionFormData}
                        setShowPositionForm={setShowPositionForm}
                        deleteSchoolPosition={deleteSchoolPosition}
                      />
                    ))}
                  </tbody>
                )}
              </table>
              {!filteredPositionItems?.length && (
                <div className="jumbotron jumbotron-fluid text-center">
                  <div className="container py-5 ">
                    <p className="lead">
                      {loading ? "Fetching data..." : "No record found"}{" "}
                    </p>
                  </div>
                </div>
              )}
            </div>
            {/* {loading && <Skeleton variant="rectangular" height={260} />} */}
            {!!filteredPositionItems.length && (
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>
            )}
          </div>

          {showPositionForm && (
            <AddOrUpdatePositionDialog
              // school={selectedTreeItem }
              setIsEditing={setIsEditing}
              showDialog={showPositionForm}
              setShowDialog={setShowPositionForm}
              isEditing={isEditing}
              formData={positionFormData}
              setFormData={setPositionFormData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  user,
  loading,
  units,
  // treeUnits,
  schoolPositions,
  selectedUnit,
  jobs,
}) => {
  return {
    user,
    units,
    // treeUnits,
    loading,
    schoolPositions,
    selectedUnit,
    jobs,
  };
};
export default connect(mapStateToProps, {
  getUnits,
  deleteSchool,
  setAsTVETWing,
  getShoolPositions,
  getSchoolLevels,
  getQualificationLevels,
  getSectors,
  deleteSchoolPosition,
  getJobs,
  lockDistrict,
})(Schools);

const TreeListItem = (props) => {
  const [hoveredNode, setHoveredNode] = useState("");

  const {
    loading,
    user,
    labelClicked,
    setSelectedTreeItem,
    selectedNode,
    node,
    handleEditUnitDialog,
    // setSchollToBeRemoved,
    // setConfirmRemoveSchool,
    // handleUnitAllowanceDialog,
    // handleUnitDeductionDialog,
    handleAddUnitDialog,
    handleLockDistrict,
    deleteSchool,
    setAsTVETWing,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);

    event.stopPropagation();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [confirmRemoveSchool, setConfirmRemoveSchool] = useState(false);
  const [confirmSetAsTVETWing, setConfirmSetAsTVETWing] = useState(false);

  return (
    <>
      <span
        // onMouseOut={() => setHoveredNode("")}
        // onMouseOver={() => setHoveredNode(node.id)}
        onClick={(e) => labelClicked(e, node)}
        className="d-flex justify-content-between  align-items-center"
        style={{ maxWidth: "100%" }}
      >
        <span
          className={`d-block text-truncate ${
            selectedNode !== node.id && "add-padding"
          }`}
          style={{ maxWidth: "60%" }}
        >
          <Tooltip title={node.name}>
            <span
              className="mr-2"
              style={{
                cursor: "default",
              }}
            >
              {node.name}{" "}
            </span>
          </Tooltip>
        </span>

        <span>
          {node.type === "SCHOOL" && (
            <>
              {node.numberOfPositions > 0 && (
                <StyledBadge
                  showZero
                  badgeContent={node.numberOfPositions || 0}
                  color="secondary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {!!node.isWing && (
                    <Tooltip title="TVET WING">
                      <small
                        style={{
                          position: "absolute",
                          left: "-40px",
                        }}
                      >
                        <span className="badge badge-success">WING</span>
                      </small>
                    </Tooltip>
                  )}
                  {node.schoolCategoryId === 2 && (
                    <Tooltip title="Private">
                      <i className="fas fa-shield-alt mr-1 text-primary"></i>
                    </Tooltip>
                  )}
                  <span
                    className="badge badge-secondary disabled text-truncate"
                    style={{
                      maxWidth: "64px",
                    }}
                  >
                    <small> {node.schoolLevelName || "N/A"}</small>
                  </span>
                </StyledBadge>
              )}
              {node.numberOfPositions === 0 && (
                <StyledBadge
                  showZero
                  badgeContent={node.numberOfPositions || 0}
                  color="error"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {node.schoolCategoryId === 2 && (
                    <Tooltip title="Private">
                      <i className="fas fa-shield-alt mr-1 text-primary"></i>
                    </Tooltip>
                  )}

                  <span
                    className="badge badge-secondary disabled text-truncate"
                    style={{
                      maxWidth: "64px",
                    }}
                  >
                    <small> {node.schoolLevelName || "N/A"}</small>
                  </span>
                </StyledBadge>
              )}

              {/* <Badge
              badgeContent={4}
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <span className="badge badge-secondary disabled">
                <small> {node.schoolLevelName || "N/A"}</small>
              </span>
            </Badge> */}
            </>
          )}
          {node.type === "DISTRICT" && (
            <span className="badge badge-primary">
              {(node.children || []).reduce(function (total, childNode) {
                return total + (childNode?.children?.length || 0);
              }, 0)}{" "}
              schools
            </span>
          )}
          {node.type === "SECTOR" && (
            <span className="badge badge-info">
              {node?.children?.length || 0} schools
            </span>
          )}
          <IconButton
            size="small"
            className="ml-2"
            onClick={(e) => {
              if (node.type !== "DISTRICT") handleOpenMenu(e);
              else {
                if (["REB", "RTB"].includes(user.selectedEntity.role))
                  handleLockDistrict(e, node);
                else e.stopPropagation();
              }
            }}
            aria-label="settings"
          >
            <Badge color="info">
              {node.type === "DISTRICT" &&
                !node[`is${user.selectedScope.id}Locked`] && (
                  <span className="material-icons">lock_open</span>
                )}

              {node.type === "DISTRICT" &&
                !!node[`is${user.selectedScope.id}Locked`] && (
                  <span className="material-icons text-danger">lock</span>
                )}

              {node.type === "SECTOR" && (
                <span className="material-icons text-primary">add</span>
              )}
              {node.type === "SCHOOL" && (
                <span className="material-icons">more_vert</span>
              )}
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={(e) => {
              handleCloseMenu();
              e.stopPropagation();
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {node.type === "SECTOR" && (
              <span>
                <MenuItem
                  onClick={(e) => {
                    handleCloseMenu();
                    e.stopPropagation();

                    handleAddUnitDialog(e, node);
                  }}
                  className="text-primary font-weight-bolder"
                >
                  <span className="material-icons  mr-1">add</span>
                  Add new school
                </MenuItem>
              </span>
            )}
            {node.type === "SCHOOL" && (
              <span>
                <MenuItem
                  onClick={(e) => {
                    handleCloseMenu();
                    handleEditUnitDialog(e, node);
                    e.stopPropagation();
                  }}
                  className="text-primary font-weight-bolder"
                >
                  <span className="material-icons  mr-1">edit_note</span>
                  Update
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {node.type === "SCHOOL" && !node.isWing && (
              <span>
                <MenuItem
                  onClick={(e) => {
                    handleCloseMenu();
                    setConfirmSetAsTVETWing(true);
                    e.stopPropagation();
                  }}
                  className="text-primary font-weight-bolder"
                >
                  <span className="material-icons  mr-1">edit_note</span>
                  Set as TVET Wing
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {node.type === "SCHOOL" && (
              <span>
                <MenuItem
                  onClick={(e) => {
                    handleCloseMenu();
                    // setSchollToBeRemoved(node);
                    setConfirmRemoveSchool(true);
                    e.stopPropagation();
                  }}
                  className="text-danger"
                >
                  <span className="material-icons mr-1">
                    remove_circle_outline
                  </span>
                  Remove
                </MenuItem>
              </span>
            )}
          </Menu>
        </span>
      </span>
      {confirmRemoveSchool && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveSchool}
          message={`Are you sure you want to remove this school?`}
          setConfirmationDialog={setConfirmRemoveSchool}
          onYes={() => {
            deleteSchool(node, setConfirmRemoveSchool);
          }}
        />
      )}

      {confirmSetAsTVETWing && (
        <ConfirmationDialog
          confirmationDialog={confirmSetAsTVETWing}
          message={`Are you sure you want to set this school as TVET Wing?`}
          setConfirmationDialog={setConfirmSetAsTVETWing}
          onYes={() => {
            setAsTVETWing(node, setConfirmSetAsTVETWing);
          }}
        />
      )}
    </>
  );
};

const PositionCard = (props) => {
  const {
    user,
    position,
    index,
    selectedUnit,
    setPositionFormData,
    setShowPositionForm,
    deleteSchoolPosition,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [confirmRemovePosition, setConfirmRemovePosition] = useState(false);
  const [showPositionEmployees, setShowPositionEmployees] = useState(false);
  const [showBudgetPostRequestDialog, setShowBudgetPostRequestDialog] =
    useState(false);

  const [showAddNewTeacherDialog, setShowAddNewTeacherDialog] = useState(false);

  return (
    <>
      <tr
        className={`${
          !!position.isHeadTeacher
            ? "bolder"
            : position.numberOfEmployees < position.budgetPosts
            ? "red-bolder"
            : ""
        }`}
      >
        <td>{index + 1}</td>
        <th scope="row" className="text-left">
          {position.id}
        </th>

        {/* <td>{position.districtName}</td> */}
        {selectedUnit?.type === "DISTRICT" && <td>{position.sectorName}</td>}
        {selectedUnit?.type !== "SCHOOL" && (
          <td>
            {position.schoolName}
            <span className="badge badge-secondary ml-1">
              {position.teachingLevelName}
            </span>
          </td>
        )}
        {/* {selectedUnit?.type !== "SCHOOL" && <td>{position.schoolLevelName}</td>} */}
        <td>{position.name}</td>
        <td>{position.teachingLevelName}</td>
        <td className="text-center">{position.qualificationLevelName}</td>
        <td className="text-center">
          <span className="d-block text-center">
            {" "}
            ({position.numberOfEmployees}/{position.budgetPosts})
          </span>

          {!!position.pendingBudgetPostRequest && (
            <span
              className={`badge badge-${
                position.pendingBudgetPostRequestStatusId === 2
                  ? "primary"
                  : position.pendingBudgetPostRequestStatusId === 3
                  ? "success"
                  : "secondary"
              }   text-center`}
            >
              (+{position.pendingBudgetPostRequest}) Requested
            </span>
          )}
        </td>
        <td className="text-right">
          <IconButton
            size="small"
            className=" p-0"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setShowPositionEmployees(true);
                }}
                className="text-primary font-weight-bolder"
              >
                <span className="material-icons mr-1">payment</span>
                Budget Posts
              </MenuItem>
              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setShowAddNewTeacherDialog(true);
                }}
                className=" text-dark "
              >
                <span className="material-icons mr-1">add</span>
                Add Teacher
              </MenuItem>
              <Divider className="my-1" />
            </span>

            {/* <span>
              <MenuItem
                onClick={() => {
                  setShowBudgetPostRequestDialog(true);

                  handleCloseMenu();
                }}
                className="text-dark"
              >
                <span className="material-icons mr-1">add_card</span>
                Post request
              </MenuItem>
              <Divider className="my-1" />
            </span> */}

            {/* {user?.selectedEntity.role !== "HEAD_TEACHER" && ( */}
            <span>
              <MenuItem
                onClick={(e) => {
                  handleCloseMenu();
                  setPositionFormData({
                    id: position.id,
                    schoolId: position.schoolId,
                    positionId: position.positionId,
                    budgetPosts: position.budgetPosts,
                    teachingLevelId: position.teachingLevelId,
                  });

                  setShowPositionForm(true);
                }}
                className="text-dark"
              >
                <span className="material-icons  mr-1">edit_note</span>
                Edit
              </MenuItem>
              <Divider className="my-1" />
            </span>
            {/* )} */}
            {/* {user?.selectedEntity.role !== "HEAD_TEACHER" && ( */}
            <span>
              <MenuItem
                onClick={(e) => {
                  handleCloseMenu();
                  setConfirmRemovePosition(true);
                }}
                className="text-danger"
              >
                <span className="material-icons mr-1">
                  remove_circle_outline
                </span>
                Remove
              </MenuItem>
            </span>
            {/* )} */}
          </Menu>
        </td>
      </tr>

      {confirmRemovePosition && (
        <ConfirmationDialog
          confirmationDialog={confirmRemovePosition}
          message={`Are you sure you want to remove this position?`}
          setConfirmationDialog={setConfirmRemovePosition}
          onYes={() => {
            deleteSchoolPosition(position, setConfirmRemovePosition);
          }}
        />
      )}

      {showPositionEmployees && (
        <PositionEmployeesDialog
          showDialog={showPositionEmployees}
          setShowDialog={setShowPositionEmployees}
          position={position}
        />
      )}

      {/* {showBudgetPostRequestDialog && (
        <BudgetPostRequestDialog
          showDialog={showBudgetPostRequestDialog}
          setShowDialog={setShowBudgetPostRequestDialog}
          position={position}
        />
      )} */}

      {showAddNewTeacherDialog && (
        <AddNewTeacherDialog
          showDialog={showAddNewTeacherDialog}
          setShowDialog={setShowAddNewTeacherDialog}
          schoolPosition={position}
        />
      )}
    </>
  );
};
