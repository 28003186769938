import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import { getTeacherProfile } from "../../../../store/users/actions";

import defaultPofile from "../../../assets/default-profile.jpg";
import moment from "moment";
import { Link } from "react-router-dom";

const PreviewTeacherProfile = (props) => {
  useEffect(() => {
    document.title = "TMIS | Security - TeacherProfile";
  }, []);

  const {
    teacherId,
    loading,
    teacherProfile,
    getTeacherProfile,
    showDialog,
    setShowDialog,
  } = props;

  const onClose = () => setShowDialog(false);

  useEffect(() => {
    getTeacherProfile(teacherId);
  }, []);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary pb-0 mb-0 text-uppercase border">
          <span className="d-flex align-items-center mb-2">
            <Typography className="d-flex align-items-center text-primary">
              <span className="text-dark mr-1">Teacher profile</span>
            </Typography>
          </span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="px-0">
          <div className="row mx-0 mt-3">
            <div className="col-sm-12">
              <React.Fragment>
                <div>
                  <div className="row no-gutters">
                    <div className="col-2 justify-content-center  text-center">
                      <div
                        style={{
                          minHeight: "150px",
                          overflow: "hidden",
                        }}
                      >
                        <>
                          <img
                            className="mt-0 rounded  text-center"
                            src={
                              !!teacherProfile.profilePicture
                                ? "data:image/jpg;base64," +
                                  teacherProfile.profilePicture
                                : defaultPofile
                            }
                            style={{
                              border: "1px solid #a7a7a7",
                              height: "150px",
                              width: "120px",
                              overflow: "hidden",
                              borderRadius: "50%",
                            }}
                          />
                        </>
                      </div>
                      <div className="text-primary">
                        <strong>NID PHOTO</strong>{" "}
                      </div>
                      {/* {!!teacherProfile?.id && (
                        <div className="text-primary">
                          <strong>{teacherProfile?.id}</strong>{" "}
                        </div>
                      )} */}
                      <label className="text-center text-uppercase  mt-n2">
                        <span style={{ fontSize: "12px" }}>
                          {teacherProfile?.lastName} {teacherProfile?.firstName}
                        </span>
                      </label>{" "}
                      <FormControl
                        className="mt-3 d-block border rounded pt-2"
                        style={{ backgroundColor: "#eee" }}
                      >
                        <FormLabel className="text-primary  px-2">
                          Education certficate
                        </FormLabel>
                        <RadioGroup
                          className="mt-0 d-flex justify-content-start px-4"
                          row
                          name="hasEducationCertficate"
                          value={
                            teacherProfile?.hasEducationCertficate || false
                          }
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio disableRipple size="small" />}
                            label="Certfied"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio disableRipple size="small" />}
                            label="Not certfied"
                          />
                        </RadioGroup>
                      </FormControl>
                      <span className="text-uppercase mt-1 mb-2 d-block">
                        <Link to="/in/security/change-password">
                          Change password
                        </Link>
                      </span>
                    </div>

                    <div
                      className="col-10 row mx-0 "
                      style={{
                        height: "73vh",
                        // backgroundColor: "#f3f4f6",
                        overflowY: "auto",
                      }}
                    >
                      <div className="col-4 justify-content-center mt-2">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <>
                                {!teacherProfile.isNIDVerified && !loading && (
                                  <span className="loaderIcon text-danger d-flex align-items-center">
                                    <span className="material-icons text-danger">
                                      report_problem
                                    </span>
                                    <span style={{ fontSize: "10px" }}>
                                      Error
                                    </span>
                                  </span>
                                )}

                                {!!teacherProfile.isNIDVerified && !loading && (
                                  <span className="loaderIcon text-success d-flex align-items-center">
                                    <span className="material-icons text-success ">
                                      verified
                                    </span>
                                    <span style={{ fontSize: "10px" }}>
                                      Verified
                                    </span>
                                  </span>
                                )}
                              </>
                            ),
                          }}
                          fullWidth
                          size="small"
                          autoComplete="false"
                          name="nidNumber"
                          label="NID Number"
                          placeholder="NID Number"
                          variant="outlined"
                          value={teacherProfile.nidNumber || ""}
                        />
                      </div>
                      <div className="col-4 text-left mt-2">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          size="small"
                          autoComplete="false"
                          name="passportNumber"
                          label="Passport number"
                          placeholder="Passport number"
                          variant="outlined"
                          value={teacherProfile.passportNumber || ""}
                        />
                      </div>

                      <div className="col-2 text-left mt-2">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          size="small"
                          name="civilStatus"
                          label="Civil Status"
                          variant="outlined"
                          value={teacherProfile.civilStatus || ""}
                        />
                      </div>
                      <div className="col-2 text-left mt-2">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="gender"
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Gender"
                          variant="outlined"
                          className=" font-weight-bold"
                          value={teacherProfile.gender || ""}
                        />
                      </div>
                      <div className="col-4  mt-3 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="lastName"
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Last Name"
                          variant="outlined"
                          className=""
                          value={teacherProfile.lastName || ""}
                        />
                      </div>
                      <div className="col-4  mt-3  text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="firstName"
                          InputProps={{
                            readOnly: true,
                          }}
                          label="First Name"
                          variant="outlined"
                          className=""
                          value={teacherProfile.firstName || ""}
                        />
                      </div>

                      <div className="col-4  mt-3 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="dateOfBirth"
                          value={
                            !!teacherProfile.dateOfBirth
                              ? moment(teacherProfile.dateOfBirth).format(
                                  "DD/MM/yyyy"
                                )
                              : ""
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Date Of Birth"
                          variant="outlined"
                        />
                      </div>
                      <div className="col-4  mt-3 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="countryOfBirth"
                          value={teacherProfile.countryOfBirth || ""}
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Country of Birth"
                          variant="outlined"
                          className=""
                        />
                      </div>
                      <div className="col-4  mt-3 text-left">
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="placeOfBirth"
                          value={teacherProfile.placeOfBirth || ""}
                          label="Place of Birth"
                          variant="outlined"
                          className=""
                        />
                      </div>

                      <div className="col-4  mt-3 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="nationality"
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Nationality"
                          placeholder="Nationality"
                          variant="outlined"
                          className=" font-weight-bold"
                          value={teacherProfile.nationalityId || ""}
                        />
                      </div>

                      <div className="col-6 mt-3 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="spouseNames"
                          value={teacherProfile?.spouseNames || "N/A"}
                          label="Your spouse names"
                          placeholder="Your spouse names"
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </div>

                      <div className="col-3 mt-3 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="employeeId"
                          value={teacherProfile?.id || ""}
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Employee ID"
                          placeholder="Employee ID"
                          variant="outlined"
                        />
                      </div>

                      <div className="col-3 mt-3 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="rssbNumber"
                          value={teacherProfile?.rssbNumber || ""}
                          InputProps={{
                            readOnly: true,
                          }}
                          label="RSSB Number"
                          placeholder="RSSB Number"
                          variant="outlined"
                          className=""
                        />
                      </div>

                      <div className="col-6 mt-3 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="bankAccount"
                          value={
                            !teacherProfile.accountNumber
                              ? ""
                              : teacherProfile.accountNumber +
                                " (" +
                                teacherProfile.bankName +
                                ")"
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Bank Account"
                          placeholder="Bank Account"
                          variant="outlined"
                        />
                      </div>

                      <div className="col-6 mt-3 ">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="rssbNumber"
                          value={teacherProfile?.positionName || ""}
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Position"
                          placeholder="Position"
                          variant="outlined"
                          className=""
                        />
                      </div>

                      <div className="col-3 mt-3 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="teachingLevel"
                          value={teacherProfile?.teachingLevelName || ""}
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Teaching Level"
                          placeholder="Teaching Level"
                          variant="outlined"
                        />
                      </div>

                      <div className="col-3 mt-3 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="qualificationLevel"
                          value={teacherProfile?.qualificationLevelName || ""}
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Qualification"
                          placeholder="Qualification"
                          variant="outlined"
                        />
                      </div>

                      <div className="col-6 mt-3 ">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="appointmentDate"
                          value={
                            !!teacherProfile.appointmentDate
                              ? moment(teacherProfile.appointmentDate).format(
                                  "DD/MM/yyyy"
                                )
                              : ""
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Position appointment date"
                          variant="outlined"
                        />
                      </div>

                      <div className="col-4  mt-3 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          size="small"
                          autoComplete="false"
                          name="phone"
                          label="Phone number"
                          placeholder="Phone number"
                          variant="outlined"
                          value={teacherProfile.phoneNumber || ""}
                        />
                      </div>

                      <div className="col-4  mt-3 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          size="small"
                          autoComplete="false"
                          name="email"
                          label="Personal email"
                          placeholder="Personal email"
                          variant="outlined"
                          value={teacherProfile.email || ""}
                        />
                      </div>

                      <div className="mt-3 col-4">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          size="small"
                          autoComplete="false"
                          name="employmentTypeName"
                          label="Employment Type"
                          placeholder="Employment Type"
                          variant="outlined"
                          value={teacherProfile.employmentTypeName || ""}
                        />
                      </div>

                      <div className="mt-3 col-4 mb-3">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          size="small"
                          autoComplete="false"
                          name="degreeName"
                          label="Your degree"
                          placeholder="Your degree"
                          variant="outlined"
                          value={teacherProfile.degreeName || ""}
                        />
                      </div>
                      <div className="mt-3 col-4 mb-3">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          size="small"
                          autoComplete="false"
                          name="specialization"
                          label="Specialization"
                          placeholder="Specialization"
                          variant="outlined"
                          value={teacherProfile.specialization || ""}
                        />
                      </div>
                      <div className="mt-3 col-4 mb-3">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="graduationDate"
                          value={
                            !!teacherProfile.graduationDate
                              ? moment(teacherProfile.graduationDate).format(
                                  "DD/MM/yyyy"
                                )
                              : ""
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Graduation date"
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  placementReportDetails,
  changeList,
  prevPlacementReport,
  signatories,
  roles,
  districts,
  sectors,
  degrees,
  specializations,
  schoolPositions,
  teacherProfile,
}) => {
  return {
    user,
    loading,
    onWait,
    placementReportDetails,
    changeList,
    prevPlacementReport,
    signatories,
    roles,
    districts,
    sectors,
    degrees,
    specializations,
    schoolPositions,
    teacherProfile,
  };
};
export default connect(mapStateToProps, {
  getTeacherProfile,
})(PreviewTeacherProfile);
