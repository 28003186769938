import { combineReducers } from "redux";

import commonReducers from "./common/reducers";
import postsReducers from "./posts/reducers";
import transferReducers from "./transfer/reducers";
import placementReducers from "./placement/reducers";
import usersReducers from "./users/reducers";
import cpdReducers from "./cpd/reducers";
import maternityLeaveReducers from "./maternity-leave/reducers";
import scholarship from "./scholarship/reducers";

export default combineReducers({
  //---- COMMON----//
  ...commonReducers,
  //----POSTS----//
  ...postsReducers,
  //----TRANSFERS----//
  ...transferReducers,

  ...placementReducers,

  ...usersReducers,

  ...cpdReducers,

  ...maternityLeaveReducers,

  ...scholarship,
});
