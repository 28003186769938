import React, { useState } from "react";

import { Button } from "@mui/material";
import Login from "./Login";

const Home = () => {
  const [showLoginDialog, setShowLoginDialog] = useState(false);

  return (
    <React.Fragment>
      <div className="container-fluid px-0">
        <div
          className="jumbotron jumbotron-fluid text-center mb-2"
          style={{ borderRadius: "0px", backgroundColor: "#015198" }}
        >
          <div className="container text-light">
            <div className=" ">
              <h1 className="h1"> Welcome to TMIS</h1>
              <p style={{ fontSize: "1.25rem" }}>
                {" "}
                Teacher Management Information System
              </p>
              <p>
                <Button
                  color="warning"
                  variant="contained"
                  className="px-4"
                  onClick={() => {
                    setShowLoginDialog(true);
                    //history.push("/in/posts");
                  }}
                >
                  Login
                </Button>
              </p>
            </div>
          </div>
        </div>

        <div className="row mb-2 justify-content-center px-3 mx-0">
          <div
            className="col-12 col-md-3 p-3 m-2  rounded border border-warning"
            style={{ backgroundColor: "#fff" }}
          >
            <span
              className="p-2 d-inline-flex"
              style={{ backgroundColor: "#fddebc" }}
            >
              <span
                className="material-icons"
                style={{ fontSize: "42px", color: "#b76103" }}
              >
                schedule_send
              </span>
            </span>
            <h2
              style={{ color: "#b76103", fontSize: "20px", fontWeight: "700" }}
            >
              Post Request
            </h2>
            <p>
              Head Teachers have the options to request a number of posts based
              on the positions they have at their schools. The DDEs have the
              interface that shows all the requested posts from schools before
              they are sent to REB. Then REB get the total number of post
              requested from all districts grouped by qualifications.
            </p>
          </div>
          <div
            className="col-12 col-md-3 p-3 m-2  rounded border border-warning"
            style={{ backgroundColor: "#fff" }}
          >
            <span
              className="p-2 d-inline-flex"
              style={{ backgroundColor: "#fddebc" }}
            >
              <span
                className="material-icons"
                style={{ fontSize: "42px", color: "#b76103" }}
              >
                sort
              </span>
            </span>

            <h2
              style={{ color: "#b76103", fontSize: "20px", fontWeight: "700" }}
            >
              Post distribution
            </h2>
            <p>
              Posts are distributed to districts by REB based on the guidelines
              of MINECOFIN. Then DDEs also distribute the received post limits
              to their schools based on their analysis and need by type of
              position and by school.
            </p>
          </div>
          <div
            className="col-12 col-md-3 p-3 m-2  rounded border border-warning"
            style={{ backgroundColor: "#fff" }}
          >
            <span
              className="p-2 d-inline-flex"
              style={{ backgroundColor: "#fddebc" }}
            >
              <span
                className="material-icons"
                style={{ fontSize: "42px", color: "#b76103" }}
              >
                location_on
              </span>
            </span>
            <h2
              style={{ color: "#b76103", fontSize: "20px", fontWeight: "700" }}
            >
              Teacher placement
            </h2>
            <p>
              Teachers are placed to the vacant posts. No teacher can be placed
              if there are no vacant posts. The teacher placement list are
              generated on monthly basis and be kept in the system for future
              references.
            </p>
          </div>
        </div>
      </div>

      {showLoginDialog && (
        <Login
          showLoginDialog={showLoginDialog}
          setShowLoginDialog={setShowLoginDialog}
        />
      )}
    </React.Fragment>
  );
};

export default Home;
