import React, { useState, useEffect } from "react";
// import { MuiOtpInput } from "mui-one-time-password-input";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import TransferRequestDialog from "../components/TransferRequestDialog";
import {
  getPermutationRequests,
  acceptedPermutation,
  deletePermutationRequest,
  downloadPermutationLetter,
  getPermutationSchedule,
} from "../../../../store/transfer/actions";
import ReactPaginate from "react-paginate";
import moment from "moment";
import PermutationRequestDialog from "../components/PermutationRequestDialog";
import { showError } from "../../../toastify";
import axios from "axios";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import PermutationAcceptionDialog from "../components/PermutationAcceptionDialog";
import PermutationPreviewDialog from "../components/PermutationPreviewDialog";
import PermutationSummaryDialog from "../components/PermutationSummaryDialog";
import AlertDialog from "../../common/components/AlertDialog";
import PermutationScheduleDialog from "../components/PermutationScheduleDialog";
// import { MuiOtpInput } from "mui-one-time-password-input";

function calculateTimeLeft(permutationSchedule) {
  const year = new Date().getFullYear();
  const deadLine = !!permutationSchedule
    ? new Date(moment(permutationSchedule.endOn).format("yyyy-MM-DD"))
    : new Date(moment().format("yyyy-MM-DD"));

  deadLine.setDate(deadLine.getDate() + 1);
  deadLine.setHours(deadLine.getHours() - 1);

  const toDay = new Date();

  const difference = +deadLine - +toDay;

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
}

const Permutations = (props) => {
  useEffect(() => {
    document.title = "TMIS | Permutations - Requests";
  }, []);

  const {
    user,
    loading,
    permutationRequests,
    getPermutationRequests,
    acceptedPermutation,
    deletePermutationRequest,
    downloadPermutationLetter,
    permutationSchedule,
    getPermutationSchedule,
  } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRecods, setTotalRecods] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);

  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );

  // const filterRequests = () => {
  //   const temp1 = permutationRequests.filter(({ statusId }) => statusId !== 1);
  //   const temp2 = permutationRequests.filter(({ statusId }) => statusId === 1);

  //   return [...temp1, ...temp2];
  // };

  // const [otp, setOtp] = useState("");

  // const handleChange = (newValue) => {
  //   setOtp(newValue);
  // };

  useEffect(() => {
    getPermutationSchedule();

    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
    };
    getPermutationRequests(query, setTotalRecods, setLoader);
  }, [user.selectedEntity, user.selectedAcademicYear]);

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };
      getPermutationRequests(query, setTotalRecods, setLoader);
    }
  };

  // //PAGINATION
  // const [paginatedItems, setPaginatedItems] = useState(null);
  // const [pageCount, setPageCount] = useState(0);

  // const [itemOffset, setItemOffset] = useState(0);

  // const itemsPerPage = 20;

  // const paginate = (items) => {
  //   const endOffset = itemOffset + itemsPerPage;
  //   setPaginatedItems(items.slice(itemOffset, endOffset));
  //   setPageCount(Math.ceil(items.length / itemsPerPage));
  // };

  // useEffect(() => {
  //   paginate(filterRequests());
  // }, [itemOffset, itemsPerPage, permutationRequests]);

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };

    getPermutationRequests(query, setTotalRecods, setLoader);
  };

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  const [showPermutationRequestDialog, setShowPermutationRequestDialog] =
    useState(false);

  const [showPermutationSummary, setShowPermutationSummary] = useState(false);

  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(permutationSchedule)
  );

  useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(permutationSchedule));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  const timerComponents = Object.keys(timeLeft).map((interval, index) => {
    if (!timeLeft[interval]) {
      <span key={index}>00 {interval} </span>;
    }

    return (
      <span key={index}>
        {timeLeft[interval].toString().padStart(2, "0")} {interval}{" "}
      </span>
    );
  });

  const [showScheduleDialog, setShowScheduleDialog] = useState(false);

  return (
    <>
      <div className="px-4 pt-2">
        <div className="border rounded">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar
              style={{
                minHeight: "0px",
                borderBottom: "3px solid #e9ecef",
              }}
              className=" my-0 py-1 bg-light"
            >
              <Typography
                variant="p"
                noWrap
                component="div"
                className="d-flex  align-items-center text-uppercase text-primary font-weight-bold"
              >
                <span className="text-dark">Teacher Permutations</span>

                {user?.selectedEntity.role === "TEACHER" && (
                  <Button
                    className="ml-2"
                    disabled={
                      loading ||
                      !(
                        !!permutationSchedule &&
                        moment(permutationSchedule.startOn).format(
                          "yyyy-MM-DD"
                        ) <= moment().format("yyyy-MM-DD") &&
                        moment(permutationSchedule.endOn).format(
                          "yyyy-MM-DD"
                        ) >= moment().format("yyyy-MM-DD")
                      )
                    }
                    onClick={() => {
                      setShowPermutationRequestDialog(true);
                    }}
                    variant="contained"
                    size="small"
                    disableElevation
                    color="primary"
                    style={{
                      borderRadius: "8px",
                      height: "32px",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                    }}
                  >
                    <span className="material-icons">add</span>
                    New Request
                  </Button>
                )}

                {!!permutationSchedule &&
                  moment(permutationSchedule.startOn).format("yyyy-MM-DD") <=
                    moment().format("yyyy-MM-DD") &&
                  moment(permutationSchedule.endOn).format("yyyy-MM-DD") >=
                    moment().format("yyyy-MM-DD") && (
                    <span
                      className="font-weight-bold ml-2"
                      style={{ color: "#ed6c02" }}
                    >
                      {timerComponents.length ? (
                        timerComponents
                      ) : (
                        <span
                          className={`badge badge-danger ml-2`}
                          style={{ cursor: "default" }}
                        >
                          <i className="fas fa-lock mr-1"></i> CLOSED
                        </span>
                      )}
                    </span>
                  )}

                {!(
                  !!permutationSchedule &&
                  moment(permutationSchedule.startOn).format("yyyy-MM-DD") <=
                    moment().format("yyyy-MM-DD") &&
                  moment(permutationSchedule.endOn).format("yyyy-MM-DD") >=
                    moment().format("yyyy-MM-DD")
                ) && (
                  <span
                    className={`badge badge-danger ml-2`}
                    style={{ cursor: "default" }}
                  >
                    <span>
                      {!permutationSchedule ||
                      (!!permutationSchedule &&
                        moment(permutationSchedule.endOn).format("yyyy-MM-DD") <
                          moment().format("yyyy-MM-DD")) ? (
                        <i className="fas fa-lock mr-1"></i>
                      ) : (
                        <i className="fas fa-clock mr-1"></i>
                      )}

                      {!permutationSchedule ||
                      (!!permutationSchedule &&
                        moment(permutationSchedule.endOn).format("yyyy-MM-DD") <
                          moment().format("yyyy-MM-DD"))
                        ? "CLOSED"
                        : `Will start on ${moment(
                            permutationSchedule.startOn
                          ).format("ll")}`}
                    </span>
                  </span>
                )}

                {["REB", "RTB"].includes(user.selectedEntity.role) && (
                  <span
                    size="small"
                    variant="text"
                    className="ml-2 btn btn-sm btn-outline-primary py-0"
                    onClick={() => setShowScheduleDialog(true)}
                  >
                    Schedule
                  </span>
                )}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />

              {["REB", "DG_REB", "HOD_TDM", "RTB", "DG_RTB", "HOD_TM"].includes(
                user.selectedEntity.role
              ) && (
                <>
                  {!!permutationSchedule?.published && (
                    <span className="d-inline-flex align-items-center mr-2 text-success badge badge-pill">
                      <span className="material-icons ">task_alt</span>
                      Published
                    </span>
                  )}
                  <Button
                    className="mr-2"
                    disabled={
                      loading ||
                      !!showPermutationSummary ||
                      !permutationRequests.length
                    }
                    onClick={() => {
                      setShowPermutationSummary(true);
                    }}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    <span className="material-icons">summarize</span>
                    Summary
                  </Button>
                </>
              )}

              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox
                  onSearch={onSearch}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      const query = {
                        searchTerm: searchTerm,
                        page: currentPage,
                        size: itemPerPage,
                      };

                      getPermutationRequests(query, setTotalRecods, setLoader);
                    }
                  }}
                  placeholder="Search…"
                />
              </Box>
            </Toolbar>
          </AppBar>
          <div
            style={{
              backgroundColor: "#f3f4f6",
              borderRadius: "0.5rem",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              height: "59vh",
              overflowY: "auto",
            }}
            className="px-3"
          >
            {/* <MuiOtpInput value={otp} onChange={handleChange} /> */}

            {!!permutationRequests.length && (
              <table className="table table-striped table-hover   fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>

                    <th scope="col" className="text-left">
                      RequestedBy
                    </th>
                    <th scope="col" className="text-left">
                      PermutationWith
                    </th>

                    {/* <th scope="col" className="text-left">
                      SchoolsOfInterest
                    </th> */}
                    {/* <th scope="col" className="text-left">
                      Position
                    </th> */}

                    <th scope="col" className="text-left">
                      RequestedOn
                    </th>

                    <th scope="col" className="text-center">
                      Status
                    </th>
                    <th scope="col" className="text-right"></th>
                  </tr>
                </thead>

                {!!permutationRequests && (
                  <tbody>
                    {permutationRequests.map((request) => (
                      <PermutationRequestCard
                        key={request.id}
                        // index={getNo(permutationRequests, "id", request.id)}
                        request={request}
                        user={user}
                        acceptedPermutation={acceptedPermutation}
                        deletePermutationRequest={deletePermutationRequest}
                        loading={loading}
                        downloadPermutationLetter={downloadPermutationLetter}
                        permutationSchedule={permutationSchedule}
                      />
                    ))}
                  </tbody>
                )}
              </table>
            )}

            {!permutationRequests?.length && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container py-5 ">
                  <p className="lead">
                    {loading ? "Fetching data..." : "No request made"}{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="d-flex justify-content-center  align-items-center"
            style={{ overflow: "hidden" }}
          >
            <>
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalRecods / itemPerPage)}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>

              {!!permutationRequests.length && (
                <select
                  className="custom-select custom-select-sm mr-3 ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                    };
                    getPermutationRequests(query, setTotalRecods, setLoader);
                  }}
                >
                  {[10, 50, 100, 150].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              )}
            </>
          </div>
        </div>
      </div>

      {loader && (
        <CircularProgress
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "rgb(7, 142, 206)",
            position: "absolute",
            top: "42%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
          size={40}
          thickness={4}
        />
      )}

      {showPermutationRequestDialog && (
        <PermutationRequestDialog
          showDialog={showPermutationRequestDialog}
          setShowDialog={setShowPermutationRequestDialog}
        />
      )}

      {showPermutationSummary && (
        <PermutationSummaryDialog
          showDialog={showPermutationSummary}
          setShowDialog={setShowPermutationSummary}
        />
      )}

      {showScheduleDialog && (
        <PermutationScheduleDialog
          permutationSchedule={permutationSchedule}
          showDialog={showScheduleDialog}
          setShowDialog={setShowScheduleDialog}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  permutationRequests,
  permutationSchedule,
}) => {
  return { user, loading, permutationRequests, permutationSchedule };
};
export default connect(mapStateToProps, {
  getPermutationRequests,
  acceptedPermutation,
  deletePermutationRequest,
  downloadPermutationLetter,
  getPermutationSchedule,
})(Permutations);

const PermutationRequestCard = (props) => {
  const {
    user,
    request,
    index,
    acceptedPermutation,
    deletePermutationRequest,
    loading,
    downloadPermutationLetter,
    permutationSchedule,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showPermutationRequestDialog, setShowPermutationRequestDialog] =
    useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const [teacherProfile, setTeacherProfile] = useState({});

  const getTeacherProfile = async (teacherId) => {
    try {
      setTeacherProfile({});

      setShowLoader(true);
      const { data } = await axios.get(
        "/api/posts/teacher-profile/" + teacherId
      );

      setShowLoader(false);

      setShowPermutationRequestDialog(true);

      setTeacherProfile(data);
    } catch (error) {
      setShowLoader(false);
      showError(error);
    }
  };

  const [teacherTwoMessage, setTeacherTwoMessage] = useState("");
  const [rebRejectionMessage, setREBRejectionMessage] = useState("");

  const [accepted, setAccepted] = useState(null);
  const [isReb, setIsReb] = useState(false);
  const [onTeacherConfirm, setOnTeacherConfirm] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [showTeacherOne, setShowTeacherOne] = useState(false);
  const [showTeacherTwo, setShowTeacherTwo] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  return (
    <>
      <tr className={``}>
        <th scope="row" className="text-left">
          {request.no}
        </th>

        <td className="text-left">
          <button
            onClick={() => setShowTeacherOne(true)}
            className="btn btn-link btn-sm px-0 text-uppercase text-left"
          >
            {request.teacherOneId === user.id
              ? "Me"
              : request.teacherOneLastName + " " + request.teacherOneFirstName}
          </button>
          {(request.statusId === 4 || request.statusId === 6) &&
            !!request.isApproved &&
            request.teacherTwoId !== user.id && (
              <IconButton
                color="success"
                size="small"
                onClick={() => {
                  downloadPermutationLetter(
                    request.teacherOneId +
                      "-" +
                      request.id.toString().padStart(10, "0")
                  );
                }}
              >
                <i className="fas fa-download"></i>
              </IconButton>
            )}
          <div>
            {request.positionName} ({request.qualificationLevelName})
          </div>
          <div className="text-left text-primary font-weight-bold">
            @{request.teacherOneSchoolName}{" "}
            <i className="fas fa-chevron-right"></i>{" "}
            {request.teacherOneSectorName} / {request.teacherOneDistrictName}
          </div>
        </td>

        <td className="text-left">
          <button
            onClick={() => setShowTeacherTwo(true)}
            className="btn btn-link btn-sm px-0 text-uppercase text-left"
          >
            {request.teacherTwoId === user.id
              ? "Me"
              : request.teacherTwoLastName + " " + request.teacherTwoFirstName}
          </button>
          {(request.statusId === 4 || request.statusId === 6) &&
            !!request.isApproved &&
            request.teacherOneId !== user.id && (
              <IconButton
                color="success"
                size="small"
                onClick={() => {
                  downloadPermutationLetter(
                    request.teacherTwoId +
                      "-" +
                      request.id.toString().padStart(10, "0")
                  );
                }}
              >
                <i className="fas fa-download"></i>
              </IconButton>
            )}
          <div>
            {request.positionName} ({request.qualificationLevelName})
          </div>
          <div className="text-left text-primary font-weight-bold">
            @{request.teacherTwoSchoolName}{" "}
            <i className="fas fa-chevron-right"></i>{" "}
            {request.teacherTwoSectorName} / {request.teacherTwoDistrictName}
          </div>
        </td>

        <td className="text-left">
          {moment(request.createdOn).format("yyyy-MM-DD HH:mm:ss")}
        </td>

        <td className="text-center">
          {request.isREB ? (
            <span>
              <span
                className={`badge badge-${
                  request.statusId === 1
                    ? "secondary"
                    : request.statusId === 2
                    ? "primary"
                    : request.statusId === 4 && !request.isApproved
                    ? "info"
                    : (request.statusId === 5 || request.statusId === 3) &&
                      !request.isApproved
                    ? "warning"
                    : (request.statusId === 4 && !!request.isApproved) ||
                      request.statusId === 6
                    ? "success"
                    : "danger"
                } `}
              >
                {!request.isApproved && request.statusId === 4 ? (
                  <>Temp Accepted</>
                ) : !request.isApproved && request.statusId === 5 ? (
                  <>Temp Rejected</>
                ) : (
                  <>{request.statusName}</>
                )}
              </span>
            </span>
          ) : (
            <span>
              <span
                className={`badge badge-${
                  request.statusId === 1
                    ? "secondary"
                    : request.statusId === 2
                    ? "primary"
                    : request.statusId === 4 || request.statusId === 6
                    ? "success"
                    : "danger"
                } `}
              >
                {request.statusName}
              </span>
            </span>
          )}
        </td>
        <td className="text-right py-1">
          {request.statusId === 1 && request.teacherOneId === user.id && (
            <IconButton
              disabled={
                !(
                  !!permutationSchedule &&
                  moment(permutationSchedule.startOn).format("yyyy-MM-DD") <=
                    moment().format("yyyy-MM-DD") &&
                  moment(permutationSchedule.endOn).format("yyyy-MM-DD") >=
                    moment().format("yyyy-MM-DD")
                )
              }
              color="error"
              size="small"
              onClick={() => {
                setConfirmDelete(true);
              }}
            >
              <span className="material-icons">delete_forever</span>
            </IconButton>
          )}

          {request.teacherTwoId === user.id && request.statusId === 1 && (
            <Button
              disabled={showLoader}
              onClick={() => {
                setTeacherTwoMessage("");
                setAccepted(true);
                setIsReb(false);
                setOnTeacherConfirm(true);
              }}
              variant="contained"
              size="small"
              disableElevation
              color="success"
              style={{
                borderRadius: "8px",
                height: "24px",
                width: "96px",
                alignItems: "center",
                fontSize: ".75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "initial",
              }}
            >
              <span className="material-icons mr-1">done_all</span>
              Accept
            </Button>
          )}
          {request.teacherTwoId === user.id && request.statusId === 1 && (
            <Button
              className="ml-2"
              disabled={showLoader}
              onClick={() => {
                setTeacherTwoMessage("");
                setAccepted(false);
                setIsReb(false);
                setOnTeacherConfirm(true);
              }}
              variant="contained"
              size="small"
              disableElevation
              color="error"
              style={{
                borderRadius: "8px",
                height: "24px",
                width: "96px",

                alignItems: "center",
                fontSize: ".75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "initial",
              }}
            >
              <span className="material-icons mr-1">highlight_off</span>
              Reject
            </Button>
          )}

          {(request.statusId === 3 || request.statusId === 5) && (
            <IconButton
              color="info"
              size="small"
              className="p-0"
              onClick={() => {
                setShowMessage(true);
              }}
            >
              <span className="material-icons">info</span>
            </IconButton>
          )}

          {(request.statusId === 4 || request.statusId === 5) &&
            ["REB", "RTB"].includes(user.selectedEntity.role) &&
            !request.isApproved && (
              <IconButton
                color="inherit"
                size="small"
                className="p-0 text-warning"
                onClick={() => {
                  setTeacherTwoMessage("");
                  setAccepted(null);
                  setIsReb(true);
                  setOnTeacherConfirm(true);
                }}
              >
                <span className="material-icons">cancel</span>
              </IconButton>
            )}

          {request.statusId === 2 &&
            ["REB", "RTB"].includes(user.selectedEntity.role) &&
            !request.isApproved && (
              <Button
                disabled={loading}
                onClick={() => {
                  setTeacherTwoMessage("");
                  setAccepted(true);
                  setIsReb(true);
                  setOnTeacherConfirm(true);
                }}
                variant="contained"
                size="small"
                disableElevation
                color="primary"
                style={{
                  borderRadius: "8px",
                  height: "24px",
                  width: "96px",
                  alignItems: "center",
                  fontSize: ".75rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "initial",
                }}
              >
                <span className="material-icons mr-1">done_all</span>
                Accept
              </Button>
            )}

          {request.statusId === 2 &&
            ["REB", "RTB"].includes(user.selectedEntity.role) &&
            !request.isApproved && (
              <Button
                className="ml-2"
                disabled={loading}
                onClick={() => {
                  setTeacherTwoMessage("");
                  setAccepted(false);
                  setIsReb(true);
                  setOnTeacherConfirm(true);
                  // acceptedPermutation(
                  //   { requestId: request.id, accepted: false, isReb: true },
                  //   () => {}
                  // );
                }}
                variant="contained"
                size="small"
                disableElevation
                color="error"
                style={{
                  borderRadius: "8px",
                  height: "24px",
                  width: "96px",

                  alignItems: "center",
                  fontSize: ".75rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "initial",
                }}
              >
                <span className="material-icons mr-1">highlight_off</span>
                Reject
              </Button>
            )}

          {/* <Button
            disabled={showLoader}
            onClick={() => {
              getTeacherProfile(request.teacherId);
            }}
            variant="contained"
            size="small"
            disableElevation
            color="primary"
            style={{
              borderRadius: "8px",
              height: "24px",

              alignItems: "center",
              fontSize: ".75rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "initial",
            }}
          >
            <span className="material-icons mr-1">open_in_new</span>
            Open
          </Button> */}
        </td>
      </tr>

      {showMessage && (
        <AlertDialog
          maxWidth="xs"
          showAlert={showMessage}
          setShowAlert={setShowMessage}
        >
          <TextField
            InputProps={{
              readOnly: true,
            }}
            className="mt-3"
            multiline
            rows={5}
            autoFocus
            fullWidth
            type="text"
            size="small"
            name="reason"
            label="Reason for rejection"
            variant="outlined"
            placeholder="Reason for rejection"
            value={
              request.rebRejectionMessage || request.teacherTwoMessage || ""
            }
          />
        </AlertDialog>
      )}

      {!!showTeacherOne && (
        <PermutationPreviewDialog
          request={request}
          showDialog={showTeacherOne}
          setShowDialog={setShowTeacherOne}
          n={1}
        />
      )}

      {!!showTeacherTwo && (
        <PermutationPreviewDialog
          request={request}
          showDialog={showTeacherTwo}
          setShowDialog={setShowTeacherTwo}
          n={2}
        />
      )}

      {onTeacherConfirm && (
        <>
          {!!accepted && !isReb && (
            <PermutationAcceptionDialog
              request={request}
              showDialog={onTeacherConfirm}
              setShowDialog={setOnTeacherConfirm}
            />
          )}
          {!accepted && (
            <ConfirmationDialog
              disabled={
                (!teacherTwoMessage && !isReb) ||
                (!rebRejectionMessage && accepted !== null)
              }
              confirmationDialog={onTeacherConfirm}
              message={
                <>
                  <span>
                    Are you sure you want to{" "}
                    {accepted !== null && (
                      <>
                        <strong
                          className={`text-${accepted ? "success" : "danger"}`}
                        >
                          {accepted ? "accept" : "reject"}
                        </strong>{" "}
                        this permutation request?
                      </>
                    )}
                    {accepted === null && (
                      <>
                        <strong className="text-danger">cancel</strong> the
                        decision?
                      </>
                    )}
                  </span>

                  {!!isReb && accepted !== null && (
                    <TextField
                      className="mt-3"
                      multiline
                      rows={3}
                      autoFocus
                      fullWidth
                      type="text"
                      size="small"
                      name="reason"
                      label="Reason for rejection *"
                      variant="outlined"
                      placeholder="Reason for rejection *"
                      value={rebRejectionMessage}
                      onChange={(e) => {
                        setREBRejectionMessage(e.target.value || "");
                      }}
                    />
                  )}

                  {!isReb && (
                    <>
                      <TextField
                        className="mt-3"
                        // multiline
                        // rows={3}
                        autoFocus
                        fullWidth
                        type="text"
                        size="small"
                        name="reason"
                        label="Enter your confirmation code *"
                        variant="outlined"
                        placeholder="Enter your confirmation code *"
                        value={teacherTwoMessage}
                        onChange={(e) => {
                          setTeacherTwoMessage(e.target.value || "");
                        }}
                      />

                      <TextField
                        className="mt-3"
                        multiline
                        rows={3}
                        autoFocus
                        fullWidth
                        type="text"
                        size="small"
                        name="reason"
                        label="Reason for rejection *"
                        variant="outlined"
                        placeholder="Reason for rejection *"
                        value={rebRejectionMessage}
                        onChange={(e) => {
                          setREBRejectionMessage(e.target.value || "");
                        }}
                      />
                    </>
                  )}
                </>
              }
              setConfirmationDialog={setOnTeacherConfirm}
              onYes={() => {
                acceptedPermutation(
                  {
                    requestId: request.id,
                    accepted,
                    teacherTwoMessage,
                    isReb,
                    rebRejectionMessage,
                  },
                  setOnTeacherConfirm
                );
              }}
            />
          )}

          {!!accepted && !!isReb && (
            <ConfirmationDialog
              confirmationDialog={onTeacherConfirm}
              message={
                <>
                  <span>
                    Are you sure you want to{" "}
                    {accepted !== null && (
                      <>
                        <strong className={`text-success`}>accept</strong> this
                        permutation request?
                      </>
                    )}
                    {accepted === null && (
                      <>
                        <strong className="text-danger">cancel</strong> the
                        decision?
                      </>
                    )}
                  </span>
                </>
              }
              setConfirmationDialog={setOnTeacherConfirm}
              onYes={() => {
                acceptedPermutation(
                  {
                    requestId: request.id,
                    accepted,
                    teacherTwoMessage,
                    isReb,
                    rebRejectionMessage,
                  },
                  setOnTeacherConfirm
                );
              }}
            />
          )}
        </>
      )}

      {confirmDelete && (
        <ConfirmationDialog
          confirmationDialog={confirmDelete}
          message={
            <>
              <span>
                Are you sure you want to{" "}
                <strong className={`text-danger`}>delete</strong> this
                permitation request?
              </span>
            </>
          }
          setConfirmationDialog={setConfirmDelete}
          onYes={() => {
            deletePermutationRequest(request.id, setConfirmDelete);
          }}
        />
      )}
    </>
  );
};
