import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import React from "react";
import { useState } from "react";

import { connect } from "react-redux";
import { saveREBPostLimits } from "../../../../store/posts/actions";
import { useEffect } from "react";

const PostLimitDialog = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    rebPostLimits,
    isApproved,
    saveREBPostLimits,
  } = props;

  const [formData, setFormData] = useState({
    a0Limit: rebPostLimits.a0Limit || 0,
    a1Limit: rebPostLimits.a1Limit || 0,
    a2Limit: rebPostLimits.a2Limit || 0,
  });

  useEffect(() => {
    setFormData(rebPostLimits);
  }, [rebPostLimits]);

  const onSave = () => {
    saveREBPostLimits(formData, setShowDialog);
  };

  return (
    <>
      <Dialog
        maxWidth="xs"
        onClose={() => {
          setShowDialog(false);
        }}
        open={showDialog}
      >
        <DialogTitle className={`text-primary`}>
          <span className="d-flex align-items-center">
            {/* <span className="material-icons mr-1">numbers</span> */}
            POST LIMIT
          </span>
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className={`text-center`}>
          <div className="row">
            <div className="col-12 col-md-4 mt-3">
              {" "}
              <TextField
                rows={2}
                fullWidth
                autoFocus
                type="number"
                size="small"
                name="a0"
                label="A0"
                variant="outlined"
                placeholder="A0"
                value={formData.a0Limit}
                onChange={(e) => {
                  setFormData({ ...formData, a0Limit: e.target.value || 0 });
                }}
                onBlur={() => {
                  if (formData.a0Limit === "")
                    setFormData({
                      ...formData,
                      a0Limit: 0,
                    });
                }}
                onKeyDown={() => {
                  if (+formData.a0Limit === 0)
                    setFormData({
                      ...formData,
                      a0Limit: "",
                    });
                }}
              />
            </div>
            <div className="col-12 col-md-4 mt-3">
              {" "}
              <TextField
                fullWidth
                type="number"
                size="small"
                name="a1"
                label="A1"
                variant="outlined"
                placeholder="A1"
                value={formData.a1Limit}
                onChange={(e) => {
                  setFormData({ ...formData, a1Limit: e.target.value || 0 });
                }}
                onBlur={() => {
                  if (formData.a1Limit === "")
                    setFormData({
                      ...formData,
                      a1Limit: 0,
                    });
                }}
                onKeyDown={() => {
                  if (+formData.a1Limit === 0)
                    setFormData({
                      ...formData,
                      a1Limit: "",
                    });
                }}
              />
            </div>
            <div className="col-12 col-md-4 mt-3">
              {" "}
              <TextField
                fullWidth
                type="number"
                size="small"
                name="a2"
                label="A2"
                variant="outlined"
                placeholder="A2"
                value={formData.a2Limit}
                onChange={(e) => {
                  setFormData({ ...formData, a2Limit: e.target.value || 0 });
                }}
                onBlur={() => {
                  if (formData.a2Limit === "")
                    setFormData({
                      ...formData,
                      a2Limit: 0,
                    });
                }}
                onKeyDown={() => {
                  if (+formData.a2Limit === 0)
                    setFormData({
                      ...formData,
                      a2Limit: "",
                    });
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            color="primary"
            variant="contained"
            onClick={onSave}
            type="button"
            className="px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, rebPostLimits }) => {
  return { loading, rebPostLimits };
};
export default connect(mapStateToProps, {
  saveREBPostLimits,
})(PostLimitDialog);
