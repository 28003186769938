import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Dashboard from "./pages/Dashboard";

import { isEmpty } from "lodash";
import { connect } from "react-redux";
import Schools from "./pages/Schools";
import PostsDistribution from "./pages/PostsDistribution";
import UserGuide from "./pages/UserGuide";
import PostsRequest from "./pages/PostsRequest";
import StaffList from "./pages/StaffList";
import { getSpecializations, getDegrees } from "./../../../store/users/actions";
import { getTeachingLevels } from "./../../../store/posts/actions";

const Layout = (props) => {
  useEffect(() => {
    document.title = "TMIS | Post Management";
  }, []);

  const { user, getSpecializations, getDegrees, getTeachingLevels } = props;

  useEffect(() => {
    getDegrees();
    getSpecializations();
    getTeachingLevels();
  }, []);

  return (
    <React.Fragment>
      <Switch>
        <Route path="/in/schools/dashboard" component={Dashboard} />
        <Route path="/in/schools/posts" component={Schools} />
        <Route path="/in/schools/staff-list" component={StaffList} />
        <Route path="/in/schools/post-requests" component={PostsRequest} />
        <Route path="/in/schools/distributions" component={PostsDistribution} />
        <Route path="/in/schools/user-guide" component={UserGuide} />

        <Route
          path="/in/schools"
          component={() => <Redirect to="/in/schools/dashboard" />}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
export default connect(mapStateToProps, {
  getSpecializations,
  getDegrees,
  getTeachingLevels,
})(Layout);
