import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import { Typography, Box, Button, Menu, MenuItem } from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";

const Dashboard = (props) => {
  useEffect(() => {
    document.title = "TMIS | Transfers - Dashboard";
  }, []);

  const { user, loading } = props;
  const [searchTeam, setSearchTeam] = useState("");

  useEffect(() => {});

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return <div>Transfers | Dashboard</div>;
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, {})(Dashboard);
