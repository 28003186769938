import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { useState } from "react";

import { connect } from "react-redux";

const ScholarshipCriteriaDialog = (props) => {
  const { showDialog, setShowDialog } = props;
  return (
    <Dialog
      onClose={() => setShowDialog(false)}
      open={showDialog}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className="text-primary">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "90%" }}
        >
          <span className="text-primary font-weight-bold">
            Scholarship selection criteria
          </span>
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setShowDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div style={{ minHeight: "55vh" }}>
          <ol>
            <li>
              Teachers who uploaded all required documents were temporaly
              shortlisted,
            </li>
            <li>
              Teachers with performance evaluation of the 2022/2023, not below
              70 were shortlisted
            </li>
            <li>
              Selected candidates were distributed in 50% Sciences, 40%
              languages, 5% Social Sciences and 5% in Early Childhood Education
            </li>
            <li>
              Candidates who did Normale Primaire (NP) were distributed based on
              the requested department from University of Rwanda/College
              Education (UR/CE)
            </li>
            <li>
              Candidates whose marks on high school certificates were
              interpreted and put on 100% by NESA,
            </li>
            <li>The ranking was done at National level,</li>
            <li>
              The Candidates with highest score ( as per the marks interpreted
              by NESA) were prioritized,
            </li>
            <li>
              <strong>
                In Case two or more candidates have equal marks, the following
                Criteria were put into consideration:
              </strong>
              <ul>
                <li>Female candidates were prioritized</li>
                <li>
                  Candidates with more experience than others were prioritized
                </li>
                <li>Candidates with the young age were prioritized</li>
                <li>
                  If the candidates intersect these criteria, time of
                  application was considered
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center pb-3 pt-0"></DialogActions>
    </Dialog>
  );
};

export default ScholarshipCriteriaDialog;
