const types = {
  SET_SYSTEM_USERS: "SET_SYSTEM_USERS",
  ADD_OR_UPDATE_SYSTEM_USER: "ADD_OR_UPDATE_SYSTEM_USER",
  REMOVE_SYSTEM_USER: "REMOVE_SYSTEM_USER",

  SET_ROLES: "SET_ROLES",
  SET_DEGREES: "SET_DEGREES",
  SET_SPECIALIZATIONS: "SET_SPECIALIZATIONS",
  SET_TEACHER_PROFILE: "SET_TEACHER_PROFILE",
};

export default types;
