import { defaultState } from "./state";
import structure from "./action-types";
import commonTypes from "../common/action-types";

const types = { ...commonTypes, ...structure };

const systemUsers = (systemUsersState = defaultState.systemUsers, action) => {
  switch (action.type) {
    case types.SET_SYSTEM_USERS:
      return action.data;

    case types.ADD_OR_UPDATE_SYSTEM_USER: {
      const tmpState = [...systemUsersState];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;
      else tmpState.unshift(action.data);

      return tmpState;
    }

    // case types.UPDATE_SYSTEM_USER: {
    //   const tmpState = [...systemUsersState];

    //   const index = tmpState.findIndex(({ id }) => id === action.data.id);

    //   if (index >= 0) tmpState[index] = action.data;

    //   return tmpState;
    // }

    case types.REMOVE_SYSTEM_USER: {
      const tmpState = [...systemUsersState];

      const index = tmpState.findIndex(
        ({ userEntityId }) => userEntityId === action.userEntityId
      );

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.systemUsers;

    default:
      return systemUsersState;
  }
};

const roles = (rolesState = defaultState.roles, action) => {
  switch (action.type) {
    case types.SET_ROLES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.roles;

    default:
      return rolesState;
  }
};

const degrees = (degreesState = defaultState.degrees, action) => {
  switch (action.type) {
    case types.SET_DEGREES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.degrees;

    default:
      return degreesState;
  }
};

const specializations = (
  specializationsState = defaultState.specializations,
  action
) => {
  switch (action.type) {
    case types.SET_SPECIALIZATIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.specializations;

    default:
      return specializationsState;
  }
};

const teacherProfile = (
  teacherProfileState = defaultState.teacherProfile,
  action
) => {
  switch (action.type) {
    case types.SET_TEACHER_PROFILE:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.teacherProfile;

    default:
      return teacherProfileState;
  }
};

export default {
  systemUsers,
  roles,
  degrees,
  specializations,
  teacherProfile,
};
