import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

const AlertDialog = (props) => {
  const { showAlert, setShowAlert, children, maxWidth } = props;

  const onClose = () => {
    setShowAlert(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAlert}
        fullWidth
        maxWidth={maxWidth}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className="mt-2">{children}</DialogContent>
        <DialogActions className="d-flex justify-content-center py-2"></DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDialog;
