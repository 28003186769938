import { defaultState } from "./state";
import structure from "./action-types";
import commonTypes from "../common/action-types";

const types = { ...commonTypes, ...structure };

const placementReports = (
  placementReportsState = defaultState.placementReports,
  action
) => {
  switch (action.type) {
    case types.SET_PLACEMENT_REPORTS:
      return action.data;

    case types.ADD_NEW_PLACEMENT_REPORT: {
      const tmpState = [...placementReportsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;
      else tmpState.unshift(action.data);

      return tmpState;
    }

    case types.UPDATE_PLACEMENT_REPORT: {
      const tmpState = [...placementReportsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.DELETE_PLACEMENT_REPORT: {
      const tmpState = [...placementReportsState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.placementReports;

    default:
      return placementReportsState;
  }
};

const placementReportDetails = (
  placementReportDetailsState = defaultState.placementReportDetails,
  action
) => {
  switch (action.type) {
    case types.SET_PLACEMENT_REPORT_DETAILS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.placementReportDetails;

    default:
      return placementReportDetailsState;
  }
};

const changeList = (changeListState = defaultState.changeList, action) => {
  switch (action.type) {
    case types.SET_PLACEMENT_CHANGE_LIST:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.changeList;

    default:
      return changeListState;
  }
};

const placementVacantPosts = (
  placementVacantPostsState = defaultState.placementVacantPosts,
  action
) => {
  switch (action.type) {
    case types.SET_PLACEMENT_VACANT_POSTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.placementVacantPosts;

    default:
      return placementVacantPostsState;
  }
};

const prevPlacementReport = (
  prevPlacementReportState = defaultState.prevPlacementReport,
  action
) => {
  switch (action.type) {
    case types.SET_PREV_PLACEMENT_REPORT:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.prevPlacementReport;

    default:
      return prevPlacementReportState;
  }
};

const signatories = (signatoriesState = defaultState.signatories, action) => {
  switch (action.type) {
    case types.SET_PLACEMENT_SIGNATORIES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.signatories;

    default:
      return signatoriesState;
  }
};

const exitReasons = (exitReasonsState = defaultState.exitReasons, action) => {
  switch (action.type) {
    case types.SET_EXIT_REASONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.exitReasons;

    default:
      return exitReasonsState;
  }
};

const banks = (banksState = defaultState.banks, action) => {
  switch (action.type) {
    case types.SET_BANKS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.banks;

    default:
      return banksState;
  }
};

const unsubmitted = (unsubmittedState = defaultState.unsubmitted, action) => {
  switch (action.type) {
    case types.SET_UNSUBMITTED:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.unsubmitted;

    default:
      return unsubmittedState;
  }
};

export default {
  placementReports,
  placementReportDetails,
  changeList,
  prevPlacementReport,
  signatories,
  exitReasons,
  placementVacantPosts,
  banks,
  unsubmitted,
};
