import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  Menu,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveScholarshipRequest } from "../../../../store/scholarship/actions";
import { showError } from "../../../toastify";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import axios from "axios";
import { isEmpty } from "lodash";

const steps = ["Eligibility to apply", "Submit application"];

const AddOrUpdateScholarshipRequestDialog = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    saveScholarshipRequest,
    districts,
    scholarshipRequest,

    scholarshipCombinations,
    scholarshipCourses,
    scholarshipDocuments,

    eligibility,
  } = props;

  const [formData, setFormData] = useState({
    id: null,
    graduationDate: null,
    attachments: [],
    schoolName: "",
  });

  const [errors, setErrors] = useState({
    courseHasError: false,
    districtHasError: false,
    schoolHasError: false,
    combinationHasError: false,
    graduationDateHasError: false,
    attachmentHasErrors: [],

    // messageHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      courseHasError: false,
      districtHasError: false,
      schoolHasError: false,
      combinationHasError: false,
      graduationDateHasError: false,
      attachmentHasErrors: [],
      // messageHasError: false,
      hasError: false,
    };

    // if (!selectedDistrict) {
    //   error.districtHasError = true;
    //   error.hasError = true;
    // }

    if (!formData.schoolName) {
      error.schoolHasError = true;
      error.hasError = true;
    }

    if (!selectedCombination) {
      error.combinationHasError = true;
      error.hasError = true;
    }

    if (!formData.graduationDate) {
      error.graduationDateHasError = true;
      error.hasError = true;
    }

    if (!selectedCourse) {
      error.courseHasError = true;
      error.hasError = true;
    }

    scholarshipDocuments.forEach((doc) => {
      const document = formData.attachments.find(({ id }) => doc.id === id);

      if (!document && !doc.isOptional) {
        error.attachmentHasErrors.push(doc);
        error.hasError = true;
      }
    });

    // if (!formData.message) {
    //   error.messageHasError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onClose = () => {
    setShowDialog(false);
    setFormData({
      id: null,
      graduationDate: null,
      attachments: [],
      schoolName: "",
    });
  };

  const onSave = () => {
    if (!formValidator()) {
      const payload = new FormData();
      const keys = Object.keys(formData);
      for (const key of keys) {
        if (formData.hasOwnProperty(key)) {
          if (key === "attachments" && formData[key] && !!formData[key].length)
            formData[key].forEach((row, i) => {
              payload.append("files", row.file);
              payload.append(`docIds`, row.id);
            });
          else if (key === "graduationDate")
            payload.append(
              `${key}`,
              moment(formData[key]).format("YYYY-MM-DD")
            );
          else payload.append(`${key}`, `${formData[key]}`);
        }
      }

      payload.append("scholarshipCourseId", selectedCourse?.id);
      // payload.append("attendedSchoolName", selectedSchool?.id);
      payload.append("combinationId", selectedCombination?.id);

      saveScholarshipRequest(payload, onClose);
    }
  };

  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(null);

  const convertToLower = (value) => {
    return value.toLowerCase();
  };

  const isFileTooLarge = (file) => {
    const size = file.size;
    return size > 3222528 ? true : false;
  };

  const handleUploadedPDFFile = (event, document) => {
    let pdfAttachment = event.target.files[0];

    setErrors({ ...errors, pdfAttachmentHasError: false });

    if (convertToLower(pdfAttachment?.name?.substr(-4)) !== ".pdf") {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("File should be pdf format");
    } else if (isFileTooLarge(pdfAttachment)) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("Allowed file should not exceed 3MB");
    } else {
      const index = formData.attachments.findIndex(
        ({ id }) => id === document.id
      );
      const attachments = [...formData.attachments];
      document.file = pdfAttachment;
      document.filename = pdfAttachment.name;

      const reader = new FileReader();

      reader.onload = (e) => {
        document.url = e.target.result;
      };

      reader.readAsDataURL(pdfAttachment);

      if (index < 0) attachments.push(document);
      else attachments[index] = document;
      setFormData({
        ...formData,
        attachments,
      });
      const index2 = errors.attachmentHasErrors.findIndex(
        ({ id }) => id === document.id
      );
      errors.attachmentHasErrors.splice(index2, 1);
      setErrors(errors);
    }
  };

  const [showLoader, setShowLoader] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedCombination, setSelectedCombination] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);

  // const [sectors, setSectors] = useState([]);
  const [schools, setSchools] = useState([]);

  // const getSectors = async (districtId) => {
  //   try {
  //     setSectors([]);
  //     setShowLoader(true);
  //     const { data } = await axios.get("/api/posts/sectors/" + districtId);
  //     setShowLoader(false);

  //     setSectors(data);
  //   } catch (error) {
  //     setShowLoader(false);

  //     showError(error);
  //   }
  // };

  // const getSchools = async (districtId) => {
  //   try {
  //     setSchools([]);
  //     setShowLoader(true);
  //     const { data } = await axios.get(
  //       "/api/scholarship/schools/" + districtId
  //     );
  //     setShowLoader(false);

  //     setSchools(data);
  //   } catch (error) {
  //     setShowLoader(false);

  //     showError(error);
  //   }
  // };

  const [activeStep, setActiveStep] = useState(0);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (activeStep !== 2) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-primary font-weight-bold">
              Scholarship application
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <Box sx={{ width: "100%" }} className="px-3">
          <Stepper activeStep={activeStep} className="pr-5">
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        <DialogContent>
          <div style={{ minHeight: "55vh" }}>
            {activeStep === 0 && (
              <div>
                <strong>
                  Only teachers who meet the following requirements are allowed
                  to apply for scholarship:
                </strong>
                <ol>
                  <li>
                    To be Rwandan by Nationality{" "}
                    {!isEmpty(eligibility) && eligibility.isRwandan ? (
                      <i className="fas fa-check-circle text-success"></i>
                    ) : (
                      <i className="fas fa-times-circle text-danger"></i>
                    )}
                  </li>
                  <li>
                    Only Teachers are allowed to apply{" "}
                    {!isEmpty(eligibility) && eligibility.isTeacher ? (
                      <i className="fas fa-check-circle text-success"></i>
                    ) : (
                      <i className="fas fa-times-circle text-danger"></i>
                    )}
                  </li>
                  <li>
                    Having or below 35 years old by date of application{" "}
                    {!isEmpty(eligibility) && eligibility.isBelow35YearsOld ? (
                      <i className="fas fa-check-circle text-success"></i>
                    ) : (
                      <i className="fas fa-times-circle text-danger"></i>
                    )}
                  </li>
                  <li>
                    Having A2 in NP or TTC, Should be certified{" "}
                    {!isEmpty(eligibility) && eligibility.isCertified ? (
                      <i className="fas fa-check-circle text-success"></i>
                    ) : (
                      <i className="fas fa-times-circle text-danger"></i>
                    )}
                  </li>
                  <li>
                    Having at least four years of experience in service{" "}
                    {!isEmpty(eligibility) && eligibility.hasExperience ? (
                      <i className="fas fa-check-circle text-success"></i>
                    ) : (
                      <i className="fas fa-times-circle text-danger"></i>
                    )}
                  </li>
                  <li>
                    Have not been suspended for at least 3 months.{" "}
                    {!isEmpty(eligibility) && eligibility.notSuspended ? (
                      <i className="fas fa-check-circle text-success"></i>
                    ) : (
                      <i className="fas fa-times-circle text-danger"></i>
                    )}
                  </li>
                </ol>
              </div>
            )}
            {activeStep === 1 && (
              <div className="row">
                <div className="col-12 row no-gutters px-3">
                  <fieldset
                    className={`col-12 row no-gutters px-2 form-group border border-primary bg-light`}
                  >
                    <legend
                      className={`col-12 w-auto px-2 text-primary`}
                      style={{
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        fontWeight: 400,
                        fontSize: "0.82rem",
                        lineHeight: "1em",
                        letterSpacing: "0.00938em",
                      }}
                    >
                      School attended
                    </legend>

                    <div className="col-4 mb-2 px-2">
                      <TextField
                        size="small"
                        fullWidth
                        name="schoolName"
                        value={formData.schoolName || ""}
                        label="Type the name of school"
                        placeholder="Type the name of school"
                        variant="outlined"
                        onChange={(event) => {
                          setErrors({
                            ...errors,
                            schoolHasError: false,
                          });

                          setFormData({
                            ...formData,
                            schoolName: event.target.value || "",
                          });
                        }}
                      />

                      {errors.schoolHasError && (
                        <small className="text-danger mb-1">
                          School attended is required
                        </small>
                      )}
                    </div>

                    <div className="col-4 mb-2 px-2">
                      <Autocomplete
                        size="small"
                        value={selectedCombination}
                        options={scholarshipCombinations}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          setErrors({
                            ...errors,
                            combinationHasError: false,
                            courseHasError: false,
                          });

                          // setThirdSchoolInterest(null);
                          // setSecondSchoolInterest(null);
                          // setFirstSchoolInterest(null);
                          // setVacancies([]);

                          setSelectedCombination(newValue);

                          // if (!!newValue) getSchools(newValue.id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            name="selectedCombination"
                            {...params}
                            label="Select combination"
                            placeholder="Select combination"
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.name}
                          </Box>
                        )}
                      />

                      {errors.combinationHasError && (
                        <small className="text-danger mb-1">
                          Please select combination
                        </small>
                      )}
                    </div>

                    <div className="col-4 mb-2 px-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          maxDate={new Date()}
                          label="Graduation date"
                          inputFormat="dd/MM/yyyy"
                          value={formData.graduationDate || null}
                          onChange={(date) => {
                            setFormData({
                              ...formData,
                              graduationDate: date,
                            });

                            setErrors({
                              ...errors,
                              graduationDateHasError: false,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              name="scholarshipGraduationDate"
                              onKeyDown={(e) => e.preventDefault()}
                              size="small"
                              fullWidth
                              {...params}
                            />
                          )}
                        />

                        {errors.graduationDateHasError && (
                          <small className="text-danger">
                            Graduation date is required
                          </small>
                        )}
                      </LocalizationProvider>
                    </div>
                  </fieldset>
                </div>

                <div className="col-12 row no-gutters px-3 mt-2">
                  <fieldset
                    className={`col-12 row no-gutters px-2 form-group border border-primary bg-light`}
                  >
                    <legend
                      className={`col-12 w-auto px-2 text-primary`}
                      style={{
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        fontWeight: 400,
                        fontSize: "0.82rem",
                        lineHeight: "1em",
                        letterSpacing: "0.00938em",
                      }}
                    >
                      Field/department of study
                    </legend>

                    <div className="col-12 mb-2">
                      <Autocomplete
                        disabled={!selectedCombination}
                        size="small"
                        value={selectedCourse}
                        options={selectedCombination?.courses || []}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          setErrors({
                            ...errors,
                            courseHasError: false,
                          });

                          // setThirdSchoolInterest(null);
                          // setSecondSchoolInterest(null);
                          // setFirstSchoolInterest(null);
                          // setVacancies([]);

                          setSelectedCourse(newValue);

                          // if (!!newValue) getSchools(newValue.id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            name="course"
                            {...params}
                            label="Which department do you want to join at the University?"
                            placeholder="Which department do you want to join at the University?"
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.name}
                          </Box>
                        )}
                      />

                      {errors.courseHasError && (
                        <small className="text-danger mb-1">
                          Please select course
                        </small>
                      )}
                    </div>
                  </fieldset>
                </div>

                <div className="col-12 row no-gutters px-3">
                  <fieldset
                    className={`col-12 row no-gutters px-2 bg-light form-group border border-${
                      !!errors.attachmentHasErrors.length ? "danger" : "primary"
                    }`}
                  >
                    <legend
                      className={`col-12 w-auto px-2 text-${
                        !!errors.attachmentHasErrors.length
                          ? "danger"
                          : "primary"
                      }`}
                      style={{
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        fontWeight: 400,
                        fontSize: "0.82rem",
                        lineHeight: "1em",
                        letterSpacing: "0.00938em",
                      }}
                    >
                      Required documents
                    </legend>

                    {scholarshipDocuments.map((document) => (
                      <DocumentItem
                        key={document.id}
                        document={document}
                        errors={errors}
                        formData={formData}
                        setFormData={setFormData}
                        setShowUploadedDocument={setShowUploadedDocument}
                        setUploadedDocument={setUploadedDocument}
                        handleUploadedPDFFile={handleUploadedPDFFile}
                      />
                    ))}
                  </fieldset>
                </div>

                {/* <div className="col-12">
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    name="message"
                    value={formData.message || ""}
                    label="Message"
                    placeholder="Message"
                    variant="outlined"
                    onChange={(event) => {
                      setErrors({
                        ...errors,
                        messageHasError: false,
                      });

                      setFormData({
                        ...formData,
                        message: event.target.value || "",
                      });
                    }}
                  />

                  {errors.messageHasError && (
                    <small className="text-danger mb-1">
                      Message is required
                    </small>
                  )}
                </div> */}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center px-4 pb-3">
          <Button
            variant="outlined"
            color="primary"
            type="button"
            className="px-4"
            disabled={activeStep === 0 || loading}
            onClick={handleBack}
          >
            Back
          </Button>

          <Box sx={{ flex: "1 1 auto" }} />

          {activeStep !== 1 && (
            <Button
              variant="contained"
              color="primary"
              disabled={
                loading ||
                !eligibility.isRwandan ||
                !eligibility.isTeacher ||
                !eligibility.isBelow35YearsOld ||
                !eligibility.isCertified ||
                !eligibility.hasExperience ||
                !eligibility.notSuspended
              }
              onClick={handleNext}
              type="button"
              className="px-4"
            >
              Next
            </Button>
          )}
          {activeStep === 1 && (
            <Button
              variant="contained"
              color="success"
              onClick={onSave}
              type="button"
              className="px-4"
              disabled={loading}
            >
              {loading ? "Wait..." : "Submit"}
            </Button>
          )}

          {/* <Button
            variant="contained"
            onClick={onSave}
            type="button"
            className="px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Submit"}
          </Button> */}
        </DialogActions>
      </Dialog>

      {showUploadedDocument && uploadedDocument && (
        <PreviewPdfUrl
          isBase64={true}
          showDocument={showUploadedDocument}
          setShowDocument={setShowUploadedDocument}
          document={{
            name: `${uploadedDocument?.name || ""}`,
            url: uploadedDocument?.url || "",
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  districts,
  scholarshipCombinations,
  scholarshipCourses,
  scholarshipDocuments,
  eligibility,
}) => {
  return {
    loading,
    onWait,
    user,
    districts,
    scholarshipCombinations,
    scholarshipCourses,
    scholarshipDocuments,
    eligibility,
  };
};
export default connect(mapStateToProps, {
  saveScholarshipRequest,
})(AddOrUpdateScholarshipRequestDialog);

const DocumentItem = (props) => {
  const {
    document,
    errors,
    formData,
    setFormData,
    setShowUploadedDocument,
    setUploadedDocument,
    handleUploadedPDFFile,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);

    event.stopPropagation();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="col-6" key={document.id}>
        <fieldset
          className={`form-group ${
            !!(
              !!errors.attachmentHasErrors.length &&
              errors.attachmentHasErrors.find((doc) => document.id === doc.id)
            )
              ? "border border-danger"
              : "border"
          } px-2`}
        >
          <legend
            className="w-auto px-2"
            style={{
              fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              fontWeight: 400,
              fontSize: "0.82rem",
              lineHeight: "1em",
              letterSpacing: "0.00938em",
              color: `${
                !!(
                  !!errors.attachmentHasErrors.length &&
                  errors.attachmentHasErrors.find(
                    (doc) => document.id === doc.id
                  )
                )
                  ? "red"
                  : "rgba(0, 0, 0, 0.6)"
              }`,
            }}
          >
            {document.id}.{" "}
            <span className="ml-1">
              {document.name} {!!document.isOptional && <span>(Optional)</span>}
            </span>
          </legend>
          <div className="form-group1 mb-2">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-paperclip"></i>
                </span>
              </div>
              {formData.attachments.find(
                (doc) => document.id === doc.id && !!doc.filename
              ) ? (
                <div
                  className="form-control"
                  style={{
                    backgroundColor: "rgb(229, 246, 253)",

                    color: "#007bff",
                  }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowUploadedDocument(true);
                      setUploadedDocument({
                        name: document.name,
                        url: formData.attachments.find(
                          (doc) => document.id === doc.id && !!doc.filename
                        ).url,
                      });
                    }}
                  >
                    {
                      formData.attachments.find(
                        (doc) => document.id === doc.id && !!doc.filename
                      ).filename
                    }
                  </span>

                  <IconButton
                    size="small"
                    onClick={handleOpenMenu}
                    style={{
                      marginTop: "-5px",
                      marginRight: "-10px",
                    }}
                    color="info"
                    className="float-right"
                    aria-label="view"
                  >
                    <MoreHorizIcon />
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={(e) => {
                      handleCloseMenu();
                      e.stopPropagation();
                    }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 2,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 15,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    <span>
                      <MenuItem
                        onClick={(e) => {
                          handleCloseMenu();
                          setUploadedDocument({
                            name: document.name,
                            url: formData.attachments.find(
                              (doc) => document.id === doc.id && !!doc.filename
                            ).url,
                          });

                          setShowUploadedDocument(true);
                        }}
                        className="text-primary font-weight-bolder"
                      >
                        <span className="material-icons  mr-1">
                          open_in_new
                        </span>
                        Open
                      </MenuItem>
                    </span>

                    <span>
                      <MenuItem
                        onClick={(e) => {
                          handleCloseMenu();

                          const tmpDocuments = [...formData.attachments];

                          const index = tmpDocuments.findIndex(
                            ({ id }) => id === document.id
                          );

                          if (index >= 0) tmpDocuments.splice(index, 1);

                          setFormData({
                            ...formData,
                            attachments: tmpDocuments,
                          });
                        }}
                        className="text-danger"
                      >
                        <span className="material-icons mr-1">
                          remove_circle_outline
                        </span>
                        Remove
                      </MenuItem>
                    </span>
                  </Menu>
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    name="file"
                    className="form-control"
                    accept="application/pdf"
                    placeholder="Select pdf file"
                    onChange={(e) => handleUploadedPDFFile(e, document)}
                  />
                </>
              )}
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
};
