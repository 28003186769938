import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";
import download from "downloadjs";

import commonTypes from "../common/action-types";
import placementTypes from "./action-types";

const types = { ...commonTypes, ...placementTypes };

export const generatePlacementReport = (teachingLevelId, setShowDialog) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.END_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/placement/generate", {
        teachingLevelId,
      });

      dispatch({ type: types.END_LOADING });
      // dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_NEW_PLACEMENT_REPORT,
        data,
      });

      showSuccess("Plecement report generated successfully");

      setShowDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      // dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getPlacementReports = (status) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_PLACEMENT_REPORTS,
        data: [],
      });

      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/placement/reports/" + status);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_PLACEMENT_REPORTS,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPlacementReportDetails = (placementReportId) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_PLACEMENT_REPORT_DETAILS,
        data: [],
      });

      dispatch({
        type: types.SET_PLACEMENT_CHANGE_LIST,
        data: [],
      });

      dispatch({
        type: types.SET_PLACEMENT_VACANT_POSTS,
        data: [],
      });

      dispatch({
        type: types.SET_PREV_PLACEMENT_REPORT,
        data: {},
      });

      dispatch({
        type: types.SET_PLACEMENT_SIGNATORIES,
        data: [],
      });

      const { data } = await axios.get(
        "/api/placement/report-details/" + placementReportId
      );

      dispatch({
        type: types.SET_PLACEMENT_REPORT_DETAILS,
        data: data.placementReportDetails,
      });

      dispatch({
        type: types.SET_PLACEMENT_CHANGE_LIST,
        data: data.changeList,
      });

      dispatch({
        type: types.SET_PLACEMENT_VACANT_POSTS,
        data: data.vacantPosts,
      });

      dispatch({
        type: types.SET_PREV_PLACEMENT_REPORT,
        data: data.prevPlacementReport,
      });

      dispatch({
        type: types.SET_PLACEMENT_SIGNATORIES,
        data: data.signatories,
      });

      dispatch({ type: types.END_LOADING });
      // dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      // dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const deletePlacementReport = (id, setConfirmDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/placement/reports/" + id);

      dispatch({
        type: types.DELETE_PLACEMENT_REPORT,
        id,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Canceled successfully");

      setConfirmDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const signPlacementReport = (id, formData, setConfirmDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/placement/reports/sign/" + id,
        formData
      );

      dispatch({
        type: types.UPDATE_PLACEMENT_REPORT,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      // showSuccess("Canceled successfully");

      setConfirmDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const endTeacherPlacement = (
  { teacherPlacementId, exitReasonId, exitComments, teachingLevelId },
  setConfirmDialog
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/placement/reports/remove-staff", {
        teacherPlacementId,
        exitReasonId,
        exitComments,
        teachingLevelId,
      });

      dispatch({
        type: types.UPDATE_PLACEMENT_REPORT,
        data: data.placementReport,
      });

      dispatch({
        type: types.SET_PLACEMENT_CHANGE_LIST,
        data: data.changeList,
      });

      dispatch({
        type: types.SET_PLACEMENT_VACANT_POSTS,
        data: data.vacantPosts,
      });

      dispatch({
        type: types.SET_PLACEMENT_REPORT_DETAILS,
        data: data.placementReportDetails,
      });

      dispatch({
        type: types.SET_PREV_PLACEMENT_REPORT,
        data: data.prevPlacementReport,
      });

      // dispatch({
      //   type: types.SET_PLACEMENT_SIGNATORIES,
      //   data: data.signatories,
      // });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      // showSuccess("Canceled successfully");

      setConfirmDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const updateTeacherInfo = (teacherPlacementId, formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/placement/reports/update-teacher-info/" + teacherPlacementId,
        formData
      );

      dispatch({
        type: types.UPDATE_PLACEMENT_REPORT,
        data: data.placementReport,
      });

      dispatch({
        type: types.SET_PLACEMENT_CHANGE_LIST,
        data: data.changeList,
      });

      dispatch({
        type: types.SET_PLACEMENT_VACANT_POSTS,
        data: data.vacantPosts,
      });

      dispatch({
        type: types.SET_PLACEMENT_REPORT_DETAILS,
        data: data.placementReportDetails,
      });

      dispatch({
        type: types.SET_PREV_PLACEMENT_REPORT,
        data: data.prevPlacementReport,
      });

      onClose();

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const downloadPlacementReport = (placementReportId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/placement/download-report/" + placementReportId,
        {
          responseType: "blob",
        }
      );

      download(
        new Blob([data]),
        `placement_report_${placementReportId}.pdf`,
        ".pdf"
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const downloadAllPlacementReports = (
  { districtId, districtName, teachingLevelId, periodId },
  setOnGeneratePlacementReport
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/placement/download-all-reports",
        {
          districtId,
          districtName,
          teachingLevelId,
          periodId,
        },
        {
          responseType: "blob",
        }
      );

      download(new Blob([data]), `placement_report.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      setOnGeneratePlacementReport(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const getExitReasons = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/placement/exit-reasons");

      dispatch({
        type: types.SET_EXIT_REASONS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getBanks = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/placement/banks");

      dispatch({
        type: types.SET_BANKS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getUnsubmittedReports = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_UNSUBMITTED,
        data: [],
      });

      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/placement/unsubmitted-reports");

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_UNSUBMITTED,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
