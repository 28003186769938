import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { currencyFormat } from "../../common/components/Utils";

import {
  getPlacementReportDetails,
  signPlacementReport,
  endTeacherPlacement,
  downloadPlacementReport,
} from "../../../../store/placement/actions";

import { getShoolPositions } from "../../../../store/posts/actions";
import { isEmpty } from "lodash";
import axios from "axios";
import { CSVLink } from "react-csv";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import moment from "moment";
import UpdateTeacherProfileDialog from "./UpdateTeacherProfileDialog";
import { showError } from "../../../toastify";
// import { showError } from "../../toastify";
import joi from "joi";
import UpdateTeacherDialog from "../../posts/components/UpdateTeacherDialog";

const personalEmailValidator = joi.object({
  email: joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ["com", "fr", "rw"] },
  }),
});

const PlacementReportDetailsDialog = (props) => {
  const {
    user,
    loading,
    onWait,
    showDialog,
    setShowDialog,
    placementReport,
    getPlacementReportDetails,
    placementReportDetails,
    changeList,
    placementVacantPosts,
    prevPlacementReport,
    isEditable,
    signatories,
    signPlacementReport,
    endTeacherPlacement,
    downloadPlacementReport,
    getShoolPositions,
    exitReasons,
  } = props;

  const onClose = () => {
    setShowDialog(false);
  };

  const dispatch = useDispatch();

  // const [payrollDetails, setPayrollDetails] = useState([]);

  useEffect(() => {
    if (!!showDialog) {
      getPlacementReportDetails(placementReport.id);
      getShoolPositions("SCHOOL", placementReport.schoolId);
    }
  }, [placementReport]);

  // const [formatedPlacementReportDetails, setFormatedPlacementReportDetails] =
  //   useState([]);

  const formatPlacementDetails = () => {
    const schools = [];

    placementReportDetails
      .filter(({ isSuspended }) => !isSuspended)
      .forEach((item) => {
        const schoolIndex = schools.findIndex(
          (school) => school.id === item.schoolId
        );

        if (schoolIndex < 0)
          schools.push({
            id: item.schoolId,
            name: item.schoolName + "/" + item.teachingLevelName,
            teachers: [item],
          });
        else {
          schools[schoolIndex].teachers.push(item);
        }
      });

    return schools;
  };

  const suspended = placementReportDetails.filter(
    ({ isSuspended }) => !!isSuspended
  );

  const newChangeList = () =>
    changeList.filter(({ status }) => status === "New");

  const removedChangeList = () =>
    changeList.filter(({ status }) => status === "Removed");

  const signatoryOne = () =>
    signatories.find(({ approvalLevel }) => approvalLevel === 1);

  const signatoryTwo = () =>
    signatories.find(({ approvalLevel }) => approvalLevel === 2);

  const signatoryThree = () =>
    signatories.find(({ approvalLevel }) => approvalLevel === 3);

  const [confirmSignPlacementReport, setConfirmSignPlacementReport] =
    useState(false);

  const [formData, setFormData] = useState({});

  const [comments, setComments] = useState("");

  const checkNIDs = () => {
    // const names = [];

    // placementReportDetails
    //   .filter(({ isNIDVerified }) => isNIDVerified === false)
    //   .forEach(({ firstName }) => names.push(firstName));

    // if (names.length) {
    //   const tmpNames = [...names];
    //   const lastItem = tmpNames.pop();

    //   showError(
    //     "Please, verify NID Numbers for: " +
    //       tmpNames.join(", ").toString() +
    //       `${!!tmpNames.length ? ", and " : ""}` +
    //       lastItem +
    //       "."
    //   );

    //   return true; // false;
    // }
    return true;
  };

  const checkPhones = () => {
    // const names = [];

    // placementReportDetails
    //   .filter(
    //     ({ phoneNumber }) =>
    //       (phoneNumber || "").replace(/\s/g, "").length !== 10
    //   )
    //   .forEach(({ firstName }) => names.push(firstName));

    // if (names.length) {
    //   const tmpNames = [...names];
    //   const lastItem = tmpNames.pop();

    //   showError(
    //     "Please, correct phone mumbers for: " +
    //       tmpNames.join(", ").toString() +
    //       `${!!tmpNames.length ? ", and " : ""}` +
    //       lastItem +
    //       "."
    //   );

    //   return true; // false;
    // }
    return true;
  };

  const checkEmails = () => {
    // const names = [];

    // placementReportDetails
    //   .filter(
    //     ({ email }) =>
    //       !email ||
    //       (!!email && personalEmailValidator.validate({ email }).error)
    //   )
    //   .forEach(({ firstName }) => names.push(firstName));

    // if (names.length) {
    //   const tmpNames = [...names];
    //   const lastItem = tmpNames.pop();

    //   showError(
    //     "Please, correct emails for: " +
    //       tmpNames.join(", ").toString() +
    //       `${!!tmpNames.length ? ", and " : ""}` +
    //       lastItem +
    //       "."
    //   );

    //   return true; // false;
    // }
    return true;
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary pb-0 mb-0 text-uppercase border">
          <span className="d-flex align-items-center mb-2">
            <Typography className="d-flex align-items-center text-dark">
              Placement Report
              <span className="material-icons text-primary">double_arrow</span>
            </Typography>
            <Typography>{placementReport.periodName}</Typography>
          </span>

          <Tooltip title="Download PDF Report">
            <span
              style={{
                // border: "1px solid #c8c6c6",
                position: "absolute",
                right: 48,
                top: 8,
              }}
              className="p-0 text-center btn"
            >
              <IconButton
                size="small"
                disabled={loading}
                onClick={() => downloadPlacementReport(placementReport.id)}
                className="ml-2"
                color="primary"
              >
                <span className="material-icons">file_download</span>
              </IconButton>
            </span>
          </Tooltip>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="px-0">
          <div className="mt-0" style={{ height: "100%" }}>
            <div
              style={{
                display: "table-cell",
                width: "100vw",
                height: "75vh",
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              {(!loading || onWait) && (
                <div id="container">
                  <div id="main-content">
                    <table className="table table-sm table-borderless">
                      <tbody>
                        <tr>
                          <td colSpan={4}></td>
                          <td colSpan={4}>
                            <table
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <th
                                    style={{
                                      backgroundColor: "#4f81bc",
                                      // borderTopLeftRadius: "15px",
                                      // borderTopRightRadius: "15px",
                                      color: "#fff",
                                    }}
                                  >
                                    PLACEMENT REPORT
                                  </th>
                                </tr>
                                <tr>
                                  <td
                                    className="text-uppercase"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {placementReport.schoolName} /{" "}
                                    {placementReport.teachingLevelName}
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    className="text-uppercase"
                                    style={{
                                      fontWeight: "bold",
                                      color: "#4f81bc",
                                    }}
                                  >
                                    - {placementReport.periodName} -
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    className="text-uppercase"
                                    style={{
                                      padding: "6px",
                                    }}
                                  ></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td colSpan={3}></td>
                        </tr>
                        <tr>
                          <th
                            colSpan={6}
                            style={{
                              width: "50%",
                              borderLeft: "1px solid #4f81bc",
                              backgroundColor: "#4f81bc",
                              color: "white",
                              textAlign: "center",
                              fontSize: "18px",
                              padding: 3,
                            }}
                          >
                            Placement Summary
                          </th>
                          <th
                            style={{
                              backgroundColor: "#fff",
                              borderLeft: "1px solid #4f81bc",
                              borderRight: "1px solid #4f81bc",
                            }}
                          />
                          <th
                            colSpan={4}
                            style={{
                              width: "50%",
                              backgroundColor: "#4f81bc",
                              borderRight: "1px solid #4f81bc",
                              color: "white",
                              textAlign: "center",
                              fontSize: "18px",
                              padding: 3,
                            }}
                          >
                            Change Summary
                          </th>
                        </tr>
                        <tr>
                          <td
                            colSpan={11}
                            style={{ backgroundColor: "#fff" }}
                          />
                        </tr>
                        <tr>
                          <th
                            colSpan={2}
                            style={{
                              width: "17%",
                              fontSize: "14px",
                              textAlign: "center",
                              border: "1px solid #4f81bc",
                              // backgroundColor: "#f3f3f3",
                              backgroundColor: "rgb(219, 234, 254)",
                            }}
                          >
                            BUDGET POSTS
                          </th>
                          <th
                            colSpan={2}
                            style={{
                              width: "17%",
                              fontSize: "14px",
                              textAlign: "center",
                              border: "1px solid #4f81bc",
                              // backgroundColor: "#f3f3f3",
                              backgroundColor: "rgb(209, 250, 229)",
                            }}
                          >
                            ACTIVE TEACHERS
                          </th>
                          <th
                            colSpan={2}
                            style={{
                              width: "17%",
                              fontSize: "14px",
                              textAlign: "center",
                              border: "1px solid #4f81bc",
                              // backgroundColor: "#f3f3f3",
                              backgroundColor: "rgb(254, 243, 199)",
                            }}
                          >
                            VACANT POSTS
                          </th>
                          <th style={{ backgroundColor: "#fff" }} />
                          <th
                            style={{
                              width: "19%",
                              padding: "1.45px 8px",
                              fontSize: "14px",
                              textAlign: "right",
                              borderTop: "1px solid #ff5722",
                              borderLeft: "1px solid #ff5722",
                              backgroundColor: "#fff",
                            }}
                          />
                          <th
                            style={{
                              width: "10%",
                              fontSize: "14px",
                              textAlign: "right",
                              border: "1px solid #ff5722",
                              backgroundColor: "#ff572230",
                            }}
                            className="text-uppercase"
                          >
                            {prevPlacementReport.prevPeriodName}
                          </th>
                          <th
                            style={{
                              width: "10%",
                              fontSize: "14px",
                              textAlign: "right",
                              border: "1px solid #ff5722",
                              backgroundColor: "#ff572230",
                            }}
                            className="text-uppercase"
                          >
                            {placementReport.periodName}
                          </th>
                          <th
                            style={{
                              width: "10%",
                              fontSize: "14px",
                              textAlign: "right",
                              border: "1px solid #ff5722",
                              backgroundColor: "#ff572230",
                            }}
                            className="text-uppercase"
                          >
                            Difference
                          </th>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "0px 8px",
                              borderLeft: "1px solid #4f81bc",
                              backgroundColor: "#dbeafe66",
                            }}
                          >
                            A2:
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              padding: "0px 8px",
                              borderRight: "1px solid #4f81bc",
                              backgroundColor: "#dbeafe66",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.totalBudgetPostsA2
                            )}
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "0px 8px",
                              borderLeft: "1px solid #4f81bc",
                              backgroundColor: "#d1fae585",
                            }}
                          >
                            A2:
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              padding: "0px 8px",
                              borderRight: "1px solid #4f81bc",
                              backgroundColor: "#d1fae585",
                            }}
                          >
                            {currencyFormat(+placementReport.totalTeachersA2)}
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "0px 8px",
                              borderLeft: "1px solid #4f81bc",
                              backgroundColor: "#fef3c782",
                            }}
                          >
                            A2:
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              padding: "0px 8px",
                              borderRight: "1px solid #4f81bc",
                              backgroundColor: "#fef3c782",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.vacantBudgetPostsA2
                            )}
                          </td>
                          <td />
                          <td
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderLeft: "1px solid #ff5722",
                              lineHeight: "24px",
                            }}
                          >
                            BUDGET POSTS:
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderLeft: "1px solid #ff5722",
                              borderRight: "1px solid #ff5722",
                              backgroundColor: "#ffdfd545",
                            }}
                          >
                            {currencyFormat(
                              +prevPlacementReport.totalBudgetPostsA2 +
                                +prevPlacementReport.totalBudgetPostsA1 +
                                +prevPlacementReport.totalBudgetPostsA0 || 0
                            )}
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderRight: "1px solid #ff5722",
                              backgroundColor: "#ffdfd545",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.totalBudgetPostsA2 +
                                +placementReport.totalBudgetPostsA1 +
                                +placementReport.totalBudgetPostsA0
                            )}
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderRight: "1px solid #ff5722",
                              backgroundColor: "#ffdfd545",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.totalBudgetPostsA2 +
                                +placementReport.totalBudgetPostsA1 +
                                +placementReport.totalBudgetPostsA0 -
                                (+prevPlacementReport.totalBudgetPostsA2 +
                                  +prevPlacementReport.totalBudgetPostsA1 +
                                  +prevPlacementReport.totalBudgetPostsA0 || 0)
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "0px 8px",
                              borderLeft: "1px solid #4f81bc",
                              backgroundColor: "#dbeafe66",
                            }}
                          >
                            A1:
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              padding: "0px 8px",
                              borderRight: "1px solid #4f81bc",
                              backgroundColor: "#dbeafe66",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.totalBudgetPostsA1
                            )}
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "0px 8px",
                              borderLeft: "1px solid #4f81bc",
                              backgroundColor: "#d1fae585",
                            }}
                          >
                            A1:
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              padding: "0px 8px",
                              borderRight: "1px solid #4f81bc",
                              backgroundColor: "#d1fae585",
                            }}
                          >
                            {currencyFormat(+placementReport.totalTeachersA1)}
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "0px 8px",
                              borderLeft: "1px solid #4f81bc",
                              backgroundColor: "#fef3c782",
                            }}
                          >
                            A1:
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              padding: "0px 8px",
                              borderRight: "1px solid #4f81bc",
                              backgroundColor: "#fef3c782",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.vacantBudgetPostsA1
                            )}
                          </td>
                          <td />
                          <td
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderLeft: "1px solid #ff5722",
                              lineHeight: "24px",
                            }}
                          >
                            ACTIVE TEACHERS:
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderLeft: "1px solid #ff5722",
                              borderRight: "1px solid #ff5722",
                              backgroundColor: "#ffdfd545",
                            }}
                          >
                            {currencyFormat(
                              +prevPlacementReport.totalTeachersA2 +
                                +prevPlacementReport.totalTeachersA1 +
                                +prevPlacementReport.totalTeachersA0 || 0
                            )}
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderRight: "1px solid #ff5722",
                              backgroundColor: "#ffdfd545",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.totalTeachersA2 +
                                +placementReport.totalTeachersA1 +
                                +placementReport.totalTeachersA0
                            )}
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderRight: "1px solid #ff5722",
                              backgroundColor: "#ffdfd545",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.totalTeachersA2 +
                                +placementReport.totalTeachersA1 +
                                +placementReport.totalTeachersA0 -
                                (+prevPlacementReport.totalTeachersA2 +
                                  +prevPlacementReport.totalTeachersA1 +
                                  +prevPlacementReport.totalTeachersA0 || 0)
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "0px 8px",
                              borderLeft: "1px solid #4f81bc",
                              backgroundColor: "#dbeafe66",
                            }}
                          >
                            A0:
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              padding: "0px 8px",
                              borderRight: "1px solid #4f81bc",
                              backgroundColor: "#dbeafe66",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.totalBudgetPostsA0
                            )}
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "0px 8px",
                              borderLeft: "1px solid #4f81bc",
                              backgroundColor: "#d1fae585",
                            }}
                          >
                            A0:
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              padding: "0px 8px",
                              borderRight: "1px solid #4f81bc",
                              backgroundColor: "#d1fae585",
                            }}
                          >
                            {currencyFormat(+placementReport.totalTeachersA0)}
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "0px 8px",
                              borderLeft: "1px solid #4f81bc",
                              backgroundColor: "#fef3c782",
                            }}
                          >
                            A0:
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              padding: "0px 8px",
                              borderRight: "1px solid #4f81bc",
                              backgroundColor: "#fef3c782",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.vacantBudgetPostsA0
                            )}
                          </td>
                          <td />
                          <td
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderLeft: "1px solid #ff5722",
                              lineHeight: "24px",
                            }}
                          >
                            VACANT POSTS:
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderLeft: "1px solid #ff5722",
                              borderRight: "1px solid #ff5722",
                              backgroundColor: "#ffdfd545",
                            }}
                          >
                            {currencyFormat(
                              +prevPlacementReport.vacantBudgetPostsA2 +
                                +prevPlacementReport.vacantBudgetPostsA1 +
                                +prevPlacementReport.vacantBudgetPostsA0 || 0
                            )}
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderRight: "1px solid #ff5722",
                              backgroundColor: "#ffdfd545",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.vacantBudgetPostsA2 +
                                +placementReport.vacantBudgetPostsA1 +
                                +placementReport.vacantBudgetPostsA0
                            )}
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderRight: "1px solid #ff5722",
                              backgroundColor: "#ffdfd545",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.vacantBudgetPostsA2 +
                                +placementReport.vacantBudgetPostsA1 +
                                +placementReport.vacantBudgetPostsA0 -
                                (+prevPlacementReport.vacantBudgetPostsA2 +
                                  +prevPlacementReport.vacantBudgetPostsA1 +
                                  +prevPlacementReport.vacantBudgetPostsA0 || 0)
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={2}
                            style={{
                              width: "17%",
                              fontSize: "14px",
                              textAlign: "center",
                              border: "1px solid #4f81bc",
                              // backgroundColor: "#f3f3f3",
                              fontWeight: "bold",
                              backgroundColor: "rgb(219, 234, 254)",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.totalBudgetPostsA2 +
                                +placementReport.totalBudgetPostsA1 +
                                +placementReport.totalBudgetPostsA0
                            )}
                          </td>

                          <td
                            colSpan={2}
                            style={{
                              width: "17%",
                              fontSize: "14px",
                              textAlign: "center",
                              border: "1px solid #4f81bc",
                              // backgroundColor: "#f3f3f3",
                              fontWeight: "bold",
                              backgroundColor: "rgb(209, 250, 229)",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.totalTeachersA2 +
                                +placementReport.totalTeachersA1 +
                                +placementReport.totalTeachersA0
                            )}
                          </td>

                          <td
                            colSpan={2}
                            style={{
                              width: "17%",
                              fontSize: "14px",
                              textAlign: "center",
                              border: "1px solid #4f81bc",
                              // backgroundColor: "#f3f3f3",
                              fontWeight: "bold",
                              backgroundColor: "rgb(254, 243, 199)",
                            }}
                          >
                            {currencyFormat(
                              +placementReport.vacantBudgetPostsA2 +
                                +placementReport.vacantBudgetPostsA1 +
                                +placementReport.vacantBudgetPostsA0
                            )}
                          </td>

                          <td />
                          <td
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderLeft: "1px solid #ff5722",
                              borderBottom: "1px solid #ff5722",
                            }}
                          ></td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderLeft: "1px solid #ff5722",
                              borderRight: "1px solid #ff5722",
                              borderBottom: "1px solid #ff5722",
                              backgroundColor: "#ffdfd545",
                            }}
                          ></td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderRight: "1px solid #ff5722",
                              borderBottom: "1px solid #ff5722",
                              backgroundColor: "#ffdfd545",
                            }}
                          ></td>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "1.45px 8px",
                              borderRight: "1px solid #ff5722",
                              borderBottom: "1px solid #ff5722",
                              backgroundColor: "#ffdfd545",
                            }}
                          ></td>
                        </tr>
                      </tbody>
                    </table>

                    {!!loading && !placementReportDetails.length && (
                      <Box
                        className="justify-content-center "
                        sx={{ display: "flex" }}
                      >
                        <CircularProgress />
                        <h5 className="mt-2 ml-2 text-primary">Loading...</h5>
                      </Box>
                    )}

                    {!!changeList.length && (
                      <table
                        className="table table-sm table-borderless table-hover"
                        style={{
                          marginTop: 24,
                          marginBottom: 8,
                        }}
                      >
                        <tbody style={{ borderRadius: "15px" }}>
                          <tr>
                            <th
                              colSpan={15}
                              style={{
                                backgroundColor: "rgb(255 223 213)",
                                border: "1px solid #ff5722",
                                color: "#ff3d00",
                                textAlign: "center",
                                fontSize: "18px",
                                padding: 3,
                              }}
                            >
                              STAFF CHANGES ({changeList.length})
                            </th>
                          </tr>

                          <tr>
                            <td
                              colSpan={15}
                              style={{
                                fontSize: "14px",
                                textAlign: "center",
                                backgroundColor: "#fff",
                                padding: "4px",
                              }}
                            />
                          </tr>

                          {!!newChangeList().length && (
                            <>
                              <tr>
                                <th
                                  colSpan={15}
                                  style={{
                                    fontSize: "14px",
                                    textAlign: "center",
                                    backgroundColor: "#ff572230",
                                    fontWeight: "bold",
                                    color: "#ff3d00",
                                    border: "1px solid #ff5722",
                                  }}
                                >
                                  ({newChangeList().length}) New
                                </th>
                              </tr>
                              <tr>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  No
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  BudgetPost
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  EmployeeId
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  Names
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  NID Number
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  Gender
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  Nationality
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  DateOfBirth
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  MartialStatus
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  RSSB Number
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  AccountNo
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  Position
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  Qualification
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  Role
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    borderRight: "1px solid #ff5722",
                                  }}
                                >
                                  AppointmentDate
                                </th>
                              </tr>

                              {newChangeList().map((teacher, index) => (
                                <tr key={teacher.id}>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {index + 1}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.budgetPostId}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.teacherId}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.lastName} {teacher.firstName}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.nidNumber}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.gender}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.nationality}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {!!teacher.dateOfBirth
                                      ? moment(teacher.dateOfBirth).format(
                                          "DD/MM/yyyy"
                                        )
                                      : ""}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.civilStatus}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.rssbNumber}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                      maxWidth: "128px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {teacher.accountNumber} ({teacher.bankName})
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                      maxWidth: "192px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {teacher.positionName}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.qualificationLevelName}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.classificationName}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      borderRight: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {!!teacher.appointmentDate
                                      ? moment(teacher.appointmentDate).format(
                                          "DD/MM/yyyy"
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}

                          {!!removedChangeList().length && (
                            <>
                              <tr>
                                <th
                                  colSpan={15}
                                  style={{
                                    fontSize: "14px",
                                    textAlign: "center",
                                    backgroundColor: "#ff572230",
                                    fontWeight: "bold",
                                    color: "#ff3d00",
                                    border: "1px solid #ff5722",
                                  }}
                                >
                                  ({removedChangeList().length}) Removed
                                </th>
                              </tr>
                              <tr>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  No
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  BudgetPost
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  EmployeeId
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  Names
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  NID Number
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  Gender
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  Nationality
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  DateOfBirth
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  MartialStatus
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  RSSB Number
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  AccountNo
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  Position
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  Qualification
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                  }}
                                >
                                  Role
                                </th>
                                <th
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    backgroundColor: "#f3f3f3",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    borderRight: "1px solid #ff5722",
                                  }}
                                >
                                  AppointmentDate
                                </th>
                              </tr>

                              {removedChangeList().map((teacher, index) => (
                                <tr key={teacher.id}>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {index + 1}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.budgetPostId}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.teacherId}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.lastName} {teacher.firstName}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.nidNumber}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.gender}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.nationality}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {!!teacher.dateOfBirth
                                      ? moment(teacher.dateOfBirth).format(
                                          "DD/MM/yyyy"
                                        )
                                      : ""}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.civilStatus}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.rssbNumber}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                      maxWidth: "128px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {teacher.accountNumber} ({teacher.bankName})
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                      maxWidth: "192px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {teacher.positionName}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.qualificationLevelName}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {teacher.classificationName}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "left",
                                      borderLeft: "1px solid #ff5722",
                                      borderBottom: "1px solid #ff5722",
                                      borderTop: "1px solid #ff5722",
                                      borderRight: "1px solid #ff5722",
                                      backgroundColor: "#ffdfd53b",
                                    }}
                                  >
                                    {!!teacher.appointmentDate
                                      ? moment(teacher.appointmentDate).format(
                                          "DD/MM/yyyy"
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    )}

                    <table
                      className="table table-sm table-borderless table-hover"
                      style={{ marginTop: 24, marginBottom: 8 }}
                    >
                      <tbody>
                        <tr>
                          <th
                            colSpan={!isEditable ? 15 : 16}
                            style={{
                              borderLeft: "1px solid #4f81bc",
                              borderRight: "1px solid #4f81bc",
                              backgroundColor: "#4f81bc",
                              color: "white",
                              textAlign: "center",
                              fontSize: "18px",
                              padding: 3,
                            }}
                          >
                            ACTIVE TEACHERS (
                            {placementReportDetails.filter(
                              ({ isSuspended }) => !isSuspended
                            ).length || 0}
                            )
                          </th>
                        </tr>
                        <tr>
                          <td
                            colSpan={!isEditable ? 15 : 16}
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                              backgroundColor: "#fff",
                            }}
                          />
                        </tr>
                        {formatPlacementDetails().map((school, index) => (
                          <Fragment key={school.id}>
                            <tr>
                              <td
                                colSpan={!isEditable ? 15 : 16}
                                style={{
                                  fontSize: "14px",
                                  textAlign: "center",
                                  backgroundColor: "#fff",
                                }}
                              />
                            </tr>
                            <tr>
                              <td
                                colSpan={!isEditable ? 15 : 16}
                                style={{
                                  fontSize: "10px",
                                  textAlign: "center",
                                  backgroundColor: "#4f81bc",
                                  borderLeft: "1px solid #4f81bc",
                                  borderRight: "1px solid #4f81bc",
                                  color: "#fff",
                                }}
                              >
                                {school.name} ({school.teachers.length})
                              </td>
                            </tr>

                            <tr>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                No
                              </th>

                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                BudgetPost
                              </th>

                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                EmployeeId
                              </th>

                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                Names
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                NID Number
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                Gender
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                Nationality
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                DateOfBirth
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                MartialStatus
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                RSSB Number
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                AccountNo
                              </th>

                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                Position
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                Qualification
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                Role
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #4f81bc",
                                  borderRight: "1px solid #4f81bc",
                                  borderBottom: "1px solid #4f81bc",
                                  borderTop: "1px solid #4f81bc",
                                }}
                              >
                                AppointmentDate
                              </th>

                              {!!isEditable && (
                                <th
                                  style={{
                                    width: "64px",
                                    fontSize: "10px",
                                    textAlign: "right",
                                    backgroundColor: "#f3f3f3",
                                    border: "1px solid #4f81bc",
                                  }}
                                ></th>
                              )}
                            </tr>

                            {school.teachers.map((teacher, index) => (
                              <TeacherItem
                                key={teacher.id}
                                index={index}
                                teacher={teacher}
                                placementReport={placementReport}
                                isEditable={isEditable}
                                endTeacherPlacement={endTeacherPlacement}
                                user={user}
                                exitReasons={exitReasons}
                              />
                            ))}
                          </Fragment>
                        ))}

                        <tr>
                          <td
                            colSpan={!isEditable ? 15 : 16}
                            style={{
                              fontSize: "14px",
                              padding: "2px",
                              textAlign: "center",
                              backgroundColor: "#fff",
                            }}
                          />
                        </tr>

                        {!!suspended.length && (
                          <>
                            <tr>
                              <th
                                colSpan={!isEditable ? 15 : 16}
                                style={{
                                  fontSize: "14px",
                                  textAlign: "center",
                                  backgroundColor: "#ff572230",
                                  fontWeight: "bold",
                                  color: "#ff3d00",
                                  border: "1px solid #ff5722",
                                }}
                              >
                                ({suspended.length}) Suspended
                              </th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                }}
                              >
                                No
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                }}
                              >
                                BudgetPost
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                }}
                              >
                                EmployeeId
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                }}
                              >
                                Names
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                }}
                              >
                                NID Number
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                }}
                              >
                                Gender
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                }}
                              >
                                Nationality
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                }}
                              >
                                DateOfBirth
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                }}
                              >
                                MartialStatus
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                }}
                              >
                                RSSB Number
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                }}
                              >
                                AccountNo
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                }}
                              >
                                Position
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                }}
                              >
                                Qualification
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                }}
                              >
                                Role
                              </th>
                              <th
                                colSpan={!isEditable ? 1 : 2}
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#f3f3f3",
                                  borderLeft: "1px solid #ff5722",
                                  borderBottom: "1px solid #ff5722",
                                  borderTop: "1px solid #ff5722",
                                  borderRight: "1px solid #ff5722",
                                }}
                              >
                                AppointmentDate
                              </th>
                            </tr>

                            {suspended.map((teacher, index) => (
                              <tr key={teacher.id}>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                  }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                  }}
                                >
                                  {teacher.budgetPostId}
                                </td>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                  }}
                                >
                                  {teacher.teacherId}
                                </td>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                  }}
                                >
                                  {teacher.lastName} {teacher.firstName}
                                </td>

                                <td
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                  }}
                                >
                                  {teacher.nidNumber}
                                </td>

                                <td
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                  }}
                                >
                                  {teacher.gender}
                                </td>

                                <td
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                  }}
                                >
                                  {teacher.nationality}
                                </td>

                                <td
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                  }}
                                >
                                  {!!teacher.dateOfBirth
                                    ? moment(teacher.dateOfBirth).format(
                                        "DD/MM/yyyy"
                                      )
                                    : ""}
                                </td>

                                <td
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                  }}
                                >
                                  {teacher.civilStatus}
                                </td>

                                <td
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                  }}
                                >
                                  {teacher.rssbNumber}
                                </td>

                                <td
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                    maxWidth: "128px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {teacher.accountNumber} ({teacher.bankName})
                                </td>

                                <td
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                    maxWidth: "192px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {teacher.positionName}
                                </td>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                  }}
                                >
                                  {teacher.qualificationLevelName}
                                </td>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                  }}
                                >
                                  {teacher.classificationName}
                                </td>
                                <td
                                  colSpan={!isEditable ? 1 : 2}
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #ff5722",
                                    borderBottom: "1px solid #ff5722",
                                    borderTop: "1px solid #ff5722",
                                    borderRight: "1px solid #ff5722",
                                    backgroundColor: "#ffdfd53b",
                                  }}
                                >
                                  {!!teacher.appointmentDate
                                    ? moment(teacher.appointmentDate).format(
                                        "DD/MM/yyyy"
                                      )
                                    : ""}
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>

                    {(!!placementReportDetails.length ||
                      !!placementVacantPosts.length) && (
                      <table
                        className="table table-sm table-borderless table-hover"
                        style={{
                          marginTop: 24,
                          marginBottom: 8,
                        }}
                      >
                        <tbody style={{ borderRadius: "15px" }}>
                          <tr>
                            <th
                              colSpan={8}
                              style={{
                                backgroundColor: "#b0ffce",
                                border: "1px solid #00ffd0",
                                color: "#00428f",
                                textAlign: "center",
                                fontSize: "18px",
                                padding: 3,
                              }}
                            >
                              VACANT POSTS ({placementVacantPosts.length})
                            </th>
                          </tr>

                          <tr>
                            <td
                              colSpan={8}
                              style={{
                                fontSize: "10px",
                                textAlign: "center",
                                backgroundColor: "#fff",
                                padding: "2px",
                              }}
                            />
                          </tr>

                          {!placementVacantPosts.length && (
                            <tr>
                              <td colSpan={8} className="text-center">
                                No vacant post
                              </td>
                            </tr>
                          )}

                          {!!placementVacantPosts.length && (
                            <tr>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#c8f5d9",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                }}
                              >
                                No
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#c8f5d9",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                }}
                              >
                                BudgetPost
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#c8f5d9",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                }}
                              >
                                SchoolCode
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#c8f5d9",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                }}
                              >
                                SchoolName
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#c8f5d9",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                }}
                              >
                                PositionCode
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#c8f5d9",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                }}
                              >
                                PositionName
                              </th>
                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#c8f5d9",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                }}
                              >
                                Qualification
                              </th>

                              <th
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  backgroundColor: "#c8f5d9",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                  borderRight: "1px solid #00ffd0",
                                }}
                              >
                                Role
                              </th>
                            </tr>
                          )}

                          {placementVacantPosts.map((vacantPost, index) => (
                            <tr key={vacantPost.id}>
                              <td
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  borderLeft: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  backgroundColor: "#e2f9eb",
                                }}
                              >
                                {index + 1}
                              </td>
                              <td
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                  backgroundColor: "#e2f9eb",
                                }}
                              >
                                {vacantPost.budgetPostId}
                              </td>

                              <td
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                  backgroundColor: "#e2f9eb",
                                }}
                              >
                                {vacantPost.schoolId}
                              </td>

                              <td
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                  backgroundColor: "#e2f9eb",
                                }}
                              >
                                {vacantPost.schoolName}
                              </td>

                              <td
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                  backgroundColor: "#e2f9eb",
                                }}
                              >
                                {vacantPost.positionCode}
                              </td>

                              <td
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                  backgroundColor: "#e2f9eb",
                                }}
                              >
                                {vacantPost.positionName}
                              </td>

                              <td
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                  backgroundColor: "#e2f9eb",
                                }}
                              >
                                {vacantPost.qualificationLevelName}
                              </td>

                              <td
                                style={{
                                  fontSize: "10px",
                                  textAlign: "left",
                                  borderLeft: "1px solid #00ffd0",
                                  borderBottom: "1px solid #00ffd0",
                                  borderTop: "1px solid #00ffd0",
                                  borderRight: "1px solid #00ffd0",
                                  backgroundColor: "#e2f9eb",
                                }}
                              >
                                {vacantPost.classificationName}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    <table style={{ marginTop: 32, marginBottom: 8 }}>
                      <tbody>
                        <tr>
                          <th
                            style={{
                              width: "20%",
                              fontSize: "14px",
                              textAlign: "center",
                              backgroundColor: "#4f81bc",
                              borderTopLeftRadius: "15px",
                              borderTopRightRadius: "15px",
                              color: "#fff",
                            }}
                          >
                            Prepared By
                          </th>
                          <th
                            style={{ width: "15%", backgroundColor: "#fff" }}
                          />
                          <th
                            style={{
                              width: "20%",
                              fontSize: "14px",
                              textAlign: "center",
                              backgroundColor: "#4f81bc",
                              borderTopLeftRadius: "15px",
                              borderTopRightRadius: "15px",
                              color: "#fff",
                            }}
                          >
                            Reviewed by
                          </th>
                          <th
                            style={{ width: "15%", backgroundColor: "#fff" }}
                          />
                          <th
                            style={{
                              width: "20%",
                              fontSize: "14px",
                              textAlign: "center",
                              backgroundColor: "#4f81bc",
                              borderTopLeftRadius: "15px",
                              borderTopRightRadius: "15px",
                              color: "#fff",
                            }}
                          >
                            Approved by
                          </th>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            {placementReport.statusId < 2 &&
                              !placementReport.approvalLevelOneUserId && (
                                <>
                                  <strong>
                                    {signatoryOne()?.lastName}{" "}
                                    {signatoryOne()?.firstName}
                                  </strong>

                                  <br />
                                  {signatoryOne()?.positionName}
                                  <br />
                                  {signatoryOne()?.schoolName}
                                </>
                              )}

                            {!!placementReport.approvalLevelOneUserId && (
                              <>
                                <strong>
                                  {placementReport.approvalLevelOneNames}
                                </strong>

                                <br />
                                {placementReport.approvalLevelOnePositionName}
                                <br />
                                {placementReport.approvalLevelOneSchoolName}
                                <br />
                                {placementReport.statusId >= 2 && (
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Confirmed
                                  </span>
                                )}
                                <br />

                                {placementReport.levelOneApprovedOn && (
                                  <span style={{ fontSize: "12px" }}>
                                    {moment(
                                      placementReport.levelOneApprovedOn
                                    ).format("yyyy-MM-DD HH:mm:ss")}
                                  </span>
                                )}
                              </>
                            )}

                            <br />

                            {placementReport.statusId < 2 && (
                              <span
                                style={{
                                  color: "#ff3709",
                                  fontWeight: "bold",
                                }}
                              >
                                -Not confirmed-
                              </span>
                            )}
                          </td>
                          <td />
                          <td
                            style={{
                              width: "20%",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            {placementReport.statusId <= 2 && (
                              <>
                                <strong>
                                  {
                                    (signatoryTwo() || signatoryThree())
                                      ?.lastName
                                  }{" "}
                                  {
                                    (signatoryTwo() || signatoryThree())
                                      ?.firstName
                                  }
                                </strong>

                                <br />
                                {
                                  (signatoryTwo() || signatoryThree())
                                    ?.positionName
                                }
                                <br />
                                {
                                  (signatoryTwo() || signatoryThree())
                                    ?.schoolName
                                }
                              </>
                            )}

                            {placementReport.statusId !== 2 &&
                              !!placementReport.approvalLevelTwoUserId && (
                                <>
                                  <strong>
                                    {placementReport.approvalLevelTwoNames}
                                  </strong>

                                  <br />
                                  {placementReport.approvalLevelTwoPositionName}
                                  <br />
                                  {placementReport.approvalLevelTwoSchoolName}
                                  <br />

                                  {placementReport.statusId !== 3 && (
                                    <span
                                      style={{
                                        color: "green",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Confirmed
                                    </span>
                                  )}

                                  {placementReport.statusId === 3 && (
                                    <span
                                      style={{
                                        color: "red",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Rejected
                                    </span>
                                  )}

                                  <br />

                                  {placementReport.levelTwoApprovedOn && (
                                    <span style={{ fontSize: "12px" }}>
                                      {moment(
                                        placementReport.levelTwoApprovedOn
                                      ).format("yyyy-MM-DD HH:mm:ss")}
                                    </span>
                                  )}
                                </>
                              )}

                            <br />

                            {placementReport.statusId !== 4 &&
                              placementReport.statusId !== 6 &&
                              placementReport.statusId !== 3 && (
                                <span
                                  style={{
                                    color: "#ff3709",
                                    fontWeight: "bold",
                                  }}
                                >
                                  -Not confirmed-
                                </span>
                              )}
                          </td>
                          <td />
                          <td
                            style={{
                              width: "20%",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            {placementReport.statusId <= 4 && (
                              <>
                                <strong>
                                  {signatoryThree()?.lastName}{" "}
                                  {signatoryThree()?.firstName}
                                </strong>

                                <br />
                                {signatoryThree()?.positionName}
                                <br />
                                {signatoryThree()?.schoolName}
                              </>
                            )}

                            {placementReport.statusId !== 4 &&
                              !!placementReport.approvalLevelThreeUserId && (
                                <>
                                  <strong>
                                    {placementReport.approvalLevelThreeNames}
                                  </strong>

                                  <br />
                                  {
                                    placementReport.approvalLevelThreePositionName
                                  }
                                  <br />
                                  {placementReport.approvalLevelThreeSchoolName}
                                  <br />

                                  {placementReport.statusId !== 5 && (
                                    <span
                                      style={{
                                        color: "green",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Confirmed
                                    </span>
                                  )}

                                  {placementReport.statusId === 5 && (
                                    <span
                                      style={{
                                        color: "red",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Rejected
                                    </span>
                                  )}

                                  <br />

                                  {placementReport.levelThreeApprovedOn && (
                                    <span style={{ fontSize: "12px" }}>
                                      {moment(
                                        placementReport.levelThreeApprovedOn
                                      ).format("yyyy-MM-DD HH:mm:ss")}
                                    </span>
                                  )}
                                </>
                              )}

                            <br />

                            {placementReport.statusId !== 5 &&
                              placementReport.statusId !== 6 && (
                                <span
                                  style={{
                                    color: "#ff3709",
                                    fontWeight: "bold",
                                  }}
                                >
                                  -Not confirmed-
                                </span>
                              )}
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              width: "20%",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            {placementReport.statusId === 1 &&
                              signatoryOne()?.id === user.id && (
                                <Button
                                  // disabled={isGettingSignature}
                                  color="success"
                                  variant="contained"
                                  disableElevation
                                  style={{
                                    borderRadius: "8px",
                                    height: "32px",
                                    alignItems: "center",
                                  }}
                                  size="sm"
                                  onClick={() => {
                                    if (
                                      checkNIDs() &&
                                      checkEmails() &&
                                      checkPhones()
                                    ) {
                                      setFormData({
                                        statusId: 2,
                                        approvalLevelOneUserId:
                                          signatoryOne().id,
                                        approvalLevelOneNames:
                                          signatoryOne().lastName +
                                          " " +
                                          signatoryOne().firstName,

                                        approvalLevelOnePositionName:
                                          signatoryOne().positionName,

                                        approvalLevelOneSchoolName:
                                          signatoryOne().schoolName,

                                        levelOneApprovedOn: new Date(),
                                      });

                                      setConfirmSignPlacementReport(true);
                                    }
                                  }}
                                >
                                  <span className="material-icons mr-1">
                                    check_circle_outline
                                  </span>{" "}
                                  confirm
                                </Button>
                              )}
                          </td>
                          <td />
                          <td
                            style={{
                              width: "20%",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            {placementReport.statusId === 2 &&
                              (signatoryTwo()?.id === user.id ||
                                (signatoryThree()?.id === user.id &&
                                  !signatoryTwo()?.id)) && (
                                <>
                                  <Button
                                    color="success"
                                    variant="contained"
                                    disableElevation
                                    style={{
                                      borderRadius: "8px",
                                      height: "32px",
                                      alignItems: "center",
                                    }}
                                    size="sm"
                                    onClick={() => {
                                      setFormData({
                                        statusId: 4,
                                        approvalLevelTwoUserId: (
                                          signatoryTwo() || signatoryThree()
                                        ).id,
                                        approvalLevelTwoNames:
                                          (signatoryTwo() || signatoryThree())
                                            .lastName +
                                          " " +
                                          (signatoryTwo() || signatoryThree())
                                            .firstName,

                                        approvalLevelTwoPositionName: (
                                          signatoryTwo() || signatoryThree()
                                        ).positionName,

                                        approvalLevelTwoSchoolName: (
                                          signatoryTwo() || signatoryThree()
                                        ).schoolName,

                                        levelTwoApprovedOn: new Date(),
                                      });

                                      setConfirmSignPlacementReport(true);
                                    }}
                                  >
                                    <span className="material-icons mr-1">
                                      check_circle_outline
                                    </span>{" "}
                                    confirm
                                  </Button>

                                  <Button
                                    color="error"
                                    disableElevation
                                    variant="contained"
                                    className="ml-2"
                                    style={{
                                      borderRadius: "8px",
                                      height: "32px",
                                      alignItems: "center",
                                    }}
                                    size="sm"
                                    onClick={() => {
                                      setFormData({
                                        statusId: 3,
                                        approvalLevelTwoUserId:
                                          signatoryTwo().id,
                                        approvalLevelTwoNames:
                                          signatoryTwo().lastName +
                                          " " +
                                          signatoryTwo().firstName,

                                        approvalLevelTwoPositionName:
                                          signatoryTwo().positionName,

                                        approvalLevelTwoSchoolName:
                                          signatoryTwo().schoolName,

                                        levelTwoApprovedOn: new Date(),
                                      });

                                      setConfirmSignPlacementReport(true);
                                    }}
                                  >
                                    <span className="material-icons mr-1">
                                      close
                                    </span>{" "}
                                    Reject
                                  </Button>
                                </>
                              )}
                          </td>
                          <td />
                          <td
                            style={{
                              width: "20%",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            {placementReport.statusId === 4 &&
                              signatoryThree()?.id === user.id && (
                                <>
                                  <Button
                                    color="success"
                                    variant="contained"
                                    disableElevation
                                    style={{
                                      borderRadius: "8px",
                                      height: "32px",
                                      alignItems: "center",
                                    }}
                                    size="sm"
                                    onClick={() => {
                                      setFormData({
                                        statusId: 6,
                                        approvalLevelThreeUserId:
                                          signatoryThree().id,
                                        approvalLevelThreeNames:
                                          signatoryThree().lastName +
                                          " " +
                                          signatoryThree().firstName,

                                        approvalLevelThreePositionName:
                                          signatoryThree().positionName,

                                        approvalLevelThreeSchoolName:
                                          signatoryThree().schoolName,

                                        levelThreeApprovedOn: new Date(),
                                      });

                                      setConfirmSignPlacementReport(true);
                                    }}
                                  >
                                    <span className="material-icons mr-1">
                                      check_circle_outline
                                    </span>{" "}
                                    confirm
                                  </Button>

                                  <Button
                                    color="error"
                                    disableElevation
                                    variant="contained"
                                    className="ml-2"
                                    style={{
                                      borderRadius: "8px",
                                      height: "32px",
                                      alignItems: "center",
                                    }}
                                    size="sm"
                                    onClick={() => {
                                      setFormData({
                                        statusId: 5,
                                        approvalLevelThreeUserId:
                                          signatoryThree().id,
                                        approvalLevelThreeNames:
                                          signatoryThree().lastName +
                                          " " +
                                          signatoryThree().firstName,

                                        approvalLevelThreePositionName:
                                          signatoryThree().positionName,

                                        approvalLevelThreeSchoolName:
                                          signatoryThree().schoolName,

                                        levelThreeApprovedOn: new Date(),
                                      });

                                      setConfirmSignPlacementReport(true);
                                    }}
                                  >
                                    <span className="material-icons mr-1">
                                      close
                                    </span>{" "}
                                    Reject
                                  </Button>
                                </>
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="py-4"></DialogActions>
      </Dialog>

      {confirmSignPlacementReport && (
        <ConfirmationDialog
          confirmationDialog={confirmSignPlacementReport}
          message={
            <>
              <p className="mb-0 mt-3">
                Are you sure you want to{" "}
                <strong
                  className={`text-${
                    formData.statusId === 2 ||
                    formData.statusId === 4 ||
                    formData.statusId === 6
                      ? "success"
                      : "danger"
                  }`}
                >
                  {formData.statusId === 2 ||
                  formData.statusId === 4 ||
                  formData.statusId === 6
                    ? "confirm"
                    : "reject"}
                </strong>{" "}
                this placement report?
              </p>
              <TextField
                className="mt-2"
                multiline
                autoFocus
                rows={3}
                fullWidth
                type="number"
                size="small"
                name="comments"
                label="Your comments"
                variant="outlined"
                placeholder="Your comments"
                value={comments || ""}
                onChange={(e) => {
                  setComments(e.target.value || "");
                }}
              />
            </>
          }
          disabled={!comments}
          setConfirmationDialog={setConfirmSignPlacementReport}
          onYes={() => {
            signPlacementReport(
              placementReport.id,

              {
                ...formData,
                approvalLevelOneComment:
                  formData.statusId === 2 ? comments : undefined,

                approvalLevelTwoComment:
                  formData.statusId === 3 || formData.statusId === 4
                    ? comments
                    : undefined,

                approvalLevelThreeComment:
                  formData.statusId === 5 || formData.statusId === 6
                    ? comments
                    : undefined,
              },
              setConfirmSignPlacementReport
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  placementReportDetails,
  changeList,
  placementVacantPosts,
  prevPlacementReport,
  signatories,
  exitReasons,
}) => {
  return {
    user,
    loading,
    onWait,
    placementReportDetails,
    changeList,
    placementVacantPosts,
    prevPlacementReport,
    signatories,
    exitReasons,
  };
};

export default connect(mapStateToProps, {
  getPlacementReportDetails,
  signPlacementReport,
  endTeacherPlacement,
  downloadPlacementReport,
  getShoolPositions,
})(PlacementReportDetailsDialog);

const TeacherItem = (props) => {
  const {
    teacher,
    placementReport,
    isEditable,
    endTeacherPlacement,
    user,
    exitReasons,
    index,
  } = props;

  const [showRemoveItem, setShowRemoveItem] = useState(false);
  const [showUpdateTeacherProfileDialog, setShowUpdateTeacherProfileDialog] =
    useState(false);

  const [exitReason, setExitReason] = useState(null);
  const [exitComments, setExitComments] = useState("");

  return (
    <>
      <tr>
        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
          }}
        >
          {index + 1}
        </td>

        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
          }}
        >
          {teacher.budgetPostId}
        </td>

        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
          }}
        >
          {teacher.id}
        </td>

        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
          }}
        >
          {teacher.lastName} {teacher.firstName}
        </td>

        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
            maxWidth: "128px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <span className="d-flex align-items-center">
            {teacher.nidNumber}

            {!!teacher.nidNumber && !!teacher.isNIDVerified && (
              <Tooltip title="Verified">
                <span
                  className="material-icons ml-1 text-success"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                >
                  verified
                </span>
              </Tooltip>
            )}

            {!!teacher.nidNumber && !teacher.isNIDVerified && (
              <Tooltip title="Not Verfied">
                <span
                  className="material-icons ml-1 text-danger"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                >
                  report_problem
                </span>
              </Tooltip>
            )}
          </span>
        </td>

        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
            maxWidth: "128px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {teacher.gender}
        </td>

        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
            maxWidth: "128px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {teacher.nationality}
        </td>

        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
            maxWidth: "128px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {!!teacher.dateOfBirth
            ? moment(teacher.dateOfBirth).format("DD/MM/yyyy")
            : ""}
        </td>

        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
            maxWidth: "128px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {teacher.civilStatus}
        </td>

        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
          }}
        >
          {teacher.rssbNumber}
        </td>

        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
            maxWidth: "128px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {teacher.accountNumber} ({teacher.bankName})
        </td>

        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
            maxWidth: "192px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {teacher.positionName}
        </td>
        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
            borderRight: "1px solid #4f81bc",
          }}
        >
          {teacher.qualificationLevelName}
        </td>
        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
            borderRight: "1px solid #4f81bc",
          }}
        >
          {teacher.classificationName}
        </td>
        <td
          style={{
            fontSize: "10px",
            textAlign: "left",
            borderLeft: "1px solid #4f81bc",
            borderBottom: "1px solid #4f81bc",
            borderTop: "1px solid #4f81bc",
            borderRight: "1px solid #4f81bc",
          }}
        >
          {!!teacher.appointmentDate
            ? moment(teacher.appointmentDate).format("DD/MM/yyyy")
            : ""}
        </td>
        {!!isEditable && (
          <td
            style={{
              fontSize: "10px",
              textAlign: "right",
              borderRight: "1px solid #4f81bc",
              borderBottom: "1px solid #4f81bc",
              borderTop: "1px solid #4f81bc",
            }}
          >
            <span className="d-flex justify-content-between">
              <span
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #c8c6c6",
                  borderRadius: "10px",
                  padding: "2px",
                }}
              >
                <IconButton
                  size="small"
                  color="primary"
                  className="p-0"
                  onClick={() => setShowUpdateTeacherProfileDialog(true)}
                >
                  <span className="material-icons" style={{ fontSize: "18px" }}>
                    edit
                  </span>
                </IconButton>
              </span>

              <span
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #ea9f9f",
                  borderRadius: "10px",
                  padding: "1px",
                }}
              >
                <IconButton
                  size="small"
                  color="error"
                  className="p-0"
                  onClick={() => setShowRemoveItem(true)}
                >
                  <span className="material-icons" style={{ fontSize: "18px" }}>
                    delete_forever
                  </span>
                </IconButton>
              </span>
            </span>
          </td>
        )}
      </tr>

      {showRemoveItem && (
        <ConfirmationDialog
          disabled={!exitReason || !exitComments}
          confirmationDialog={showRemoveItem}
          message={
            <>
              <p className="mb-0 mt-3">
                Are you sure you want to{" "}
                <strong className="text-danger">remove</strong>{" "}
                <strong className="text-primary">
                  {teacher.lastName} {teacher.firstName}
                </strong>{" "}
                from teachers of{" "}
                <strong className="text-dark">
                  {user?.selectedEntity?.name}
                </strong>{" "}
                School?
              </p>

              <div className="mt-3">
                <Autocomplete
                  fullWidth
                  size="small"
                  defaultValue={null}
                  value={exitReason || null}
                  options={exitReasons}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, exitReason) => {
                    setExitReason(exitReason || null);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, exitReason) => (
                    <Box component="li" {...props}>
                      {exitReason.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select exit reason"
                      name="exitReason"
                      placeholder="Select exit reason"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </div>

              <div className="mt-3">
                <TextField
                  multiline
                  rows={2}
                  fullWidth
                  size="small"
                  name="exitComments"
                  label="Comments"
                  variant="outlined"
                  placeholder="Comments"
                  value={exitComments}
                  onChange={(e) => {
                    setExitComments(e.target.value || "");
                  }}
                />
              </div>
            </>
          }
          setConfirmationDialog={setShowRemoveItem}
          onYes={() =>
            endTeacherPlacement(
              {
                teacherPlacementId: teacher.teacherPlacementId,
                exitReasonId: exitReason?.id || null,
                exitComments,
                teachingLevelId: placementReport.teachingLevelId,
              },
              setShowRemoveItem
            )
          }
        />
      )}

      {showUpdateTeacherProfileDialog && (
        <UpdateTeacherDialog
          teacher={teacher}
          showDialog={showUpdateTeacherProfileDialog}
          setShowDialog={setShowUpdateTeacherProfileDialog}
          placementReport={placementReport}
        />
      )}
    </>
  );
};
