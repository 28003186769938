import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  Button,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { savePositionAdditionalBudgetPosts } from "../../../../store/posts/actions";
import { showError } from "../../../toastify";
import ConfirmApprovalPostRequestDialog from "./ConfirmApprovalPostRequestDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const PostRequestDetailsDialog = (props) => {
  const {
    user,
    loading,
    postRequest,
    showDialog,
    setShowDialog,
    savePositionAdditionalBudgetPosts,
    htPostLimits,
  } = props;

  const onClose = () => {
    setShowDialog(false);
  };

  const [showApproveDialog, setShowApproveDialog] = useState(false);

  const [isApproved, setIsApproved] = useState(null);

  const onApproval = (isApproved) => {
    setIsApproved(isApproved);

    setShowApproveDialog(true);
  };

  const [approvedNumber, setApprovedNumber] = useState(
    postRequest.statusId === 3
      ? postRequest.approvedNumber
      : postRequest.requestedNumber
  );
  const [error, setError] = useState("");
  const [confirmAdditionalBudgetPosts, setConfirmAdditionalBudgetPosts] =
    useState(false);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-primary font-weight-bold">
              Budget post request
            </span>{" "}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="row mt-2">
            <div className="col-8">
              <TextField
                style={{ backgroundColor: "#eee" }}
                fullWidth
                size="small"
                name="position"
                label="position"
                variant="outlined"
                placeholder="Position"
                value={postRequest?.positionName || ""}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>

            <div className="col-4">
              <TextField
                style={{ backgroundColor: "#eee" }}
                fullWidth
                size="small"
                name="qualificationLevelName"
                label="Qualification Level"
                variant="outlined"
                placeholder="Qualification Level"
                value={postRequest.qualificationLevelName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
            <div className="mt-3 col-6">
              <TextField
                style={{ backgroundColor: "#eee" }}
                fullWidth
                size="small"
                name="budgetPosts"
                label="Current Posts"
                variant="outlined"
                placeholder="Current Posts"
                value={postRequest.currentBudgetPosts}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
            <div className="mt-3 col-6">
              <TextField
                // style={{ backgroundColor: "#eee" }}
                fullWidth
                size="small"
                name="newBudgetPosts"
                label="Requested Posts"
                variant="outlined"
                placeholder="Requested Posts"
                value={postRequest.requestedNumber}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
            <div className="mt-3 col-12">
              <TextField
                // style={{ backgroundColor: "#eee" }}
                multiline
                rows={2}
                fullWidth
                type="number"
                size="small"
                name="reason"
                label="Reason"
                variant="outlined"
                placeholder="Reason"
                value={postRequest.reason}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>

            <div className="mt-2 col-12 text-center">
              Status:{" "}
              <span
                className={`badge badge-${
                  postRequest.statusId === 1
                    ? "secondary"
                    : postRequest.statusId === 2
                    ? "primary"
                    : postRequest.statusId === 3 || postRequest.statusId === 6
                    ? "success"
                    : postRequest.statusId === 4
                    ? "warning"
                    : "danger"
                } `}
              >
                {postRequest.statusName}
              </span>
            </div>
            {postRequest.statusId !== 1 && (
              <div className="mt-3 col-12">
                <TextField
                  style={{ backgroundColor: "#eee" }}
                  multiline
                  rows={2}
                  fullWidth
                  type="number"
                  size="small"
                  name="ddeComment"
                  label="DDE Comment"
                  variant="outlined"
                  placeholder="DDE Comment"
                  value={postRequest.ddeComment || " "}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            )}

            {(postRequest.statusId === 3 ||
              (postRequest.statusId === 1 &&
                user.selectedEntity.role === "DDE")) && (
              <div className="mt-3 col-12 alert alert-primary">
                <div>
                  <TextField
                    style={{ backgroundColor: "#fff", borderRadius: "6px" }}
                    fullWidth
                    autoFocus
                    size="small"
                    name="increaseNumber"
                    label="Approved post"
                    variant="outlined"
                    placeholder="Approved post"
                    value={approvedNumber}
                    InputProps={{
                      readOnly: postRequest.statusId !== 1,
                    }}
                    onBlur={() => {
                      if (approvedNumber === "") setApprovedNumber(0);
                    }}
                    onKeyDown={() => {
                      if (+approvedNumber === 0) setApprovedNumber("");
                    }}
                    onChange={(e) => {
                      setError("");
                      setApprovedNumber(e.target.value || 0);
                    }}
                  />
                  {error && <small className="text-danger">{error}</small>}
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          {user.selectedEntity.role === "DDE" && postRequest.statusId === 1 && (
            <span className="d-flex justify-content-center">
              <Button
                color="success"
                variant="contained"
                onClick={() => onApproval(true)}
                type="button"
                className="px-4 mx-2"
                disabled={
                  loading ||
                  !approvedNumber ||
                  +approvedNumber > +postRequest.requestedNumber
                }
              >
                Approve
              </Button>
              <Button
                color="error"
                variant="contained"
                onClick={() => onApproval(false)}
                type="button"
                className="px-4 mx-2"
                disabled={loading}
              >
                Reject
              </Button>
            </span>
          )}
        </DialogActions>
      </Dialog>

      {showApproveDialog && (
        <ConfirmApprovalPostRequestDialog
          showDialog={showApproveDialog}
          setShowDialog={setShowApproveDialog}
          onClose={onClose}
          postRequest={postRequest}
          isApproved={isApproved}
          approvedNumber={approvedNumber}
        />
      )}

      {/* {confirmAdditionalBudgetPosts && (
        <ConfirmationDialog
          confirmationDialog={confirmAdditionalBudgetPosts}
          message={`Are you sure you want to add ${approvedNumber} additional posts on the position of ${postRequest?.positionName}?`}
          setConfirmationDialog={setConfirmAdditionalBudgetPosts}
          onYes={() => {
            savePositionAdditionalBudgetPosts(
              {
                budgetPostRequestId: postRequest.id,
                additionalBudgetPosts: +approvedNumber || 0,
              },
              setConfirmAdditionalBudgetPosts
            );
          }}
        />
      )} */}
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  unitTypes,
  units,
  user,
  sectors,
  cells,
  villages,
  schoolLevels,
  jobs,
  selectedUnit,
  qualificationLevels,
  htPostLimits,
}) => {
  return {
    loading,
    onWait,
    unitTypes,
    units,
    user,
    sectors,
    cells,
    villages,
    schoolLevels,
    jobs,
    selectedUnit,
    qualificationLevels,
    htPostLimits,
  };
};

export default connect(mapStateToProps, {
  savePositionAdditionalBudgetPosts,
})(PostRequestDetailsDialog);
