import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  Button,
  Divider,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveBudgetPostRequest } from "../../../../store/posts/actions";
import { showError } from "../../../toastify";

const BudgetPostRequestDialog = (props) => {
  const {
    loading,
    onWait,
    // position,
    showDialog,
    setShowDialog,
    saveBudgetPostRequest,
    pudgetPostRequestPositions,
  } = props;

  const [position, setPosition] = useState(null);

  const [errors, setErrors] = useState({
    numberHasError: false,
    reasonHasError: false,
  });

  const [formData, setFormData] = useState({
    number: 0,
    reason: "",
  });

  const formValidator = () => {
    const error = {
      numberHasError: false,
      reasonHasError: false,
      hasError: false,
    };

    if (!+formData.number || +formData.number > 5) {
      error.numberHasError = true;
      error.hasError = true;
    }

    // if (!formData.reason) {
    //   error.reasonHasError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onClose = () => {
    setShowDialog(false);

    setFormData({
      number: 0,
      reason: "",
    });
  };

  const onSave = () => {
    if (!formValidator()) {
      saveBudgetPostRequest(
        {
          ...formData,
          positionId: position?.id,
          schoolPositionId: position?.schoolPositionId,
        },
        setFormData,
        setShowDialog
      );
    }
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-primary font-weight-bold">
              Request for New Budget Post
            </span>{" "}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="row">
            <div className="col-12 mt-3 ">
              <Autocomplete
                // style={{
                //   backgroundColor: "#e9ecef",
                // }}
                // readOnly
                size="small"
                defaultValue={null}
                value={position || null}
                options={pudgetPostRequestPositions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.id + " - " + option.name}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <div>
                      <span className="d-block text-primary">
                        {option.id} - {option.name}
                      </span>

                      <small className="d-block mb-1 ">
                        {/* <span
                          className={`badge badge badge-${
                            +option.budgetPosts - +option.numberOfEmployees > 0
                              ? "success"
                              : "danger"
                          } mr-1`}
                        >
                          {+option.budgetPosts - +option.numberOfEmployees}{" "}
                          Vacant
                          {+option.budgetPosts - +option.numberOfEmployees > 1
                            ? "s"
                            : ""}
                        </span>
                        / */}
                        <span
                          className={`badge badge-${
                            !!option.budgetPosts ? "secondary" : "danger"
                          } ml-1`}
                        >
                          {!!option.budgetPosts ? (
                            <>
                              {option.budgetPosts} Current post
                              {option.budgetPosts > 1 ? "s" : ""}
                            </>
                          ) : (
                            <>No post</>
                          )}
                        </span>
                      </small>
                      <Divider />
                      <Divider />
                    </div>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="position"
                    label="Select position"
                    placeholder="Select position"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
                onChange={(event, position) => {
                  setPosition(position || null);

                  // setErrors({
                  //   ...errors,
                  //   positionHasError:
                  //     (!!position &&
                  //       +position?.budgetPosts -
                  //         +position?.numberOfEmployees >
                  //         0) ||
                  //     position?.id === teacher.schoolPositionId
                  //       ? false
                  //       : true,
                  // });
                }}
              />
              {errors.positionHasError && (
                <small className="text-danger">Position is required</small>
              )}
            </div>
            {/* <div className="col-12">
              <TextField
                style={{ backgroundColor: "#eee" }}
                fullWidth
                size="small"
                name="position"
                label="position"
                variant="outlined"
                placeholder="Position"
                value={position?.name || ""}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div> */}

            <div className="mt-3 col-6">
              <TextField
                style={{ backgroundColor: "#eee" }}
                fullWidth
                size="small"
                name="qualificationLevelName"
                label="Qualification Level"
                variant="outlined"
                placeholder="Qualification Level"
                value={position?.qualificationLevelName || ""}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
            <div className="mt-3 col-6">
              <TextField
                style={{ backgroundColor: "#eee" }}
                fullWidth
                size="small"
                name="budgetPosts"
                label="Current Number"
                variant="outlined"
                placeholder="Current Number"
                value={position?.budgetPosts || 0}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
            <div className="mt-3 col-12">
              <TextField
                fullWidth
                size="small"
                name="newBudgetPosts"
                label="Additional Budget Posts"
                variant="outlined"
                placeholder="Additional Budget Posts"
                value={formData.number}
                onBlur={() => {
                  if (formData.number === "")
                    setFormData({
                      ...formData,
                      number: 0,
                    });
                }}
                onKeyDown={() => {
                  if (+formData.number === 0)
                    setFormData({
                      ...formData,
                      number: "",
                    });
                }}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    numberHasError: false,
                  });

                  setFormData({
                    ...formData,
                    number: e.target.value || 0,
                  });
                }}
              />
              {errors.numberHasError && (
                <small className="text-danger ">
                  {!!formData.number ? "Invalid number" : "Required"}{" "}
                </small>
              )}
            </div>
            <div className="mt-3 col-12">
              <TextField
                multiline
                rows={2}
                fullWidth
                type="number"
                size="small"
                name="reason"
                label="Reason"
                variant="outlined"
                placeholder="Reason"
                value={formData.reason}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    reasonHasError: false,
                  });

                  setFormData({
                    ...formData,
                    reason: e.target.value || "",
                  });
                }}
              />
              {errors.reasonHasError && (
                <small className="text-danger ">Required</small>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            variant="contained"
            onClick={onSave}
            type="button"
            className="px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  unitTypes,
  units,
  user,
  sectors,
  cells,
  villages,
  schoolLevels,
  jobs,
  selectedUnit,
  qualificationLevels,
  pudgetPostRequestPositions,
}) => {
  return {
    loading,
    onWait,
    unitTypes,
    units,
    user,
    sectors,
    cells,
    villages,
    schoolLevels,
    jobs,
    selectedUnit,
    qualificationLevels,
    pudgetPostRequestPositions,
  };
};
export default connect(mapStateToProps, {
  saveBudgetPostRequest,
})(BudgetPostRequestDialog);
