import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  Divider,
  TextField,
  Tooltip,
  Autocomplete,
  Toolbar,
} from "@mui/material";
import { connect } from "react-redux";
import { getUnsubmittedReports } from "../../../../store/placement/actions";

import ReactPaginate from "react-paginate";
import { TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const Unsubmitted = (props) => {
  useEffect(() => {
    document.title = "TMIS | Post Management - Unsubmitted Reports";
  }, []);

  const { user, loading, unsubmitted, getUnsubmittedReports } = props;

  useEffect(() => {
    getUnsubmittedReports();
  }, [user?.selectedEntity, user?.selectedAcademicYear]);

  const handleToggleNode = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (!!unsubmitted.length) setExpanded([`${unsubmitted[0].districtId}`]);
  }, [unsubmitted]);

  const formatList = () => {
    const districts = [];

    unsubmitted.forEach((school) => {
      const districtIndex = districts.findIndex(
        ({ districtId }) => districtId === school.districtId
      );

      if (districtIndex >= 0) districts[districtIndex].schools.push(school);
      else
        districts.push({
          districtId: school.districtId,
          districtName: school.districtName,
          schools: [school],
        });
    });

    return districts;
  };

  return (
    <>
      <div className="px-4 pt-2">
        <div className="border rounded">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar
              style={{
                minHeight: "0px",
                borderBottom: "3px solid #e9ecef",
              }}
              className=" my-0 py-1 bg-light"
            >
              <Typography
                variant="p"
                noWrap
                component="div"
                className="d-flex  ilign-items-center text-uppercase text-primary font-weight-bold"
              >
                <span className="text-dark">
                  ({unsubmitted.length}) Unsubmitted
                </span>
                <span className="material-icons" style={{ color: "#cecece" }}>
                  chevron_right
                </span>

                <span
                  className="d-block text-truncate"
                  style={{ maxWidth: "70%" }}
                >
                  {user?.selectedEntity?.name}{" "}
                  {!!user?.selectedEntity?.type &&
                  !(user?.selectedEntity?.name)
                    .toUpperCase()
                    .includes(user?.selectedEntity?.type)
                    ? user?.selectedEntity?.type
                    : ""}
                  {user?.selectedEntity?.id === "00000000" && <>S</>}
                </span>
              </Typography>
              <Box sx={{ flexGrow: 1 }} />

              <span className="text-uppercase">
                {unsubmitted[0]?.periodName}{" "}
              </span>
            </Toolbar>
          </AppBar>

          <div
            style={{
              backgroundColor: "#f3f4f6",
              borderRadius: "0.5rem",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              height: "69vh",
              overflowY: "auto",
            }}
            className="px-3"
          >
            <TreeView
              aria-label="controlled"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              onNodeToggle={handleToggleNode}
            >
              {formatList().map((district, index1) => (
                <TreeItem
                  key={`${district.districtId}`}
                  className={`nav-item bg-white mb-2 `}
                  nodeId={`${district.districtId}`}
                  label={
                    <span
                      className="d-flex justify-content-between  align-items-center py-2"
                      style={{ maxWidth: "100%" }}
                    >
                      <span className={`d-block`}>
                        <span
                          className="mr-2 menu-header text-uppercase text-primary"
                          style={{
                            cursor: "default",
                          }}
                        >
                          {index1 + 1}: {district.districtName} DISTRICT{" "}
                          <small>({district?.schools?.length})</small>
                        </span>
                      </span>
                      <span>
                        {" "}
                        <span className="badge badge-pill font-weight-light text-primary mr-1">
                          {
                            district?.schools?.filter(
                              ({ teachingLevelId }) => teachingLevelId === 1
                            ).length
                          }{" "}
                          Pre-primary
                        </span>
                        <span className="badge badge-pill font-weight-light text-primary mr-1">
                          {
                            district?.schools?.filter(
                              ({ teachingLevelId }) => teachingLevelId === 2
                            ).length
                          }{" "}
                          Primary
                        </span>
                        <span className="badge badge-pill font-weight-light text-primary mr-1">
                          {
                            district?.schools?.filter(
                              ({ teachingLevelId }) => teachingLevelId === 3
                            ).length
                          }{" "}
                          Secondary
                        </span>
                      </span>
                    </span>
                  }
                >
                  <SchoolList unsubmitted={district.schools} user={user} />

                  <div className="ml-n3">
                    <Divider />
                    <Divider />
                  </div>
                </TreeItem>
              ))}
            </TreeView>

            {!unsubmitted?.length && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container py-5 ">
                  <p className="lead">
                    {loading ? "Fetching data..." : "No record found"}{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, loading, unsubmitted }) => {
  return { user, loading, unsubmitted };
};
export default connect(mapStateToProps, {
  getUnsubmittedReports,
})(Unsubmitted);

const SchoolList = (props) => {
  const { unsubmitted, user } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const onSearch = (term) => {
    setSearchTerm(term);
  };

  const [filteredUnsubmitted, setFilteredUnsubmitted] = useState([
    ...unsubmitted,
  ]);

  useEffect(() => {
    const filtered = [...unsubmitted].filter(
      ({
        teachingLevelName,
        schoolName,
        schoollevelName,
        sectorName,
        districtName,
        periodName,
        periodId,
      }) =>
        (
          (teachingLevelName || "") +
          (schoolName || "") +
          (schoollevelName || "") +
          (sectorName || "") +
          (districtName || "") +
          (periodName || "") +
          (periodId || "")
        )
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(searchTerm.toLowerCase().replace(/\s/g, ""))
    );
    setFilteredUnsubmitted(filtered);

    paginate(filtered);
  }, [searchTerm, unsubmitted]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 15;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredUnsubmitted);
  }, [itemOffset, itemsPerPage, filteredUnsubmitted]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredUnsubmitted.length;

    setItemOffset(newOffset);
  };

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  return (
    <>
      {!!unsubmitted.length && (
        <table className="table table-striped table-hover table-sm">
          <thead>
            <tr>
              <th
                scope="col"
                style={{
                  borderLeft: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                  borderTop: "1px solid #a8b6c3",
                }}
              >
                #
              </th>

              <th
                scope="col"
                style={{
                  borderLeft: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                  borderTop: "1px solid #a8b6c3",
                }}
              >
                <span className="d-flex align-items-center">
                  <span className="mr-2">SCHOOLS</span>{" "}
                  <SearchBox onSearch={onSearch} placeholder="Search…" />
                </span>
              </th>
              <th
                className="text-left text-uppercase"
                style={{
                  borderLeft: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                  borderTop: "1px solid #a8b6c3",
                }}
              >
                STATUS
              </th>
              <th
                className="text-left text-uppercase"
                style={{
                  borderLeft: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                  borderTop: "1px solid #a8b6c3",
                }}
              >
                TEACHING LEVEL
              </th>
            </tr>
          </thead>

          {!!paginatedItems && (
            <tbody>
              {paginatedItems.map((school) => (
                <ItemCard
                  key={school.id}
                  index={getNo(unsubmitted, "id", school.id)}
                  school={school}
                />
              ))}
            </tbody>
          )}
        </table>
      )}
      {!!unsubmitted.length && (
        <div className="mb-3 mt-n2">
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <>
                <span className="pr-1 d-none d-md-inline">Next</span>
                <i className="fas fa-angle-double-right"></i>
              </>
            }
            previousLabel={
              <>
                <i className="fas fa-angle-double-left"></i>
                <span className="pl-1  d-none d-md-inline">Previous</span>
              </>
            }
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
            previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
            nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
            activeLinkClassName="active"
          />
        </div>
      )}
    </>
  );
};

const ItemCard = (props) => {
  const { school, index } = props;

  return (
    <>
      <tr className={``}>
        <th
          scope="row"
          className="text-left"
          style={{
            borderLeft: "solid 1px #c6c7ce",
          }}
        >
          {index + 1}
        </th>

        <td
          className="text-uppercase"
          style={{
            borderLeft: "solid 1px #c6c7ce",
          }}
        >
          {school.schoolName} / {school.sectorName}
        </td>
        <td
          className="text-left"
          style={{
            borderLeft: "solid 1px #c6c7ce",
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {school.schoolStatus}
        </td>
        <td
          className="text-left"
          style={{
            borderLeft: "solid 1px #c6c7ce",
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {school.teachingLevelName}
        </td>
      </tr>
    </>
  );
};
