const types = {
  SET_TREE_UNITS: "SET_TREE_UNITS",
  SET_UNITS: "SET_UNITS",
  ADD_NEW_UNIT: "ADD_NEW_UNIT",
  SET_SCHOOL_POSITIONS: "SET_SCHOOL_POSITIONS",
  ADD_NEW_SCHOOL_POSITION: "ADD_NEW_SCHOOL_POSITION",
  UPDATE_SCHOOL_POSITION: "UPDATE_SCHOOL_POSITION",
  DELETE_SCHOOL_POSITION: "DELETE_SCHOOL_POSITION",
  SET_JOBS: "SET_JOBS",
  SET_SELECTED_UNIT: "SET_SELECTED_UNIT",

  SET_SECTORS: "SET_SECTORS",
  SET_CELLS: "SET_CELLS",
  SET_VILLAGES: "SET_VILLAGES",
  SET_SCHOOL_LEVELS: "SET_SCHOOL_LEVELS",
  UPDATE_UNIT: "UPDATE_UNIT",
  DELETE_UNIT: "DELETE_UNIT",

  SET_QUALIFICATION_LEVELS: "SET_QUALIFICATION_LEVELS",
  SET_POSITION_BUDGET_POSTS: "SET_POSITION_BUDGET_POSTS",
  SET_BUDGET_POST_REQUESTS: "SET_BUDGET_POST_REQUESTS",
  ADD_OR_UPDATE_BUDGET_POST_REQUEST: "ADD_OR_UPDATE_BUDGET_POST_REQUEST",
  SET_REB_POST_DISTRIBUTIONS: "SET_REB_POST_DISTRIBUTIONS",
  UPDATE_REB_POST_DISTRIBUTION: "UPDATE_REB_POST_DISTRIBUTION",

  SET_DDE_POST_DISTRIBUTIONS: "SET_DDE_POST_DISTRIBUTIONS",
  UPDATE_DDE_POST_DISTRIBUTION: "UPDATE_DDE_POST_DISTRIBUTION",

  SET_HT_POST_DISTRIBUTIONS: "SET_HT_POST_DISTRIBUTIONS",
  UPDATE_HT_POST_DISTRIBUTION: "UPDATE_HT_POST_DISTRIBUTION",

  SET_REB_POST_LIMITS: "SET_REB_POST_LIMITS",
  SET_DDE_POST_LIMITS: "SET_DDE_POST_LIMITS",
  SET_HT_POST_LIMITS: "SET_HT_POST_LIMITS",

  SET_DISTRICTS: "SET_DISTRICTS",

  SET_SCHOOL_TEACHERS: "SET_SCHOOL_TEACHERS",
  ADD_OR_UPDATE_NEW_SCHOOL_TEACHER: "ADD_OR_UPDATE_NEW_SCHOOL_TEACHER",
  // UPDATE_SCHOOL_TEACHER: "UPDATE_SCHOOL_TEACHER",
  DELETE_SCHOOL_TEACHER: "DELETE_SCHOOL_TEACHER",

  SET_SYSTEM_SETTING_POSITIONS: "SET_SYSTEM_SETTING_POSITIONS",
  ADD_OR_UPDATE_SYSTEM_SETTING_POSITION:
    "ADD_OR_UPDATE_SYSTEM_SETTING_POSITION",
  DELETE_SYSTEM_SETTING_POSITION: "DELETE_SYSTEM_SETTING_POSITION",

  SET_POSITION_ROLES: "SET_POSITION_ROLES",

  DELETE_POSITION_BUDGET_POST: "DELETE_POSITION_BUDGET_POST",
  SET_SCHOOLS: "SET_SCHOOLS",
  SET_POSITIONS: "SET_POSITIONS",

  SET_PUDGETPOST_REQUEST_POSITIONS: "SET_PUDGETPOST_REQUEST_POSITIONS",

  DELETE_BUDGET_POST_REQUEST: "DELETE_BUDGET_POST_REQUEST",
  SET_TEACHING_LEVELS: "SET_TEACHING_LEVELS",
};

export default types;
