export const defaultState = {
  transferRequests: [],
  permutationRequests: [],
  permutationRequestAttachments: [],
  transferRequestAttachments: [],
  permutationSummary: {},
  transferSummary: {},

  permutationSchedule: null,
  transferSchedule: null,

  transferEligibility: {},
};
