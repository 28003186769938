import types from "./action-types";
import { defaultState } from "./state";

const env = (envState = defaultState.env, action) => {
  switch (action.type) {
    case types.SET_ENV:
      return action.data;

    default:
      return envState;
  }
};

const loading = (loadingState = defaultState.loading, action) => {
  switch (action.type) {
    case types.START_LOADING:
      return true;

    case types.END_LOADING:
      return false;

    case types.CLEAN_STATE:
      return defaultState.loading;

    default:
      return loadingState;
  }
};

const onWait = (waitState = defaultState.onWait, action) => {
  switch (action.type) {
    case types.START_WAIT:
      return true;

    case types.END_WAIT:
      return false;

    case types.CLEAN_STATE:
      return defaultState.onWait;

    default:
      return waitState;
  }
};

const isFetchingUser = (state = defaultState.fetchingUser, action) => {
  switch (action.type) {
    case types.END_LOADING:
      return false;

    default:
      return state;
  }
};

const strongPassword = (
  strongPasswordState = defaultState.strongPassword,
  action
) => {
  switch (action.type) {
    case types.SET_STRONG_PASSWORD:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.strongPassword;

    default:
      return strongPasswordState;
  }
};

const user = (userState = defaultState.user, action) => {
  switch (action.type) {
    case types.SET_USER:
      return action.data;

    case types.SET_SELECTED_ACADEMIC_YEAR: {
      const tmpUserState = { ...userState };

      tmpUserState.selectedAcademicYear = action.data;

      return tmpUserState;
    }

    case types.SET_SELECTED_USER_ENTITY: {
      const tmpUserState = { ...userState };

      tmpUserState.selectedEntity = action.data;

      return tmpUserState;
    }

    case types.SET_SELECTED_SCOPE: {
      const tmpUserState = { ...userState };

      tmpUserState.selectedScope = action.data;

      return tmpUserState;
    }

    case types.LOGOUT:
      return defaultState.user;

    default:
      return userState;
  }
};

const academicYears = (
  academicYearsState = defaultState.academicYears,
  action
) => {
  switch (action.type) {
    case types.SET_ACADEMIC_YEARS:
      return action.data;

    case types.UPDATE_ACADEMIC_YEAR: {
      const tmpState = [...academicYearsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.academicYears;

    default:
      return academicYearsState;
  }
};

const userEntities = (
  userEntitiesState = defaultState.userEntities,
  action
) => {
  switch (action.type) {
    case types.SET_USER_ENTITIES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.userEntities;

    default:
      return userEntitiesState;
  }
};

const scopes = (scopeState = defaultState.scopes, action) => {
  switch (action.type) {
    case types.SET_SCOPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.scopes;

    default:
      return scopeState;
  }
};

export default {
  loading,
  onWait,
  isFetchingUser,
  strongPassword,
  user,
  env,
  academicYears,
  userEntities,
  scopes,
};
