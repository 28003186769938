const types = {
  SET_TRANSFER_REQUESTS: "SET_TRANSFER_REQUESTS",
  ADD_NEW_TRANSFER_REQUEST: "ADD_NEW_TRANSFER_REQUEST",
  UPDATE_TRANSFER_REQUEST: "UPDATE_TRANSFER_REQUEST",
  DELETE_TRANSFER_REQUEST: "DELETE_TRANSFER_REQUEST",

  SET_PERMUTATION_REQUESTS: "SET_PERMUTATION_REQUESTS",
  ADD_NEW_PERMUTATION_REQUEST: "ADD_NEW_PERMUTATION_REQUEST",
  UPDATE_PERMUTATION_REQUEST: "UPDATE_PERMUTATION_REQUEST",
  DELETE_PERMUTATION_REQUEST: "DELETE_PERMUTATION_REQUEST",
  SET_PERMUTATION_REQUEST_ATTACHEMENTS: "SET_PERMUTATION_REQUEST_ATTACHEMENTS",
  SET_TRANSFER_REQUEST_ATTACHEMENTS: "SET_TRANSFER_REQUEST_ATTACHEMENTS",
  SET_PERMUTATION_SUMMARY: "SET_PERMUTATION_SUMMARY",
  SET_TRANSFER_SUMMARY: "SET_TRANSFER_SUMMARY",
  UPDATE_TRANSFER_REQUEST_ATTACHEMENT: "UPDATE_TRANSFER_REQUEST_ATTACHEMENT",

  SET_TRANSFER_SCHEDULE: "SET_TRANSFER_SCHEDULE",
  SET_PERMUTATION_SCHEDULE: "SET_PERMUTATION_SCHEDULE",

  SET_TRANSFER_ELIGIBILITY: "SET_TRANSFER_ELIGIBILITY",
};

export default types;
