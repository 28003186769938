import structure from "./action-types";
import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";
import commonTypes from "../common/action-types";
import download from "downloadjs";

const types = { ...commonTypes, ...structure };

export const getTransferRequests = (query, setTotalRecords, setLoader) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      setLoader(true);

      const { data } = await axios.get(
        "/api/transfer/requests" + "?" + new URLSearchParams(query).toString()
      );

      setLoader(false);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_TRANSFER_REQUESTS,
        data: data.records,
      });

      setTotalRecords(data.totalRecords);
    } catch (error) {
      setLoader(false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveTransferRequest = (formData, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/transfer/requests", formData);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_NEW_TRANSFER_REQUEST,
        data,
      });

      showSuccess("Submitted successfully");

      setShowDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const deleteTransferRequest = (id, setConfirmDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/transfer/requests/" + id);

      dispatch({
        type: types.DELETE_TRANSFER_REQUEST,
        id,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      setConfirmDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const acceptedTransferRequest = (
  { requestId, accepted },
  setShowDialog
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/transfer/accept", {
        requestId,
        accepted,
      });

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.UPDATE_TRANSFER_REQUEST,
        data,
      });

      showSuccess(
        `Transfer ${accepted ? "accepted" : "rejected"}  successfully`
      );

      setShowDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const executeTransferAlgorithm = (setConfirmDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/transfer/execute");

      dispatch({ type: types.END_LOADING });

      setConfirmDialog(false);

      dispatch({
        type: types.SET_TRANSFER_REQUESTS,
        data: data.records,
      });

      showSuccess("Transfer algorithm executed successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPermutationRequests = (query, setTotalRecords, setLoader) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      setLoader(true);
      const { data } = await axios.get(
        "/api/permutation/requests" +
          "?" +
          new URLSearchParams(query).toString()
      );
      setLoader(false);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_PERMUTATION_REQUESTS,
        data: data.records,
      });

      setTotalRecords(data.totalRecords);
    } catch (error) {
      setLoader(false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const savePermutationRequest = (formData, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/permutation/requests", formData);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_NEW_PERMUTATION_REQUEST,
        data,
      });

      showSuccess("Submitted successfully");

      setShowDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const acceptedPermutation = (
  { requestId, accepted, teacherTwoMessage, isReb, rebRejectionMessage },
  setShowDialog
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/permutation/accept", {
        requestId,
        accepted,
        teacherTwoMessage,
        isReb,
        rebRejectionMessage,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.UPDATE_PERMUTATION_REQUEST,
        data,
      });

      showSuccess(
        `Request ${accepted ? "accepted" : "rejected"}  successfully`
      );

      setShowDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const onTeacherTwoAccept = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/permutation/on-teacher-two-accept",
        payload
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.UPDATE_PERMUTATION_REQUEST,
        data,
      });

      showSuccess(`Request accepted`);

      cb();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const deletePermutationRequest = (id, setConfirmDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/permutation/requests/" + id);

      dispatch({
        type: types.DELETE_PERMUTATION_REQUEST,
        id,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      setConfirmDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const publishPermutationRequests = (setConfirmDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/permutation/publish");

      dispatch({ type: types.END_LOADING });

      setConfirmDialog(false);

      dispatch({
        type: types.SET_PERMUTATION_REQUESTS,
        data: data.records,
      });

      dispatch({
        type: types.SET_PERMUTATION_SUMMARY,
        data: data.summary,
      });

      dispatch({
        type: types.SET_PERMUTATION_SCHEDULE,
        data: data.permutationSchedule,
      });

      showSuccess("Published successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const publishTransferRequests = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/transfer/publish");

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_TRANSFER_REQUESTS,
        data: data.records,
      });

      dispatch({
        type: types.SET_TRANSFER_SCHEDULE,
        data: data.transferSchedule,
      });

      cb();

      showSuccess("Published successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPermutationRequestAttachment = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_PERMUTATION_REQUEST_ATTACHEMENTS,
        data: [],
      });
      const { data } = await axios.get("/api/permutation/attachments/" + id);

      dispatch({
        type: types.SET_PERMUTATION_REQUEST_ATTACHEMENTS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getTransferRequestAttachment = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_TRANSFER_REQUEST_ATTACHEMENTS,
        data: [],
      });

      const { data } = await axios.get("/api/transfer/attachments/" + id);

      dispatch({
        type: types.SET_TRANSFER_REQUEST_ATTACHEMENTS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getPermutationSummary = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_PERMUTATION_SUMMARY,
        data: {},
      });

      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/permutation/summary");
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_PERMUTATION_SUMMARY,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getTransferSummary = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_TRANSFER_SUMMARY,
        data: {},
      });

      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/transfer/summary");
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_TRANSFER_SUMMARY,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const approveTransferAttachment = (
  payload,
  setShowConfirmAttachment
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/transfer/approve-attachment",
        payload
      );

      dispatch({
        type: types.UPDATE_TRANSFER_REQUEST,
        data: data.transferRequest,
      });

      dispatch({
        type: types.UPDATE_TRANSFER_REQUEST_ATTACHEMENT,
        data: data.attachment,
      });

      setShowConfirmAttachment(false);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const downloadTransferLetter = (requestId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/transfer/letter/" + requestId, {
        responseType: "blob",
      });

      download(new Blob([data]), `${requestId}.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const downloadPermutationLetter = (letterNo) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/permutation/letter/" + letterNo, {
        responseType: "blob",
      });

      download(new Blob([data]), `${letterNo}.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const getPermutationSchedule = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/permutation/schedules");

      dispatch({
        type: types.SET_PERMUTATION_SCHEDULE,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};
export const savePermutationSchedule = (payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/permutation/schedules", payload);

      dispatch({
        type: types.SET_PERMUTATION_SCHEDULE,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};
export const deletePermutationSchedule = (id, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/permutation/schedules/" + id);

      dispatch({
        type: types.SET_PERMUTATION_SCHEDULE,
        data: null,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getTransferSchedule = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/transfer/schedules");

      dispatch({
        type: types.SET_TRANSFER_SCHEDULE,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};
export const saveTransferSchedule = (payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/transfer/schedules", payload);

      dispatch({
        type: types.SET_TRANSFER_SCHEDULE,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};
export const deleteTransferSchedule = (id, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/transfer/schedules/" + id);

      dispatch({
        type: types.SET_TRANSFER_SCHEDULE,
        data: null,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getTransferEligibility = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/transfer/eligibility");

      dispatch({
        type: types.SET_TRANSFER_ELIGIBILITY,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const downloadTransferExcelReport = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/transfer/excel-report", {
        responseType: "blob",
      });

      download(new Blob([data]), `TEACHER TRANSFERS.xlsx`, ".xlsx");

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      showError(error);
    }
  };
};
