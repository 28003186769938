import React, { useState, useEffect, Fragment } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import TransferRequestDialog from "../components/TransferRequestDialog";
import {
  getTransferRequests,
  deleteTransferRequest,
  executeTransferAlgorithm,
  acceptedTransferRequest,
  downloadTransferLetter,
  getTransferSchedule,
} from "../../../../store/transfer/actions";
import ReactPaginate from "react-paginate";
import moment from "moment";
// import TransferRequestDetailsDialog from "../components/TransferRequestDetailsDialog";
import { showError } from "../../../toastify";
import axios from "axios";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import TransferRequestPreviewDialog from "../components/TransferRequestPreviewDialog";
import TransferSummaryDialog from "../components/TransferSummaryDialog";
import TransferScheduleDialog from "../components/TransferScheduleDialog";

import { downloadTransferExcelReport } from "../../../../store/transfer/actions";

function calculateTimeLeft(transferSchedule) {
  const year = new Date().getFullYear();
  const deadLine = !!transferSchedule
    ? new Date(moment(transferSchedule.endOn).format("yyyy-MM-DD"))
    : new Date(moment().format("yyyy-MM-DD"));

  deadLine.setDate(deadLine.getDate() + 1);
  deadLine.setHours(deadLine.getHours() - 1);

  const toDay = new Date();

  const difference = +deadLine - +toDay;

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
}

const Transfers = (props) => {
  useEffect(() => {
    document.title = "TMIS | Transfers - Requests";
  }, []);

  const {
    user,
    loading,
    transferRequests,
    getTransferRequests,
    deleteTransferRequest,
    executeTransferAlgorithm,
    acceptedTransferRequest,
    env,
    downloadTransferLetter,
    transferSchedule,
    getTransferSchedule,
    downloadTransferExcelReport,
  } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecods, setTotalRecods] = useState(0);
  const [loader, setLoader] = useState(false);

  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );

  useEffect(() => {
    getTransferSchedule();
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
    };
    getTransferRequests(query, setTotalRecods, setLoader);
  }, [user.selectedEntity, user.selectedAcademicYear]);

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };
      getTransferRequests(query, setTotalRecods, setLoader);
    }
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };

    getTransferRequests(query, setTotalRecods, setLoader);
  };

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  const [showTransferRequestDialog, setShowTransferRequestDialog] =
    useState(false);

  const [confirmExecute, setConfirmExecute] = useState(false);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(transferSchedule));

  useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(transferSchedule));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  const timerComponents = Object.keys(timeLeft).map((interval, index) => {
    if (!timeLeft[interval]) {
      <span key={index}>00 {interval} </span>;
    }

    return (
      <span key={index}>
        {timeLeft[interval].toString().padStart(2, "0")} {interval}{" "}
      </span>
    );
  });

  const [showTransferSummary, setShowTransferSummary] = useState(false);

  const [showScheduleDialog, setShowScheduleDialog] = useState(false);

  return (
    <>
      <div className="px-4 pt-2">
        <div className="border rounded">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar
              style={{
                minHeight: "0px",
                borderBottom: "3px solid #e9ecef",
              }}
              className=" my-0 py-1 bg-light"
            >
              <Typography
                variant="p"
                noWrap
                component="div"
                className="d-flex  align-items-center text-uppercase text-primary font-weight-bold"
              >
                <span className="text-dark">Teacher Transfers</span>
                {!["TEACHER", "HEAD_TEACHER"].includes(
                  user.selectedEntity.role
                ) && (
                  <Tooltip title="Download">
                    <span className="ml-2">
                      <IconButton
                        size="small"
                        disabled={loading || !transferRequests.length}
                        className=" mr-1"
                        color="primary"
                        onClick={() => downloadTransferExcelReport()}
                      >
                        <span className="material-icons">file_download</span>
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {user?.selectedEntity.role === "TEACHER" && (
                  <Button
                    className="ml-2"
                    disabled={
                      loading ||
                      !(
                        !!transferSchedule &&
                        moment(transferSchedule.startOn).format("yyyy-MM-DD") <=
                          moment().format("yyyy-MM-DD") &&
                        moment(transferSchedule.endOn).format("yyyy-MM-DD") >=
                          moment().format("yyyy-MM-DD")
                      )
                    }
                    onClick={() => {
                      setShowTransferRequestDialog(true);
                    }}
                    variant="contained"
                    size="small"
                    disableElevation
                    color="primary"
                    style={{
                      borderRadius: "8px",
                      height: "32px",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                    }}
                  >
                    <span className="material-icons">add</span>
                    New Request
                  </Button>
                )}

                {!!transferSchedule &&
                  moment(transferSchedule.startOn).format("yyyy-MM-DD") <=
                    moment().format("yyyy-MM-DD") &&
                  moment(transferSchedule.endOn).format("yyyy-MM-DD") >=
                    moment().format("yyyy-MM-DD") && (
                    <span
                      className="font-weight-bold ml-2"
                      style={{ color: "#ed6c02" }}
                    >
                      {timerComponents.length ? (
                        timerComponents
                      ) : (
                        <span
                          className={`badge badge-danger ml-2`}
                          style={{ cursor: "default" }}
                        >
                          <i className="fas fa-lock mr-1"></i> CLOSED
                        </span>
                      )}{" "}
                      {!!transferSchedule && (
                        <>
                          {transferSchedule.internal ? (
                            <span className="badge badge-success">
                              Internal
                            </span>
                          ) : (
                            <span className="badge badge-primary">
                              External{" "}
                            </span>
                          )}
                        </>
                      )}
                    </span>
                  )}

                {!(
                  !!transferSchedule &&
                  moment(transferSchedule.startOn).format("yyyy-MM-DD") <=
                    moment().format("yyyy-MM-DD") &&
                  moment(transferSchedule.endOn).format("yyyy-MM-DD") >=
                    moment().format("yyyy-MM-DD")
                ) && (
                  <span
                    className={`badge badge-danger ml-2`}
                    style={{ cursor: "default" }}
                  >
                    <span>
                      {!transferSchedule ||
                      (!!transferSchedule &&
                        moment(transferSchedule.endOn).format("yyyy-MM-DD") <
                          moment().format("yyyy-MM-DD")) ? (
                        <i className="fas fa-lock mr-1"></i>
                      ) : (
                        <i className="fas fa-clock mr-1"></i>
                      )}

                      {!transferSchedule ||
                      (!!transferSchedule &&
                        moment(transferSchedule.endOn).format("yyyy-MM-DD") <
                          moment().format("yyyy-MM-DD"))
                        ? "CLOSED"
                        : `Will start on ${moment(
                            transferSchedule.startOn
                          ).format("ll")}`}
                    </span>
                  </span>
                )}

                {["REB", "RTB"].includes(user.selectedEntity.role) && (
                  <span
                    size="small"
                    variant="text"
                    className="ml-2 btn btn-sm btn-outline-primary py-0"
                    onClick={() => setShowScheduleDialog(true)}
                  >
                    Schedule
                  </span>
                )}
              </Typography>
              {["REB", "RTB", "DG_REB", "HOD_TDM", "DG_RTB", "HOD_TM"].includes(
                user.selectedEntity.role
              ) && (
                <Button
                  className="ml-2"
                  onClick={() => setConfirmExecute(true)}
                  disabled={loading}
                  variant="contained"
                  size="small"
                  disableElevation
                  color="inherit"
                  style={{
                    borderRadius: "8px",
                    height: "30px",
                    alignItems: "center",
                    fontSize: ".75rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "initial",
                  }}
                >
                  <span className="material-icons">terminal</span>
                  Execute
                </Button>
              )}
              <Box sx={{ flexGrow: 1 }} />

              {["REB", "DG_REB", "HOD_TDM", "RTB", "DG_RTB", "HOD_TM"].includes(
                user.selectedEntity.role
              ) && (
                <>
                  {!!transferSchedule?.published && (
                    <span className="d-inline-flex align-items-center mr-2 text-success badge badge-pill">
                      <span className="material-icons ">task_alt</span>
                      Published
                    </span>
                  )}

                  <Button
                    className="mr-2"
                    disabled={
                      loading ||
                      !!showTransferSummary ||
                      !transferRequests.length
                    }
                    onClick={() => {
                      setShowTransferSummary(true);
                    }}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    <span className="material-icons">summarize</span>
                    Summary
                  </Button>
                </>
              )}
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox
                  onSearch={onSearch}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      const query = {
                        searchTerm: searchTerm,
                        page: currentPage,
                        size: itemPerPage,
                      };

                      getTransferRequests(query, setTotalRecods, setLoader);
                    }
                  }}
                  placeholder="Search…"
                />
              </Box>
            </Toolbar>
          </AppBar>
          <div
            style={{
              backgroundColor: "#f3f4f6",
              borderRadius: "0.5rem",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              height: "59vh",
              overflowY: "auto",
            }}
            className="px-3"
          >
            {!!transferRequests.length && (
              <table className="table table-striped table-hover   fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>
                    <th scope="col" className="text-left">
                      Requested By
                    </th>
                    {/* <th scope="col" className="text-left">
                      CurrentPosition
                    </th> */}
                    {/* <th scope="col">DistrictOfInterest</th> */}
                    <th scope="col" className="text-left px-2">
                      SchoolsOfInterest
                    </th>

                    <th scope="col" className="text-left">
                      Requested On
                    </th>

                    <th scope="col" className="text-center">
                      Status
                    </th>
                    <th scope="col" className="text-right"></th>
                  </tr>
                </thead>

                {!!transferRequests && (
                  <tbody>
                    {transferRequests.map((request) => (
                      <TransferRequestCard
                        key={request.id}
                        // index={getNo(transferRequests, "id", request.id)}
                        request={request}
                        user={user}
                        deleteTransferRequest={deleteTransferRequest}
                        acceptedTransferRequest={acceptedTransferRequest}
                        loading={loading}
                        downloadTransferLetter={downloadTransferLetter}
                        transferSchedule={transferSchedule}
                      />
                    ))}
                  </tbody>
                )}
              </table>
            )}

            {!transferRequests?.length && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container py-5 ">
                  <p className="lead">
                    {loading ? "Fetching data..." : "No request made"}{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="d-flex justify-content-center  align-items-center"
            style={{ overflow: "hidden" }}
          >
            <>
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalRecods / itemPerPage)}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>

              {!!transferRequests.length && (
                <select
                  className="custom-select custom-select-sm mr-3 ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                    };
                    getTransferRequests(query, setTotalRecods, setLoader);
                  }}
                >
                  {[10, 50, 100, 150].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              )}
            </>
          </div>
        </div>
      </div>

      {loader && (
        <CircularProgress
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "rgb(7, 142, 206)",
            position: "absolute",
            top: "42%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
          size={40}
          thickness={4}
        />
      )}

      {showTransferSummary && (
        <TransferSummaryDialog
          showDialog={showTransferSummary}
          setShowDialog={setShowTransferSummary}
        />
      )}

      {showTransferRequestDialog && (
        <TransferRequestDialog
          showDialog={showTransferRequestDialog}
          setShowDialog={setShowTransferRequestDialog}
        />
      )}

      {confirmExecute && (
        <ConfirmationDialog
          confirmationDialog={confirmExecute}
          message="Are you sure you want to excute transfer algorithm?"
          setConfirmationDialog={setConfirmExecute}
          onYes={() => {
            executeTransferAlgorithm(setConfirmExecute);
          }}
        />
      )}

      {showScheduleDialog && (
        <TransferScheduleDialog
          transferSchedule={transferSchedule}
          showDialog={showScheduleDialog}
          setShowDialog={setShowScheduleDialog}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  transferRequests,
  acceptedTransferRequest,
  env,
  transferSchedule,
}) => {
  return {
    user,
    loading,
    transferRequests,
    acceptedTransferRequest,
    env,
    transferSchedule,
  };
};
export default connect(mapStateToProps, {
  getTransferRequests,
  deleteTransferRequest,
  executeTransferAlgorithm,
  acceptedTransferRequest,
  downloadTransferLetter,
  getTransferSchedule,
  downloadTransferExcelReport,
})(Transfers);

const TransferRequestCard = (props) => {
  const {
    loading,
    user,
    request,
    // index,
    deleteTransferRequest,
    acceptedTransferRequest,
    downloadTransferLetter,
    transferSchedule,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // const [
  //   showTransferRequestDetailsDialog,
  //   setShowTransferRequestDetailsDialog,
  // ] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const [teacherProfile, setTeacherProfile] = useState({});

  // const getTeacherProfile = async (teacherId) => {
  //   try {
  //     setTeacherProfile({});

  //     setShowLoader(true);
  //     const { data } = await axios.get(
  //       "/api/posts/teacher-profile/" + teacherId
  //     );

  //     setShowLoader(false);

  //     setShowTransferRequestDetailsDialog(true);

  //     setTeacherProfile(data);
  //   } catch (error) {
  //     setShowLoader(false);
  //     showError(error);
  //   }
  // };

  const [confirmDelete, setConfirmDelete] = useState(false);

  const [accepted, setAccepted] = useState("");
  const [showConfirmOffer, setShowConfirmOffer] = useState(false);

  const [showPreviewDialog, setShowPreviewDialog] = useState(false);

  return (
    <>
      <tr className={``}>
        <th scope="row" className="text-left">
          {request.no}
        </th>

        <td className="text-left">
          <span className="d-flex align-items-center">
            <div>
              <span className="d-flex align-items-center">
                <button
                  onClick={() => setShowPreviewDialog(true)}
                  className="btn btn-link btn-sm px-0 text-uppercase text-left"
                >
                  {request.teacherId === user.id
                    ? "Me"
                    : request.lastName + " " + request.firstName}
                </button>{" "}
                {request.isSpecial &&
                  (["REB", "RTB"].includes(user.selectedEntity.role) ||
                    !!request.isApproved) && (
                    <Tooltip title="Special needs">
                      <span
                        className="badge badge-pill text-dark text-uppercase cursor-pointer"
                        onClick={() => setShowPreviewDialog(true)}
                      >
                        <i className="fas fa-wheelchair"></i> Special
                      </span>
                    </Tooltip>
                  )}
                {(request.statusId === 2 ||
                  request.statusId === 4 ||
                  (!!request.toBudgetPostId &&
                    [
                      "REB",
                      "RTB",
                      "DG_REB",
                      "HOD_TDM",
                      "DG_RTB",
                      "HOD_TM",
                    ].includes(user.selectedEntity.role))) && (
                  <IconButton
                    color="success"
                    size="small"
                    className="ml-1"
                    onClick={() => {
                      downloadTransferLetter(
                        request.id.toString().padStart(10, "0")
                      );
                    }}
                  >
                    <i className="fas fa-download"></i>
                  </IconButton>
                )}
              </span>

              <div>
                {request.positionName} ({request.qualificationLevelName}) <br />
                <span className="text-primary font-weight-bold">
                  @{request.fromSchoolName}{" "}
                  <i className="fas fa-chevron-right"></i>{" "}
                  {request.fromSectorName} / {request.fromDistrictName}
                </span>
              </div>
            </div>
          </span>
        </td>

        {/* <td className="text-left">
          {request.positionName} ({request.qualificationLevelName}) <br />
          <span className="text-primary">
            @ {request.fromSchoolName} <i className="fas fa-chevron-right"></i>{" "}
            {request.fromSectorName} / {request.fromDistrictName}
          </span>
        </td> */}

        {/* <td className="text-left">{request.toDistrictName}</td> */}

        <td className="text-left">
          {!!request.toSchoolPositionOneId &&
          request.toSchoolPositionOneId ===
            request.schoolPositionIdOfPlacement &&
          (!!request.isApproved ||
            ["REB", "HOD_TDM", "DG_REB", "RTB", "DG_RTB"].includes(
              user.selectedEntity.role
            )) ? (
            <div
              className={`text-left py-1 px-2 d-flex justify-content-between border border-success font-weight-bold`}
              style={{ borderRadius: "5px", backgroundColor: "#bcf5c3" }}
            >
              <span>
                1:{" "}
                {!!request.toSchoolOneName ? (
                  <>
                    {request.toSchoolOneName}{" "}
                    <i className="fas fa-chevron-right"></i>{" "}
                    {request.toSectorOneName} / {request.toDistrictOneName}
                    <span className="text-primary ml-1">
                      / {request.toPositionOneName}
                    </span>
                  </>
                ) : (
                  <>N/A</>
                )}
              </span>
              <span className="text-success">
                <i className="fas fa-check-circle"></i> Approved
              </span>
            </div>
          ) : (
            <div className={`text-left py-1 px-2`}>
              {!!request.isApproved ? (
                <del className="font-weight-light">
                  <span>
                    1:{" "}
                    {!!request.toSchoolOneName ? (
                      <>
                        {request.toSchoolOneName}{" "}
                        <i className="fas fa-chevron-right"></i>{" "}
                        {request.toSectorOneName} / {request.toDistrictOneName}{" "}
                        <span className="text-primary ml-1">
                          / {request.toPositionOneName}
                        </span>
                      </>
                    ) : (
                      <>N/A</>
                    )}
                  </span>
                </del>
              ) : (
                <span>
                  1:{" "}
                  {!!request.toSchoolOneName ? (
                    <>
                      {request.toSchoolOneName}{" "}
                      <i className="fas fa-chevron-right"></i>{" "}
                      {request.toSectorOneName} / {request.toDistrictOneName}{" "}
                      <span className="text-primary ml-1">
                        / {request.toPositionOneName}
                      </span>
                    </>
                  ) : (
                    <>N/A</>
                  )}
                </span>
              )}
            </div>
          )}

          {!!request.toSchoolPositionTwoId &&
          request.toSchoolPositionTwoId ===
            request.schoolPositionIdOfPlacement &&
          (!!request.isApproved ||
            ["REB", "HOD_TDM", "DG_REB", "RTB", "DG_RTB"].includes(
              user.selectedEntity.role
            )) ? (
            <div
              className={`text-left py-1 px-2 d-flex justify-content-between border border-success font-weight-bold`}
              style={{ borderRadius: "5px", backgroundColor: "#bcf5c3" }}
            >
              <span>
                2:{" "}
                {!!request.toSchoolTwoName ? (
                  <>
                    {request.toSchoolTwoName}{" "}
                    <i className="fas fa-chevron-right"></i>{" "}
                    {request.toSectorTwoName} / {request.toDistrictTwoName}
                    <span className="text-primary ml-1">
                      / {request.toPositionTwoName}
                    </span>
                  </>
                ) : (
                  <>N/A</>
                )}
              </span>
              <span className="text-success">
                <i className="fas fa-check-circle"></i> Approved
              </span>
            </div>
          ) : (
            <div className={`text-left py-1 px-2`}>
              {!!request.isApproved ? (
                <del className="font-weight-light">
                  {" "}
                  <span>
                    2:{" "}
                    {!!request.toSchoolTwoName ? (
                      <>
                        {request.toSchoolTwoName}{" "}
                        <i className="fas fa-chevron-right"></i>{" "}
                        {request.toSectorTwoName} / {request.toDistrictTwoName}{" "}
                        <span className="text-primary ml-1">
                          / {request.toPositionTwoName}
                        </span>
                      </>
                    ) : (
                      <>N/A</>
                    )}
                  </span>
                </del>
              ) : (
                <span>
                  2:{" "}
                  {!!request.toSchoolTwoName ? (
                    <>
                      {request.toSchoolTwoName}{" "}
                      <i className="fas fa-chevron-right"></i>{" "}
                      {request.toSectorTwoName} / {request.toDistrictTwoName}{" "}
                      <span className="text-primary ml-1">
                        / {request.toPositionTwoName}
                      </span>
                    </>
                  ) : (
                    <>N/A</>
                  )}
                </span>
              )}
            </div>
          )}

          {!!request.toSchoolPositionThreeId &&
          request.toSchoolPositionThreeId ===
            request.schoolPositionIdOfPlacement &&
          (!!request.isApproved ||
            ["REB", "HOD_TDM", "DG_REB", "RTB", "DG_RTB"].includes(
              user.selectedEntity.role
            )) ? (
            <div
              className={`text-left py-1 px-2 d-flex justify-content-between border border-success font-weight-bold`}
              style={{ borderRadius: "5px", backgroundColor: "#bcf5c3" }}
            >
              <span>
                3:{" "}
                {!!request.toSchoolThreeName ? (
                  <>
                    {request.toSchoolThreeName}{" "}
                    <i className="fas fa-chevron-right"></i>{" "}
                    {request.toSectorThreeName} / {request.toDistrictThreeName}{" "}
                    <span className="text-primary ml-1">
                      / {request.toPositionThreeName}
                    </span>
                  </>
                ) : (
                  <>N/A</>
                )}
              </span>
              <span className="text-success">
                <i className="fas fa-check-circle"></i> Approved
              </span>
            </div>
          ) : (
            <div className={`text-left py-1 px-2`}>
              {!!request.isApproved ? (
                <del className="font-weight-light">
                  {" "}
                  <span>
                    3:{" "}
                    {!!request.toSchoolThreeName ? (
                      <>
                        {request.toSchoolThreeName}{" "}
                        <i className="fas fa-chevron-right"></i>{" "}
                        {request.toSectorThreeName} /{" "}
                        {request.toDistrictThreeName}{" "}
                        <span className="text-primary ml-1">
                          / {request.toPositionThreeName}
                        </span>
                      </>
                    ) : (
                      <>N/A</>
                    )}
                  </span>
                </del>
              ) : (
                <span>
                  3:{" "}
                  {!!request.toSchoolThreeName ? (
                    <>
                      {request.toSchoolThreeName}{" "}
                      <i className="fas fa-chevron-right"></i>{" "}
                      {request.toSectorThreeName} /{" "}
                      {request.toDistrictThreeName}{" "}
                      <span className="text-primary ml-1">
                        / {request.toPositionThreeName}
                      </span>
                    </>
                  ) : (
                    <>N/A</>
                  )}
                </span>
              )}
            </div>
          )}

          <span className="ml-2">
            {request.internal === true && (
              <span className="badge badge-success ">Internal</span>
            )}
            {request.internal === false && (
              <span className="badge badge-primary">External </span>
            )}
          </span>
        </td>
        <td className="text-left">
          {moment(request.applicationDate).format("yyyy-MM-DD HH:mm:ss")}
        </td>

        <td className="text-center">
          {["REB", "RTB"].includes(user.selectedEntity.role) &&
          !request.isApproved &&
          request.validated !== null ? (
            request.validated === true ? (
              <span className={`badge badge-primary`}>Accepted</span>
            ) : (
              <span className={`badge badge-danger`}>Rejected</span>
            )
          ) : (
            <span
              className={`badge badge-${
                request.statusId === 1
                  ? "secondary"
                  : request.statusId === 2 || request.statusId === 4
                  ? "success"
                  : request.statusId === 3 || request.statusId === 5
                  ? "danger"
                  : "danger"
              } `}
            >
              {request.statusName}
            </span>
          )}
        </td>
        <td className="text-right py-1">
          {request.statusId === 1 && request.teacherId === user.id && (
            <IconButton
              disabled={
                !(
                  !!transferSchedule &&
                  moment(transferSchedule.startOn).format("yyyy-MM-DD") <=
                    moment().format("yyyy-MM-DD") &&
                  moment(transferSchedule.endOn).format("yyyy-MM-DD") >=
                    moment().format("yyyy-MM-DD")
                )
              }
              color="error"
              size="small"
              className="mr-2"
              onClick={() => {
                setConfirmDelete(true);
              }}
            >
              <span className="material-icons">delete_forever</span>
            </IconButton>
          )}
        </td>
      </tr>

      {!!showPreviewDialog && (
        <TransferRequestPreviewDialog
          request={request}
          showDialog={showPreviewDialog}
          setShowDialog={setShowPreviewDialog}
        />
      )}

      {/* {showTransferRequestDetailsDialog && (
        <TransferRequestDetailsDialog
          showDialog={showTransferRequestDetailsDialog}
          setShowDialog={setShowTransferRequestDetailsDialog}
          request={request}
          teacherProfile={teacherProfile}
        />
      )} */}

      {confirmDelete && (
        <ConfirmationDialog
          confirmationDialog={confirmDelete}
          message={
            <>
              <span>
                Are you sure you want to{" "}
                <strong className={`text-danger`}>delete</strong> this transfer
                request?
              </span>
            </>
          }
          setConfirmationDialog={setConfirmDelete}
          onYes={() => {
            deleteTransferRequest(request.id, setConfirmDelete);
          }}
        />
      )}

      {showConfirmOffer && (
        <ConfirmationDialog
          confirmationDialog={showConfirmOffer}
          message={
            <>
              <span>
                Are you sure you want to{" "}
                <strong className={`text-${accepted ? "success" : "danger"}`}>
                  {accepted ? "accepted" : "reject"}
                </strong>{" "}
                this transfer?
              </span>
            </>
          }
          setConfirmationDialog={setShowConfirmOffer}
          onYes={() => {
            acceptedTransferRequest(
              { requestId: request.id, accepted },
              setShowConfirmOffer
            );
          }}
        />
      )}
    </>
  );
};
