import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  Divider,
  TextField,
  Tooltip,
} from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  getDropdownPositions,
  disableDropdownPosition,
  getPositionRoles,
  removeDropdownPosition,
} from "../../../../store/posts/actions";
import ReactPaginate from "react-paginate";
// import PostRequestDetailsDialog from "../components/PostRequestDetailsDialog";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import NewUserDialog from "./NewUserDialog";
import PasswordRessetDialog from "./PasswordRessetDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import UpdateUserDialog from "./UpdateUserDialog";
import AddOrUpdatePositionDropdownDialog from "./AddOrUpdatePositionDropdownDialog";

const PositionsDropdown = (props) => {
  useEffect(() => {
    document.title = "TMIS | System Users";
  }, []);

  const {
    user,
    loading,
    systemSettingPositions,
    getDropdownPositions,
    disableDropdownPosition,
    getPositionRoles,
    removeDropdownPosition,
  } = props;
  const [searchTeam, setSearchTeam] = useState("");

  useEffect(() => {
    getDropdownPositions();
    getPositionRoles();
  }, []);

  const [filteredPositions, setFilteredPositions] = useState([
    ...systemSettingPositions,
  ]);

  useEffect(() => {
    const filtered = [...systemSettingPositions].filter(
      ({
        name,
        qualificationLevelName,
        teachingLevelName,
        classificationName,
      }) =>
        (
          (name || "") +
          (qualificationLevelName || "") +
          (teachingLevelName || "") +
          (classificationName || "")
        )
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(searchTeam.toLowerCase().replace(/\s/g, ""))
    );
    setFilteredPositions(filtered);

    paginate(filtered);
  }, [searchTeam, systemSettingPositions]);

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredPositions);
  }, [itemOffset, itemsPerPage, filteredPositions]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredPositions.length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  const [showUserDialog, setShowUserDialog] = useState(false);

  const [showAddOrUpdatePositionDialog, setShowAddOrUpdatePositionDialog] =
    useState(false);

  const [formData, setFormData] = useState({
    id: null,
    name: "",
    qualificationLevelId: "",
    classificationId: 1,
    teachingLevelId: "",
    ippisPositionCode: "",
  });

  return (
    <div>
      <div
        style={{
          backgroundColor: "#f3f4f6",
          borderRadius: "0.5rem",
          boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
          MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
          height: "59vh",
          overflowY: "auto",
        }}
        className="px-3"
      >
        <AppBar position="static" elevation={0} className="app-bar w-100">
          <Toolbar
            style={{
              minHeight: "0px",
              borderBottom: "3px solid #e9ecef",
            }}
            className=" my-0 py-1 bg-light mx-n3"
          >
            <Box sx={{ display: { xs: "flex", md: "flex" } }}>
              <SearchBox onSearch={onSearch} placeholder="Search…" />
            </Box>

            <Box sx={{ flexGrow: 1 }} />

            <Button
              className="ml-2"
              disabled={loading}
              onClick={() => {
                setShowAddOrUpdatePositionDialog(true);
              }}
              variant="contained"
              size="small"
              disableElevation
              color="primary"
              style={{
                borderRadius: "8px",
                height: "32px",
                alignItems: "center",
                fontSize: ".75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "initial",
              }}
            >
              <span className="material-icons">add</span>
              New Position
            </Button>
          </Toolbar>
        </AppBar>
        <table className="table table-striped table-hover  fixTableHead">
          {!!systemSettingPositions.length && (
            <thead>
              <tr style={{ backgroundColor: "#f0f2f5" }}>
                <th scope="col">#</th>

                <th scope="col">id</th>
                {/* <th scope="col">IPPIS Position Code</th> */}

                <th scope="col">Position Name</th>

                <th scope="col">Teaching Level</th>
                <th scope="col">Qualification</th>

                <th scope="col">Role</th>
                <th scope="col" className="text-center">
                  Status
                </th>

                <th scope="col" className="text-right"></th>
              </tr>
            </thead>
          )}

          {!!paginatedItems && (
            <tbody>
              {paginatedItems.map((position) => (
                <PositionCard
                  key={position.id}
                  index={getNo(systemSettingPositions, "id", position.id)}
                  position={position}
                  disableDropdownPosition={disableDropdownPosition}
                  setFormData={setFormData}
                  setShowAddOrUpdatePositionDialog={
                    setShowAddOrUpdatePositionDialog
                  }
                  loading={loading}
                  removeDropdownPosition={removeDropdownPosition}
                />
              ))}
            </tbody>
          )}
        </table>

        {!paginatedItems?.length && (
          <div className="jumbotron jumbotron-fluid text-center">
            <div className="container py-5 ">
              <p className="lead">
                {loading ? "Fetching data..." : "No record found"}{" "}
              </p>
            </div>
          </div>
        )}

        {showAddOrUpdatePositionDialog && (
          <AddOrUpdatePositionDropdownDialog
            showDialog={showAddOrUpdatePositionDialog}
            setShowDialog={setShowAddOrUpdatePositionDialog}
            formData={formData}
            setFormData={setFormData}
          />
        )}
      </div>

      {systemSettingPositions.length > 20 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <>
              <span className="pr-1 d-none d-md-inline">Next</span>
              <i className="fas fa-angle-double-right"></i>
            </>
          }
          previousLabel={
            <>
              <i className="fas fa-angle-double-left"></i>
              <span className="pl-1  d-none d-md-inline">Previous</span>
            </>
          }
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
          previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
          nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
          activeLinkClassName="active"
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ user, loading, systemSettingPositions }) => {
  return { user, loading, systemSettingPositions };
};

export default connect(mapStateToProps, {
  getDropdownPositions,
  disableDropdownPosition,
  getPositionRoles,
  removeDropdownPosition,
})(PositionsDropdown);

const PositionCard = (props) => {
  const {
    position,
    index,
    disableDropdownPosition,
    setFormData,
    setShowAddOrUpdatePositionDialog,
    loading,
    removeDropdownPosition,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showConfirmRemovePosition, setShowConfirmRemovePosition] =
    useState(false);

  return (
    <>
      <tr className={``}>
        <th scope="row" className="text-left">
          {index + 1}
        </th>

        <td className="text-left">{position.id}</td>

        <td className="text-left">
          {position.name} <br />
          <span
            className={`badge badge-${
              !!position.ippisPositionCode ? "success" : "danger"
            }`}
          >
            IPPIS Code: {position.ippisPositionCode || "Not set"}
          </span>
        </td>
        <td className="text-left">{position.teachingLevelName}</td>
        <td className="text-left">{position.qualificationLevelName}</td>

        <td className="text-left">{position.classificationName}</td>
        <td className="text-center">
          <span
            className={`badge badge-${
              !position.isActive ? "danger" : "success"
            }`}
          >
            {!position.isActive ? "Disabled" : "Active"}
          </span>
        </td>

        <td className="text-right py-1">
          {position.isAssigned && (
            <Tooltip title="Assigned">
              <FiberManualRecordIcon
                className="text-danger"
                style={{ fontSize: "14px" }}
              />
            </Tooltip>
          )}
          <IconButton
            size="small"
            className="p-0"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setFormData({
                    id: position.id,
                    name: position.name,
                    qualificationLevelId: position.qualificationLevelId,
                    classificationId: position.classificationId,
                    teachingLevelId: position.teachingLevelId,
                    ippisPositionCode: position.ippisPositionCode,
                  });
                  setShowAddOrUpdatePositionDialog(true);
                }}
                className="text-primary font-weight-bolder"
              >
                <span className="material-icons mr-1">edit_note</span>
                Edit
              </MenuItem>
            </span>

            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  disableDropdownPosition(position.id, handleCloseMenu);
                }}
                className={`text-dark`}
              >
                {!position.isActive && (
                  <span className={`material-icons mr-1  text-success`}>
                    toggle_off{" "}
                  </span>
                )}
                {position.isActive && (
                  <span className={`material-icons mr-1 text-danger`}>
                    toggle_on
                  </span>
                )}
                <span
                  className={`text-${
                    !position.isActive ? "success" : "danger"
                  }`}
                >
                  {position.isActive ? "Disable" : "Enable"}
                </span>
              </MenuItem>
            </span>

            <span>
              <MenuItem
                disabled={loading || position.isAssigned}
                onClick={() => {
                  setShowConfirmRemovePosition(true);
                  handleCloseMenu();
                }}
                className={`text-danger`}
              >
                <span className="material-icons mr-1">delete_forever</span>
                Remove
              </MenuItem>
            </span>
          </Menu>
        </td>
      </tr>

      {showConfirmRemovePosition && (
        <ConfirmationDialog
          confirmationDialog={showConfirmRemovePosition}
          message={
            <>
              <p className="mb-0 mt-3">
                Are you sure you want to{" "}
                <strong className="text-danger">remove</strong> this position?
              </p>
            </>
          }
          setConfirmationDialog={setShowConfirmRemovePosition}
          onYes={() => {
            removeDropdownPosition(position.id, () =>
              setShowConfirmRemovePosition(false)
            );
          }}
        />
      )}
    </>
  );
};
