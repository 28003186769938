const types = {
  SET_BANKS: "SET_BANKS",

  SET_PLACEMENT_REPORTS: "SET_PLACEMENT_REPORTS",
  ADD_NEW_PLACEMENT_REPORT: "ADD_NEW_PLACEMENT_REPORT",
  UPDATE_PLACEMENT_REPORT: "UPDATE_PLACEMENT_REPORT",
  DELETE_PLACEMENT_REPORT: "DELETE_PLACEMENT_REPORT",

  SET_PLACEMENT_REPORT_DETAILS: "SET_PLACEMENT_REPORT_DETAILS",
  SET_PLACEMENT_CHANGE_LIST: "SET_PLACEMENT_CHANGE_LIST",
  SET_PREV_PLACEMENT_REPORT: "SET_PREV_PLACEMENT_REPORT",

  SET_PLACEMENT_SIGNATORIES: "SET_PLACEMENT_SIGNATORIES",
  SET_EXIT_REASONS: "SET_EXIT_REASONS",
  SET_PLACEMENT_VACANT_POSTS: "SET_PLACEMENT_VACANT_POSTS",
  SET_UNSUBMITTED: "SET_UNSUBMITTED",
};

export default types;
