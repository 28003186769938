import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Dashboard from "./pages/Dashboard";

import { isEmpty } from "lodash";
import { connect } from "react-redux";
import UserGuide from "./pages/UserGuide";
import NewRequests from "./pages/NewRequests";
import OfferedLicenses from "./pages/OfferedLicenses";

const Layout = (props) => {
  useEffect(() => {
    document.title = "TMIS | Licenses";
  }, []);

  const { user } = props;
  const history = useHistory();

  return (
    <React.Fragment>
      <Switch>
        <Route path="/in/licenses/dashboard" component={Dashboard} />
        <Route path="/in/licenses/new-requests" component={NewRequests} />
        <Route path="/in/licenses/offered" component={OfferedLicenses} />
        <Route path="/in/licenses/user-guide" component={UserGuide} />

        <Route
          path="/in/licenses"
          component={() => <Redirect to="/in/licenses/dashboard" />}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
export default connect(mapStateToProps)(Layout);
