import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { currencyFormat } from "../../common/components/Utils";

import { addOrUpdateTeacher } from "../../../../store/posts/actions";
import { getSectors } from "../../../../store/posts/actions";

import { isEmpty } from "lodash";
import axios from "axios";
import { CSVLink } from "react-csv";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import moment from "moment";
// import { showError } from "../../toastify";
import defaultPofile from "../../../assets/default-profile.jpg";
// import { idNumberPattern } from "../../common/components/Utils";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import MaskedInput from "react-text-mask";
import joi from "joi";
import { showError } from "../../../toastify";

const personalEmailValidator = joi.object({
  email: joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ["com", "fr", "rw"] },
  }),
});

const idNumberPattern = [
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
];

const AddNewTeacherDialog = (props) => {
  const {
    user,
    loading,
    onWait,
    teacher,
    placementReport,
    showDialog,
    setShowDialog,
    addOrUpdateTeacher,
    roles,
    districts,
    getSectors,
    sectors,
    // position,
    degrees,
    specializations,
    schoolPositions,
    schoolPosition,
    qualificationLevels,
  } = props;

  const onClose = () => {
    setShowDialog(false);
  };

  const [errors, setErrors] = useState({
    nidNumberHasError: false,
    emailHasError: false,
    phoneNumberHasError: false,
    employeeIdHasError: false,
    staffCodeHasError: false,
    employmentTypeHasError: false,
    bankAccountNumberHasError: false,
    educationCertficateHasError: false,
    positionHasError: false,
    passportNumberHasError: false,
    employmentDateInEducationHasError: false,
    hasError: false,
  });

  const teacherInfoFormValidator = () => {
    const error = {
      nidNumberHasError: false,
      emailHasError: false,
      phoneNumberHasError: false,
      employeeIdHasError: false,
      staffCodeHasError: false,
      employmentTypeHasError: false,
      bankAccountNumberHasError: false,
      educationCertficateHasError: false,
      positionHasError: false,
      passportNumberHasError: false,
      employmentDateInEducationHasError: false,
      hasError: false,
    };

    // console.log(teacherForm.nidNumber.replace(/\s/g, "").length);
    if (
      ((teacherForm.nidNumber || "").replace(/\s/g, "").length !== 16 &&
        (teacherForm.nidNumber || "").replace(/\s/g, "").length !== 10) ||
      !!idHasError
    ) {
      error.nidNumberHasError = true;
      error.hasError = true;
    }

    if (
      !!teacherForm.nidNumber &&
      (teacherForm.nidNumber || "").replace(/\s/g, "").length !== 16 &&
      !teacherForm.passportNumber
    ) {
      error.passportNumberHasError = true;
      error.hasError = true;
    }

    if (!teacherForm.employeeId) {
      error.employeeIdHasError = true;
      error.hasError = true;
    }

    if (!teacherForm.sdmsStaffCode) {
      error.staffCodeHasError = true;
      error.hasError = true;
    }

    if ((teacherForm.phoneNumber || "").replace(/\s/g, "").length !== 10) {
      error.phoneNumberHasError = true;
      error.hasError = true;
    }

    if (
      !teacherForm.email ||
      (!!teacherForm.email &&
        personalEmailValidator.validate({ email: teacherForm.email }).error)
    ) {
      error.emailHasError = true;
      error.hasError = true;
    }

    if (!selectedEmploymentType) {
      error.employmentTypeHasError = true;
      error.hasError = true;
    }

    if (!teacherForm.bankId) {
      error.bankAccountNumberHasError = true;
      error.hasError = true;
    }

    if (teacherForm.hasEducationCertficate === "") {
      error.educationCertficateHasError = true;
      error.hasError = true;
    }

    if (
      !position ||
      (!!position && +position?.budgetPosts <= +position?.numberOfEmployees)
    ) {
      error.positionHasError = true;
      error.hasError = true;
    }

    if (
      (!!teacherForm.employmentDateInEducation &&
        new Date(teacherForm.employmentDateInEducation) > new Date()) ||
      !teacherForm.employmentDateInEducation
    ) {
      error.employmentDateInEducationHasError = true;
      error.hasError = true;
    }

    // if (!selectedDegree) {
    //   error.degreeHasError = true;
    //   error.hasError = true;
    // }

    // if (!selectedSpecialization) {
    //   error.specializationHasError = true;
    //   error.hasError = true;
    // }

    // if (
    //   (!!teacherForm.graduationDate &&
    //     new Date(teacherForm.graduationDate) > new Date()) ||
    //   !teacherForm.graduationDate
    // ) {
    //   error.graduationDateHasError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const employmentTypes = [
    { id: 1, name: "Permanent" },
    { id: 2, name: "Contractual" },
  ];

  const [isVerifing, setIsVerifing] = useState(false);
  const [wasVerified, setWasVerified] = useState(false);
  const [idHasError, setIdHasError] = useState(false);

  const [selectedEmploymentType, setSelectedEmploymentType] = useState({
    id: 1,
    name: "Permanent",
  });
  const [selectedDegree, setSelectedDegree] = useState(null);
  const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  const [position, setPosition] = useState(null);

  const [teacherForm, setTeacherForm] = useState({
    nidNumber: "",
    passportNumber: "",
    nin: "",
    email: "",
    phoneNumber: "",
    gender: "",
    genderId: "",
    countryOfBirth: "",
    countryIdOfBirth: "",
    firstName: "",
    lastName: "",
    civilStatus: "",
    civilStatusId: "",
    spouseNames: "",
    fatherNames: "",
    motherNames: "",
    dateOfBirth: "",
    placeOfBirth: "",
    nationalityId: "",
    villageId: "",
    placementDate: null,
    profilePicture: "",
    employeeId: "",
    rssbNumber: "",
    accountNumber: "",
    bankId: "",
    bankName: "",
    sdmsStaffCode: "",
    hasEducationCertficate: "",
    graduationDate: null,
    positionAppointmentDate: null,
    employmentDateInEducation: null,
  });

  const getNidDetails = async (nidNumber) => {
    try {
      let staff = {};
      setWasVerified(false);
      setIsVerifing(true);

      try {
        const { data: tmisData } = await axios.get(
          "/api/users/search-staff/" + (nidNumber || "").replace(/\s/g, "")
        );

        staff = tmisData;
      } catch (error) {}

      setSelectedDegree(
        degrees.find(({ id }) => id === staff.degreeId) ||
          selectedDegree ||
          null
      );
      setSelectedSpecialization(
        specializations.find(({ id }) => id === staff.specializationId) ||
          selectedSpecialization ||
          null
      );

      const { data } = await axios.get(
        "https://ippis.rw/api/search-tmis-employee/" +
          (nidNumber || "").replace(/\s/g, "")
      );

      setTeacherForm({
        ...teacherForm,

        hasEducationCertficate: staff.hasEducationCertficate || "",
        graduationDate: staff.graduationDate || null,

        nidNumber: nidNumber,
        nin: data.nin,
        gender: data.gender,
        genderId: data.genderId,
        countryOfBirth: data.countryOfBirth,
        countryIdOfBirth: data.countryIdOfBirth,
        firstName: data.firstName,
        lastName: data.lastName,
        civilStatus: data.civilStatus,
        civilStatusId: data.civilStatusId,
        spouseNames: data.spouseNames,
        fatherNames: data.fatherNames,
        motherNames: data.motherNames,
        dateOfBirth: data.dateOfBirth,
        placeOfBirth: data.placeOfBirth,
        nationalityId: data.nationalityId,
        villageId: data.villageId,
        profilePicture: data.profileImage,

        employeeId: data.employee?.id || "",
        rssbNumber: data.employee?.rssbNumber || "",
        accountNumber: data.employee?.accountNumber || staff.bankId || "",
        // employmentDate:
        //   data.employee?.employmentData || staff.employmentDate || null,
        bankId: data.employee?.bankId || "",
        bankName: data.employee?.bankName || staff.bankName || "",

        sdmsStaffCode: data.employee?.id,
        email: staff?.email?.trim() || data.employee?.email?.trim() || "",
        phoneNumber:
          staff?.phoneNumber?.trim() ||
          data.employee?.phoneNumber?.trim() ||
          "",
      });

      // setChanged(true);
      setIdHasError(false);
      setWasVerified(true);
      setIsVerifing(false);
    } catch (error) {
      // showError(error);
      setIdHasError(true);
      setWasVerified(false);
      setIsVerifing(false);
    }
  };

  const onSave = () => {
    if (!teacherInfoFormValidator())
      addOrUpdateTeacher(
        {
          id: teacherForm.employeeId,
          lastName: teacherForm.lastName || null,
          firstName: teacherForm.firstName || null,
          email: teacherForm.email?.trim(),
          phoneNumber: teacherForm.phoneNumber?.trim(),
          nidNumber: (teacherForm.nidNumber || "").replace(/\s/g, "") || null,
          passportNumber: teacherForm.passportNumber || null,
          nin: teacherForm.nin || null,
          gender: teacherForm.genderId || null,
          dateOfBirth: teacherForm.dateOfBirth || null,
          placeOfBirth: teacherForm.placeOfBirth || null,
          fatherNames: teacherForm.fatherNames || null,
          motherNames: teacherForm.motherNames || null,
          civilStatusId: teacherForm.civilStatusId || null,
          spouseNames: teacherForm.spouseNames || null,
          countryOfBirth: teacherForm.countryIdOfBirth || null,
          nationalityId: teacherForm.nationalityId || null,
          residenceVillageId: teacherForm.villageId || null,
          bankId: teacherForm.bankId || null,
          bankAccountNumber: teacherForm.accountNumber || null,
          rssbNumber: teacherForm.rssbNumber || null,
          sdmsStaffCode: teacherForm.employeeId || null,
          hasEducationCertficate: teacherForm.hasEducationCertficate,
          schoolPositionId: position?.id,

          employmentTypeId: selectedEmploymentType.id,
          degreeId: selectedDegree?.id || null,
          specializationId: selectedSpecialization?.id || null,
          graduationDate: !!teacherForm.graduationDate
            ? moment(teacherForm.graduationDate).format("yyyy-MM-DD")
            : null,
          employmentDateInEducation: !!teacherForm.employmentDateInEducation
            ? moment(teacherForm.employmentDateInEducation).format("yyyy-MM-DD")
            : null,
          positionAppointmentDate: null,
          isUpdate: false,
        },
        onClose
      );
  };

  useEffect(() => {
    if (!!showDialog && !!schoolPosition) {
      setPosition(schoolPosition);

      if (schoolPosition.budgetPosts <= schoolPosition?.numberOfEmployees) {
        setErrors({ ...errors, positionHasError: true, hasError: true });
      }
    }
  }, [showDialog]);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary pb-0 mb-0 text-uppercase border">
          <span className="d-flex align-items-center mb-2">
            <Typography className="d-flex align-items-center text-primary">
              <span className="text-dark mr-1">Add New Teacher</span>
              {!!teacher && (
                <>
                  {teacher?.lastName} {teacher?.firstName}
                </>
              )}
            </Typography>
          </span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="px-0">
          <div className="mt-0" style={{ height: "100%" }}>
            <div
              style={{
                display: "table-cell",
                width: "100vw",
                height: "45vh",
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              <div className="row mx-0">
                <div className="col-sm-12">
                  <React.Fragment>
                    <div>
                      <div className="row no-gutters">
                        <div className="col-2 justify-content-center  text-center">
                          <div
                            style={{
                              minHeight: "150px",
                              overflow: "hidden",
                            }}
                          >
                            <>
                              <img
                                className="mt-0 rounded  text-center"
                                src={
                                  !!teacherForm.profilePicture
                                    ? "data:image/jpg;base64," +
                                      teacherForm.profilePicture
                                    : defaultPofile
                                }
                                style={{
                                  border: "1px solid #a7a7a7",
                                  height: "150px",
                                  width: "120px",
                                  overflow: "hidden",
                                  borderRadius: "50%",
                                }}
                              />
                            </>
                          </div>

                          <div className="text-primary">
                            <strong>NID PHOTO</strong>{" "}
                          </div>
                          <label className="text-center text-uppercase  mt-n2">
                            <span style={{ fontSize: "12px" }}>
                              {teacherForm?.lastName} {teacherForm?.firstName}
                            </span>
                          </label>

                          <FormControl
                            className="mt-3 d-block border rounded pt-2"
                            style={{ backgroundColor: "#eee" }}
                          >
                            <FormLabel className="text-primary  px-2">
                              Education certficate
                            </FormLabel>
                            <RadioGroup
                              className="mt-0 d-flex justify-content-start px-4"
                              row
                              name="hasEducationCertficate"
                              value={teacherForm?.hasEducationCertficate}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  hasEducationCertficate: e.target.value,
                                });

                                setErrors({
                                  ...errors,
                                  educationCertficateHasError: false,
                                });
                              }}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio disableRipple size="small" />}
                                label="Certfied"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio disableRipple size="small" />}
                                label="Not certfied"
                              />
                            </RadioGroup>
                            {errors.educationCertficateHasError && (
                              <small className="text-danger mb-1">
                                Required
                              </small>
                            )}
                          </FormControl>
                        </div>

                        <div className="col-10 row mx-0">
                          <div className="col-4 justify-content-center">
                            {!idHasError && !isVerifing && !wasVerified && (
                              <span className="material-icons loaderIcon text-danger">
                                help
                              </span>
                            )}

                            {!!isVerifing && (
                              <i
                                className="fas fa-spinner spinner loaderIcon text-primary"
                                style={{ fontSize: "22px" }}
                              ></i>
                            )}

                            {!isVerifing && !!idHasError && (
                              <span className="loaderIcon text-danger d-flex align-items-center">
                                <span className="material-icons text-danger">
                                  report_problem
                                </span>
                                <span style={{ fontSize: "10px" }}>Error</span>
                              </span>
                            )}

                            {!idHasError && !isVerifing && !!wasVerified && (
                              <span className="loaderIcon text-success d-flex align-items-center">
                                <span className="material-icons text-success ">
                                  verified
                                </span>
                                <span style={{ fontSize: "10px" }}>
                                  Verified
                                </span>
                              </span>
                            )}

                            <MaskedInput
                              mask={idNumberPattern}
                              className="form-control "
                              placeholder="NID Number"
                              guide={false}
                              value={teacherForm.nidNumber}
                              onKeyPress={(e) => {
                                if (
                                  e.key === "Enter" &&
                                  !!e.target.value.length
                                ) {
                                  getNidDetails(e.target.value);
                                }
                              }}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  nidNumber: e.target.value,
                                });

                                if (e.target.value.length === 21) {
                                  getNidDetails(e.target.value);
                                } else {
                                  setIdHasError(false);
                                  setWasVerified(false);
                                  setIsVerifing(false);
                                }
                                setErrors({
                                  ...errors,
                                  nidNumberHasError: false,
                                  passportNumberHasError: false,
                                  emailHasError: false,
                                  phoneNumberHasError: false,
                                  employeeIdHasError: false,
                                  staffCodeHasError: false,
                                  bankAccountNumberHasError: false,
                                  hasError: false,
                                });
                              }}
                            />

                            {errors.nidNumberHasError && (
                              <small className="text-danger mb-1">
                                {!!teacherForm.nidNumber
                                  ? "Invalid NID Number"
                                  : " NID Number is required"}
                              </small>
                            )}
                          </div>
                          <div className="col-4 text-left">
                            <TextField
                              fullWidth
                              size="small"
                              autoComplete="false"
                              name="passportNumber"
                              label="Passport number"
                              placeholder="Passport number"
                              variant="outlined"
                              value={teacherForm.passportNumber}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  passportNumber: e.target.value,
                                });

                                setErrors({
                                  ...errors,
                                  passportNumberHasError: false,
                                });
                              }}
                            />
                            {errors.passportNumberHasError && (
                              <small className="text-danger mt-1">
                                Passport number is required
                              </small>
                            )}
                          </div>
                          <div className="col-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="civilStatus"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Civil Status"
                              variant="outlined"
                              value={teacherForm.civilStatus}
                            />
                          </div>
                          <div className="col-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="gender"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Gender"
                              variant="outlined"
                              className=" font-weight-bold"
                              value={teacherForm.gender}
                            />
                          </div>
                          <div className="col-4  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="lastName"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Last Name"
                              variant="outlined"
                              className=""
                              value={teacherForm.lastName}
                            />
                          </div>
                          <div className="col-4  mt-3  text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="firstName"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="First Name"
                              variant="outlined"
                              className=""
                              value={teacherForm.firstName}
                            />
                          </div>

                          <div className="col-4  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="dateOfBirth"
                              value={
                                !!teacherForm.dateOfBirth
                                  ? moment(teacherForm.dateOfBirth).format(
                                      "DD/MM/yyyy"
                                    )
                                  : ""
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Date Of Birth"
                              variant="outlined"
                            />
                          </div>
                          <div className="col-4  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="countryOfBirth"
                              value={teacherForm.countryOfBirth}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Country of Birth"
                              variant="outlined"
                              className=""
                            />
                          </div>
                          <div className="col-4  mt-3 text-left">
                            <TextField
                              InputProps={{
                                readOnly: true,
                              }}
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="placeOfBirth"
                              value={teacherForm.placeOfBirth}
                              label="Place of Birth"
                              variant="outlined"
                              className=""
                            />
                          </div>

                          <div className="col-4  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="nationality"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Nationality"
                              placeholder="Nationality"
                              variant="outlined"
                              className=" font-weight-bold"
                              value={teacherForm.nationalityId}
                            />
                          </div>

                          <div className="col-4 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="employeeId"
                              value={teacherForm?.employeeId || ""}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="IPPIS Employee ID"
                              placeholder="IPPIS Employee ID"
                              variant="outlined"
                            />

                            {errors.employeeIdHasError && (
                              <small className="text-danger mt-1">
                                Employee Id is required
                              </small>
                            )}
                          </div>
                          <div className="col-4 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="sdmsStaffCode"
                              value={teacherForm?.sdmsStaffCode || ""}
                              label="TMIS Staff Code"
                              placeholder="TMIS Staff Code"
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  sdmsStaffCode: e.target.value,
                                });

                                setErrors({
                                  ...errors,
                                  sdmsStaffCodeHasError: false,
                                });
                              }}
                            />

                            {errors.staffCodeHasError && (
                              <small className="text-danger mt-1">
                                Staff Code is required
                              </small>
                            )}
                          </div>

                          <div className="col-4 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="rssbNumber"
                              value={teacherForm?.rssbNumber || ""}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="RSSB Number"
                              placeholder="RSSB Number"
                              variant="outlined"
                              className=""
                            />

                            {errors.employeeIdHasError && (
                              <small className="text-danger mt-1">
                                RSSB Number is required
                              </small>
                            )}
                          </div>
                          {/* 
                          <div className="col-3  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="employmentDate"
                              value={
                                !!teacherForm.employmentDate
                                  ? moment(teacherForm.employmentDate).format(
                                      "DD/MM/yyyy"
                                    )
                                  : ""
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Employment date in education"
                              variant="outlined"
                            />
                            {errors.employmentDateHasError && (
                              <small className="text-danger mt-1">
                                Employment date is required
                              </small>
                            )}
                          </div> */}

                          <div className="col-4 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="bankAccount"
                              value={
                                !teacherForm.accountNumber
                                  ? ""
                                  : teacherForm.accountNumber +
                                    " (" +
                                    teacherForm.bankName +
                                    ")"
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Bank Account"
                              placeholder="Bank Account"
                              variant="outlined"
                            />

                            {!!errors.bankAccountNumberHasError && (
                              <small className="text-danger">
                                Bank account is required
                              </small>
                            )}
                          </div>

                          <div className="col-4 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="teachingLevel"
                              value={position?.teachingLevelName || ""}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Teaching Level"
                              placeholder="Teaching Level"
                              variant="outlined"
                            />
                          </div>

                          <div className="col-4 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="qualificationLevel"
                              value={position?.qualificationLevelName || ""}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Position qualification"
                              placeholder="Position qualification"
                              variant="outlined"
                            />
                          </div>

                          <div className="col-8 mt-3 ">
                            <Autocomplete
                              // style={{
                              //   backgroundColor: !!schoolPosition
                              //     ? "#e9ecef"
                              //     : "#fff",
                              // }}
                              // readOnly={!!schoolPosition}
                              size="small"
                              defaultValue={null}
                              value={position}
                              options={schoolPositions}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <div>
                                    <span className="d-block text-primary">
                                      {option.name}
                                    </span>

                                    <small className="d-block mb-1 ">
                                      <span
                                        className={`badge badge badge-${
                                          +option.budgetPosts -
                                            +option.numberOfEmployees >
                                          0
                                            ? "success"
                                            : "danger"
                                        } mr-1`}
                                      >
                                        {+option.budgetPosts -
                                          +option.numberOfEmployees}{" "}
                                        Vacant
                                        {+option.budgetPosts -
                                          +option.numberOfEmployees >
                                        1
                                          ? "s"
                                          : ""}
                                      </span>
                                      /
                                      <span className="badge badge-secondary ml-1">
                                        {option.budgetPosts} Budget post
                                        {option.budgetPosts > 1 ? "s" : ""}
                                      </span>
                                    </small>
                                    <Divider />
                                    <Divider />
                                  </div>
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="position"
                                  label="Position"
                                  placeholder="Position"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              onChange={(event, position) => {
                                setPosition(position || null);

                                setErrors({
                                  ...errors,
                                  positionHasError:
                                    !!position &&
                                    +position?.budgetPosts <=
                                      +position?.numberOfEmployees
                                      ? true
                                      : false,
                                });
                              }}
                            />
                            {errors.positionHasError && (
                              <small className="text-danger">
                                {!!position
                                  ? "No vacant post available"
                                  : "Position is required"}
                              </small>
                            )}
                          </div>

                          <div className="col-4 mt-3 ">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                label="Employment date in education"
                                placeholder="Employment date in education"
                                inputFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                value={
                                  teacherForm.employmentDateInEducation || null
                                }
                                onChange={(date) => {
                                  setTeacherForm({
                                    ...teacherForm,
                                    employmentDateInEducation: date,
                                  });

                                  setErrors({
                                    ...errors,
                                    employmentDateInEducationHasError: false,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    fullWidth
                                    {...params}
                                  />
                                )}
                              />

                              {errors.employmentDateInEducationHasError && (
                                <small className="text-danger mt-1">
                                  {!!teacherForm.employmentDateInEducation
                                    ? "Invalid date"
                                    : "Employment date is required"}
                                </small>
                              )}
                            </LocalizationProvider>
                          </div>

                          <div className="col-4  mt-3 text-left">
                            <TextField
                              fullWidth
                              size="small"
                              autoComplete="false"
                              name="phone"
                              label="Phone number"
                              placeholder="Phone number"
                              variant="outlined"
                              value={teacherForm.phoneNumber}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  phoneNumber: e.target.value,
                                });

                                setErrors({
                                  ...errors,
                                  phoneNumberHasError: false,
                                });
                              }}
                            />
                            {errors.phoneNumberHasError && (
                              <small className="text-danger mt-1">
                                {!!teacherForm.phoneNumber
                                  ? "Invalid Phone Number"
                                  : " Phone Number is required"}
                              </small>
                            )}
                          </div>

                          <div className="col-4  mt-3 text-left">
                            <TextField
                              fullWidth
                              size="small"
                              autoComplete="false"
                              name="email"
                              label="Personal email"
                              placeholder="Personal email"
                              variant="outlined"
                              value={teacherForm.email}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  email: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  emailHasError: false,
                                });
                              }}
                            />

                            {errors.emailHasError && (
                              <small className="text-danger">
                                {!!teacherForm.email
                                  ? "Invalid Personal email"
                                  : " Personal email is required"}
                              </small>
                            )}
                          </div>

                          <div className="mt-3 col-4">
                            <Autocomplete
                              size="small"
                              defaultValue={null}
                              value={selectedEmploymentType}
                              options={employmentTypes}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="employmentType"
                                  label="Employment Type"
                                  placeholder="Employment Type"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              onChange={(event, employmentType) => {
                                setSelectedEmploymentType(
                                  employmentType || null
                                );

                                setErrors({
                                  ...errors,
                                  employmentTypeHasError: false,
                                });
                              }}
                            />
                            {errors.employmentTypeHasError && (
                              <small className="text-danger">
                                Employment Type is required
                              </small>
                            )}
                          </div>

                          <div className="mt-3 col-4">
                            <Autocomplete
                              size="small"
                              defaultValue={null}
                              value={selectedDegree}
                              options={degrees}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Highest Degree"
                                  name="degree"
                                  placeholder="Highest Degree"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              onChange={(event, degree) => {
                                setSelectedDegree(degree || null);

                                setErrors({
                                  ...errors,
                                  degreeHasError: false,
                                });
                              }}
                            />
                            {errors.degreeHasError && (
                              <small className="text-danger">
                                Highest Degree is required
                              </small>
                            )}
                          </div>
                          <div className="mt-3 col-4">
                            <Autocomplete
                              size="small"
                              defaultValue={null}
                              value={selectedSpecialization}
                              options={specializations}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Specialization"
                                  name="specialization"
                                  placeholder="Specialization"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              onChange={(event, specialization) => {
                                setSelectedSpecialization(
                                  specialization || null
                                );

                                setErrors({
                                  ...errors,
                                  specializationHasError: false,
                                });
                              }}
                            />
                            {errors.specializationHasError && (
                              <small className="text-danger">
                                Specialization is required
                              </small>
                            )}
                          </div>
                          <div className="mt-3 col-4">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                label="Graduation date (dd/MM/yyyy)"
                                inputFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                value={teacherForm.graduationDate || null}
                                onChange={(date) => {
                                  setTeacherForm({
                                    ...teacherForm,
                                    graduationDate: date,
                                  });

                                  setErrors({
                                    ...errors,
                                    graduationDateHasError: false,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    fullWidth
                                    {...params}
                                  />
                                )}
                              />

                              {errors.graduationDateHasError && (
                                <small className="text-danger mt-1">
                                  {!!teacherForm.graduationDate
                                    ? "Invalid Date"
                                    : "Graduation Date is required"}
                                </small>
                              )}
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className=" d-flex justify-content-center py-4">
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            disableElevation
            size="sm"
            className="px-5"
            onClick={onSave}
          >
            {loading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  placementReportDetails,
  changeList,
  prevPlacementReport,
  signatories,
  roles,
  districts,
  sectors,
  degrees,
  specializations,
  schoolPositions,
  qualificationLevels,
}) => {
  return {
    user,
    loading,
    onWait,
    placementReportDetails,
    changeList,
    prevPlacementReport,
    signatories,
    roles,
    districts,
    sectors,
    degrees,
    specializations,
    schoolPositions,
    qualificationLevels,
  };
};
export default connect(mapStateToProps, {
  addOrUpdateTeacher,
  getSectors,
})(AddNewTeacherDialog);
