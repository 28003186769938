import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  Button,
  Badge,
  Menu,
  MenuItem,
  Divider,
  Alert,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getPositionBudgetPosts,
  // cancelBudgetPostRequest,
  deleteBudgetPost,
} from "../../../../store/posts/actions";
import { showError } from "../../../toastify";
import moment from "moment";
import Draggable, { DraggableCore } from "react-draggable";
import BudgetPostRequestDialog from "./BudgetPostRequestDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const PositionBudgetPostsDialog = (props) => {
  const {
    user,
    loading,
    onWait,
    showDialog,
    setShowDialog,
    position,
    positionBudgetPosts,
    getPositionBudgetPosts,
    // cancelBudgetPostRequest,
    deleteBudgetPost,
  } = props;

  useEffect(() => {
    if (!!position && !!showDialog) getPositionBudgetPosts(position.id);
  }, [showDialog]);

  const onClose = () => {
    setShowDialog(false);
  };

  const [showBudgetPostRequestDialog, setShowBudgetPostRequestDialog] =
    useState(false);

  const [confirmCancelBudgetPostRequest, setConfirmCancelBudgetPostRequest] =
    useState(false);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-dark font-weight-bold d-inline-flex align-items-center">
              <span>Budget Posts</span>
              <span className="material-icons text-info">arrow_right</span>{" "}
              <span className="text-info">{position.name}</span>
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              borderRadius: "0.5rem",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              height: "50vh",
              overflowY: "auto",
            }}
            className="px-3 "
          >
            {/* <div className="text-right">
              <Button
                onClick={() => {
                  setShowBudgetPostRequestDialog(true);
                }}
                variant="contained"
                size="small"
                disableElevation
                color="primary"
                style={{
                  borderRadius: "8px",
                  height: "32px",

                  alignItems: "center",
                  fontSize: ".75rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "initial",
                }}
              >
                <span className="material-icons mr-1">add_card</span>
                Post request
              </Button>
            </div> */}

            <table className="table table-striped table-hover  mt-2  fixTableHead">
              {!!positionBudgetPosts.length && (
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>
                    <th scope="col">Budget Post Id</th>

                    <th scope="col">Active Teacher</th>
                    <th scope="col" className="text-left">
                      Placement Date
                    </th>

                    <th scope="col" className="text-right">
                      {/* Actions */}
                    </th>
                  </tr>
                </thead>
              )}

              {!!positionBudgetPosts.length && (
                <tbody>
                  {positionBudgetPosts.map((budgetPost, index) => (
                    <PositionBudgetPostCard
                      key={index}
                      index={index}
                      budgetPost={budgetPost}
                      deleteBudgetPost={deleteBudgetPost}
                    />
                  ))}
                </tbody>
              )}
            </table>
            {!!position.pendingBudgetPostRequest && !loading && (
              <div
                className={`alert alert-${
                  position.pendingBudgetPostRequestStatusId === 3
                    ? "success"
                    : "secondary"
                } py-1 text-center d-flex justify-content-between align-items-center pr-1`}
              >
                <span></span>
                <span className="h6">
                  {" "}
                  <span className="badge badge-secondary">
                    {position.pendingBudgetPostRequest}
                  </span>{" "}
                  Pending post{position.pendingBudgetPostRequest > 1 ? "s" : ""}{" "}
                  request...
                </span>

                <span>
                  {position.pendingBudgetPostRequestStatusId === 1 &&
                    user?.selectedEntity.role === "HEAD_TEACHER" && (
                      <Button
                        onClick={() => {
                          setConfirmCancelBudgetPostRequest(true);
                        }}
                        disabled={loading}
                        size="small"
                        disableElevation
                        color="error"
                        style={{
                          borderRadius: "8px",
                          height: "32px",

                          alignItems: "center",
                          fontSize: ".75rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "initial",
                        }}
                      >
                        <span className="material-icons mr-1">
                          highlight_off
                        </span>
                        Cancel request
                      </Button>
                    )}
                </span>
              </div>
            )}
            {!positionBudgetPosts?.length && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container py-3 ">
                  <p className="lead">
                    {loading ? "Loading..." : "No record found"}{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4"></DialogActions>
      </Dialog>

      {showBudgetPostRequestDialog && (
        <BudgetPostRequestDialog
          showDialog={showBudgetPostRequestDialog}
          setShowDialog={setShowBudgetPostRequestDialog}
          position={position}
        />
      )}

      {/* {confirmCancelBudgetPostRequest && (
        <ConfirmationDialog
          confirmationDialog={confirmCancelBudgetPostRequest}
          message={`Are you sure you want to cancel this request?`}
          setConfirmationDialog={setConfirmCancelBudgetPostRequest}
          onYes={() => {
            cancelBudgetPostRequest(
              position.id,
              setConfirmCancelBudgetPostRequest
            );
          }}
        />
      )} */}
    </>
  );
};

const mapStateToProps = ({ loading, onWait, user, positionBudgetPosts }) => {
  return {
    loading,
    onWait,
    user,
    positionBudgetPosts,
  };
};
export default connect(mapStateToProps, {
  getPositionBudgetPosts,
  // cancelBudgetPostRequest,
  deleteBudgetPost,
})(PositionBudgetPostsDialog);

const PositionBudgetPostCard = (props) => {
  const { budgetPost, index, deleteBudgetPost } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [confirmRemoveBudgetPost, setConfirmRemoveBudgetPost] = useState(false);

  return (
    <>
      <tr className={`${!budgetPost.teacherId ? "tr-danger" : ""}`}>
        <th>{index + 1}</th>
        <th>{budgetPost.id}</th>
        <td className={`${!budgetPost.teacherId ? "text-danger " : ""}`}>
          {!!budgetPost.teacherId
            ? budgetPost.lastName + " " + budgetPost.firstName
            : "Vacant"}
        </td>
        <td>
          {!!budgetPost.placementDate
            ? moment(budgetPost.placementDate).format("DD/MM/yyyy")
            : "-"}
        </td>
        <td className="text-right">
          {!budgetPost.teacherId && (
            <IconButton
              size="small"
              className="p-0"
              onClick={() => setConfirmRemoveBudgetPost(true)}
              aria-label="settings"
            >
              <Badge color="info">
                <span className="material-icons text-danger">
                  delete_forever
                </span>
              </Badge>
            </IconButton>
          )}

          {!!budgetPost.teacherId && <>-</>}
        </td>
      </tr>

      {confirmRemoveBudgetPost && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveBudgetPost}
          message={
            <>
              Are you sure you want to{" "}
              <strong className="text-danger">remove</strong> this budget post ?
            </>
          }
          setConfirmationDialog={setConfirmRemoveBudgetPost}
          onYes={() => {
            deleteBudgetPost(budgetPost.id, setConfirmRemoveBudgetPost);
          }}
        />
      )}
    </>
  );
};
