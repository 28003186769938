export const defaultState = {
  units: [],
  schoolPositions: [],
  schoolTeachers: [],
  jobs: [],
  districts: [],
  selectedUnit: {},
  organigramData: [],
  sectors: [],
  cells: [],
  villages: [],
  schoolLevels: [],
  schools: [],
  positions: [],
  qualificationLevels: [],
  positionBudgetPosts: [],
  budgetPostRequests: [],
  rebPostDistributions: [],
  ddePostDistributions: [],
  htPostDistributions: [],
  rebPostLimits: {
    a0Limit: 0,
    a1Limit: 0,
    a2Limit: 0,
    a0Remaining: 0,
    a1Remaining: 0,
    a2Remaining: 0,
  },
  ddePostLimits: {
    a0Limit: 0,
    a1Limit: 0,
    a2Limit: 0,
    a0Remaining: 0,
    a1Remaining: 0,
    a2Remaining: 0,
  },
  htPostLimits: {
    a0Limit: 0,
    a1Limit: 0,
    a2Limit: 0,
    a0Remaining: 0,
    a1Remaining: 0,
    a2Remaining: 0,
  },

  systemSettingPositions: [],
  positionRoles: [],

  pudgetPostRequestPositions: [],
  teachingLevels: [],
};
