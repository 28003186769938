import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoSmall from "../../assets/REB_Logo.png";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import Login from "../pages/Login";
import SupportStaff from "../pages/SupportStaff";
const Header = () => {
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [showSupportStaffDialog, setShowSupportStaffDialog] = useState(false);

  return (
    <>
      <Box>
        <AppBar position="static" elevation={0} className="app-bar">
          <Toolbar className="pr-2 tool-bar">
            <Link to="/" style={{ textDecoration: "none" }}>
              <Typography component="div" className="d-flex align-items-center">
                <img src={logoSmall} className="brand_logo mr-2" alt="Logo" />

                <span
                  className="d-none d-md-inline"
                  style={{
                    color: "#ed6c02",
                    fontWeight: "bolder",
                    fontSize: "22px",
                  }}
                >
                  TMIS
                </span>
              </Typography>
            </Link>
            <Box sx={{ flexGrow: 1 }} />

            <Button
              onClick={() => setShowSupportStaffDialog(true)}
              style={{ backgroundColor: "#dbeafe" }}
              className="p-0 support-staff d-none d-lg-inline-flex"
            >
              {" "}
              <span
                style={{ backgroundColor: "#bfdbfe", borderRadius: "5px" }}
                className="d-inline-flex mr-2 py-2 px-2 ico"
              >
                <PermPhoneMsgIcon />
              </span>{" "}
              <span>Support Staff </span>
              <span
                className="bg-white d-inline-flex  mx-2 p-1 ico"
                style={{ borderRadius: "50%" }}
              >
                <ArrowForwardIcon />
              </span>
            </Button>
            <IconButton
              onClick={() => setShowSupportStaffDialog(true)}
              className="p-0 d-inline d-lg-none"
            >
              <span
                style={{ backgroundColor: "#bfdbfe", borderRadius: "5px" }}
                className="d-inline-flex mr-2 py-2 px-2 ico"
              >
                <span className="material-icons">perm_phone_msg</span>
              </span>
            </IconButton>

            <Box sx={{ flexGrow: 1 }} />

            <div className="mr-2 d-flex">
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setShowLoginDialog(true)}
              >
                Login
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </Box>

      {showLoginDialog && (
        <Login
          showLoginDialog={showLoginDialog}
          setShowLoginDialog={setShowLoginDialog}
        />
      )}

      {showSupportStaffDialog && (
        <SupportStaff
          showSupportStaffDialog={showSupportStaffDialog}
          setShowSupportStaffDialog={setShowSupportStaffDialog}
        />
      )}
    </>
  );
};

export default Header;
