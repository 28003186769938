import { defaultState } from "./state";

import commonTypes from "../common/action-types";
import otherTypes from "./action-types";
const types = { ...commonTypes, ...otherTypes };

const scholarshipCombinations = (
  scholarshipCombinationsState = defaultState.scholarshipCombinations,
  action
) => {
  switch (action.type) {
    case types.SET_SCHOLARSHIP_COMBINATIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.scholarshipCombinations;

    default:
      return scholarshipCombinationsState;
  }
};

const scholarshipCourses = (
  scholarshipCoursesState = defaultState.scholarshipCourses,
  action
) => {
  switch (action.type) {
    case types.SET_SCHOLARSHIP_COURSES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.scholarshipCourses;

    default:
      return scholarshipCoursesState;
  }
};

const scholarshipDocuments = (
  scholarshipDocumentsState = defaultState.scholarshipDocuments,
  action
) => {
  switch (action.type) {
    case types.SET_SCHOLARSHIP_DOCUMENTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.scholarshipDocuments;

    default:
      return scholarshipDocumentsState;
  }
};

const scholarshipRequests = (
  scholarshipRequestsState = defaultState.scholarshipRequests,
  action
) => {
  switch (action.type) {
    case types.SET_SCHOLARSHIP_REQUESTS:
      return action.data;

    case types.ADD_OR_UPDATE_SCHOLARSHIP_REQUEST: {
      const tmpState = [...scholarshipRequestsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0)
        tmpState[index] = { no: tmpState[index].no, ...action.data };
      else if (!!action.data)
        tmpState.push({ no: tmpState.length + 1, ...action.data });

      return tmpState;
    }

    case types.DELETE_SCHOLARSHIP_REQUEST: {
      const tmpState = [...scholarshipRequestsState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.scholarshipRequests;

    default:
      return scholarshipRequestsState;
  }
};

const scholarshipRequestAttachments = (
  scholarshipRequestAttachmentsState = defaultState.scholarshipRequestAttachments,
  action
) => {
  switch (action.type) {
    case types.SET_SCHOLARSHIP_REQUEST_ATTACHMENTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.scholarshipRequestAttachments;

    default:
      return scholarshipRequestAttachmentsState;
  }
};

const scholarshipRequestLogs = (
  scholarshipRequestLogsState = defaultState.scholarshipRequestLogs,
  action
) => {
  switch (action.type) {
    case types.SET_SCHOLARSHIP_REQUEST_LOGS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.scholarshipRequestLogs;

    default:
      return scholarshipRequestLogsState;
  }
};

const eligibility = (eligibilityState = defaultState.eligibility, action) => {
  switch (action.type) {
    case types.SET_ELIGIBILITY:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.eligibility;

    default:
      return eligibilityState;
  }
};

const scholarshipSummary = (
  scholarshipSummaryState = defaultState.scholarshipSummary,
  action
) => {
  switch (action.type) {
    case types.SET_SCHOLARSHIP_SUMMARY:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.scholarshipSummary;

    default:
      return scholarshipSummaryState;
  }
};

export default {
  scholarshipCombinations,
  scholarshipCourses,
  scholarshipDocuments,
  scholarshipRequests,
  scholarshipRequestAttachments,
  scholarshipRequestLogs,
  eligibility,
  scholarshipSummary,
};
