import React, { useEffect } from "react";

import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import ToastifyMessage from "./inner/common/components/ToastifyMessage";
import OfflineMessage from "./inner/common/components/OfflineMessage";
import TopProgressBar from "./inner/common/components/TopProgressBar";

import { getEnv, getUser } from "../store/common/actions";
import Footer from "./home/components/Footer";
import loadingGif from "./assets/loading.webp";

import EnvMessage from "./inner/common/components/EnvMessage";

import HomeLayout from "./home/Layout";
import InnerLayout from "./inner/Layout";
import PasswordReset from "./home/pages/PasswordReset";

function App(props) {
  const history = useHistory();
  const location = useLocation();

  const { env, getUser, isFetchingUser, getEnv } = props;

  useEffect(() => {
    getEnv();
    getUser(history, location);
  }, []);

  return (
    <>
      {isFetchingUser && (
        <div className="loader ">
          <img src={loadingGif} height="96" alt="loading" />
        </div>
      )}
      {!isFetchingUser && (
        <div id="main" style={{ overflowX: "visible" }}>
          <TopProgressBar />
          <ToastifyMessage />
          {!!env && env !== "prod" && <EnvMessage env={env} />}
          <OfflineMessage />

          <Switch>
            <Route path="/in" component={InnerLayout} />

            <Route path="/" component={HomeLayout} />
          </Switch>
        </div>
      )}

      {!location.pathname.includes("/in") && <Footer />}
    </>
  );
}

const mapStateToProps = ({ env, isFetchingUser }) => {
  return {
    env,
    isFetchingUser,
  };
};
export default connect(mapStateToProps, {
  getEnv,
  getUser,
})(App);
