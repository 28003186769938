import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import { connect } from "react-redux";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import {
  savePermutationSchedule,
  deletePermutationSchedule,
} from "../../../../store/transfer/actions";
import { useEffect } from "react";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

const PermutationScheduleDialog = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    savePermutationSchedule,
    permutationSchedule,
    deletePermutationSchedule,
  } = props;

  const [formData, setFormData] = useState({
    id: permutationSchedule?.id || null,
    startOn: permutationSchedule?.startOn || null,
    endOn: permutationSchedule?.endOn || null,
  });

  const onSave = (e) => {
    e.preventDefault();
    savePermutationSchedule(
      {
        startOn: moment(formData.startOn).format("yyyy-MM-DD"),
        endOn: moment(formData.endOn).format("yyyy-MM-DD"),
      },
      () => setShowDialog(false)
    );
  };

  const [showRemoveSchedule, setShowRemoveSchedule] = useState(false);

  return (
    <>
      <Dialog
        onClose={() => {
          setShowDialog(false);
        }}
        fullWidth
        maxWidth="xs"
        open={showDialog}
      >
        <DialogTitle>
          <Typography className="text-center text-uppercase text-primary">
            Schedule Permutation
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="row align-items-center mx-0 justify-content-center mt-3">
            <div className="col-11 mb-2">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  minDate={new Date()}
                  label="Start on"
                  inputFormat="dd/MM/yyyy"
                  value={formData.startOn || null}
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      startOn: date,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      name="scholarshipGraduationDate"
                      onKeyDown={(e) => e.preventDefault()}
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-11 mb-2">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  disabled={!formData.startOn}
                  minDate={new Date()}
                  label="End on"
                  inputFormat="dd/MM/yyyy"
                  value={formData.endOn || null}
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      endOn: date,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      name="scholarshipGraduationDate"
                      onKeyDown={(e) => e.preventDefault()}
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            color="primary"
            variant="contained"
            onClick={onSave}
            type="button"
            disabled={
              loading ||
              !formData.startOn ||
              !formData.endOn ||
              !!showRemoveSchedule
            }
            className="px-5"
          >
            Confirm
          </Button>
          {/* {!!permutationSchedule && (
            <Button
              className="px-5"
              color="error"
              variant="contained"
              onClick={() => setShowRemoveSchedule(true)}
              type="button"
              disabled={loading || !!showRemoveSchedule}
            >
              Remove
            </Button>
          )} */}
        </DialogActions>
      </Dialog>

      {showRemoveSchedule && (
        <ConfirmationDialog
          confirmationDialog={showRemoveSchedule}
          message={
            <>
              <span>Are you sure you want to remove this schedule?</span>
            </>
          }
          setConfirmationDialog={setShowRemoveSchedule}
          onYes={() => {
            deletePermutationSchedule(permutationSchedule?.id, () =>
              setShowDialog(false)
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return { loading };
};
export default connect(mapStateToProps, {
  savePermutationSchedule,
  deletePermutationSchedule,
})(PermutationScheduleDialog);
