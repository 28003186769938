import React, { useEffect } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import pdfREB from "../../../assets/transfer_user_guide_REB.pdf";
import pdfRTB from "../../../assets/transfer_user_guide_RTB.pdf";
import { connect } from "react-redux";

const UserGuide = (props) => {
  const { user } = props;

  useEffect(() => {
    document.title = "TMIS | Transfers - User Guide";
  });

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div
      className=""
      style={{
        height: "75vh",
      }}
    >
      {user?.selectedScope?.id === "RTB" && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
          <Viewer fileUrl={pdfRTB} plugins={[defaultLayoutPluginInstance]} />
        </Worker>
      )}

      {user?.selectedScope?.id === "REB" && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
          <Viewer fileUrl={pdfREB} plugins={[defaultLayoutPluginInstance]} />
        </Worker>
      )}
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
export default connect(mapStateToProps, {})(UserGuide);
