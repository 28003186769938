import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  Button,
  Divider,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { moveBadgetPost } from "../../../../store/posts/actions";
import { showError } from "../../../toastify";

const qualificationLevels = [
  { id: 1, name: "A2" },
  { id: 2, name: "A1" },
  { id: 3, name: "A0" },
];

const MoveBadgetPostDialog = (props) => {
  const { loading, showDialog, setShowDialog, district, moveBadgetPost } =
    props;

  const [fromQualification, setFromQualification] = useState(null);
  const [toQualification, setToQualification] = useState(null);
  const [number, setNumber] = useState(0);

  const onClose = () => {
    setFromQualification(null);
    setToQualification(null);
    setNumber(0);
    setShowDialog(false);
  };

  const onSave = () => {
    moveBadgetPost(
      {
        districtId: district.id,
        number: +number,
        fromQualificationId: fromQualification?.id,
        toQualificationId: toQualification?.id,
      },
      () => {
        onClose();
      }
    );
  };

  const getFromQualificationLevels = () =>
    qualificationLevels.sort((a, b) => b.id - a.id);

  const getToQualificationLevels = () =>
    qualificationLevels
      .sort((a, b) => b.id - a.id)
      .filter(({ id }) => !!fromQualification && id !== fromQualification.id);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-dark font-weight-bold">Move Badget Post</span>{" "}
            | {district.name} DISTRICT
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="mt-3">
            <Autocomplete
              size="small"
              defaultValue={null}
              value={fromQualification || null}
              options={getFromQualificationLevels()}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, fromQualification) => {
                setNumber(0);
                setToQualification(null);
                setFromQualification(fromQualification || null);
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, fromQualification) => (
                <Box component="li" {...props}>
                  {fromQualification.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Move From"
                  name="fromQualification"
                  placeholder="Move From"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </div>

          <div className="mt-3">
            <Autocomplete
              disabled={!fromQualification}
              size="small"
              defaultValue={null}
              value={toQualification}
              options={getToQualificationLevels()}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, toQualification) => {
                setNumber(0);
                setToQualification(toQualification || null);
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, toQualification) => (
                <Box component="li" {...props}>
                  {toQualification.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Move To"
                  name="toQualification"
                  placeholder="Move To"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </div>

          <div className="mt-3">
            <TextField
              disabled={!fromQualification || !toQualification}
              type="number"
              fullWidth
              size="small"
              name="number"
              label="Number"
              variant="outlined"
              placeholder="Number"
              value={number}
              onBlur={() => {
                if (number === "") setNumber(0);
              }}
              onKeyDown={() => {
                if (+number === 0) setNumber("");
              }}
              onChange={(e) => {
                setNumber(e.target.value || 0);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            variant="contained"
            onClick={onSave}
            type="button"
            className="px-5"
            disabled={
              loading || !fromQualification || !toQualification || !number
            }
          >
            {loading ? "Wait..." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  moveBadgetPost,
})(MoveBadgetPostDialog);
