import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Dashboard from "./pages/Dashboard";

import { isEmpty } from "lodash";
import { connect } from "react-redux";
import PostsDistribution from "./pages/InProgress";
import UserGuide from "./pages/UserGuide";
import InProgress from "./pages/InProgress";
import Archived from "./pages/Archived";

import { getCpdData } from "../../../store/cpd/actions";

const Layout = (props) => {
  useEffect(() => {
    document.title = "TMIS | Trainings";
  }, []);

  const { user, cpdTestData, getCpdData } = props;
  const history = useHistory();

  // useEffect(() => {
  //   getCpdData();
  // }, []);

  return (
    <React.Fragment>
      <Switch>
        <Route path="/in/cpd/dashboard" component={Dashboard} />
        <Route path="/in/cpd/in-progress-trainings" component={InProgress} />
        <Route path="/in/cpd/archived-trainings" component={Archived} />
        <Route path="/in/cpd/user-guide" component={UserGuide} />

        <Route
          path="/in/cpd"
          component={() => <Redirect to="/in/cpd/dashboard" />}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, cpdTestData }) => {
  return {
    user,
    cpdTestData,
  };
};
export default connect(mapStateToProps, { getCpdData })(Layout);
