import cpdTypes from "./action-types";
import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";
import commonTypes from "../common/action-types";
import download from "downloadjs";

const types = { ...commonTypes, ...cpdTypes };

export const getInProgressMaternityLeaves = (
  query,
  setTotalRecords,
  setLoader
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      setLoader(true);

      const { data } = await axios.get(
        "/api/maternity-leave/in-progress" +
          "?" +
          new URLSearchParams(query).toString()
      );

      setLoader(false);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_IN_PROGRESS_MATERNITY_LEAVES,
        data: data.records,
      });

      setTotalRecords(data.totalRecords);
    } catch (error) {
      setLoader(false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getArchivedMaternityLeaves = (
  query,
  setTotalRecords,
  setLoader
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      setLoader(true);

      const { data } = await axios.get(
        "/api/maternity-leave/archived" +
          "?" +
          new URLSearchParams(query).toString()
      );

      setLoader(false);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_ARCHIVED_MATERNITY_LEAVES,
        data: data.records,
      });

      setTotalRecords(data.totalRecords);
    } catch (error) {
      setLoader(false);
      dispatch({ type: types.END_LOADING });

      showError(error);
    }
  };
};

export const saveOrUpdateMaternityLeave = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/maternity-leave/requests",
        formData
      );

      onClose();

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_OR_UPDATE_MATERNITY_LEAVE,
        data: data,
      });

      showSuccess(
        !formData?.id ? "Saved successfully" : "Updated successfully"
      );
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const approveMaternityLeaveRequest = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/maternity-leave/approve-request",
        formData
      );

      onClose();

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_OR_UPDATE_MATERNITY_LEAVE,
        data: data,
      });

      showSuccess(
        formData?.statusId === 2
          ? "Approved successfully"
          : "Rejected successfully"
      );
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const deleteMaternityLeaveRequest = (id, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/maternity-leave/requests/" + id);

      onClose();

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.DELETE_MATERNITY_LEAVE,
        id,
      });

      showSuccess("Deleted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getMaternityLeaveReplacements = (
  query,
  setTotalRecords,
  setLoader
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      setLoader(true);

      const { data } = await axios.get(
        "/api/maternity-leave/replacements" +
          "?" +
          new URLSearchParams(query).toString()
      );

      setLoader(false);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_MATERNITY_LEAVE_REPLACEMENTS,
        data: data.records,
      });

      setTotalRecords(data.totalRecords);
    } catch (error) {
      setLoader(false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const addOrUpdateReplacementStaff = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/maternity-leave/replacement-staff",
        formData
      );

      onClose();

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_OR_UPDATE_MATERNITY_LEAVE,
        data: data,
      });

      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getReplacementStaffProfile = (actingStaffId, setTeacherForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/maternity-leave/replacement-staff/" + actingStaffId
      );

      setTeacherForm(data);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
