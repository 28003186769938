import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import { Typography, Box, Button, Menu, MenuItem } from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";

const Welcome = (props) => {
  useEffect(() => {
    document.title = "TMIS - Welcome";
  }, []);

  const { user, loading } = props;
  const [searchTeam, setSearchTeam] = useState("");

  useEffect(() => {});

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <div className="px-4 pt-2">
      <div
        className="d-flex border align-items-center justify-content-center flex-column"
        style={{
          height: "74vh",
          backgroundColor: "#f3f4f6",
        }}
      >
        <span className="lead">
          Welcome{" "}
          <strong>
            {props.user.lastName} {props.user.firstName}
          </strong>{" "}
          on TMIS,
        </span>
        <small className="text-primary">
          The Teacher Management Information System
        </small>

        {/* <div
        className="jumbotron jumbotron-fluid text-center mt-2"
        style={{ width: "100%" }}
      >
        <div className="container">
          
        </div>
      </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, {})(Welcome);
