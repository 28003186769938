import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  Button,
  Avatar,
  Checkbox,
  Divider,
  LinearProgress,
  Paper,
  InputBase,
  Stepper,
  Step,
  StepLabel,
  Menu,
  MenuItem,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { savePermutationRequest } from "../../../../store/transfer/actions";
import { getDistricts } from "../../../../store/posts/actions";
import { getTeacherProfile } from "../../../../store/users/actions";
import { showError } from "../../../toastify";
import axios from "axios";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import defaultPofile from "../../../assets/default-profile.jpg";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import { isEmpty } from "lodash";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

import {
  onTeacherTwoAccept,
  getTransferRequestAttachment,
  approveTransferAttachment,
} from "../../../../store/transfer/actions";

const steps = ["Teacher details ", "Permutation details"];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ITEM_HEIGHT = 60;

export const documents = [
  {
    id: 1,
    name: "Degree",
  },
  {
    id: 2,
    name: "Appointment letter",
  },
  {
    id: 3,
    name: "Peformance appraisal",
  },
  {
    id: 4,
    name: "proof of disability (NCPD card for teachers with disability)",
    lable:
      "Upload your proof of disability (NCPD card for teachers with disability)",
    question: "Do you have disability?",
  },
  {
    id: 5,
    name: "a copy of medical certificate from a referral or district hospital approved by three (3) Doctors.",
    lable:
      "Upload a copy of medical certificate from a referral or district hospital approved by three (3) Doctors.",
    question: "Do you have chronic disease?",
  },
  {
    id: 6,
    name: "birth certificate and proof of disability/chronic disease of your child",
    lable:
      "Upload birth certificate and proof of disability/chronic disease of your child",
    question: "Does your child has disability/chronic disease?",
  },
  {
    id: 7,
    name: "marriage certificate and proof of disability/chronic disease of your spouse",
    lable:
      "Upload marriage certificate and proof of disability/chronic disease of your spouse",
    question: "Does your spouse has disability/chronic disease?",
  },
  {
    id: 8,
    name: "Recommendation letter  from the District",
    lable: "Recommendation letter  from the District",
    question: "Upload the recommendation letter  from the District",
  },
];

const TransferRequestPreviewDialog = (props) => {
  const {
    user,
    loading,
    showDialog,
    setShowDialog,
    savePermutationRequest,
    districts,
    getDistricts,
    teacherProfile,
    getTeacherProfile,
    request,
    onTeacherTwoAccept,
    getTransferRequestAttachment,
    transferRequestAttachments,
    approveTransferAttachment,
    n,
    transferEligibility,
  } = props;

  useEffect(() => {
    getTeacherProfile(request.teacherId);

    getTransferRequestAttachment(request.id);
  }, []);

  const [formData, setFormData] = useState({
    teacherId: "",
    attachments: [],
    reason: "",
  });

  const onClose = () => {
    setShowDialog(false);

    setFormData({
      teacherId: "",
      reason: "",
    });
  };

  const [errors, setErrors] = useState({
    reasonHasError: false,
    attachmentHasErrors: [],
    hasError: false,
  });

  const formStepper0Validator = () => {
    const error = {
      reasonHasError: false,
      attachmentHasErrors: [],
      hasError: false,
    };

    documents
      .filter(({ id }) => id <= 3)
      .forEach((doc) => {
        const document = formData.attachments.find(({ id }) => doc.id === id);

        if (!document) {
          error.attachmentHasErrors.push(doc);
          error.hasError = true;
        }
      });

    documents
      .filter(
        ({ id }) => id > 3 && formData[`hasOtherDocument${id}`] === "true"
      )
      .forEach((doc) => {
        const document = formData.attachments.find(({ id }) => doc.id === id);

        if (!document) {
          error.attachmentHasErrors.push(doc);
          error.hasError = true;
        }
      });

    setErrors(error);

    if (error.hasError) {
      showError(`Please upload all the mandatory documents`);

      return true;
    }
    return false;
  };

  const [showConfirm, setShowConfirm] = useState(false);
  const [foundTeacher, setFoundTeacher] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [teacherTwoMessage, setTeacherTwoMessage] = useState("");

  const convertToLower = (value) => {
    return value.toLowerCase();
  };

  const isFileTooLarge = (file) => {
    const size = file.size;
    return size > 1074176 ? true : false;
  };

  const handleUploadedPDFFile = (event, document) => {
    let pdfAttachment = event.target.files[0];

    setErrors({ ...errors, pdfAttachmentHasError: false });

    if (convertToLower(pdfAttachment?.name?.substr(-4)) !== ".pdf") {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("File should be pdf format");
    } else if (isFileTooLarge(pdfAttachment)) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("Allowed file should not exceed 1MB");
    } else {
      const index = formData.attachments.findIndex(
        ({ id }) => id === document.id
      );
      const attachments = [...formData.attachments];
      document.file = pdfAttachment;
      document.filename = pdfAttachment.name;

      const reader = new FileReader();

      reader.onload = (e) => {
        document.url = e.target.result;
      };

      reader.readAsDataURL(pdfAttachment);

      if (index < 0) attachments.push(document);
      else attachments[index] = document;
      setFormData({
        ...formData,
        attachments,
      });
      const index2 = errors.attachmentHasErrors.findIndex(
        ({ id }) => id === document.id
      );
      errors.attachmentHasErrors.splice(index2, 1);
      setErrors(errors);
    }
  };

  const onSave = () => {
    const payload = new FormData();
    const keys = Object.keys(formData);
    for (const key of keys) {
      if (formData.hasOwnProperty(key)) {
        if (key === "attachments" && formData[key] && !!formData[key].length)
          formData[key].forEach((row, i) => {
            payload.append("files", row.file);
            payload.append(`selectedIds`, row.id);
          });
        else payload.append(`${key}`, `${formData[key]}`);
      }
    }

    payload.append(`requestId`, request.id);
    payload.append(`accepted`, true);
    payload.append(`teacherTwoMessage`, teacherTwoMessage);
    payload.append(`isReb`, false);

    onTeacherTwoAccept(payload, () => {
      setShowConfirm(false);
      setShowDialog(false);
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);

    event.stopPropagation();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const attachment = (id) =>
    transferRequestAttachments.find(({ docNo }) => docNo === id);

  const [isApproved, setIsApproved] = useState(null);
  const [showConfirmAttachment, setShowConfirmAttachment] = useState(false);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary mx-5 px-2">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-primary font-weight-bold">
              Transfer request form
            </span>{" "}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="mx-3">
          <>
            <div className="row mx-0">
              <div className="col-sm-12">
                <React.Fragment>
                  <div>
                    <div className="row no-gutters">
                      <div
                        className="col-2 mt-2 justify-content-center  text-center py-3"
                        style={{
                          backgroundColor: "#f3f4f6",
                        }}
                      >
                        <div
                          style={{
                            minHeight: "150px",
                            overflow: "hidden",
                          }}
                        >
                          <>
                            <img
                              className="mt-0 rounded  text-center"
                              src={
                                !!teacherProfile.profilePicture
                                  ? "data:image/jpg;base64," +
                                    teacherProfile.profilePicture
                                  : defaultPofile
                              }
                              style={{
                                border: "1px solid #a7a7a7",
                                height: "150px",
                                width: "120px",
                                overflow: "hidden",
                                borderRadius: "50%",
                              }}
                            />
                          </>
                        </div>
                        <div className="text-primary">
                          <strong>NID PHOTO</strong>{" "}
                        </div>
                        {!!teacherProfile?.id && (
                          <div className="text-primary">
                            <strong>{teacherProfile?.id}</strong>{" "}
                          </div>
                        )}
                        <label className="text-center text-uppercase  mt-n2 mb-0">
                          <span style={{ fontSize: "12px" }}>
                            {teacherProfile?.lastName}{" "}
                            {teacherProfile?.firstName}
                          </span>
                        </label>{" "}
                        {/* {!isEmpty(teacherProfile) && (
                          <label className="text-center text-uppercase  mt-n3">
                            <span
                              style={{ fontSize: "12px" }}
                              className="badge badge-primary"
                            >
                              {teacherProfile?.positionName}
                              {" ("}
                              {teacherProfile?.qualificationLevelName} {")"}
                            </span>
                          </label>
                        )} */}
                        {!isEmpty(teacherProfile) && (
                          <FormControl
                            className="mt-3 d-flex-block   pt-2"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <FormLabel className="text-primary   px-4 text-left">
                              Education certficate
                            </FormLabel>
                            <RadioGroup
                              className="mt-0 d-flex justify-content-start px-4"
                              row
                              name="hasEducationCertficate"
                              value={
                                teacherProfile?.hasEducationCertficate || false
                              }
                            >
                              {!!teacherProfile?.hasEducationCertficate && (
                                <FormControlLabel
                                  value={true}
                                  control={<Radio disableRipple size="small" />}
                                  label="Certfied"
                                />
                              )}
                              {!teacherProfile?.hasEducationCertficate && (
                                <FormControlLabel
                                  value={false}
                                  control={<Radio disableRipple size="small" />}
                                  label="Not certfied"
                                />
                              )}
                            </RadioGroup>
                          </FormControl>
                        )}
                      </div>
                      <div
                        className="col-10 row mx-0"
                        style={{
                          height: "77vh",
                          overflowY: "auto",
                        }}
                      >
                        <div className="col-12 row mx-0">
                          {/* <div className="col-12 row no-gutters px-3">
                            <fieldset
                              className={`col-12 d-flex align-items-center  row no-gutters px-2 form-group border border-${
                                !!errors.attachmentHasErrors.length &&
                                !!errors.attachmentHasErrors.find(
                                  ({ id }) => id <= 3
                                )
                                  ? "danger"
                                  : "primary"
                              }`}
                            >
                              <legend
                                className={`col-12 w-auto px-2 text-${
                                  !!errors.attachmentHasErrors.length &&
                                  !!errors.attachmentHasErrors.find(
                                    ({ id }) => id <= 3
                                  )
                                    ? "danger"
                                    : "primary"
                                }`}
                                style={{
                                  fontFamily:
                                    '"Roboto","Helvetica","Arial",sans-serif',
                                  fontWeight: 400,
                                  fontSize: "0.82rem",
                                  lineHeight: "1em",
                                  letterSpacing: "0.00938em",
                                }}
                              >
                                Mandatory documents
                              </legend>

                              {documents
                                .filter(({ id }) => id <= 3)
                                .map((document) => (
                                  <div className="col-4" key={document.id}>
                                    <fieldset
                                      className={`form-group ${
                                        !!(
                                          !!errors.attachmentHasErrors.length &&
                                          errors.attachmentHasErrors.find(
                                            (doc) => document === doc
                                          )
                                        )
                                          ? "border border-danger"
                                          : "border"
                                      } px-2`}
                                    >
                                      <legend
                                        className="w-auto px-2"
                                        style={{
                                          fontFamily:
                                            '"Roboto","Helvetica","Arial",sans-serif',
                                          fontWeight: 400,
                                          // maxWidth: "100%",
                                          fontSize: "0.82rem",
                                          lineHeight: "1em",
                                          letterSpacing: "0.00938em",
                                          color: `${
                                            !!(
                                              !!errors.attachmentHasErrors
                                                .length &&
                                              errors.attachmentHasErrors.find(
                                                (doc) => document === doc
                                              )
                                            )
                                              ? "red"
                                              : "rgba(0, 0, 0, 0.6)"
                                          }`,
                                        }}
                                      >
                                        {document.id}.{" "}
                                        <span className="ml-1 ">
                                          {document.name}
                                        </span>
                                      </legend>
                                      <div className="form-group1 mb-2">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              <i className="fas fa-paperclip"></i>
                                            </span>
                                          </div>
                                          {!!attachment(document.id) ? (
                                            <div
                                              className="form-control"
                                              style={{
                                                backgroundColor:
                                                  "rgb(229, 246, 253)",
                                                color: "#007bff",
                                              }}
                                            >
                                              <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setShowUploadedDocument(true);
                                                  setUploadedDocument({
                                                    name: document.name,
                                                    url: attachment(document.id)
                                                      .filePath,
                                                  });
                                                }}
                                              >
                                                {document.name}
                                              </span>

                                              <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                  setShowUploadedDocument(true);
                                                  setUploadedDocument({
                                                    name: document.name,
                                                    url: attachment(document.id)
                                                      .filePath,
                                                  });
                                                }}
                                                style={{
                                                  marginTop: "-5px",
                                                  marginRight: "-10px",
                                                }}
                                                color="info"
                                                className="float-right"
                                                aria-label="view"
                                              >
                                                <span className="material-icons  mr-1">
                                                  open_in_new
                                                </span>
                                              </IconButton>
                                            </div>
                                          ) : (
                                            <span className="text-danger ml-2">
                                              No file attached
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                ))}
                              
                            </fieldset>
                          </div> */}

                          <div className="col-12 row no-gutters px-3">
                            <fieldset
                              className={`col-12 row no-gutters px-2 form-group border border-primary`}
                            >
                              <legend
                                className={`col-12 w-auto px-2 text-primary`}
                                style={{
                                  fontFamily:
                                    '"Roboto","Helvetica","Arial",sans-serif',
                                  fontWeight: 400,
                                  fontSize: "0.82rem",
                                  lineHeight: "1em",
                                  letterSpacing: "0.00938em",
                                }}
                              >
                                Teacher profile
                              </legend>

                              <div className="col-4 px-1 justify-content-center mt-2">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <>
                                        {!teacherProfile.isNIDVerified &&
                                          !loading && (
                                            <span className="loaderIcon text-danger d-flex align-items-center">
                                              <span className="material-icons text-danger">
                                                report_problem
                                              </span>
                                              <span
                                                style={{ fontSize: "10px" }}
                                              >
                                                Error
                                              </span>
                                            </span>
                                          )}

                                        {!!teacherProfile.isNIDVerified &&
                                          !loading && (
                                            <span className="loaderIcon text-success d-flex align-items-center">
                                              <span className="material-icons text-success ">
                                                verified
                                              </span>
                                              <span
                                                style={{ fontSize: "10px" }}
                                              >
                                                Verified
                                              </span>
                                            </span>
                                          )}
                                      </>
                                    ),
                                  }}
                                  fullWidth
                                  size="small"
                                  autoComplete="false"
                                  name="nidNumber"
                                  label="NID Number"
                                  placeholder="NID Number"
                                  variant="outlined"
                                  value={teacherProfile.nidNumber || ""}
                                />
                              </div>
                              <div className="col-4 px-1 text-left mt-2">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                  size="small"
                                  autoComplete="false"
                                  name="passportNumber"
                                  label="Passport number"
                                  placeholder="Passport number"
                                  variant="outlined"
                                  value={teacherProfile.passportNumber || ""}
                                />
                              </div>

                              <div className="col-2 px-1 text-left mt-2">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                  size="small"
                                  name="civilStatus"
                                  label="Civil Status"
                                  variant="outlined"
                                  value={teacherProfile.civilStatus || ""}
                                />
                              </div>
                              <div className="col-2 px-1 text-left mt-2">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="gender"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="Gender"
                                  variant="outlined"
                                  className=" font-weight-bold"
                                  value={teacherProfile.gender || ""}
                                />
                              </div>
                              <div className="col-4 px-1  mt-3 text-left">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="lastName"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="Last Name"
                                  variant="outlined"
                                  className=""
                                  value={teacherProfile.lastName || ""}
                                />
                              </div>
                              <div className="col-4 px-1  mt-3  text-left">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="firstName"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="First Name"
                                  variant="outlined"
                                  className=""
                                  value={teacherProfile.firstName || ""}
                                />
                              </div>
                              <div className="col-4 px-1  mt-3 text-left">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="dateOfBirth"
                                  value={
                                    !!teacherProfile.dateOfBirth
                                      ? moment(
                                          teacherProfile.dateOfBirth
                                        ).format("DD/MM/yyyy")
                                      : ""
                                  }
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="Date Of Birth"
                                  variant="outlined"
                                />
                              </div>
                              <div className="col-4 px-1  mt-3 text-left">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="countryOfBirth"
                                  value={teacherProfile.countryOfBirth || ""}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="Country of Birth"
                                  variant="outlined"
                                  className=""
                                />
                              </div>
                              <div className="col-4 px-1  mt-3 text-left">
                                <TextField
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="placeOfBirth"
                                  value={teacherProfile.placeOfBirth || ""}
                                  label="Place of Birth"
                                  variant="outlined"
                                  className=""
                                />
                              </div>
                              <div className="col-4 px-1  mt-3 text-left">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="nationality"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="Nationality"
                                  placeholder="Nationality"
                                  variant="outlined"
                                  className=" font-weight-bold"
                                  value={teacherProfile.nationalityId || ""}
                                />
                              </div>
                              <div className="col-4 px-1 mt-3 text-left">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="spouseNames"
                                  value={teacherProfile?.spouseNames || "N/A"}
                                  label="Your spouse names"
                                  placeholder="Your spouse names"
                                  variant="outlined"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div className="col-2 px-1 mt-3 text-left">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="employeeId"
                                  value={teacherProfile?.id || ""}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="Employee ID"
                                  placeholder="Employee ID"
                                  variant="outlined"
                                />
                              </div>
                              <div className="col-2 px-1 mt-3 text-left">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="rssbNumber"
                                  value={teacherProfile?.rssbNumber || ""}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="RSSB Number"
                                  placeholder="RSSB Number"
                                  variant="outlined"
                                  className=""
                                />
                              </div>
                              <div className="col-4 px-1 mt-3 text-left">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="bankAccount"
                                  value={
                                    !teacherProfile.accountNumber
                                      ? ""
                                      : teacherProfile.accountNumber +
                                        " (" +
                                        teacherProfile.bankName +
                                        ")"
                                  }
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="Bank Account"
                                  placeholder="Bank Account"
                                  variant="outlined"
                                />
                              </div>

                              <div className="col-6 px-1 mt-3 ">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="rssbNumber"
                                  value={teacherProfile?.positionName || ""}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="Current position"
                                  placeholder="Current position"
                                  variant="outlined"
                                  className=""
                                />
                              </div>
                              <div className="col-6 px-1 mt-3 ">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="schoolName"
                                  value={
                                    !!teacherProfile?.schoolName
                                      ? teacherProfile?.schoolName +
                                        "/" +
                                        teacherProfile?.sectorName +
                                        "/" +
                                        teacherProfile?.districtName
                                      : ""
                                  }
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="Current school"
                                  placeholder="Current school"
                                  variant="outlined"
                                  className=""
                                />
                              </div>
                              <div className="col-4 px-1 mt-3 text-left">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="teachingLevel"
                                  value={
                                    teacherProfile?.teachingLevelName || ""
                                  }
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="Teaching Level"
                                  placeholder="Teaching Level"
                                  variant="outlined"
                                />
                              </div>
                              <div className="col-4 px-1 mt-3 text-left">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="qualificationLevel"
                                  value={
                                    teacherProfile?.qualificationLevelName || ""
                                  }
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="Position qualification"
                                  placeholder="Position qualification"
                                  variant="outlined"
                                />
                              </div>
                              <div className="col-4 px-1 mt-3 ">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="appointmentDate"
                                  value={
                                    !!teacherProfile.appointmentDate
                                      ? moment(
                                          teacherProfile.appointmentDate
                                        ).format("DD/MM/yyyy")
                                      : ""
                                  }
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="Position appointment date"
                                  variant="outlined"
                                />
                              </div>
                              <div className="col-4 px-1  mt-3 text-left">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                  size="small"
                                  autoComplete="false"
                                  name="phone"
                                  label="Phone number"
                                  placeholder="Phone number"
                                  variant="outlined"
                                  value={teacherProfile.phoneNumber || ""}
                                />
                              </div>
                              <div className="col-4 px-1  mt-3 text-left">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                  size="small"
                                  autoComplete="false"
                                  name="email"
                                  label="Personal email"
                                  placeholder="Personal email"
                                  variant="outlined"
                                  value={teacherProfile.email || ""}
                                />
                              </div>
                              <div className="mt-3 col-4 px-1">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                  size="small"
                                  autoComplete="false"
                                  name="employmentTypeName"
                                  label="Employment Type"
                                  placeholder="Employment Type"
                                  variant="outlined"
                                  value={
                                    teacherProfile.employmentTypeName || ""
                                  }
                                />
                              </div>
                              <div className="mt-3 col-4 px-1 mb-3">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                  size="small"
                                  autoComplete="false"
                                  name="degreeName"
                                  label="Your degree"
                                  placeholder="Your degree"
                                  variant="outlined"
                                  value={teacherProfile.degreeName || ""}
                                />
                              </div>
                              <div className="mt-3 col-4 px-1 mb-3">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                  size="small"
                                  autoComplete="false"
                                  name="specialization"
                                  label="Specialization"
                                  placeholder="Specialization"
                                  variant="outlined"
                                  value={teacherProfile.specialization || ""}
                                />
                              </div>
                              <div className="mt-3 col-4 px-1 mb-3">
                                <TextField
                                  style={{ backgroundColor: "#e9ecef" }}
                                  fullWidth
                                  size="small"
                                  name="graduationDate"
                                  value={
                                    !!teacherProfile.graduationDate
                                      ? moment(
                                          teacherProfile.graduationDate
                                        ).format("DD/MM/yyyy")
                                      : ""
                                  }
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  label="Graduation date"
                                  variant="outlined"
                                />
                              </div>
                            </fieldset>
                          </div>

                          <div className="col-12 row mx-0 ">
                            <fieldset
                              className={`col-12 row no-gutters px-2 form-group border border-primary`}
                            >
                              <legend
                                className={`col-12 w-auto px-2 text-${
                                  !!errors.attachmentHasErrors.length &&
                                  !!errors.attachmentHasErrors.find(
                                    ({ id }) => id > 3
                                  )
                                    ? "danger"
                                    : "primary"
                                }`}
                                style={{
                                  fontFamily:
                                    '"Roboto","Helvetica","Arial",sans-serif',
                                  fontWeight: 400,
                                  fontSize: "0.82rem",
                                  lineHeight: "1em",
                                  letterSpacing: "0.00938em",
                                }}
                              >
                                Mandatory documents
                              </legend>

                              {documents
                                .filter(({ id }) => id <= 3)
                                .map((document) => (
                                  <React.Fragment key={document.id}>
                                    <div className="col-12">
                                      {!!attachment(document.id) && (
                                        <fieldset
                                          className={`form-group border px-2`}
                                        >
                                          <legend
                                            className="w-auto px-2"
                                            style={{
                                              fontFamily:
                                                '"Roboto","Helvetica","Arial",sans-serif',
                                              fontWeight: 400,
                                              fontSize: "0.82rem",
                                              lineHeight: "1em",
                                              letterSpacing: "0.00938em",
                                              color: `${
                                                !!(
                                                  !!errors.attachmentHasErrors
                                                    .length &&
                                                  errors.attachmentHasErrors.find(
                                                    (doc) => document === doc
                                                  )
                                                )
                                                  ? "red"
                                                  : "rgba(0, 0, 0, 0.6)"
                                              }`,
                                            }}
                                          >
                                            <span className="ml-1">
                                              {document.lable}
                                            </span>
                                          </legend>
                                          <div className="form-group1 mb-2">
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="fas fa-paperclip"></i>
                                                </span>
                                              </div>
                                              {!!attachment(document.id) ? (
                                                <div
                                                  className="form-control"
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(229, 246, 253)",
                                                    color: "#007bff",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      setShowUploadedDocument(
                                                        true
                                                      );
                                                      setUploadedDocument({
                                                        id: attachment(
                                                          document.id
                                                        ).id,

                                                        name: document.name,
                                                        url: attachment(
                                                          document.id
                                                        ).filePath,
                                                      });
                                                    }}
                                                  >
                                                    {document.name}
                                                  </span>

                                                  <IconButton
                                                    size="small"
                                                    onClick={(e) => {
                                                      setShowUploadedDocument(
                                                        true
                                                      );
                                                      setUploadedDocument({
                                                        id: attachment(
                                                          document.id
                                                        ).id,
                                                        name: document.name,
                                                        url: attachment(
                                                          document.id
                                                        ).filePath,
                                                      });
                                                    }}
                                                    style={{
                                                      marginTop: "-5px",
                                                      marginRight: "-10px",
                                                    }}
                                                    color="info"
                                                    className="float-right"
                                                    aria-label="view"
                                                  >
                                                    <span className="material-icons  mr-1">
                                                      open_in_new
                                                    </span>
                                                  </IconButton>
                                                  {(["REB", "RTB"].includes(
                                                    user.selectedEntity.role
                                                  ) ||
                                                    !!request.isApproved) && (
                                                    <>
                                                      {attachment(document.id)
                                                        .isApproved !==
                                                        null && (
                                                        <IconButton
                                                          size="small"
                                                          onClick={(e) => {
                                                            if (
                                                              request.statusId !==
                                                              1
                                                            )
                                                              return;

                                                            setIsApproved(null);

                                                            setUploadedDocument(
                                                              {
                                                                id: attachment(
                                                                  document.id
                                                                ).id,
                                                                name: document.name,
                                                                url: attachment(
                                                                  document.id
                                                                ).filePath,
                                                              }
                                                            );

                                                            setShowConfirmAttachment(
                                                              true
                                                            );
                                                          }}
                                                          style={{
                                                            marginTop: "-5px",
                                                            marginRight: "-5px",
                                                          }}
                                                          className={`float-right text-${
                                                            attachment(
                                                              document.id
                                                            ).isApproved ===
                                                            true
                                                              ? "success"
                                                              : "danger"
                                                          } `}
                                                          aria-label="view"
                                                        >
                                                          {attachment(
                                                            document.id
                                                          ).isApproved ===
                                                            true && (
                                                            <span className="material-icons">
                                                              verified
                                                            </span>
                                                          )}
                                                          {attachment(
                                                            document.id
                                                          ).isApproved ===
                                                            false && (
                                                            <span className="material-icons">
                                                              cancel
                                                            </span>
                                                          )}
                                                        </IconButton>
                                                      )}

                                                      {attachment(document.id)
                                                        .isApproved ===
                                                        null && (
                                                        <Button
                                                          disabled={loading}
                                                          onClick={() => {
                                                            setIsApproved(
                                                              false
                                                            );

                                                            setUploadedDocument(
                                                              {
                                                                id: attachment(
                                                                  document.id
                                                                ).id,
                                                                name: document.name,
                                                                url: attachment(
                                                                  document.id
                                                                ).filePath,
                                                              }
                                                            );

                                                            setShowConfirmAttachment(
                                                              true
                                                            );
                                                          }}
                                                          variant="contained"
                                                          size="small"
                                                          disableElevation
                                                          color="error"
                                                          className="float-right mr-1"
                                                          style={{
                                                            borderRadius: "8px",
                                                            height: "24px",
                                                            alignItems:
                                                              "center",
                                                            fontSize: ".75rem",
                                                            whiteSpace:
                                                              "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                              "ellipsis",
                                                            textTransform:
                                                              "initial",
                                                          }}
                                                        >
                                                          <span className="material-icons">
                                                            close
                                                          </span>
                                                          Reject
                                                        </Button>
                                                      )}

                                                      {attachment(document.id)
                                                        .isApproved ===
                                                        null && (
                                                        <Button
                                                          disabled={loading}
                                                          onClick={() => {
                                                            if (
                                                              request.statusId !==
                                                              1
                                                            )
                                                              return;

                                                            setIsApproved(true);

                                                            setUploadedDocument(
                                                              {
                                                                id: attachment(
                                                                  document.id
                                                                ).id,
                                                                name: document.name,
                                                                url: attachment(
                                                                  document.id
                                                                ).filePath,
                                                              }
                                                            );

                                                            setShowConfirmAttachment(
                                                              true
                                                            );
                                                          }}
                                                          variant="contained"
                                                          size="small"
                                                          disableElevation
                                                          color="primary"
                                                          className="float-right mr-1"
                                                          style={{
                                                            borderRadius: "8px",
                                                            height: "24px",
                                                            alignItems:
                                                              "center",
                                                            fontSize: ".75rem",
                                                            whiteSpace:
                                                              "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                              "ellipsis",
                                                            textTransform:
                                                              "initial",
                                                          }}
                                                        >
                                                          <span className="material-icons">
                                                            check_circle
                                                          </span>
                                                          Accept
                                                        </Button>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              ) : (
                                                <span className="text-danger ml-2">
                                                  No file attached
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </fieldset>
                                      )}
                                    </div>
                                  </React.Fragment>
                                ))}
                            </fieldset>
                          </div>

                          <div className="col-12 row mx-0 ">
                            <fieldset
                              className={`col-12 row no-gutters px-2 form-group border border-primary`}
                            >
                              <legend
                                className={`col-12 w-auto px-2 text-${
                                  !!errors.attachmentHasErrors.length &&
                                  !!errors.attachmentHasErrors.find(
                                    ({ id }) => id > 3
                                  )
                                    ? "danger"
                                    : "primary"
                                }`}
                                style={{
                                  fontFamily:
                                    '"Roboto","Helvetica","Arial",sans-serif',
                                  fontWeight: 400,
                                  fontSize: "0.82rem",
                                  lineHeight: "1em",
                                  letterSpacing: "0.00938em",
                                }}
                              >
                                Other documents
                              </legend>

                              {documents
                                .filter(
                                  ({ id }) =>
                                    id > 3 &&
                                    (id !== 8 || !!request.hasRecommendation)
                                )
                                .map((document) => (
                                  <React.Fragment key={document.id}>
                                    <div className="col-12">
                                      {document.id !== 8 ? (
                                        <FormControl
                                          className={`mt-3 d-block border ${
                                            !!attachment(document.id)
                                              ? " bg-light elevated"
                                              : ""
                                          }
                                        
                                        rounded pt-2`}
                                        >
                                          <FormLabel className="text-primary  px-2">
                                            {document.question}
                                          </FormLabel>
                                          <RadioGroup
                                            className="mt-0 d-flex justify-content-start px-4"
                                            row
                                            name={`hasOtherDocument${document.id}`}
                                            value={!!attachment(document.id)}
                                          >
                                            <FormControlLabel
                                              value={false}
                                              control={
                                                <Radio
                                                  disableRipple
                                                  size="small"
                                                />
                                              }
                                              label="No"
                                            />
                                            <FormControlLabel
                                              value={true}
                                              control={
                                                <Radio
                                                  disableRipple
                                                  size="small"
                                                />
                                              }
                                              label="Yes"
                                            />
                                          </RadioGroup>
                                          {!!attachment(document.id) && (
                                            <fieldset
                                              className={`form-group border px-2`}
                                            >
                                              <legend
                                                className="w-auto px-2"
                                                style={{
                                                  fontFamily:
                                                    '"Roboto","Helvetica","Arial",sans-serif',
                                                  fontWeight: 400,
                                                  fontSize: "0.82rem",
                                                  lineHeight: "1em",
                                                  letterSpacing: "0.00938em",
                                                  color: `${
                                                    !!(
                                                      !!errors
                                                        .attachmentHasErrors
                                                        .length &&
                                                      errors.attachmentHasErrors.find(
                                                        (doc) =>
                                                          document === doc
                                                      )
                                                    )
                                                      ? "red"
                                                      : "rgba(0, 0, 0, 0.6)"
                                                  }`,
                                                }}
                                              >
                                                <span className="ml-1">
                                                  {document.lable}
                                                </span>
                                              </legend>
                                              <div className="form-group1 mb-2">
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                      <i className="fas fa-paperclip"></i>
                                                    </span>
                                                  </div>
                                                  {!!attachment(document.id) ? (
                                                    <div
                                                      className="form-control"
                                                      style={{
                                                        backgroundColor:
                                                          "rgb(229, 246, 253)",
                                                        color: "#007bff",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setShowUploadedDocument(
                                                            true
                                                          );
                                                          setUploadedDocument({
                                                            id: attachment(
                                                              document.id
                                                            ).id,

                                                            name: document.name,
                                                            url: attachment(
                                                              document.id
                                                            ).filePath,
                                                          });
                                                        }}
                                                      >
                                                        {document.name}
                                                      </span>

                                                      <IconButton
                                                        size="small"
                                                        onClick={(e) => {
                                                          setShowUploadedDocument(
                                                            true
                                                          );
                                                          setUploadedDocument({
                                                            id: attachment(
                                                              document.id
                                                            ).id,
                                                            name: document.name,
                                                            url: attachment(
                                                              document.id
                                                            ).filePath,
                                                          });
                                                        }}
                                                        style={{
                                                          marginTop: "-5px",
                                                          marginRight: "-10px",
                                                        }}
                                                        color="info"
                                                        className="float-right"
                                                        aria-label="view"
                                                      >
                                                        <span className="material-icons  mr-1">
                                                          open_in_new
                                                        </span>
                                                      </IconButton>
                                                      {["REB", "RTB"].includes(
                                                        user.selectedEntity.role
                                                      ) && (
                                                        <>
                                                          {attachment(
                                                            document.id
                                                          ).isApproved !==
                                                            null && (
                                                            <IconButton
                                                              size="small"
                                                              onClick={(e) => {
                                                                setIsApproved(
                                                                  null
                                                                );

                                                                setUploadedDocument(
                                                                  {
                                                                    id: attachment(
                                                                      document.id
                                                                    ).id,
                                                                    name: document.name,
                                                                    url: attachment(
                                                                      document.id
                                                                    ).filePath,
                                                                  }
                                                                );

                                                                setShowConfirmAttachment(
                                                                  true
                                                                );
                                                              }}
                                                              style={{
                                                                marginTop:
                                                                  "-5px",
                                                                marginRight:
                                                                  "-5px",
                                                              }}
                                                              className={`float-right text-${
                                                                attachment(
                                                                  document.id
                                                                ).isApproved ===
                                                                true
                                                                  ? "success"
                                                                  : "danger"
                                                              } `}
                                                              aria-label="view"
                                                            >
                                                              {attachment(
                                                                document.id
                                                              ).isApproved ===
                                                                true && (
                                                                <span className="material-icons">
                                                                  verified
                                                                </span>
                                                              )}
                                                              {attachment(
                                                                document.id
                                                              ).isApproved ===
                                                                false && (
                                                                <span className="material-icons">
                                                                  cancel
                                                                </span>
                                                              )}
                                                            </IconButton>
                                                          )}

                                                          {attachment(
                                                            document.id
                                                          ).isApproved ===
                                                            null && (
                                                            <Button
                                                              disabled={loading}
                                                              onClick={() => {
                                                                setIsApproved(
                                                                  false
                                                                );

                                                                setUploadedDocument(
                                                                  {
                                                                    id: attachment(
                                                                      document.id
                                                                    ).id,
                                                                    name: document.name,
                                                                    url: attachment(
                                                                      document.id
                                                                    ).filePath,
                                                                  }
                                                                );

                                                                setShowConfirmAttachment(
                                                                  true
                                                                );
                                                              }}
                                                              variant="contained"
                                                              size="small"
                                                              disableElevation
                                                              color="error"
                                                              className="float-right mr-1"
                                                              style={{
                                                                borderRadius:
                                                                  "8px",
                                                                height: "24px",
                                                                alignItems:
                                                                  "center",
                                                                fontSize:
                                                                  ".75rem",
                                                                whiteSpace:
                                                                  "nowrap",
                                                                overflow:
                                                                  "hidden",
                                                                textOverflow:
                                                                  "ellipsis",
                                                                textTransform:
                                                                  "initial",
                                                              }}
                                                            >
                                                              <span className="material-icons">
                                                                close
                                                              </span>
                                                              Reject
                                                            </Button>
                                                          )}

                                                          {attachment(
                                                            document.id
                                                          ).isApproved ===
                                                            null && (
                                                            <Button
                                                              disabled={loading}
                                                              onClick={() => {
                                                                setIsApproved(
                                                                  true
                                                                );

                                                                setUploadedDocument(
                                                                  {
                                                                    id: attachment(
                                                                      document.id
                                                                    ).id,
                                                                    name: document.name,
                                                                    url: attachment(
                                                                      document.id
                                                                    ).filePath,
                                                                  }
                                                                );

                                                                setShowConfirmAttachment(
                                                                  true
                                                                );
                                                              }}
                                                              variant="contained"
                                                              size="small"
                                                              disableElevation
                                                              color="primary"
                                                              className="float-right mr-1"
                                                              style={{
                                                                borderRadius:
                                                                  "8px",
                                                                height: "24px",
                                                                alignItems:
                                                                  "center",
                                                                fontSize:
                                                                  ".75rem",
                                                                whiteSpace:
                                                                  "nowrap",
                                                                overflow:
                                                                  "hidden",
                                                                textOverflow:
                                                                  "ellipsis",
                                                                textTransform:
                                                                  "initial",
                                                              }}
                                                            >
                                                              <span className="material-icons">
                                                                check_circle
                                                              </span>
                                                              Accept
                                                            </Button>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <span className="text-danger ml-2">
                                                      No file attached
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </fieldset>
                                          )}
                                        </FormControl>
                                      ) : (
                                        !!attachment(document.id) && (
                                          <fieldset
                                            className={`form-group border px-2 mt-3`}
                                          >
                                            <legend
                                              className="w-auto px-2"
                                              style={{
                                                fontFamily:
                                                  '"Roboto","Helvetica","Arial",sans-serif',
                                                fontWeight: 400,
                                                fontSize: "0.82rem",
                                                lineHeight: "1em",
                                                letterSpacing: "0.00938em",
                                                color: `${
                                                  !!(
                                                    !!errors.attachmentHasErrors
                                                      .length &&
                                                    errors.attachmentHasErrors.find(
                                                      (doc) => document === doc
                                                    )
                                                  )
                                                    ? "red"
                                                    : "rgba(0, 0, 0, 0.6)"
                                                }`,
                                              }}
                                            >
                                              <span className="ml-1">
                                                {document.lable}
                                              </span>
                                            </legend>
                                            <div className="form-group1 mb-2">
                                              <div className="input-group">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text">
                                                    <i className="fas fa-paperclip"></i>
                                                  </span>
                                                </div>
                                                {!!attachment(document.id) ? (
                                                  <div
                                                    className="form-control"
                                                    style={{
                                                      backgroundColor:
                                                        "rgb(229, 246, 253)",
                                                      color: "#007bff",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        setShowUploadedDocument(
                                                          true
                                                        );
                                                        setUploadedDocument({
                                                          id: attachment(
                                                            document.id
                                                          ).id,

                                                          name: document.name,
                                                          url: attachment(
                                                            document.id
                                                          ).filePath,
                                                        });
                                                      }}
                                                    >
                                                      {document.name}
                                                    </span>

                                                    <IconButton
                                                      size="small"
                                                      onClick={(e) => {
                                                        setShowUploadedDocument(
                                                          true
                                                        );
                                                        setUploadedDocument({
                                                          id: attachment(
                                                            document.id
                                                          ).id,
                                                          name: document.name,
                                                          url: attachment(
                                                            document.id
                                                          ).filePath,
                                                        });
                                                      }}
                                                      style={{
                                                        marginTop: "-5px",
                                                        marginRight: "-10px",
                                                      }}
                                                      color="info"
                                                      className="float-right"
                                                      aria-label="view"
                                                    >
                                                      <span className="material-icons  mr-1">
                                                        open_in_new
                                                      </span>
                                                    </IconButton>
                                                    {["REB", "RTB"].includes(
                                                      user.selectedEntity.role
                                                    ) && (
                                                      <>
                                                        {attachment(document.id)
                                                          .isApproved !==
                                                          null && (
                                                          <IconButton
                                                            size="small"
                                                            onClick={(e) => {
                                                              setIsApproved(
                                                                null
                                                              );

                                                              setUploadedDocument(
                                                                {
                                                                  id: attachment(
                                                                    document.id
                                                                  ).id,
                                                                  name: document.name,
                                                                  url: attachment(
                                                                    document.id
                                                                  ).filePath,
                                                                }
                                                              );

                                                              setShowConfirmAttachment(
                                                                true
                                                              );
                                                            }}
                                                            style={{
                                                              marginTop: "-5px",
                                                              marginRight:
                                                                "-5px",
                                                            }}
                                                            className={`float-right text-${
                                                              attachment(
                                                                document.id
                                                              ).isApproved ===
                                                              true
                                                                ? "success"
                                                                : "danger"
                                                            } `}
                                                            aria-label="view"
                                                          >
                                                            {attachment(
                                                              document.id
                                                            ).isApproved ===
                                                              true && (
                                                              <span className="material-icons">
                                                                verified
                                                              </span>
                                                            )}
                                                            {attachment(
                                                              document.id
                                                            ).isApproved ===
                                                              false && (
                                                              <span className="material-icons">
                                                                cancel
                                                              </span>
                                                            )}
                                                          </IconButton>
                                                        )}

                                                        {attachment(document.id)
                                                          .isApproved ===
                                                          null && (
                                                          <Button
                                                            disabled={loading}
                                                            onClick={() => {
                                                              setIsApproved(
                                                                false
                                                              );

                                                              setUploadedDocument(
                                                                {
                                                                  id: attachment(
                                                                    document.id
                                                                  ).id,
                                                                  name: document.name,
                                                                  url: attachment(
                                                                    document.id
                                                                  ).filePath,
                                                                }
                                                              );

                                                              setShowConfirmAttachment(
                                                                true
                                                              );
                                                            }}
                                                            variant="contained"
                                                            size="small"
                                                            disableElevation
                                                            color="error"
                                                            className="float-right mr-1"
                                                            style={{
                                                              borderRadius:
                                                                "8px",
                                                              height: "24px",
                                                              alignItems:
                                                                "center",
                                                              fontSize:
                                                                ".75rem",
                                                              whiteSpace:
                                                                "nowrap",
                                                              overflow:
                                                                "hidden",
                                                              textOverflow:
                                                                "ellipsis",
                                                              textTransform:
                                                                "initial",
                                                            }}
                                                          >
                                                            <span className="material-icons">
                                                              close
                                                            </span>
                                                            Reject
                                                          </Button>
                                                        )}

                                                        {attachment(document.id)
                                                          .isApproved ===
                                                          null && (
                                                          <Button
                                                            disabled={loading}
                                                            onClick={() => {
                                                              setIsApproved(
                                                                true
                                                              );

                                                              setUploadedDocument(
                                                                {
                                                                  id: attachment(
                                                                    document.id
                                                                  ).id,
                                                                  name: document.name,
                                                                  url: attachment(
                                                                    document.id
                                                                  ).filePath,
                                                                }
                                                              );

                                                              setShowConfirmAttachment(
                                                                true
                                                              );
                                                            }}
                                                            variant="contained"
                                                            size="small"
                                                            disableElevation
                                                            color="primary"
                                                            className="float-right mr-1"
                                                            style={{
                                                              borderRadius:
                                                                "8px",
                                                              height: "24px",
                                                              alignItems:
                                                                "center",
                                                              fontSize:
                                                                ".75rem",
                                                              whiteSpace:
                                                                "nowrap",
                                                              overflow:
                                                                "hidden",
                                                              textOverflow:
                                                                "ellipsis",
                                                              textTransform:
                                                                "initial",
                                                            }}
                                                          >
                                                            <span className="material-icons">
                                                              check_circle
                                                            </span>
                                                            Accept
                                                          </Button>
                                                        )}
                                                      </>
                                                    )}
                                                  </div>
                                                ) : (
                                                  <span className="text-danger ml-2">
                                                    No file attached
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </fieldset>
                                        )
                                      )}
                                    </div>
                                  </React.Fragment>
                                ))}
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            </div>

            {!!showConfirmAttachment && (
              <ConfirmationDialog
                disabled={loading}
                confirmationDialog={showConfirmAttachment}
                message={
                  <>
                    {isApproved !== null && (
                      <span>
                        Are you sure you want to{" "}
                        {isApproved === true && (
                          <strong className={`text-success`}>accept</strong>
                        )}
                        {isApproved === false && (
                          <strong className={`text-danger`}>reject</strong>
                        )}{" "}
                        this attachment?
                      </span>
                    )}
                    {isApproved === null && (
                      <span>
                        Do you want to{" "}
                        <strong className={`text-danger`}>cancel</strong> this
                        decision?
                      </span>
                    )}
                  </>
                }
                setConfirmationDialog={setShowConfirmAttachment}
                onYes={() =>
                  approveTransferAttachment(
                    {
                      attachmentId: uploadedDocument.id,
                      isApproved,
                    },
                    setShowConfirmAttachment
                  )
                }
              />
            )}

            {showUploadedDocument && uploadedDocument && (
              <PreviewPdfUrl
                isBase64={true}
                showDocument={showUploadedDocument}
                setShowDocument={setShowUploadedDocument}
                document={{
                  name: `${uploadedDocument?.name || ""}`,
                  teacherName:
                    n === 1
                      ? `${request?.teacherOneLastName || ""} ${
                          request?.teacherOneFirstName || ""
                        }`
                      : `${request?.teacherTwoLastName || ""} ${
                          request?.teacherTwoFirstName || ""
                        }`,
                  url: uploadedDocument?.url || "",
                }}
              />
            )}
          </>
        </DialogContent>
        {/* <DialogActions className="d-flex justify-content-center pb-4 px-4">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (!formStepper0Validator()) setShowConfirm(true);
            }}
            type="button"
            className="px-5"
            disabled={loading || showConfirm}
          >
            {loading ? "Wait..." : "Accept"}
          </Button>
        </DialogActions> */}
      </Dialog>

      {!!showConfirm && (
        <ConfirmationDialog
          disabled={!teacherTwoMessage || loading}
          confirmationDialog={showConfirm}
          message={
            <>
              <span>
                Are you sure you want to{" "}
                <strong className={`text-success`}>accept</strong> this
                permutation request?
              </span>

              <TextField
                className="mt-3"
                autoFocus
                fullWidth
                type="text"
                size="small"
                name="reason"
                label="Enter your confirmation code *"
                variant="outlined"
                placeholder="Enter your confirmation code *"
                value={teacherTwoMessage}
                onChange={(e) => {
                  setTeacherTwoMessage(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setShowConfirm}
          onYes={onSave}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  user,
  districts,
  teacherProfile,
  transferRequestAttachments,
  transferEligibility,
}) => {
  return {
    loading,
    onWait,
    user,
    districts,
    teacherProfile,
    transferRequestAttachments,
    transferEligibility,
  };
};
export default connect(mapStateToProps, {
  savePermutationRequest,
  getDistricts,

  getTeacherProfile,
  onTeacherTwoAccept,
  getTransferRequestAttachment,
  approveTransferAttachment,
})(TransferRequestPreviewDialog);
