import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  Button,
  Avatar,
  Checkbox,
  Divider,
  LinearProgress,
  Stepper,
  Step,
  StepLabel,
  Menu,
  MenuItem,
  Alert,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveTransferRequest } from "../../../../store/transfer/actions";
import { getDistricts } from "../../../../store/posts/actions";
import { getTeacherProfile } from "../../../../store/users/actions";
import { showError } from "../../../toastify";
import axios from "axios";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import defaultPofile from "../../../assets/default-profile.jpg";
import moment from "moment";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import { isEmpty } from "lodash";
import TransferCriteriaDialog from "./TransferCriteriaDialog";

const steps = [
  "Criteria",
  "Teacher details ",
  "Other Documents",
  "Transfer details",
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ITEM_HEIGHT = 60;

export const documents = [
  {
    id: 1,
    name: "degree",
  },
  {
    id: 2,
    name: "definitive appointment letter",
  },
  {
    id: 3,
    name: "peformance evaluation report",
  },
  {
    id: 4,
    name: "proof of disability (NCPD card for teachers with disability)",
    lable:
      "Upload your proof of disability (NCPD card for teachers with disability)",
    question: "Do you have disability?",
  },
  {
    id: 5,
    name: "a copy of medical certificate from a referral or District Hospital approved by three (3) Doctors.",
    lable:
      "Upload a copy of medical certificate from a referral or District Hospital approved by three (3) Doctors.",
    question: "Do you have chronic disease?",
  },
  {
    id: 6,
    name: "birth certificate and proof of disability/chronic disease of your child",
    lable:
      "Upload birth certificate and proof of disability/chronic disease of your child",
    question: "Does your child has disability/chronic disease?",
  },
  {
    id: 7,
    name: "marriage certificate and proof of disability/chronic disease of your spouse",
    lable:
      "Upload marriage certificate and proof of disability/chronic disease of your spouse",
    question: "Does your spouse has disability/chronic disease?",
  },
  {
    id: 8,
    name: "Recommendation letter  from the District",
    lable: "Recommendation letter  from the District",
    question: "Upload the recommendation letter  from the District",
  },
];

const TransferRequestDialog = (props) => {
  const {
    user,
    loading,
    showDialog,
    setShowDialog,
    saveTransferRequest,
    districts,
    getDistricts,
    teacherProfile,
    getTeacherProfile,
    transferEligibility,
    transferSchedule,
  } = props;

  useEffect(() => {
    getTeacherProfile(user.id);
  }, []);

  const [errors, setErrors] = useState({
    districtHasError: false,
    schoolHasError: false,
    reasonHasError: false,
    attachmentHasErrors: [],
    hasError: false,
  });

  const [formData, setFormData] = useState({
    hasOtherDocument4: "false",
    hasOtherDocument5: "false",
    hasOtherDocument6: "false",
    hasOtherDocument7: "false",
    hasOtherDocument8: "false",
    attachments: [],
    reason: "",
  });

  const formStepper0Validator = () => {
    const error = {
      districtHasError: false,
      schoolHasError: false,
      reasonHasError: false,
      attachmentHasErrors: [],
      hasError: false,
    };

    documents
      .filter(({ id }) => id <= 3)
      .forEach((doc) => {
        const document = formData.attachments.find(({ id }) => doc.id === id);

        if (!document) {
          error.attachmentHasErrors.push(doc);
          error.hasError = true;
        }
      });

    documents
      .filter(
        ({ id }) => id > 3 && formData[`hasOtherDocument${id}`] === "true"
      )
      .forEach((doc) => {
        const document = formData.attachments.find(({ id }) => doc.id === id);

        if (!document) {
          error.attachmentHasErrors.push(doc);
          error.hasError = true;
        }
      });

    setErrors(error);

    if (error.hasError) {
      showError(`Please upload all the mandatory documents`);

      return true;
    }
    return false;
  };

  const formStepper1Validator = () => {
    const error = {
      districtHasError: false,
      schoolHasError: false,
      reasonHasError: false,
      attachmentHasErrors: [],
      hasError: false,
    };

    if (!selectedDistrict) {
      error.districtHasError = true;
      error.hasError = true;
    }

    if (!firstSchoolInterest) {
      error.schoolHasError = true;
      error.hasError = true;
    }

    if (!formData.reason) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError(`Please fill all the mandatory fields`);
      return true;
    }

    let count = 0;
    if (!!firstSchoolInterest) count++;
    if (!!secondSchoolInterest) count++;
    if (!!thirdSchoolInterest) count++;
    if (!!firstSchoolInterest2) count++;
    if (!!secondSchoolInterest2) count++;
    if (!!firstSchoolInterest3) count++;

    if (count > 3) {
      showError(`Please, select only 3 schools`);
      return true;
    }

    return false;
  };

  const onClose = () => {
    setShowDialog(false);

    setFormData({
      hasOtherDocument4: "false",
      hasOtherDocument5: "false",
      hasOtherDocument6: "false",
      hasOtherDocument7: "false",
      hasOtherDocument8: "false",
      attachments: [],
      reason: "",
    });
  };

  const [showLoader, setShowLoader] = useState(false);

  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [firstSchoolInterest, setFirstSchoolInterest] = useState(null);
  const [secondSchoolInterest, setSecondSchoolInterest] = useState(null);
  const [thirdSchoolInterest, setThirdSchoolInterest] = useState(null);

  const [selectedDistrict2, setSelectedDistrict2] = useState(null);
  const [firstSchoolInterest2, setFirstSchoolInterest2] = useState(null);
  const [secondSchoolInterest2, setSecondSchoolInterest2] = useState(null);

  const [selectedDistrict3, setSelectedDistrict3] = useState(null);
  const [firstSchoolInterest3, setFirstSchoolInterest3] = useState(null);

  const [vacancies, setVacancies] = useState([]);
  const [vacancies2, setVacancies2] = useState([]);
  const [vacancies3, setVacancies3] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (!districts.length) getDistricts();
  }, []);

  const getDistrictVacancies = async (districtId, n = 1) => {
    try {
      n === 2
        ? setVacancies2([])
        : n === 3
        ? setVacancies3([])
        : setVacancies([]);

      setShowLoader(true);
      const { data } = await axios.get(
        "/api/posts/district-vacancies/" + districtId
      );
      setShowLoader(false);

      n === 2
        ? setVacancies2(data)
        : n === 3
        ? setVacancies3(data)
        : setVacancies(data);
    } catch (error) {
      setShowLoader(false);

      showError(error);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (activeStep < 2 || !transferEligibility.needRecommendation)
      setFormData({ ...formData, hasOtherDocument8: "false" });
    else setFormData({ ...formData, hasOtherDocument8: "true" });
  }, [activeStep]);

  const handleNext = () => {
    if (activeStep === 0)
      return setActiveStep((prevActiveStep) => prevActiveStep + 1);
    else if ([1, 2].includes(activeStep) && !formStepper0Validator())
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(null);

  const convertToLower = (value) => {
    return value.toLowerCase();
  };
  const isFileTooLarge = (file) => {
    const size = file.size;
    return size > 1074176 ? true : false;
  };

  const handleUploadedPDFFile = (event, document) => {
    let pdfAttachment = event.target.files[0];

    setErrors({ ...errors, pdfAttachmentHasError: false });

    if (convertToLower(pdfAttachment?.name?.substr(-4)) !== ".pdf") {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("File should be pdf format");
    } else if (isFileTooLarge(pdfAttachment)) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("Allowed file should not exceed 1MB");
    } else {
      const index = formData.attachments.findIndex(
        ({ id }) => id === document.id
      );
      const attachments = [...formData.attachments];
      document.file = pdfAttachment;
      document.filename = pdfAttachment.name;

      const reader = new FileReader();

      reader.onload = (e) => {
        document.url = e.target.result;
      };

      reader.readAsDataURL(pdfAttachment);

      if (index < 0) attachments.push(document);
      else attachments[index] = document;
      setFormData({
        ...formData,
        attachments,
      });
      const index2 = errors.attachmentHasErrors.findIndex(
        ({ id }) => id === document.id
      );
      errors.attachmentHasErrors.splice(index2, 1);
      setErrors(errors);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);

    event.stopPropagation();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onSave = () => {
    if (!formStepper1Validator()) {
      const payload = new FormData();

      const keys = Object.keys(formData);
      for (const key of keys) {
        if (formData.hasOwnProperty(key)) {
          if (key === "attachments" && formData[key] && !!formData[key].length)
            formData[key].forEach((row, i) => {
              payload.append("files", row.file);
              payload.append(`selectedIds`, row.id);
            });
          else payload.append(`${key}`, `${formData[key]}`);
        }
      }

      payload.append(`toSchoolPositionOneId`, firstSchoolInterest.id);
      payload.append(
        `toSchoolPositionTwoId`,
        (secondSchoolInterest || firstSchoolInterest2)?.id || null
      );
      payload.append(
        `toSchoolPositionThreeId`,
        (thirdSchoolInterest || secondSchoolInterest2 || firstSchoolInterest3)
          ?.id || null
      );

      payload.append(`reason`, formData.reason);
      payload.append(`districtId`, selectedDistrict.id);

      saveTransferRequest(payload, setShowDialog);
    }
  };

  const filterDistricts = (internal) => {
    if (!!internal)
      return districts.filter(({ id }) => id === user.myPlacement.districtId);
    else
      return districts.filter(({ id }) => id !== user.myPlacement.districtId);
  };

  // const filterDistricts2 = (internal, ) => {
  //   if (!!internal)
  //     return districts.filter(({ id }) => id === user.myPlacement.districtId );
  //   else
  //     return districts.filter(({ id }) => id !== user.myPlacement.districtId);
  // };

  // const filterDistricts3 = (internal, ) => {
  //   if (!!internal)
  //     return districts.filter(({ id }) => id === user.myPlacement.districtId );
  //   else
  //     return districts.filter(({ id }) => id !== user.myPlacement.districtId);
  // };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-primary font-weight-bold">
              Transer Request
            </span>{" "}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <Box sx={{ width: "100%" }} className="px-5">
          <Stepper activeStep={activeStep} className="pr-5">
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        {showLoader && <LinearProgress />}
        <DialogContent>
          <>
            <div className="row mx-0">
              <div className="col-sm-12">
                <React.Fragment>
                  <div>
                    <div className="row no-gutters">
                      <div
                        className="col-2 mt-2 justify-content-center  text-center py-3"
                        style={{
                          backgroundColor: "#f3f4f6",
                        }}
                      >
                        <div
                          style={{
                            minHeight: "150px",
                            overflow: "hidden",
                          }}
                        >
                          <>
                            <img
                              className="mt-0 rounded  text-center"
                              src={
                                !!teacherProfile.profilePicture
                                  ? "data:image/jpg;base64," +
                                    teacherProfile.profilePicture
                                  : defaultPofile
                              }
                              style={{
                                border: "1px solid #a7a7a7",
                                height: "150px",
                                width: "120px",
                                overflow: "hidden",
                                borderRadius: "50%",
                              }}
                            />
                          </>
                        </div>
                        <div className="text-primary">
                          <strong>NID PHOTO</strong>{" "}
                        </div>
                        {!!teacherProfile?.id && (
                          <div className="text-primary">
                            <strong>{teacherProfile?.id}</strong>{" "}
                          </div>
                        )}
                        <label className="text-center text-uppercase  mt-n2">
                          <span style={{ fontSize: "12px" }}>
                            {teacherProfile?.lastName}{" "}
                            {teacherProfile?.firstName}
                          </span>
                        </label>{" "}
                        {!isEmpty(teacherProfile) && (
                          <FormControl
                            className="mt-3 d-flex-block   pt-2"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <FormLabel className="text-primary   px-4 text-left">
                              Education certficate
                            </FormLabel>
                            <RadioGroup
                              className="mt-0 d-flex justify-content-start px-4"
                              row
                              name="hasEducationCertficate"
                              value={teacherProfile?.hasEducationCertficate}
                            >
                              {!!teacherProfile?.hasEducationCertficate && (
                                <FormControlLabel
                                  value={true}
                                  control={<Radio disableRipple size="small" />}
                                  label="Certfied"
                                />
                              )}

                              {!teacherProfile?.hasEducationCertficate && (
                                <FormControlLabel
                                  value={false}
                                  control={<Radio disableRipple size="small" />}
                                  label="Not certfied"
                                />
                              )}
                            </RadioGroup>
                          </FormControl>
                        )}
                      </div>
                      <div
                        className="col-10 row mx-0"
                        style={{
                          height: "62vh",
                          overflowY: "auto",
                        }}
                      >
                        {!!transferSchedule && (
                          <div className="col-12 ">
                            <Alert severity="info">
                              Only{" "}
                              <strong>
                                {!!transferSchedule.internal
                                  ? "internal"
                                  : "external"}
                              </strong>{" "}
                              transfer is available.
                            </Alert>
                          </div>
                        )}

                        {activeStep === 0 && (
                          <div className="col-12 row mx-0 mt-2">
                            <div className="col-10 row no-gutters px-3">
                              <fieldset
                                className={`col-12 row no-gutters px-2 form-group border `}
                              >
                                <legend
                                  className={`col-12 w-auto px-2 text-primary`}
                                  style={{
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                  }}
                                >
                                  Criteria for Teacher permutation and transfer
                                  application
                                </legend>

                                <TransferCriteriaDialog />
                              </fieldset>
                            </div>
                          </div>
                        )}

                        {activeStep === 1 && (
                          <div className="col-12 row mx-0 mt-2">
                            <div className="col-12 row no-gutters px-3">
                              <fieldset
                                className={`col-12 row no-gutters px-2 form-group border border-${
                                  !!errors.attachmentHasErrors.length &&
                                  !!errors.attachmentHasErrors.find(
                                    ({ id }) => id <= 3
                                  )
                                    ? "danger"
                                    : "primary"
                                }`}
                              >
                                <legend
                                  className={`col-12 w-auto px-2 text-${
                                    !!errors.attachmentHasErrors.length &&
                                    !!errors.attachmentHasErrors.find(
                                      ({ id }) => id <= 3
                                    )
                                      ? "danger"
                                      : "primary"
                                  }`}
                                  style={{
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                  }}
                                >
                                  Mandatory documents
                                </legend>

                                {documents
                                  .filter(({ id }) => id <= 3)
                                  .map((document) => (
                                    <div className=" col-4" key={document.id}>
                                      <fieldset
                                        className={`form-group ${
                                          !!(
                                            !!errors.attachmentHasErrors
                                              .length &&
                                            errors.attachmentHasErrors.find(
                                              (doc) => document === doc
                                            )
                                          )
                                            ? "border border-danger"
                                            : "border"
                                        } px-2`}
                                      >
                                        <legend
                                          className="w-auto px-2"
                                          style={{
                                            fontFamily:
                                              '"Roboto","Helvetica","Arial",sans-serif',
                                            fontWeight: 400,
                                            fontSize: "0.82rem",
                                            lineHeight: "1em",
                                            letterSpacing: "0.00938em",
                                            color: `${
                                              !!(
                                                !!errors.attachmentHasErrors
                                                  .length &&
                                                errors.attachmentHasErrors.find(
                                                  (doc) => document === doc
                                                )
                                              )
                                                ? "red"
                                                : "rgba(0, 0, 0, 0.6)"
                                            }`,
                                          }}
                                        >
                                          {document.id}.{" "}
                                          <span className="ml-1">
                                            Upload your {document.name}
                                          </span>
                                        </legend>
                                        <div className="form-group1 mb-2">
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                <i className="fas fa-paperclip"></i>
                                              </span>
                                            </div>
                                            {formData.attachments.find(
                                              (doc) =>
                                                document === doc &&
                                                !!doc.filename
                                            ) ? (
                                              <div
                                                className="form-control"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(229, 246, 253)",

                                                  color: "#007bff",
                                                }}
                                              >
                                                <span
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    setShowUploadedDocument(
                                                      true
                                                    );
                                                    setUploadedDocument({
                                                      name: document.name,
                                                      url: formData.attachments.find(
                                                        (doc) =>
                                                          document === doc &&
                                                          !!doc.filename
                                                      ).url,
                                                    });
                                                  }}
                                                >
                                                  {
                                                    formData.attachments.find(
                                                      (doc) =>
                                                        document === doc &&
                                                        !!doc.filename
                                                    ).filename
                                                  }
                                                </span>

                                                <IconButton
                                                  size="small"
                                                  onClick={handleOpenMenu}
                                                  style={{
                                                    marginTop: "-5px",
                                                    marginRight: "-10px",
                                                  }}
                                                  color="info"
                                                  className="float-right"
                                                  aria-label="view"
                                                >
                                                  <MoreHorizIcon />
                                                </IconButton>

                                                <Menu
                                                  anchorEl={anchorEl}
                                                  id="account-menu"
                                                  open={open}
                                                  onClose={(e) => {
                                                    handleCloseMenu();
                                                    e.stopPropagation();
                                                  }}
                                                  PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                      overflow: "visible",
                                                      filter:
                                                        "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                      mt: 1.5,
                                                      "& .MuiAvatar-root": {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 2,
                                                      },
                                                      "&:before": {
                                                        content: '""',
                                                        display: "block",
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 15,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor:
                                                          "background.paper",
                                                        transform:
                                                          "translateY(-50%) rotate(45deg)",
                                                        zIndex: 0,
                                                      },
                                                    },
                                                  }}
                                                  transformOrigin={{
                                                    horizontal: "right",
                                                    vertical: "top",
                                                  }}
                                                  anchorOrigin={{
                                                    horizontal: "right",
                                                    vertical: "bottom",
                                                  }}
                                                >
                                                  <span>
                                                    <MenuItem
                                                      onClick={(e) => {
                                                        handleCloseMenu();
                                                        setShowUploadedDocument(
                                                          true
                                                        );
                                                        setUploadedDocument({
                                                          name: document.name,
                                                          url: formData.attachments.find(
                                                            (doc) =>
                                                              document ===
                                                                doc &&
                                                              !!doc.filename
                                                          ).url,
                                                        });
                                                      }}
                                                      className="text-primary font-weight-bolder"
                                                    >
                                                      <span className="material-icons  mr-1">
                                                        open_in_new
                                                      </span>
                                                      Preview
                                                    </MenuItem>
                                                  </span>

                                                  <span>
                                                    <MenuItem
                                                      onClick={(e) => {
                                                        handleCloseMenu();
                                                        const tmpDocuments = [
                                                          ...formData.attachments,
                                                        ];
                                                        const index =
                                                          tmpDocuments.findIndex(
                                                            ({ id }) =>
                                                              id === document.id
                                                          );

                                                        tmpDocuments.splice(
                                                          index,
                                                          1
                                                        );
                                                        setFormData({
                                                          ...formData,
                                                          attachments:
                                                            tmpDocuments,
                                                        });
                                                      }}
                                                      className="text-danger"
                                                    >
                                                      <span className="material-icons mr-1">
                                                        remove_circle_outline
                                                      </span>
                                                      Remove
                                                    </MenuItem>
                                                  </span>
                                                </Menu>
                                              </div>
                                            ) : (
                                              <>
                                                <input
                                                  type="file"
                                                  name="file"
                                                  className="form-control"
                                                  accept="application/pdf"
                                                  placeholder="Select pdf file"
                                                  onChange={(e) =>
                                                    handleUploadedPDFFile(
                                                      e,
                                                      document
                                                    )
                                                  }
                                                />
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </fieldset>
                                    </div>
                                  ))}
                              </fieldset>
                            </div>

                            <div className="col-12 row no-gutters px-3">
                              <fieldset
                                className={`col-12 row no-gutters px-2 form-group border border-primary`}
                              >
                                <legend
                                  className={`col-12 w-auto px-2 text-primary`}
                                  style={{
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                  }}
                                >
                                  Your profile
                                </legend>

                                <div className="col-4 px-1 justify-content-center mt-2">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <>
                                          {!teacherProfile.isNIDVerified &&
                                            !loading && (
                                              <span className="loaderIcon text-danger d-flex align-items-center">
                                                <span className="material-icons text-danger">
                                                  report_problem
                                                </span>
                                                <span
                                                  style={{ fontSize: "10px" }}
                                                >
                                                  Error
                                                </span>
                                              </span>
                                            )}

                                          {!!teacherProfile.isNIDVerified &&
                                            !loading && (
                                              <span className="loaderIcon text-success d-flex align-items-center">
                                                <span className="material-icons text-success ">
                                                  verified
                                                </span>
                                                <span
                                                  style={{ fontSize: "10px" }}
                                                >
                                                  Verified
                                                </span>
                                              </span>
                                            )}
                                        </>
                                      ),
                                    }}
                                    fullWidth
                                    size="small"
                                    autoComplete="false"
                                    name="nidNumber"
                                    label="NID Number"
                                    placeholder="NID Number"
                                    variant="outlined"
                                    value={teacherProfile.nidNumber || ""}
                                  />
                                </div>
                                <div className="col-4 px-1 text-left mt-2">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                    autoComplete="false"
                                    name="passportNumber"
                                    label="Passport number"
                                    placeholder="Passport number"
                                    variant="outlined"
                                    value={teacherProfile.passportNumber || ""}
                                  />
                                </div>

                                <div className="col-2 px-1 text-left mt-2">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                    name="civilStatus"
                                    label="Civil Status"
                                    variant="outlined"
                                    value={teacherProfile.civilStatus || ""}
                                  />
                                </div>
                                <div className="col-2 px-1 text-left mt-2">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="gender"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Gender"
                                    variant="outlined"
                                    className=" font-weight-bold"
                                    value={teacherProfile.gender || ""}
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="lastName"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Last Name"
                                    variant="outlined"
                                    className=""
                                    value={teacherProfile.lastName || ""}
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3  text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="firstName"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="First Name"
                                    variant="outlined"
                                    className=""
                                    value={teacherProfile.firstName || ""}
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="dateOfBirth"
                                    value={
                                      !!teacherProfile.dateOfBirth
                                        ? moment(
                                            teacherProfile.dateOfBirth
                                          ).format("DD/MM/yyyy")
                                        : ""
                                    }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Date Of Birth"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="countryOfBirth"
                                    value={teacherProfile.countryOfBirth || ""}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Country of Birth"
                                    variant="outlined"
                                    className=""
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3 text-left">
                                  <TextField
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="placeOfBirth"
                                    value={teacherProfile.placeOfBirth || ""}
                                    label="Place of Birth"
                                    variant="outlined"
                                    className=""
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="nationality"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Nationality"
                                    placeholder="Nationality"
                                    variant="outlined"
                                    className=" font-weight-bold"
                                    value={teacherProfile.nationalityId || ""}
                                  />
                                </div>
                                <div className="col-6 px-1 mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="spouseNames"
                                    value={teacherProfile?.spouseNames || "N/A"}
                                    label="Your spouse names"
                                    placeholder="Your spouse names"
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </div>
                                <div className="col-3 px-1 mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="employeeId"
                                    value={teacherProfile?.id || ""}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Employee ID"
                                    placeholder="Employee ID"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="col-3 px-1 mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="rssbNumber"
                                    value={teacherProfile?.rssbNumber || ""}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="RSSB Number"
                                    placeholder="RSSB Number"
                                    variant="outlined"
                                    className=""
                                  />
                                </div>
                                <div className="col-6 px-1 mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="bankAccount"
                                    value={
                                      !teacherProfile.accountNumber
                                        ? ""
                                        : teacherProfile.accountNumber +
                                          " (" +
                                          teacherProfile.bankName +
                                          ")"
                                    }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Bank Account"
                                    placeholder="Bank Account"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="col-6 px-1 mt-3 ">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="rssbNumber"
                                    value={teacherProfile?.positionName || ""}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Position"
                                    placeholder="Position"
                                    variant="outlined"
                                    className=""
                                  />
                                </div>
                                <div className="col-3 px-1 mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="teachingLevel"
                                    value={
                                      teacherProfile?.teachingLevelName || ""
                                    }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Teaching Level"
                                    placeholder="Teaching Level"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="col-3 px-1 mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="qualificationLevel"
                                    value={
                                      teacherProfile?.qualificationLevelName ||
                                      ""
                                    }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Position qualification"
                                    placeholder="Position qualification"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="col-6 px-1 mt-3 ">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="appointmentDate"
                                    value={
                                      !!teacherProfile.appointmentDate
                                        ? moment(
                                            teacherProfile.appointmentDate
                                          ).format("DD/MM/yyyy")
                                        : ""
                                    }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Position appointment date"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                    autoComplete="false"
                                    name="phone"
                                    label="Phone number"
                                    placeholder="Phone number"
                                    variant="outlined"
                                    value={teacherProfile.phoneNumber || ""}
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                    autoComplete="false"
                                    name="email"
                                    label="Personal email"
                                    placeholder="Personal email"
                                    variant="outlined"
                                    value={teacherProfile.email || ""}
                                  />
                                </div>
                                <div className="mt-3 col-4 px-1">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                    autoComplete="false"
                                    name="employmentTypeName"
                                    label="Employment Type"
                                    placeholder="Employment Type"
                                    variant="outlined"
                                    value={
                                      teacherProfile.employmentTypeName || ""
                                    }
                                  />
                                </div>
                                <div className="mt-3 col-4 px-1 mb-3">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                    autoComplete="false"
                                    name="degreeName"
                                    label="Your degree"
                                    placeholder="Your degree"
                                    variant="outlined"
                                    value={teacherProfile.degreeName || ""}
                                  />
                                </div>
                                <div className="mt-3 col-4 px-1 mb-3">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                    autoComplete="false"
                                    name="specialization"
                                    label="Specialization"
                                    placeholder="Specialization"
                                    variant="outlined"
                                    value={teacherProfile.specialization || ""}
                                  />
                                </div>
                                <div className="mt-3 col-4 px-1 mb-3">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="graduationDate"
                                    value={
                                      !!teacherProfile.graduationDate
                                        ? moment(
                                            teacherProfile.graduationDate
                                          ).format("DD/MM/yyyy")
                                        : ""
                                    }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Graduation date"
                                    variant="outlined"
                                  />
                                </div>
                              </fieldset>
                            </div>
                          </div>
                        )}

                        {activeStep === 2 && (
                          <div className="col-12 row mx-0 mt-2">
                            <div className=" row no-gutters px-3">
                              <fieldset
                                className={`col-12 row no-gutters px-2 form-group border ${
                                  !!errors.attachmentHasErrors.length &&
                                  !!errors.attachmentHasErrors.find(
                                    ({ id }) => id > 3
                                  )
                                    ? "border border-danger"
                                    : ""
                                }`}
                              >
                                <legend
                                  className={`col-12 w-auto px-2 text-${
                                    !!errors.attachmentHasErrors.length &&
                                    !!errors.attachmentHasErrors.find(
                                      ({ id }) => id > 3
                                    )
                                      ? "danger"
                                      : "primary"
                                  }`}
                                  style={{
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                  }}
                                >
                                  Other documents
                                </legend>

                                {documents
                                  .filter(
                                    ({ id }) =>
                                      id > 3 &&
                                      (id !== 8 ||
                                        !!transferEligibility.needRecommendation)
                                  )
                                  .map((document) => (
                                    <React.Fragment key={document.id}>
                                      <div className="col-12">
                                        {document.id !== 8 ? (
                                          <FormControl
                                            className="mt-3 d-block border rounded pt-2"
                                            // style={{ backgroundColor: "#eee" }}
                                          >
                                            <FormLabel className="text-primary  px-2">
                                              {document.question}
                                            </FormLabel>
                                            <RadioGroup
                                              className="mt-0 d-flex justify-content-start px-4"
                                              row
                                              name={`hasOtherDocument${document.id}`}
                                              value={
                                                formData[
                                                  `hasOtherDocument${document.id}`
                                                ] || ""
                                              }
                                              onChange={(e) => {
                                                setFormData({
                                                  ...formData,
                                                  [`hasOtherDocument${document.id}`]:
                                                    e.target.value,
                                                });
                                              }}
                                            >
                                              <FormControlLabel
                                                value={false}
                                                control={
                                                  <Radio
                                                    disableRipple
                                                    size="small"
                                                  />
                                                }
                                                label="No"
                                              />
                                              <FormControlLabel
                                                value={true}
                                                control={
                                                  <Radio
                                                    disableRipple
                                                    size="small"
                                                  />
                                                }
                                                label="Yes"
                                              />
                                            </RadioGroup>
                                            {!!formData[
                                              `hasOtherDocument${document.id}`
                                            ] &&
                                              formData[
                                                `hasOtherDocument${document.id}`
                                              ] !== "false" && (
                                                <fieldset
                                                  className={`form-group ${
                                                    !!(
                                                      !!errors
                                                        .attachmentHasErrors
                                                        .length &&
                                                      errors.attachmentHasErrors.find(
                                                        (doc) =>
                                                          document === doc
                                                      )
                                                    )
                                                      ? "border border-danger"
                                                      : "border"
                                                  } px-2`}
                                                >
                                                  <legend
                                                    className="w-auto px-2"
                                                    style={{
                                                      fontFamily:
                                                        '"Roboto","Helvetica","Arial",sans-serif',
                                                      fontWeight: 400,
                                                      fontSize: "0.82rem",
                                                      lineHeight: "1em",
                                                      letterSpacing:
                                                        "0.00938em",
                                                      color: `${
                                                        !!(
                                                          !!errors
                                                            .attachmentHasErrors
                                                            .length &&
                                                          errors.attachmentHasErrors.find(
                                                            (doc) =>
                                                              document === doc
                                                          )
                                                        )
                                                          ? "red"
                                                          : "rgba(0, 0, 0, 0.6)"
                                                      }`,
                                                    }}
                                                  >
                                                    <span className="ml-1">
                                                      {document.lable}
                                                    </span>
                                                  </legend>
                                                  <div className="form-group1 mb-2">
                                                    <div className="input-group">
                                                      <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                          <i className="fas fa-paperclip"></i>
                                                        </span>
                                                      </div>
                                                      {formData.attachments.find(
                                                        (doc) =>
                                                          document === doc &&
                                                          !!doc.filename
                                                      ) ? (
                                                        <div
                                                          className="form-control"
                                                          style={{
                                                            backgroundColor:
                                                              "rgb(229, 246, 253)",

                                                            color: "#007bff",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                              setShowUploadedDocument(
                                                                true
                                                              );
                                                              setUploadedDocument(
                                                                {
                                                                  name: document.name,
                                                                  url: formData.attachments.find(
                                                                    (doc) =>
                                                                      document ===
                                                                        doc &&
                                                                      !!doc.filename
                                                                  ).url,
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            {
                                                              formData.attachments.find(
                                                                (doc) =>
                                                                  document ===
                                                                    doc &&
                                                                  !!doc.filename
                                                              ).filename
                                                            }
                                                          </span>

                                                          <IconButton
                                                            size="small"
                                                            onClick={
                                                              handleOpenMenu
                                                            }
                                                            style={{
                                                              marginTop: "-5px",
                                                              marginRight:
                                                                "-10px",
                                                            }}
                                                            color="info"
                                                            className="float-right"
                                                            aria-label="view"
                                                          >
                                                            <MoreHorizIcon />
                                                          </IconButton>

                                                          <Menu
                                                            anchorEl={anchorEl}
                                                            id="account-menu"
                                                            open={open}
                                                            onClose={(e) => {
                                                              handleCloseMenu();
                                                              e.stopPropagation();
                                                            }}
                                                            PaperProps={{
                                                              elevation: 0,
                                                              sx: {
                                                                overflow:
                                                                  "visible",
                                                                filter:
                                                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                mt: 1.5,
                                                                "& .MuiAvatar-root":
                                                                  {
                                                                    width: 32,
                                                                    height: 32,
                                                                    ml: -0.5,
                                                                    mr: 2,
                                                                  },
                                                                "&:before": {
                                                                  content: '""',
                                                                  display:
                                                                    "block",
                                                                  position:
                                                                    "absolute",
                                                                  top: 0,
                                                                  right: 15,
                                                                  width: 10,
                                                                  height: 10,
                                                                  bgcolor:
                                                                    "background.paper",
                                                                  transform:
                                                                    "translateY(-50%) rotate(45deg)",
                                                                  zIndex: 0,
                                                                },
                                                              },
                                                            }}
                                                            transformOrigin={{
                                                              horizontal:
                                                                "right",
                                                              vertical: "top",
                                                            }}
                                                            anchorOrigin={{
                                                              horizontal:
                                                                "right",
                                                              vertical:
                                                                "bottom",
                                                            }}
                                                          >
                                                            <span>
                                                              <MenuItem
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  handleCloseMenu();
                                                                  setShowUploadedDocument(
                                                                    true
                                                                  );
                                                                  setUploadedDocument(
                                                                    {
                                                                      name: document.name,
                                                                      url: formData.attachments.find(
                                                                        (doc) =>
                                                                          document ===
                                                                            doc &&
                                                                          !!doc.filename
                                                                      ).url,
                                                                    }
                                                                  );
                                                                }}
                                                                className="text-primary font-weight-bolder"
                                                              >
                                                                <span className="material-icons  mr-1">
                                                                  open_in_new
                                                                </span>
                                                                Preview
                                                              </MenuItem>
                                                            </span>

                                                            <span>
                                                              <MenuItem
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  handleCloseMenu();
                                                                  const tmpDocuments =
                                                                    [
                                                                      ...formData.attachments,
                                                                    ];
                                                                  const index =
                                                                    tmpDocuments.findIndex(
                                                                      ({
                                                                        id,
                                                                      }) =>
                                                                        id ===
                                                                        document.id
                                                                    );

                                                                  tmpDocuments.splice(
                                                                    index,
                                                                    1
                                                                  );
                                                                  setFormData({
                                                                    ...formData,
                                                                    attachments:
                                                                      tmpDocuments,
                                                                  });
                                                                }}
                                                                className="text-danger"
                                                              >
                                                                <span className="material-icons mr-1">
                                                                  remove_circle_outline
                                                                </span>
                                                                Remove
                                                              </MenuItem>
                                                            </span>
                                                          </Menu>
                                                        </div>
                                                      ) : (
                                                        <>
                                                          <input
                                                            type="file"
                                                            name="file"
                                                            className="form-control"
                                                            accept="application/pdf"
                                                            placeholder="Select pdf file"
                                                            onChange={(e) =>
                                                              handleUploadedPDFFile(
                                                                e,
                                                                document
                                                              )
                                                            }
                                                          />
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </fieldset>
                                              )}
                                          </FormControl>
                                        ) : (
                                          !!formData[
                                            `hasOtherDocument${document.id}`
                                          ] &&
                                          formData[
                                            `hasOtherDocument${document.id}`
                                          ] !== "false" && (
                                            <fieldset
                                              className={`form-group ${
                                                !!(
                                                  !!errors.attachmentHasErrors
                                                    .length &&
                                                  errors.attachmentHasErrors.find(
                                                    (doc) => document === doc
                                                  )
                                                )
                                                  ? "border border-danger"
                                                  : "border"
                                              } px-2 mt-3`}
                                            >
                                              <legend
                                                className="w-auto px-2"
                                                style={{
                                                  fontFamily:
                                                    '"Roboto","Helvetica","Arial",sans-serif',
                                                  fontWeight: 400,
                                                  fontSize: "0.82rem",
                                                  lineHeight: "1em",
                                                  letterSpacing: "0.00938em",
                                                  color: `${
                                                    !!(
                                                      !!errors
                                                        .attachmentHasErrors
                                                        .length &&
                                                      errors.attachmentHasErrors.find(
                                                        (doc) =>
                                                          document === doc
                                                      )
                                                    )
                                                      ? "red"
                                                      : "rgba(0, 0, 0, 0.6)"
                                                  }`,
                                                }}
                                              >
                                                <span className="ml-1">
                                                  {document.lable}
                                                </span>
                                              </legend>
                                              <div className="form-group1 mb-2">
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                      <i className="fas fa-paperclip"></i>
                                                    </span>
                                                  </div>
                                                  {formData.attachments.find(
                                                    (doc) =>
                                                      document === doc &&
                                                      !!doc.filename
                                                  ) ? (
                                                    <div
                                                      className="form-control"
                                                      style={{
                                                        backgroundColor:
                                                          "rgb(229, 246, 253)",

                                                        color: "#007bff",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setShowUploadedDocument(
                                                            true
                                                          );
                                                          setUploadedDocument({
                                                            name: document.name,
                                                            url: formData.attachments.find(
                                                              (doc) =>
                                                                document ===
                                                                  doc &&
                                                                !!doc.filename
                                                            ).url,
                                                          });
                                                        }}
                                                      >
                                                        {
                                                          formData.attachments.find(
                                                            (doc) =>
                                                              document ===
                                                                doc &&
                                                              !!doc.filename
                                                          ).filename
                                                        }
                                                      </span>

                                                      <IconButton
                                                        size="small"
                                                        onClick={handleOpenMenu}
                                                        style={{
                                                          marginTop: "-5px",
                                                          marginRight: "-10px",
                                                        }}
                                                        color="info"
                                                        className="float-right"
                                                        aria-label="view"
                                                      >
                                                        <MoreHorizIcon />
                                                      </IconButton>

                                                      <Menu
                                                        anchorEl={anchorEl}
                                                        id="account-menu"
                                                        open={open}
                                                        onClose={(e) => {
                                                          handleCloseMenu();
                                                          e.stopPropagation();
                                                        }}
                                                        PaperProps={{
                                                          elevation: 0,
                                                          sx: {
                                                            overflow: "visible",
                                                            filter:
                                                              "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                            mt: 1.5,
                                                            "& .MuiAvatar-root":
                                                              {
                                                                width: 32,
                                                                height: 32,
                                                                ml: -0.5,
                                                                mr: 2,
                                                              },
                                                            "&:before": {
                                                              content: '""',
                                                              display: "block",
                                                              position:
                                                                "absolute",
                                                              top: 0,
                                                              right: 15,
                                                              width: 10,
                                                              height: 10,
                                                              bgcolor:
                                                                "background.paper",
                                                              transform:
                                                                "translateY(-50%) rotate(45deg)",
                                                              zIndex: 0,
                                                            },
                                                          },
                                                        }}
                                                        transformOrigin={{
                                                          horizontal: "right",
                                                          vertical: "top",
                                                        }}
                                                        anchorOrigin={{
                                                          horizontal: "right",
                                                          vertical: "bottom",
                                                        }}
                                                      >
                                                        <span>
                                                          <MenuItem
                                                            onClick={(e) => {
                                                              handleCloseMenu();
                                                              setShowUploadedDocument(
                                                                true
                                                              );
                                                              setUploadedDocument(
                                                                {
                                                                  name: document.name,
                                                                  url: formData.attachments.find(
                                                                    (doc) =>
                                                                      document ===
                                                                        doc &&
                                                                      !!doc.filename
                                                                  ).url,
                                                                }
                                                              );
                                                            }}
                                                            className="text-primary font-weight-bolder"
                                                          >
                                                            <span className="material-icons  mr-1">
                                                              open_in_new
                                                            </span>
                                                            Preview
                                                          </MenuItem>
                                                        </span>

                                                        <span>
                                                          <MenuItem
                                                            onClick={(e) => {
                                                              handleCloseMenu();
                                                              const tmpDocuments =
                                                                [
                                                                  ...formData.attachments,
                                                                ];
                                                              const index =
                                                                tmpDocuments.findIndex(
                                                                  ({ id }) =>
                                                                    id ===
                                                                    document.id
                                                                );

                                                              tmpDocuments.splice(
                                                                index,
                                                                1
                                                              );
                                                              setFormData({
                                                                ...formData,
                                                                attachments:
                                                                  tmpDocuments,
                                                              });
                                                            }}
                                                            className="text-danger"
                                                          >
                                                            <span className="material-icons mr-1">
                                                              remove_circle_outline
                                                            </span>
                                                            Remove
                                                          </MenuItem>
                                                        </span>
                                                      </Menu>
                                                    </div>
                                                  ) : (
                                                    <>
                                                      <input
                                                        type="file"
                                                        name="file"
                                                        className="form-control"
                                                        accept="application/pdf"
                                                        placeholder="Select pdf file"
                                                        onChange={(e) =>
                                                          handleUploadedPDFFile(
                                                            e,
                                                            document
                                                          )
                                                        }
                                                      />
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </fieldset>
                                          )
                                        )}
                                      </div>
                                    </React.Fragment>
                                  ))}
                              </fieldset>
                            </div>
                          </div>
                        )}

                        {activeStep === 3 && (
                          <div className="col-12 mt-3 row mx-0 ">
                            {/* <div className="row col-12"> */}
                            <div className="col-9">
                              <TextField
                                style={{ backgroundColor: "#eee" }}
                                fullWidth
                                size="small"
                                name="position"
                                label="Your position"
                                variant="outlined"
                                placeholder="Your position"
                                value={user?.myPlacement?.positionName || "N/A"}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </div>

                            <div className="col-3">
                              <TextField
                                style={{ backgroundColor: "#eee" }}
                                fullWidth
                                size="small"
                                name="qualificationLevelName"
                                label="Qualification"
                                variant="outlined"
                                placeholder="Qualification"
                                value={
                                  user?.myPlacement?.qualificationLevelName ||
                                  "N/A"
                                }
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </div>

                            <div
                              className={`mt-3 col-12 row no-gutters align-items-start`}
                            >
                              <div
                                className={` ${
                                  user.selectedScope?.id === "RTB"
                                    ? "col-4"
                                    : "col-12"
                                }  row no-gutters`}
                              >
                                <div className={`col-12`}>
                                  <Autocomplete
                                    size="small"
                                    value={selectedDistrict}
                                    options={filterDistricts(
                                      transferSchedule.internal
                                    ).filter(
                                      ({ id }) =>
                                        id !== selectedDistrict2?.id &&
                                        id !== selectedDistrict3?.id
                                    )}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    onChange={(event, newValue) => {
                                      setErrors({
                                        ...errors,
                                        districtHasError: false,
                                      });

                                      setThirdSchoolInterest(null);
                                      setSecondSchoolInterest(null);
                                      setFirstSchoolInterest(null);
                                      setVacancies([]);

                                      setSelectedDistrict(newValue);

                                      if (!!newValue)
                                        getDistrictVacancies(newValue.id);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="District of your interest *"
                                        placeholder="District of your interest *"
                                      />
                                    )}
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        {option.name}
                                      </Box>
                                    )}
                                  />

                                  {errors.districtHasError && (
                                    <small className="text-danger mb-1">
                                      Please select district of your interest
                                    </small>
                                  )}
                                </div>

                                <div className="mt-3 col-12">
                                  <Autocomplete
                                    loading={showLoader}
                                    disabled={!selectedDistrict}
                                    size="small"
                                    value={firstSchoolInterest}
                                    options={vacancies}
                                    getOptionLabel={(option) =>
                                      option.schoolName + " / " + option.name
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    onChange={(event, newValue) => {
                                      setErrors({
                                        ...errors,
                                        schoolHasError: false,
                                      });
                                      setSecondSchoolInterest(null);
                                      setThirdSchoolInterest(null);
                                      setFirstSchoolInterest(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="First school of interest *"
                                        placeholder="First school of interest *"
                                      />
                                    )}
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        <Avatar
                                          style={{
                                            border: "1px solid #d1cbcb",
                                          }}
                                          className="mr-2"
                                        >
                                          {vacancies.findIndex(
                                            ({ id }) => id === option.id
                                          ) + 1}
                                        </Avatar>
                                        <div>
                                          <span className="d-block text-primary">
                                            {option.schoolName} {" / "}{" "}
                                            <small>{option.name} </small>
                                          </span>
                                          <small className="d-block text-uppercase">
                                            {option.sectorName} sector /{" "}
                                            {option.cellName} cell
                                          </small>
                                          <small className="d-block mb-1 ">
                                            <span className="badge badge-pill text-info pl-0">
                                              {option.requests} Requested
                                            </span>
                                            /
                                            <span className="badge badge-secondary ml-1">
                                              {option.vacantBudgetPosts} vacant
                                              {option.vacantBudgetPosts > 1
                                                ? "s"
                                                : ""}
                                            </span>
                                          </small>
                                          <Divider />
                                          <Divider />
                                        </div>
                                      </Box>
                                    )}
                                  />

                                  {errors.schoolHasError && (
                                    <small className="text-danger mb-1">
                                      Please select atleast one(1) school of
                                      your interest
                                    </small>
                                  )}
                                </div>
                                <div className="mt-3 col-12">
                                  <Autocomplete
                                    disabled={!firstSchoolInterest}
                                    loading={showLoader}
                                    size="small"
                                    value={secondSchoolInterest}
                                    options={vacancies.filter(
                                      ({ id }) =>
                                        !!firstSchoolInterest &&
                                        id !== firstSchoolInterest.id
                                    )}
                                    getOptionLabel={(option) =>
                                      option.schoolName + " / " + option.name
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    onChange={(event, newValue) => {
                                      setThirdSchoolInterest(null);
                                      setSecondSchoolInterest(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Second school of interest (Optional)"
                                        placeholder="Second school of interest (Optional)"
                                      />
                                    )}
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        <Avatar
                                          style={{
                                            border: "1px solid #d1cbcb",
                                          }}
                                          className="mr-2"
                                        >
                                          {vacancies
                                            .filter(
                                              ({ id }) =>
                                                !!firstSchoolInterest &&
                                                id !== firstSchoolInterest.id
                                            )
                                            .findIndex(
                                              ({ id }) => id === option.id
                                            ) + 1}
                                        </Avatar>
                                        <div>
                                          <span className="d-block text-primary">
                                            {option.schoolName} {" / "}{" "}
                                            <small>{option.name} </small>
                                          </span>
                                          <small className="d-block text-uppercase">
                                            {option.sectorName} sector /{" "}
                                            {option.cellName} cell
                                          </small>
                                          <small className="d-block mb-1 ">
                                            <span className="badge badge-pill text-info pl-0">
                                              {option.requests} Requested
                                            </span>
                                            /
                                            <span className="badge badge-secondary ml-1">
                                              {option.vacantBudgetPosts} vacant
                                              {option.vacantBudgetPosts > 1
                                                ? "s"
                                                : ""}
                                            </span>
                                          </small>
                                          <Divider />
                                          <Divider />
                                        </div>
                                      </Box>
                                    )}
                                  />
                                </div>
                                <div className="mt-3 col-12">
                                  <Autocomplete
                                    disabled={!secondSchoolInterest}
                                    loading={showLoader}
                                    size="small"
                                    value={thirdSchoolInterest}
                                    options={vacancies.filter(
                                      ({ id }) =>
                                        !!firstSchoolInterest &&
                                        !!secondSchoolInterest &&
                                        id !== firstSchoolInterest.id &&
                                        id !== secondSchoolInterest.id
                                    )}
                                    getOptionLabel={(option) =>
                                      option.schoolName + " / " + option.name
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    onChange={(event, newValue) => {
                                      setThirdSchoolInterest(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Third school of interest (Optional)"
                                        placeholder="Third school of interest (Optional)"
                                      />
                                    )}
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        <Avatar
                                          style={{
                                            border: "1px solid #d1cbcb",
                                          }}
                                          className="mr-2"
                                        >
                                          {vacancies
                                            .filter(
                                              ({ id }) =>
                                                !!firstSchoolInterest &&
                                                !!secondSchoolInterest &&
                                                id !== firstSchoolInterest.id &&
                                                id !== secondSchoolInterest.id
                                            )
                                            .findIndex(
                                              ({ id }) => id === option.id
                                            ) + 1}
                                        </Avatar>
                                        <div>
                                          <span className="d-block text-primary">
                                            {option.schoolName} {" / "}{" "}
                                            <small>{option.name} </small>
                                          </span>
                                          <small className="d-block text-uppercase">
                                            {option.sectorName} sector /{" "}
                                            {option.cellName} cell
                                          </small>
                                          <small className="d-block mb-1 ">
                                            <span className="badge badge-pill text-info pl-0">
                                              {option.requests} Requested
                                            </span>
                                            /
                                            <span className="badge badge-secondary ml-1">
                                              {option.vacantBudgetPosts} vacant
                                              {option.vacantBudgetPosts > 1
                                                ? "s"
                                                : ""}
                                            </span>
                                          </small>
                                          <Divider />
                                          <Divider />
                                        </div>
                                      </Box>
                                    )}
                                  />
                                </div>
                              </div>
                              {user.selectedScope?.id === "RTB" && (
                                <div className={`col-4 row no-gutters pl-2`}>
                                  <div className={`col-12`}>
                                    <Autocomplete
                                      disabled={!firstSchoolInterest}
                                      size="small"
                                      value={selectedDistrict2}
                                      options={filterDistricts(
                                        transferSchedule.internal
                                      ).filter(
                                        ({ id }) =>
                                          id !== selectedDistrict?.id &&
                                          id !== selectedDistrict3?.id
                                      )}
                                      getOptionLabel={(option) => option.name}
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      onChange={(event, newValue) => {
                                        setSecondSchoolInterest2(null);
                                        setFirstSchoolInterest2(null);
                                        setVacancies2([]);

                                        setSelectedDistrict2(newValue);

                                        if (!!newValue)
                                          getDistrictVacancies(newValue.id, 2);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Second district of interest (Optional)"
                                          placeholder="Second district of interest (Optional)"
                                        />
                                      )}
                                      renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                          {option.name}
                                        </Box>
                                      )}
                                    />
                                  </div>

                                  <div className="mt-3 col-12">
                                    <Autocomplete
                                      loading={showLoader}
                                      disabled={!selectedDistrict2}
                                      size="small"
                                      value={firstSchoolInterest2}
                                      options={vacancies2}
                                      getOptionLabel={(option) =>
                                        option.schoolName + " / " + option.name
                                      }
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      onChange={(event, newValue) => {
                                        setSecondSchoolInterest2(null);
                                        setFirstSchoolInterest2(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="First school of interest (Optional)"
                                          placeholder="First school of interest (Optional)"
                                        />
                                      )}
                                      renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                          <Avatar
                                            style={{
                                              border: "1px solid #d1cbcb",
                                            }}
                                            className="mr-2"
                                          >
                                            {vacancies2.findIndex(
                                              ({ id }) => id === option.id
                                            ) + 1}
                                          </Avatar>
                                          <div>
                                            <span className="d-block text-primary">
                                              {option.schoolName} {" / "}{" "}
                                              <small>{option.name} </small>
                                            </span>
                                            <small className="d-block text-uppercase">
                                              {option.sectorName} sector /{" "}
                                              {option.cellName} cell
                                            </small>
                                            <small className="d-block mb-1 ">
                                              <span className="badge badge-pill text-info pl-0">
                                                {option.requests} Requested
                                              </span>
                                              /
                                              <span className="badge badge-secondary ml-1">
                                                {option.vacantBudgetPosts}{" "}
                                                vacant
                                                {option.vacantBudgetPosts > 1
                                                  ? "s"
                                                  : ""}
                                              </span>
                                            </small>
                                            <Divider />
                                            <Divider />
                                          </div>
                                        </Box>
                                      )}
                                    />
                                  </div>
                                  <div className="mt-3 col-12">
                                    <Autocomplete
                                      disabled={!firstSchoolInterest2}
                                      loading={showLoader}
                                      size="small"
                                      value={secondSchoolInterest2}
                                      options={vacancies2.filter(
                                        ({ id }) =>
                                          !!firstSchoolInterest2 &&
                                          id !== firstSchoolInterest2.id
                                      )}
                                      getOptionLabel={(option) =>
                                        option.schoolName + " / " + option.name
                                      }
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      onChange={(event, newValue) => {
                                        setSecondSchoolInterest2(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Second school of interest (Optional)"
                                          placeholder="Second school of interest (Optional)"
                                        />
                                      )}
                                      renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                          <Avatar
                                            style={{
                                              border: "1px solid #d1cbcb",
                                            }}
                                            className="mr-2"
                                          >
                                            {vacancies2
                                              .filter(
                                                ({ id }) =>
                                                  !!firstSchoolInterest2 &&
                                                  id !== firstSchoolInterest2.id
                                              )
                                              .findIndex(
                                                ({ id }) => id === option.id
                                              ) + 1}
                                          </Avatar>
                                          <div>
                                            <span className="d-block text-primary">
                                              {option.schoolName +
                                                " / " +
                                                option.name}
                                            </span>
                                            <small className="d-block text-uppercase">
                                              {option.sectorName} sector /{" "}
                                              {option.cellName} cell
                                            </small>
                                            <small className="d-block mb-1 ">
                                              <span className="badge badge-pill text-info pl-0">
                                                {option.requests} Requested
                                              </span>
                                              /
                                              <span className="badge badge-secondary ml-1">
                                                {option.vacantBudgetPosts}{" "}
                                                vacant
                                                {option.vacantBudgetPosts > 1
                                                  ? "s"
                                                  : ""}
                                              </span>
                                            </small>
                                            <Divider />
                                            <Divider />
                                          </div>
                                        </Box>
                                      )}
                                    />
                                  </div>
                                </div>
                              )}
                              {user.selectedScope?.id === "RTB" && (
                                <div className={`col-4 row no-gutters pl-2`}>
                                  <div className={`col-12`}>
                                    <Autocomplete
                                      disabled={!firstSchoolInterest2}
                                      size="small"
                                      value={selectedDistrict3}
                                      options={filterDistricts(
                                        transferSchedule.internal
                                      ).filter(
                                        ({ id }) =>
                                          id !== selectedDistrict?.id &&
                                          id !== selectedDistrict2?.id
                                      )}
                                      getOptionLabel={(option) => option.name}
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      onChange={(event, newValue) => {
                                        setFirstSchoolInterest3(null);
                                        setVacancies3([]);

                                        setSelectedDistrict3(newValue);

                                        if (!!newValue)
                                          getDistrictVacancies(newValue.id, 3);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Third district of interest (Optional)"
                                          placeholder="Third district of interest (Optional)"
                                        />
                                      )}
                                      renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                          {option.name}
                                        </Box>
                                      )}
                                    />
                                  </div>

                                  <div className="mt-3 col-12">
                                    <Autocomplete
                                      loading={showLoader}
                                      disabled={!selectedDistrict3}
                                      size="small"
                                      value={firstSchoolInterest3}
                                      options={vacancies3}
                                      getOptionLabel={(option) =>
                                        option.schoolName + " / " + option.name
                                      }
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      onChange={(event, newValue) => {
                                        setFirstSchoolInterest3(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="First school of interest (Optional)"
                                          placeholder="First school of interest  (Optional)"
                                        />
                                      )}
                                      renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                          <Avatar
                                            style={{
                                              border: "1px solid #d1cbcb",
                                            }}
                                            className="mr-2"
                                          >
                                            {vacancies2.findIndex(
                                              ({ id }) => id === option.id
                                            ) + 1}
                                          </Avatar>
                                          <div>
                                            <span className="d-block text-primary">
                                              {option.schoolName} {" / "}{" "}
                                              <small>{option.name} </small>
                                            </span>
                                            <small className="d-block text-uppercase">
                                              {option.sectorName} sector /{" "}
                                              {option.cellName} cell
                                            </small>
                                            <small className="d-block mb-1 ">
                                              <span className="badge badge-pill text-info pl-0">
                                                {option.requests} Requested
                                              </span>
                                              /
                                              <span className="badge badge-secondary ml-1">
                                                {option.vacantBudgetPosts}{" "}
                                                vacant
                                                {option.vacantBudgetPosts > 1
                                                  ? "s"
                                                  : ""}
                                              </span>
                                            </small>
                                            <Divider />
                                            <Divider />
                                          </div>
                                        </Box>
                                      )}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="mt-3 col-12">
                              <TextField
                                multiline
                                rows={2}
                                fullWidth
                                type="number"
                                size="small"
                                name="reason"
                                label="Motivate your request *"
                                variant="outlined"
                                placeholder="Motivate your request *"
                                value={formData.reason}
                                onChange={(e) => {
                                  setErrors({
                                    ...errors,
                                    reasonHasError: false,
                                  });

                                  setFormData({
                                    ...formData,
                                    reason: e.target.value || "",
                                  });
                                }}
                              />

                              {errors.reasonHasError && (
                                <small className="text-danger mb-1">
                                  Please motivate your request
                                </small>
                              )}
                            </div>
                            <div className="col-12 mt-3">
                              <Alert severity="error" icon={false}>
                                Mugihe uhitamo ibigo, banza ushishoze umenye aho
                                biherereye. Ntabwo ari itegeko guhitamo ibigo
                                bitatu mu gihe utishimiye aho biherereye.
                                Ushobora guhitamo IKIGO KIMWE gusa
                                bigakunda.Byaba byiza ufashe ifoto z'ibigo
                                wahisemo ukayibika.
                              </Alert>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            </div>

            {showUploadedDocument && uploadedDocument && (
              <PreviewPdfUrl
                isBase64={true}
                showDocument={showUploadedDocument}
                setShowDocument={setShowUploadedDocument}
                document={{
                  name: `${uploadedDocument?.name || ""}`,
                  teacherName: `${teacherProfile?.lastName || ""} ${
                    teacherProfile?.firstName || ""
                  }`,
                  url: uploadedDocument?.url || "",
                }}
              />
            )}
          </>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4 px-4">
          <Button
            variant="outlined"
            color="primary"
            type="button"
            className="px-4"
            disabled={activeStep === 0 || loading}
            onClick={handleBack}
          >
            Back
          </Button>

          <Box sx={{ flex: "1 1 auto" }} />

          {activeStep !== 3 && (
            <Button
              variant="contained"
              color="primary"
              disabled={
                loading ||
                !transferEligibility.hasVerifiedProfile ||
                !transferEligibility.hasThreeYearsInService ||
                !transferEligibility.hasThreeYearsAfterPreviousTransfer ||
                (!transferEligibility.isCertified &&
                  user?.selectedScope?.id !== "RTB") ||
                !transferEligibility.isRoleAllowed
              }
              onClick={handleNext}
              type="button"
              className="px-4"
            >
              Next
            </Button>
          )}
          {activeStep === 3 && (
            <Button
              variant="contained"
              color="success"
              onClick={onSave}
              type="button"
              className="px-4"
              disabled={loading || !firstSchoolInterest || !formData.reason}
            >
              {loading ? "Wait..." : "Submit"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  user,
  districts,
  teacherProfile,
  transferEligibility,
  transferSchedule,
}) => {
  return {
    loading,
    onWait,
    user,
    districts,
    teacherProfile,
    transferEligibility,
    transferSchedule,
  };
};
export default connect(mapStateToProps, {
  saveTransferRequest,
  getDistricts,
  getTeacherProfile,
})(TransferRequestDialog);
