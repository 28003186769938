import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import { connect } from "react-redux";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import {
  publishScholarshipRequests,
  getScholarshipSummary,
} from "../../../../store/scholarship/actions";
import { useEffect } from "react";
const ScholarshipSummaryDialog = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    publishScholarshipRequests,
    getScholarshipSummary,
    scholarshipSummary,
    executed,
  } = props;

  useEffect(() => {
    if (!!showDialog) getScholarshipSummary();
  }, []);

  const [confirmPermutations, setConfirmPermutations] = useState(false);

  return (
    <>
      <Dialog
        onClose={() => {
          setShowDialog(false);
        }}
        fullWidth
        maxWidth="sm"
        open={showDialog}
      >
        <DialogTitle>
          <Typography className="text-center">Scholarship summary</Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="rounded border" style={{ overflow: "hidden" }}>
            <table className="table table-striped" style={{ fontSize: "12px" }}>
              <tr>
                <th
                  style={{ width: "50%" }}
                  className="text-right text-dark text-uppercase"
                >
                  Total
                </th>
                <th className=" text-dark text-uppercase">
                  {scholarshipSummary.total || 0}
                </th>
              </tr>
              <tr>
                <th className="text-right text-danger  text-uppercase">
                  Rejected
                </th>
                <th className="text-danger">
                  {scholarshipSummary.rejected || 0}
                </th>
              </tr>
              <tr>
                <th className="text-right text-primary  text-uppercase">
                  Sholtlisted
                </th>
                <th className="text-primary">
                  {scholarshipSummary.sholtlisted || 0}
                </th>
              </tr>

              <tr>
                <th className="text-right text-secondary  text-uppercase">
                  Pending
                </th>
                <th className="text-secondary">
                  {scholarshipSummary.pending || 0}
                </th>
              </tr>

              <tr>
                <th
                  className="text-right text-success  text-uppercase"
                  style={{ backgroundColor: "#b1e4ff" }}
                >
                  Selected
                </th>
                <th
                  className="text-success"
                  style={{ backgroundColor: "#b1e4ffc2" }}
                >
                  {scholarshipSummary.selected || 0}
                </th>
              </tr>
              <tr>
                <th
                  className="text-right text-secondary  text-uppercase"
                  style={{ backgroundColor: "#b1e4ff" }}
                >
                  Not Selected
                </th>
                <th
                  className="text-secondary"
                  style={{ backgroundColor: "#b1e4ffc2" }}
                >
                  {scholarshipSummary.notSelected || 0}
                </th>
              </tr>
            </table>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            color="success"
            variant="contained"
            onClick={() => setConfirmPermutations(true)}
            type="button"
            disabled={
              loading ||
              !!scholarshipSummary.published ||
              !scholarshipSummary.total ||
              !!scholarshipSummary.pending ||
              !executed
            }
            className="mt-n3 px-5"
          >
            <span className="material-icons">publish</span>
            Publish
          </Button>
        </DialogActions>
      </Dialog>

      {confirmPermutations && (
        <ConfirmationDialog
          confirmationDialog={confirmPermutations}
          message={
            <>
              <span>Are you sure you want to publish?</span>
            </>
          }
          setConfirmationDialog={setConfirmPermutations}
          onYes={() => {
            publishScholarshipRequests(() => {
              setConfirmPermutations(false);
              setShowDialog(false);
            });
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, scholarshipSummary }) => {
  return { loading, scholarshipSummary };
};
export default connect(mapStateToProps, {
  publishScholarshipRequests,
  getScholarshipSummary,
})(ScholarshipSummaryDialog);
