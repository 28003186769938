import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";

import { connect } from "react-redux";

const ConfirmationDialog = (props) => {
  const {
    message,
    btnMessage,
    setConfirmationDialog,
    loading,
    confirmationDialog,
    onYes,
    noPreFormat,
    error,
    disabled,
  } = props;

  return (
    <>
      <Dialog
        maxWidth="xs"
        onClose={() => {
          setConfirmationDialog(false);
        }}
        open={confirmationDialog}
      >
        <DialogTitle className={`text-${error ? "danger" : "primary"}`}>
          {/* <span className="d-flex align-items-center">
            {!error && <span className="material-icons mr-1">error</span>}
            {error && <span className="material-icons mr-1">warning</span>}

            {error ? "Error" : "Confirm"}
          </span> */}
          <IconButton
            aria-label="close"
            onClick={() => {
              setConfirmationDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={`pt-2 text-center ${error ? "text-danger" : ""} `}
        >
          {noPreFormat ? (
            message
          ) : (
            <pre
              style={{
                fontFamily: `"Roboto","Helvetica","Arial","sans-serif"`,
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "1.5",
                whiteSpace: "pre-wrap",
              }}
            >
              {message}
            </pre>
          )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            variant="contained"
            onClick={onYes}
            type="button"
            disabled={loading || disabled}
            className="mt-n3"
          >
            {loading && <>Wait...</>}
            {!loading && <>{!!btnMessage ? btnMessage : "Confirm"}</>}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return { loading };
};
export default connect(mapStateToProps)(ConfirmationDialog);
