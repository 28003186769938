import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  Button,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveOrUpdateMaternityLeave } from "../../../../store/maternity-leave/actions";
import { showError } from "../../../toastify";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

const AddOrUpdateMaternityLeaveDialog = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    saveOrUpdateMaternityLeave,
    formData,
    setFormData,
  } = props;

  const [errors, setErrors] = useState({
    fromDateHasError: false,
    toDateHasError: false,
    commentHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      fromDateHasError: false,
      toDateHasError: false,
      commentHasError: false,
      hasError: false,
    };

    if (!formData.fromDate) {
      error.fromDateHasError = true;
      error.hasError = true;
    }

    if (!formData.toDate) {
      error.toDateHasError = true;
      error.hasError = true;
    }

    if (!formData.requestComment) {
      error.commentHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onClose = () => {
    setShowDialog(false);
    setFormData({
      id: null,
      fromDate: null,
      toDate: null,
      requestComment: "",
    });
  };

  useEffect(() => {
    const defaultDate = new Date().setDate(new Date().getDate() + 30);
    if (!formData.id)
      setFormData({
        id: null,
        fromDate: defaultDate,
        toDate: new Date(defaultDate).setDate(
          new Date(defaultDate).getDate() + 98
        ),
        requestComment: "",
      });
  }, []);

  const onSave = () => {
    if (!formValidator()) {
      saveOrUpdateMaternityLeave(
        {
          ...formData,
          fromDate: moment(formData.fromDate).format("yyy-MM-DD"),
          toDate: moment(formData.toDate).format("yyy-MM-DD"),
        },
        onClose
      );
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-primary font-weight-bold">
              {!!formData.id ? "Edit " : "Request For "} Maternity Leave
            </span>{" "}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="row">
            <div className="col-12 mb-3">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  minDate={new Date().setDate(new Date().getDate() + 30)}
                  label="From Date"
                  inputFormat="dd/MM/yyyy"
                  value={formData.fromDate}
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      fromDate: date,
                      toDate: new Date(date).setDate(
                        new Date(date).getDate() + 98
                      ),
                    });

                    setErrors({
                      ...errors,
                      fromDateHasError: false,
                      toDateHasError: false,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={(e) => e.preventDefault()}
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                />

                {errors.fromDateHasError && (
                  <small className="text-danger">From Date is required</small>
                )}
              </LocalizationProvider>
            </div>
            <div className="col-12 mb-3">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  readOnly
                  disabled={!formData.fromDate}
                  minDate={new Date(formData.fromDate).setDate(
                    new Date(formData.fromDate).getDate() + 30
                  )}
                  label="To Date"
                  inputFormat="dd/MM/yyyy"
                  value={formData.toDate}
                  onChange={(date) => {
                    // setFormData({ ...formData, toDate: date });
                    // setErrors({ ...errors, toDateHasError: false });
                  }}
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={(e) => e.preventDefault()}
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                />

                {errors.toDateHasError && (
                  <small className="text-danger">To Date is required</small>
                )}
              </LocalizationProvider>
            </div>

            <div className="mt-3 col-12">
              <TextField
                InputProps={{
                  readOnly: !!formData.id,
                }}
                multiline
                rows={4}
                fullWidth
                size="small"
                name="comments"
                label="Your comments"
                variant="outlined"
                placeholder="Your comments"
                value={formData.requestComment}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    requestComment: e.target.value || "",
                  });
                  setErrors({ ...errors, commentHasError: false });
                }}
              />
              {errors.commentHasError && (
                <small className="text-danger">Your comment is required</small>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            variant="contained"
            onClick={onSave}
            type="button"
            className="px-4"
            disabled={
              loading ||
              !formData.fromDate ||
              !formData.toDate ||
              !formData.requestComment
            }
          >
            {loading ? "Wait..." : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, onWait, user }) => {
  return {
    loading,
    onWait,
    user,
  };
};
export default connect(mapStateToProps, {
  saveOrUpdateMaternityLeave,
})(AddOrUpdateMaternityLeaveDialog);
