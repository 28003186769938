import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Dashboard from "./pages/Dashboard";

import { isEmpty } from "lodash";
import { connect } from "react-redux";
import PostsDistribution from "./pages/InProgress";
import UserGuide from "./pages/UserGuide";
import InProgress from "./pages/InProgress";
import Archived from "./pages/Archived";
import Replacements from "./pages/Replacements";
import { getBanks } from "../../../store/placement/actions";
import { getSpecializations, getDegrees } from "./../../../store/users/actions";

const Layout = (props) => {
  useEffect(() => {
    document.title = "TMIS | Maternity Leave";
  }, []);

  const {
    user,
    banks,
    getBanks,
    getSpecializations,
    getDegrees,
    specializations,
    degrees,
  } = props;
  const history = useHistory();

  useEffect(() => {
    if (!banks.length) getBanks();
    if (!degrees.length) getDegrees();
    if (!specializations.length) getSpecializations();
  }, []);

  return (
    <React.Fragment>
      <Switch>
        <Route path="/in/maternity-leave/dashboard" component={Dashboard} />
        <Route path="/in/maternity-leave/in-progress" component={InProgress} />
        <Route path="/in/maternity-leave/archived" component={Archived} />
        <Route
          path="/in/maternity-leave/replacing-staff"
          component={Replacements}
        />
        <Route path="/in/maternity-leave/user-guide" component={UserGuide} />

        <Route
          path="/in/maternity-leave"
          component={() => <Redirect to="/in/maternity-leave/dashboard" />}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, banks, specializations, degrees }) => {
  return {
    user,
    banks,
    specializations,
    degrees,
  };
};
export default connect(mapStateToProps, {
  getBanks,
  getSpecializations,
  getDegrees,
})(Layout);
