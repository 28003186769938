import { defaultState } from "./state";
import structure from "./action-types";
import commonTypes from "../common/action-types";

const types = { ...commonTypes, ...structure };

const transferRequests = (
  transferRequestsState = defaultState.transferRequests,
  action
) => {
  switch (action.type) {
    case types.SET_TRANSFER_REQUESTS:
      return action.data;

    case types.ADD_NEW_TRANSFER_REQUEST: {
      const tmpState = [...transferRequestsState];
      tmpState.unshift(action.data);
      return tmpState;
    }

    case types.UPDATE_TRANSFER_REQUEST: {
      const tmpState = [...transferRequestsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.DELETE_TRANSFER_REQUEST: {
      const tmpState = [...transferRequestsState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.transferRequests;

    default:
      return transferRequestsState;
  }
};

const permutationRequests = (
  permutationRequestsState = defaultState.permutationRequests,
  action
) => {
  switch (action.type) {
    case types.SET_PERMUTATION_REQUESTS:
      return action.data;

    case types.ADD_NEW_PERMUTATION_REQUEST: {
      const tmpState = [...permutationRequestsState];
      tmpState.unshift(action.data);
      return tmpState;
    }

    case types.UPDATE_PERMUTATION_REQUEST: {
      const tmpState = [...permutationRequestsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.DELETE_PERMUTATION_REQUEST: {
      const tmpState = [...permutationRequestsState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.permutationRequests;

    default:
      return permutationRequestsState;
  }
};

const permutationRequestAttachments = (
  permutationRequestAttachmentsState = defaultState.permutationRequestAttachments,
  action
) => {
  switch (action.type) {
    case types.SET_PERMUTATION_REQUEST_ATTACHEMENTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.permutationRequestAttachments;

    default:
      return permutationRequestAttachmentsState;
  }
};

const transferRequestAttachments = (
  transferRequestAttachmentsState = defaultState.transferRequestAttachments,
  action
) => {
  switch (action.type) {
    case types.SET_TRANSFER_REQUEST_ATTACHEMENTS:
      return action.data;

    case types.UPDATE_TRANSFER_REQUEST_ATTACHEMENT: {
      const tmpState = [...transferRequestAttachmentsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.transferRequestAttachments;

    default:
      return transferRequestAttachmentsState;
  }
};

const permutationSummary = (
  permutationSummaryState = defaultState.permutationSummary,
  action
) => {
  switch (action.type) {
    case types.SET_PERMUTATION_SUMMARY:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.permutationSummary;

    default:
      return permutationSummaryState;
  }
};

const transferSummary = (
  transferSummaryState = defaultState.transferSummary,
  action
) => {
  switch (action.type) {
    case types.SET_TRANSFER_SUMMARY:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.transferSummary;

    default:
      return transferSummaryState;
  }
};

const permutationSchedule = (
  permutationScheduleState = defaultState.permutationSchedule,
  action
) => {
  switch (action.type) {
    case types.SET_PERMUTATION_SCHEDULE:
      return action.data || defaultState.permutationSchedule;

    case types.CLEAN_STATE:
      return defaultState.permutationSchedule;

    default:
      return permutationScheduleState;
  }
};

const transferSchedule = (
  transferScheduleState = defaultState.transferSchedule,
  action
) => {
  switch (action.type) {
    case types.SET_TRANSFER_SCHEDULE:
      return action.data || defaultState.transferSchedule;

    case types.CLEAN_STATE:
      return defaultState.transferSchedule;

    default:
      return transferScheduleState;
  }
};

const transferEligibility = (
  transferEligibilityState = defaultState.transferEligibility,
  action
) => {
  switch (action.type) {
    case types.SET_TRANSFER_ELIGIBILITY:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.transferEligibility;

    default:
      return transferEligibilityState;
  }
};

export default {
  transferRequests,
  permutationRequests,
  permutationRequestAttachments,
  transferRequestAttachments,
  permutationSummary,
  transferSummary,

  permutationSchedule,
  transferSchedule,

  transferEligibility,
};
