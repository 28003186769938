import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  Button,
  Avatar,
  Checkbox,
  Divider,
  LinearProgress,
  Paper,
  InputBase,
  Stepper,
  Step,
  StepLabel,
  Menu,
  MenuItem,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { savePermutationRequest } from "../../../../store/transfer/actions";
import { getDistricts } from "../../../../store/posts/actions";
import { getTeacherProfile } from "../../../../store/users/actions";
import { showError } from "../../../toastify";
import axios from "axios";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import defaultPofile from "../../../assets/default-profile.jpg";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import { isEmpty } from "lodash";
import TransferCriteriaDialog from "./TransferCriteriaDialog";

const steps = ["Criteria", "Teacher details ", "Permutation details"];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ITEM_HEIGHT = 60;

export const documents = [
  {
    id: 1,
    name: "Upload your degree",
  },
  {
    id: 2,
    name: "Upload your definitive appointment letter",
  },
  {
    id: 3,
    name: "Upload first and last pages of your peformance appraisal sheet",
  },
];

const TransferRequestDialog = (props) => {
  const {
    user,
    loading,
    showDialog,
    setShowDialog,
    savePermutationRequest,
    districts,
    getDistricts,
    teacherProfile,
    getTeacherProfile,
    preview,
    transferEligibility,
  } = props;

  useEffect(() => {
    if (!preview) getTeacherProfile(user.id);
  }, []);

  const [formData, setFormData] = useState({
    teacherId: "",
    attachments: [],
    reason: "",
  });

  const onClose = () => {
    setShowDialog(false);

    setFormData({
      teacherId: "",
      reason: "",
    });
  };

  const [errors, setErrors] = useState({
    reasonHasError: false,
    attachmentHasErrors: [],
    hasError: false,
  });

  const formStepper0Validator = () => {
    const error = {
      reasonHasError: false,
      attachmentHasErrors: [],
      hasError: false,
    };

    documents
      .filter(({ id }) => id <= 3)
      .forEach((doc) => {
        const document = formData.attachments.find(({ id }) => doc.id === id);

        if (!document) {
          error.attachmentHasErrors.push(doc);
          error.hasError = true;
        }
      });

    documents
      .filter(
        ({ id }) => id > 3 && formData[`hasOtherDocument${id}`] === "true"
      )
      .forEach((doc) => {
        const document = formData.attachments.find(({ id }) => doc.id === id);

        if (!document) {
          error.attachmentHasErrors.push(doc);
          error.hasError = true;
        }
      });

    setErrors(error);

    if (error.hasError) {
      showError(`Please upload all the mandatory documents`);

      return true;
    }
    return false;
  };

  const formStepper1Validator = () => {
    const error = {
      reasonHasError: false,
      attachmentHasErrors: [],
      hasError: false,
    };

    if (!formData.reason) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError(`Please fill all the mandatory fields`);
      return true;
    }
    return false;
  };

  const [showLoader, setShowLoader] = useState(false);
  const [foundTeacher, setFoundTeacher] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(null);

  const convertToLower = (value) => {
    return value.toLowerCase();
  };
  const isFileTooLarge = (file) => {
    const size = file.size;
    return size > 1074176 ? true : false;
  };

  const searchTeacher = async () => {
    try {
      setFoundTeacher(null);
      setShowLoader(true);
      const { data } = await axios.get(
        "/api/permutation/search-teacher/" + formData.teacherId
      );
      setShowLoader(false);

      setFoundTeacher(data);
    } catch (error) {
      setShowLoader(false);
      showError(error);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (activeStep === 0)
      return setActiveStep((prevActiveStep) => prevActiveStep + 1);

    if (activeStep === 1 && !formStepper0Validator())
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleUploadedPDFFile = (event, document) => {
    let pdfAttachment = event.target.files[0];

    setErrors({ ...errors, pdfAttachmentHasError: false });

    if (convertToLower(pdfAttachment?.name?.substr(-4)) !== ".pdf") {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("File should be pdf format");
    } else if (isFileTooLarge(pdfAttachment)) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("Allowed file should not exceed 1MB");
    } else {
      const index = formData.attachments.findIndex(
        ({ id }) => id === document.id
      );
      const attachments = [...formData.attachments];
      document.file = pdfAttachment;
      document.filename = pdfAttachment.name;

      const reader = new FileReader();

      reader.onload = (e) => {
        document.url = e.target.result;
      };

      reader.readAsDataURL(pdfAttachment);

      if (index < 0) attachments.push(document);
      else attachments[index] = document;
      setFormData({
        ...formData,
        attachments,
      });
      const index2 = errors.attachmentHasErrors.findIndex(
        ({ id }) => id === document.id
      );
      errors.attachmentHasErrors.splice(index2, 1);
      setErrors(errors);
    }
  };

  const onSave = () => {
    if (!formStepper1Validator()) {
      const payload = new FormData();
      const keys = Object.keys(formData);
      for (const key of keys) {
        if (formData.hasOwnProperty(key)) {
          if (key === "attachments" && formData[key] && !!formData[key].length)
            formData[key].forEach((row, i) => {
              payload.append("files", row.file);
              payload.append(`selectedIds`, row.id);
            });
          else payload.append(`${key}`, `${formData[key]}`);
        }
      }

      payload.append(`teacherTwoId`, foundTeacher.id);
      payload.append(`teacherTwoBudgetPostId`, foundTeacher.budgetPostId);

      savePermutationRequest(payload, setShowDialog);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);

    event.stopPropagation();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary mx-5 px-2">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-primary font-weight-bold">
              Permutation Request
            </span>{" "}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <Box sx={{ width: "100%" }} className="px-5">
          <Stepper activeStep={activeStep} className="pr-5">
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        <DialogContent className="mx-3">
          <>
            <div className="row mx-0">
              <div className="col-sm-12">
                <React.Fragment>
                  <div>
                    <div className="row no-gutters">
                      <div
                        className="col-2 mt-2 justify-content-center  text-center py-3"
                        style={{
                          backgroundColor: "#f3f4f6",
                        }}
                      >
                        <div
                          style={{
                            minHeight: "150px",
                            overflow: "hidden",
                          }}
                        >
                          <>
                            <img
                              className="mt-0 rounded  text-center"
                              src={
                                !!teacherProfile.profilePicture
                                  ? "data:image/jpg;base64," +
                                    teacherProfile.profilePicture
                                  : defaultPofile
                              }
                              style={{
                                border: "1px solid #a7a7a7",
                                height: "150px",
                                width: "120px",
                                overflow: "hidden",
                                borderRadius: "50%",
                              }}
                            />
                          </>
                        </div>
                        <div className="text-primary">
                          <strong>NID PHOTO</strong>{" "}
                        </div>
                        {!!teacherProfile?.id && (
                          <div className="text-primary">
                            <strong>{teacherProfile?.id}</strong>{" "}
                          </div>
                        )}
                        <label className="text-center text-uppercase  mt-n2 mb-0">
                          <span style={{ fontSize: "12px" }}>
                            {teacherProfile?.lastName}{" "}
                            {teacherProfile?.firstName}
                          </span>
                        </label>{" "}
                        {/* {!isEmpty(teacherProfile) && (
                          <label className="text-center text-uppercase  mt-n3">
                            <span
                              style={{ fontSize: "12px" }}
                              className="badge badge-primary"
                            >
                              {teacherProfile?.positionName}
                              {" ("}
                              {teacherProfile?.qualificationLevelName} {")"}
                            </span>
                          </label>
                        )} */}
                        {!isEmpty(teacherProfile) && (
                          <FormControl
                            className="mt-3 d-flex-block   pt-2"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <FormLabel className="text-primary   px-4 text-left">
                              Education certficate
                            </FormLabel>
                            <RadioGroup
                              className="mt-0 d-flex justify-content-start px-4"
                              row
                              name="hasEducationCertficate"
                              value={teacherProfile?.hasEducationCertficate}
                            >
                              {!!teacherProfile?.hasEducationCertficate && (
                                <FormControlLabel
                                  value={true}
                                  control={<Radio disableRipple size="small" />}
                                  label="Certfied"
                                />
                              )}
                              {!teacherProfile?.hasEducationCertficate && (
                                <FormControlLabel
                                  value={false}
                                  control={<Radio disableRipple size="small" />}
                                  label="Not certfied"
                                />
                              )}
                            </RadioGroup>
                          </FormControl>
                        )}
                      </div>
                      <div
                        className="col-10 row mx-0"
                        style={{
                          height: "62vh",
                          overflowY: "auto",
                        }}
                      >
                        {activeStep === 0 && (
                          <div className="col-12 row mx-0 ">
                            <div className="col-10 row no-gutters px-3">
                              <fieldset
                                className={`col-12 row no-gutters px-2 form-group border `}
                              >
                                <legend
                                  className={`col-12 w-auto px-2 text-primary`}
                                  style={{
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                  }}
                                >
                                  Criteria for Teacher permutation and transfer
                                  application
                                </legend>

                                <TransferCriteriaDialog />
                              </fieldset>
                            </div>
                          </div>
                        )}

                        {activeStep === 1 && (
                          <div className="col-12 row mx-0 ">
                            <div className="col-12 row no-gutters px-3">
                              <fieldset
                                className={`col-12 row no-gutters px-2 form-group border border-${
                                  !!errors.attachmentHasErrors.length &&
                                  !!errors.attachmentHasErrors.find(
                                    ({ id }) => id <= 3
                                  )
                                    ? "danger"
                                    : "primary"
                                }`}
                              >
                                <legend
                                  className={`col-12 w-auto px-2 text-${
                                    !!errors.attachmentHasErrors.length &&
                                    !!errors.attachmentHasErrors.find(
                                      ({ id }) => id <= 3
                                    )
                                      ? "danger"
                                      : "primary"
                                  }`}
                                  style={{
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                  }}
                                >
                                  Mandatory documents
                                </legend>

                                {documents
                                  .filter(({ id }) => id <= 3)
                                  .map((document) => (
                                    <div className=" col-4" key={document.id}>
                                      <fieldset
                                        className={`form-group ${
                                          !!(
                                            !!errors.attachmentHasErrors
                                              .length &&
                                            errors.attachmentHasErrors.find(
                                              (doc) => document === doc
                                            )
                                          )
                                            ? "border border-danger"
                                            : "border"
                                        } px-2`}
                                      >
                                        <legend
                                          className="w-auto px-2"
                                          style={{
                                            fontFamily:
                                              '"Roboto","Helvetica","Arial",sans-serif',
                                            fontWeight: 400,
                                            // maxWidth: "100%",
                                            fontSize: "0.82rem",
                                            lineHeight: "1em",
                                            letterSpacing: "0.00938em",
                                            color: `${
                                              !!(
                                                !!errors.attachmentHasErrors
                                                  .length &&
                                                errors.attachmentHasErrors.find(
                                                  (doc) => document === doc
                                                )
                                              )
                                                ? "red"
                                                : "rgba(0, 0, 0, 0.6)"
                                            }`,
                                          }}
                                        >
                                          {document.id}.{" "}
                                          <span className="ml-1 ">
                                            {document.name}
                                          </span>
                                        </legend>
                                        <div className="form-group1 mb-2">
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                <i className="fas fa-paperclip"></i>
                                              </span>
                                            </div>
                                            {formData.attachments.find(
                                              (doc) =>
                                                document === doc &&
                                                !!doc.filename
                                            ) ? (
                                              <div
                                                className="form-control"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(229, 246, 253)",

                                                  color: "#007bff",
                                                }}
                                              >
                                                <span
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    setShowUploadedDocument(
                                                      true
                                                    );
                                                    setUploadedDocument({
                                                      name: document.name,
                                                      url: formData.attachments.find(
                                                        (doc) =>
                                                          document === doc &&
                                                          !!doc.filename
                                                      ).url,
                                                    });
                                                  }}
                                                >
                                                  {
                                                    formData.attachments.find(
                                                      (doc) =>
                                                        document === doc &&
                                                        !!doc.filename
                                                    ).filename
                                                  }
                                                </span>

                                                <IconButton
                                                  size="small"
                                                  onClick={handleOpenMenu}
                                                  style={{
                                                    marginTop: "-5px",
                                                    marginRight: "-10px",
                                                  }}
                                                  color="info"
                                                  className="float-right"
                                                  aria-label="view"
                                                >
                                                  <MoreHorizIcon />
                                                </IconButton>

                                                <Menu
                                                  anchorEl={anchorEl}
                                                  id="account-menu"
                                                  open={open}
                                                  onClose={(e) => {
                                                    handleCloseMenu();
                                                    e.stopPropagation();
                                                  }}
                                                  PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                      overflow: "visible",
                                                      filter:
                                                        "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                      mt: 1.5,
                                                      "& .MuiAvatar-root": {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 2,
                                                      },
                                                      "&:before": {
                                                        content: '""',
                                                        display: "block",
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 15,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor:
                                                          "background.paper",
                                                        transform:
                                                          "translateY(-50%) rotate(45deg)",
                                                        zIndex: 0,
                                                      },
                                                    },
                                                  }}
                                                  transformOrigin={{
                                                    horizontal: "right",
                                                    vertical: "top",
                                                  }}
                                                  anchorOrigin={{
                                                    horizontal: "right",
                                                    vertical: "bottom",
                                                  }}
                                                >
                                                  <span>
                                                    <MenuItem
                                                      onClick={(e) => {
                                                        handleCloseMenu();
                                                        setShowUploadedDocument(
                                                          true
                                                        );
                                                        setUploadedDocument({
                                                          name: document.name,
                                                          url: formData.attachments.find(
                                                            (doc) =>
                                                              document ===
                                                                doc &&
                                                              !!doc.filename
                                                          ).url,
                                                        });
                                                      }}
                                                      className="text-primary font-weight-bolder"
                                                    >
                                                      <span className="material-icons  mr-1">
                                                        open_in_new
                                                      </span>
                                                      Preview
                                                    </MenuItem>
                                                  </span>

                                                  <span>
                                                    <MenuItem
                                                      onClick={(e) => {
                                                        handleCloseMenu();
                                                        const tmpDocuments = [
                                                          ...formData.attachments,
                                                        ];
                                                        const index =
                                                          tmpDocuments.findIndex(
                                                            ({ id }) =>
                                                              id === document.id
                                                          );

                                                        tmpDocuments.splice(
                                                          index,
                                                          1
                                                        );
                                                        setFormData({
                                                          ...formData,
                                                          attachments:
                                                            tmpDocuments,
                                                        });
                                                      }}
                                                      className="text-danger"
                                                    >
                                                      <span className="material-icons mr-1">
                                                        remove_circle_outline
                                                      </span>
                                                      Remove
                                                    </MenuItem>
                                                  </span>
                                                </Menu>
                                              </div>
                                            ) : (
                                              <>
                                                <input
                                                  type="file"
                                                  name="file"
                                                  className="form-control"
                                                  accept="application/pdf"
                                                  placeholder="Select pdf file"
                                                  onChange={(e) =>
                                                    handleUploadedPDFFile(
                                                      e,
                                                      document
                                                    )
                                                  }
                                                />
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </fieldset>
                                    </div>
                                  ))}
                              </fieldset>
                            </div>

                            <div className="col-12 row no-gutters px-3">
                              <fieldset
                                className={`col-12 row no-gutters px-2 form-group border border-primary`}
                              >
                                <legend
                                  className={`col-12 w-auto px-2 text-primary`}
                                  style={{
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                  }}
                                >
                                  Your profile
                                </legend>

                                <div className="col-4 px-1 justify-content-center mt-2">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <>
                                          {!teacherProfile.isNIDVerified &&
                                            !loading && (
                                              <span className="loaderIcon text-danger d-flex align-items-center">
                                                <span className="material-icons text-danger">
                                                  report_problem
                                                </span>
                                                <span
                                                  style={{ fontSize: "10px" }}
                                                >
                                                  Error
                                                </span>
                                              </span>
                                            )}

                                          {!!teacherProfile.isNIDVerified &&
                                            !loading && (
                                              <span className="loaderIcon text-success d-flex align-items-center">
                                                <span className="material-icons text-success ">
                                                  verified
                                                </span>
                                                <span
                                                  style={{ fontSize: "10px" }}
                                                >
                                                  Verified
                                                </span>
                                              </span>
                                            )}
                                        </>
                                      ),
                                    }}
                                    fullWidth
                                    size="small"
                                    autoComplete="false"
                                    name="nidNumber"
                                    label="NID Number"
                                    placeholder="NID Number"
                                    variant="outlined"
                                    value={teacherProfile.nidNumber || ""}
                                  />
                                </div>
                                <div className="col-4 px-1 text-left mt-2">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                    autoComplete="false"
                                    name="passportNumber"
                                    label="Passport number"
                                    placeholder="Passport number"
                                    variant="outlined"
                                    value={teacherProfile.passportNumber || ""}
                                  />
                                </div>

                                <div className="col-2 px-1 text-left mt-2">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                    name="civilStatus"
                                    label="Civil Status"
                                    variant="outlined"
                                    value={teacherProfile.civilStatus || ""}
                                  />
                                </div>
                                <div className="col-2 px-1 text-left mt-2">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="gender"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Gender"
                                    variant="outlined"
                                    className=" font-weight-bold"
                                    value={teacherProfile.gender || ""}
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="lastName"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Last Name"
                                    variant="outlined"
                                    className=""
                                    value={teacherProfile.lastName || ""}
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3  text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="firstName"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="First Name"
                                    variant="outlined"
                                    className=""
                                    value={teacherProfile.firstName || ""}
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="dateOfBirth"
                                    value={
                                      !!teacherProfile.dateOfBirth
                                        ? moment(
                                            teacherProfile.dateOfBirth
                                          ).format("DD/MM/yyyy")
                                        : ""
                                    }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Date Of Birth"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="countryOfBirth"
                                    value={teacherProfile.countryOfBirth || ""}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Country of Birth"
                                    variant="outlined"
                                    className=""
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3 text-left">
                                  <TextField
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="placeOfBirth"
                                    value={teacherProfile.placeOfBirth || ""}
                                    label="Place of Birth"
                                    variant="outlined"
                                    className=""
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="nationality"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Nationality"
                                    placeholder="Nationality"
                                    variant="outlined"
                                    className=" font-weight-bold"
                                    value={teacherProfile.nationalityId || ""}
                                  />
                                </div>
                                <div className="col-6 px-1 mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="spouseNames"
                                    value={teacherProfile?.spouseNames || "N/A"}
                                    label="Your spouse names"
                                    placeholder="Your spouse names"
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </div>
                                <div className="col-3 px-1 mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="employeeId"
                                    value={teacherProfile?.id || ""}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Employee ID"
                                    placeholder="Employee ID"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="col-3 px-1 mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="rssbNumber"
                                    value={teacherProfile?.rssbNumber || ""}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="RSSB Number"
                                    placeholder="RSSB Number"
                                    variant="outlined"
                                    className=""
                                  />
                                </div>
                                <div className="col-6 px-1 mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="bankAccount"
                                    value={
                                      !teacherProfile.accountNumber
                                        ? ""
                                        : teacherProfile.accountNumber +
                                          " (" +
                                          teacherProfile.bankName +
                                          ")"
                                    }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Bank Account"
                                    placeholder="Bank Account"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="col-6 px-1 mt-3 ">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="rssbNumber"
                                    value={teacherProfile?.positionName || ""}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Position"
                                    placeholder="Position"
                                    variant="outlined"
                                    className=""
                                  />
                                </div>
                                <div className="col-3 px-1 mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="teachingLevel"
                                    value={
                                      teacherProfile?.teachingLevelName || ""
                                    }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Teaching Level"
                                    placeholder="Teaching Level"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="col-3 px-1 mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="qualificationLevel"
                                    value={
                                      teacherProfile?.qualificationLevelName ||
                                      ""
                                    }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Position qualification"
                                    placeholder="Position qualification"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="col-6 px-1 mt-3 ">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="appointmentDate"
                                    value={
                                      !!teacherProfile.appointmentDate
                                        ? moment(
                                            teacherProfile.appointmentDate
                                          ).format("DD/MM/yyyy")
                                        : ""
                                    }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Position appointment date"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                    autoComplete="false"
                                    name="phone"
                                    label="Phone number"
                                    placeholder="Phone number"
                                    variant="outlined"
                                    value={teacherProfile.phoneNumber || ""}
                                  />
                                </div>
                                <div className="col-4 px-1  mt-3 text-left">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                    autoComplete="false"
                                    name="email"
                                    label="Personal email"
                                    placeholder="Personal email"
                                    variant="outlined"
                                    value={teacherProfile.email || ""}
                                  />
                                </div>
                                <div className="mt-3 col-4 px-1">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                    autoComplete="false"
                                    name="employmentTypeName"
                                    label="Employment Type"
                                    placeholder="Employment Type"
                                    variant="outlined"
                                    value={
                                      teacherProfile.employmentTypeName || ""
                                    }
                                  />
                                </div>
                                <div className="mt-3 col-4 px-1 mb-3">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                    autoComplete="false"
                                    name="degreeName"
                                    label="Your degree"
                                    placeholder="Your degree"
                                    variant="outlined"
                                    value={teacherProfile.degreeName || ""}
                                  />
                                </div>
                                <div className="mt-3 col-4 px-1 mb-3">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                    autoComplete="false"
                                    name="specialization"
                                    label="Specialization"
                                    placeholder="Specialization"
                                    variant="outlined"
                                    value={teacherProfile.specialization || ""}
                                  />
                                </div>
                                <div className="mt-3 col-4 px-1 mb-3">
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    fullWidth
                                    size="small"
                                    name="graduationDate"
                                    value={
                                      !!teacherProfile.graduationDate
                                        ? moment(
                                            teacherProfile.graduationDate
                                          ).format("DD/MM/yyyy")
                                        : ""
                                    }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Graduation date"
                                    variant="outlined"
                                  />
                                </div>
                              </fieldset>
                            </div>
                          </div>
                        )}

                        {activeStep === 2 && (
                          <div className=" col-12 mx-0 px-4 mt-2">
                            <div
                              className="jumbotron rounded py-4 mb-0 jumbotron-fluid text-center"
                              style={{ minHeight: "60vh" }}
                            >
                              <div className="container ">
                                <p
                                  className="lead mb-1 "
                                  style={{ fontSize: "18px" }}
                                >
                                  Find the teacher you want to permutate with.
                                </p>
                                <div className="d-flex justify-content-center ">
                                  <Paper
                                    component="form"
                                    sx={{
                                      p: "2px 4px",
                                      display: "flex",
                                      alignItems: "center",
                                      width: 400,
                                    }}
                                  >
                                    <IconButton
                                      type="button"
                                      sx={{ p: "10px" }}
                                      aria-label="menu"
                                    >
                                      <span className="material-icons">
                                        person_search
                                      </span>
                                    </IconButton>
                                    <InputBase
                                      components="input"
                                      type="text"
                                      fullWidth
                                      sx={{ ml: 1, flex: 1 }}
                                      placeholder="Search by phone number"
                                      inputProps={{
                                        "aria-label": "Search by phone number",
                                      }}
                                      value={formData.teacherId}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          teacherId: e.target.value || "",
                                        });
                                      }}
                                    />

                                    <Button
                                      disabled={
                                        showLoader || !formData.teacherId
                                      }
                                      onClick={searchTeacher}
                                      variant="contained"
                                      size="small"
                                      disableElevation
                                      color="primary"
                                    >
                                      <span className="material-icons mr-1">
                                        search
                                      </span>
                                      {showLoader ? "Wait..." : "Search"}
                                    </Button>
                                  </Paper>
                                </div>
                                <div className="mt-2">
                                  {showLoader && <LinearProgress />}
                                </div>
                                {!!foundTeacher && (
                                  <div
                                    className="rounded tmis-shadow px-2 pb-4 mt-3 bg-white"
                                    style={{
                                      border: "1px solid #b0b6b9",
                                    }}
                                  >
                                    <div className="row mx-0">
                                      <div className="col-12 ">
                                        <Typography className="d-flex align-items-center justify-content-center text-uppercase text-center font-weight-bold text-primary my-2">
                                          {foundTeacher.lastName}{" "}
                                          {foundTeacher.firstName}
                                          {foundTeacher.isAvailable && (
                                            <span className="badge badge-success ml-1">
                                              Available
                                            </span>
                                          )}
                                          {!foundTeacher.isAvailable && (
                                            <span className="badge badge-danger ml-1">
                                              Not Available
                                            </span>
                                          )}
                                        </Typography>
                                      </div>
                                      <div className="col-3 justify-content-center text-center">
                                        <div
                                          style={{
                                            minHeight: "118px",
                                            // overflow: "hidden",
                                          }}
                                        >
                                          <img
                                            className="mt-0 rounded  text-center"
                                            src={
                                              !!foundTeacher.profilePicture
                                                ? "data:image/jpg;base64," +
                                                  foundTeacher.profilePicture
                                                : defaultPofile
                                            }
                                            style={{
                                              border: "1px solid #a6d3df",
                                              height: "118px",
                                              // width: "120px",
                                              overflow: "hidden",
                                              // borderRadius: "50%",
                                            }}
                                          />
                                        </div>

                                        <div className="text-primary">
                                          <strong>{foundTeacher.id}</strong>{" "}
                                        </div>

                                        <FormControl
                                          className="mt-1 d-flex-block"
                                          style={{ backgroundColor: "#fff" }}
                                        >
                                          {/* <FormLabel className="text-primary   px-4 text-left">
                                            Education certficate
                                          </FormLabel> */}
                                          <RadioGroup
                                            className="mt-0 d-flex justify-content-start px-4"
                                            row
                                            name="hasEducationCertficate"
                                            value={
                                              foundTeacher?.hasEducationCertficate ||
                                              ""
                                            }
                                          >
                                            {!!foundTeacher?.hasEducationCertficate && (
                                              <FormControlLabel
                                                value={true}
                                                control={
                                                  <Radio
                                                    disableRipple
                                                    size="small"
                                                  />
                                                }
                                                label="Certfied"
                                              />
                                            )}
                                            {!foundTeacher?.hasEducationCertficate && (
                                              <FormControlLabel
                                                value={false}
                                                control={
                                                  <Radio
                                                    disableRipple
                                                    size="small"
                                                  />
                                                }
                                                label="Not certfied"
                                              />
                                            )}
                                          </RadioGroup>
                                        </FormControl>
                                      </div>
                                      <div className="col-9 row mx-0">
                                        <div className="col-6 mb-3">
                                          <TextField
                                            style={{
                                              backgroundColor: "#e9ecef",
                                            }}
                                            fullWidth
                                            size="small"
                                            name="position"
                                            label="Position"
                                            variant="outlined"
                                            placeholder="Position"
                                            value={foundTeacher.positionName}
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </div>

                                        <div className="col-3 mb-3">
                                          <TextField
                                            style={{
                                              backgroundColor: "#e9ecef",
                                            }}
                                            fullWidth
                                            size="small"
                                            name="qualificationLevelName"
                                            label="Qualification"
                                            variant="outlined"
                                            placeholder="Qualification"
                                            value={
                                              foundTeacher.qualificationLevelName ||
                                              "N/A"
                                            }
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </div>

                                        <div className="col-3">
                                          <TextField
                                            style={{
                                              backgroundColor: "#e9ecef",
                                            }}
                                            fullWidth
                                            size="small"
                                            name="employmentDate"
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                            label="Appointment date"
                                            variant="outlined"
                                            value={moment(
                                              foundTeacher.placementDate
                                            ).format("ll")}
                                          />
                                        </div>

                                        <div className="col-6 mb-3">
                                          <TextField
                                            style={{
                                              backgroundColor: "#e9ecef",
                                            }}
                                            fullWidth
                                            size="small"
                                            name="school"
                                            label="School"
                                            variant="outlined"
                                            placeholder="School"
                                            value={
                                              foundTeacher.schoolName +
                                              "/" +
                                              foundTeacher.districtName
                                            }
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </div>
                                        <div className="col-3">
                                          <TextField
                                            style={{
                                              backgroundColor: "#e9ecef",
                                            }}
                                            fullWidth
                                            size="small"
                                            name="cell"
                                            label="Cell"
                                            variant="outlined"
                                            placeholder="Cell"
                                            value={foundTeacher.cellName}
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </div>

                                        <div className="col-3">
                                          <TextField
                                            style={{
                                              backgroundColor: "#e9ecef",
                                            }}
                                            fullWidth
                                            size="small"
                                            name="sector"
                                            label="Sector"
                                            variant="outlined"
                                            placeholder="Sector"
                                            value={foundTeacher.sectorName}
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </div>
                                        {/* <div className="col-4">
                                          <TextField
                                            style={{
                                              backgroundColor: "#e9ecef",
                                            }}
                                            className="text-uppercase"
                                            fullWidth
                                            size="small"
                                            name="district"
                                            label="District"
                                            variant="outlined"
                                            placeholder="District"
                                            value={foundTeacher.districtName}
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </div>
                                        <div className="col-8">
                                          <TextField
                                            style={{
                                              backgroundColor: "#e9ecef",
                                            }}
                                            fullWidth
                                            size="small"
                                            name="employmentDate"
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                            label="Appointment date"
                                            variant="outlined"
                                            value={moment(
                                              foundTeacher.placementDate
                                            ).format("lll")}
                                          />
                                        </div> */}
                                        <div className="col-12 mt-2 ">
                                          <TextField
                                            multiline
                                            rows={4}
                                            autoFocus
                                            fullWidth
                                            type="number"
                                            size="small"
                                            name="reason"
                                            label="Motivate your request *"
                                            variant="outlined"
                                            placeholder="Motivate your request *"
                                            value={formData.reason}
                                            onChange={(e) => {
                                              setErrors({
                                                ...errors,
                                                reasonHasError: false,
                                              });

                                              setFormData({
                                                ...formData,
                                                reason: e.target.value || "",
                                              });
                                            }}
                                          />
                                          {errors.reasonHasError && (
                                            <small className="text-danger mb-1">
                                              Please motivate your request
                                            </small>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {!foundTeacher && (
                                <div className="jumbotron jumbotron-fluid text-center mt-0">
                                  <div className="container py-4 ">
                                    <p className="lead">
                                      {showLoader
                                        ? "Searching..."
                                        : "No record found."}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            </div>

            {showUploadedDocument && uploadedDocument && (
              <PreviewPdfUrl
                isBase64={true}
                showDocument={showUploadedDocument}
                setShowDocument={setShowUploadedDocument}
                document={{
                  name: `${uploadedDocument?.name || ""}`,
                  teacherName: `${teacherProfile?.lastName || ""} ${
                    teacherProfile?.firstName || ""
                  }`,
                  url: uploadedDocument?.url || "",
                }}
              />
            )}
          </>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4 px-4">
          <Button
            variant="outlined"
            color="primary"
            type="button"
            className="px-4"
            disabled={activeStep === 0 || loading}
            onClick={handleBack}
          >
            Back
          </Button>

          <Box sx={{ flex: "1 1 auto" }} />

          {activeStep !== 2 && (
            <Button
              variant="contained"
              color="primary"
              disabled={
                loading ||
                !transferEligibility.hasVerifiedProfile ||
                !transferEligibility.hasThreeYearsInService ||
                !transferEligibility.hasThreeYearsAfterPreviousTransfer ||
                (!transferEligibility.isCertified &&
                  user?.selectedScope?.id !== "RTB") ||
                !transferEligibility.isRoleAllowed
              }
              onClick={handleNext}
              type="button"
              className="px-4"
            >
              Next
            </Button>
          )}
          {activeStep === 2 && (
            <Button
              variant="contained"
              color="success"
              onClick={onSave}
              type="button"
              className="px-4"
              disabled={loading || !foundTeacher?.isAvailable}
            >
              {loading ? "Wait..." : "Submit"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  user,
  districts,
  teacherProfile,
  transferEligibility,
}) => {
  return {
    loading,
    onWait,
    user,
    districts,
    teacherProfile,
    transferEligibility,
  };
};
export default connect(mapStateToProps, {
  savePermutationRequest,
  getDistricts,

  getTeacherProfile,
})(TransferRequestDialog);
