import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import { connect } from "react-redux";
import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useHistory } from "react-router-dom";

import { showSuccess, showError } from "../../../toastify";
import axios from "axios";

const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
const eightCharsOrMore = /.{8,}/g; // eight characters or more

const ChangePassword = (props) => {
  useEffect(() => {
    document.title = "TMIS | Profile - Change Password";
  }, []);

  const [loading, setLoading] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  const [credentials, setCredentials] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
    showOldPassword: false,
    showPassword: false,
    showConfirmPassword: false,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    confirmChangePassword();
  };

  const confirmChangePassword = async () => {
    // setSuccessMessage("");
    // setError("");
    setLoading(true);

    try {
      const { data } = await axios.post("/api/auth/change-password", {
        oldPassword: credentials.oldPassword,
        newPassword: credentials.password,
        confirmNewPassword: credentials.confirmPassword,
      });

      // setError("");
      setSuccessMessage(data.message);
      showSuccess(data.message);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };

  return (
    <div className="px-4 pt-2">
      <div
        className="d-flex border align-items-center justify-content-center flex-column"
        style={{
          height: "74vh",
          backgroundColor: "#f3f4f6",
        }}
      >
        <div className="container-fluid px-0">
          <div className="row justify-content-center pt-3 mx-0 px-0 text-center mt-3">
            <div
              className="col-12 col-md-6 col-lg-4 row d-flex justify-content-center rounded border elevated"
              style={{
                backgroundColor: "#fff",
              }}
            >
              <div className="col-12 py-3 text-center">
                <h1 className="h3  font-weight-normal text-center text-primary ">
                  Change Password
                </h1>
                {!!successMessage && (
                  <Alert severity="success">{successMessage}</Alert>
                )}
              </div>

              <div className="col-12 pt-3 pb-5">
                <form onSubmit={onSubmit}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className="border bg-white"
                  >
                    <InputLabel htmlFor="oldPassword">Old password</InputLabel>
                    <OutlinedInput
                      id="oldPassword"
                      placeholder="Old password"
                      name="oldPassword"
                      type={credentials.showOldPassword ? "text" : "password"}
                      value={credentials.oldPassword}
                      onChange={(e) => {
                        const oldPassword = e.target.value;
                        setCredentials({ ...credentials, oldPassword });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disableFocusRipple
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setCredentials({
                                ...credentials,
                                showOldPassword: !credentials.showOldPassword,
                              })
                            }
                            edge="end"
                          >
                            {credentials.showOldPassword ? (
                              <span className="material-icons">
                                visibility_off
                              </span>
                            ) : (
                              <span className="material-icons">visibility</span>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Old password"
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className="border bg-white mt-3"
                  >
                    <InputLabel htmlFor="password">New password</InputLabel>
                    <OutlinedInput
                      id="password"
                      placeholder="New password"
                      name="password"
                      type={credentials.showPassword ? "text" : "password"}
                      value={credentials.password}
                      onChange={(e) => {
                        const password = e.target.value;
                        setCredentials({ ...credentials, password });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disableFocusRipple
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setCredentials({
                                ...credentials,
                                showPassword: !credentials.showPassword,
                              })
                            }
                            edge="end"
                          >
                            {credentials.showPassword ? (
                              <span className="material-icons">
                                visibility_off
                              </span>
                            ) : (
                              <span className="material-icons">visibility</span>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="New password"
                    />
                  </FormControl>

                  <FormControl
                    fullWidth
                    variant="outlined"
                    className="mt-3 bg-white"
                  >
                    <InputLabel htmlFor="password">
                      Confirm New Password
                    </InputLabel>
                    <OutlinedInput
                      id="confirmPassword"
                      placeholder="Confirm New Password"
                      name="confirmPassword"
                      type={
                        credentials.showConfirmPassword ? "text" : "password"
                      }
                      value={credentials.confirmPassword}
                      onChange={(e) => {
                        const confirmPassword = e.target.value;
                        setCredentials({ ...credentials, confirmPassword });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disableFocusRipple
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setCredentials({
                                ...credentials,
                                showConfirmPassword:
                                  !credentials.showConfirmPassword,
                              })
                            }
                            edge="end"
                          >
                            {credentials.showConfirmPassword ? (
                              <span className="material-icons">
                                visibility_off
                              </span>
                            ) : (
                              <span className="material-icons">visibility</span>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Confirm New Password"
                    />
                  </FormControl>

                  {!loading && (
                    <Button
                      color="warning"
                      type="submit"
                      disabled={
                        loading ||
                        credentials.confirmPassword !== credentials.password ||
                        !credentials.password ||
                        !credentials.oldPassword ||
                        !!successMessage
                      }
                      variant="contained"
                      disableElevation
                      className="btn btn-block text-light mt-3"
                      size="large"
                    >
                      Change password
                    </Button>
                  )}

                  {loading && (
                    <Button
                      type="button"
                      color="warning"
                      variant="contained"
                      disableElevation
                      className="btn btn-block text-light mt-3"
                      size="large"
                    >
                      Processing...
                    </Button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
