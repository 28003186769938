import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  Divider,
  TextField,
  Tooltip,
} from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  getDDEPostDistributions,
  getDDEPostLimits,
  saveSchoolPostLimits,
  confirmDDEPostDistributions,
} from "../../../../store/posts/actions";
import ReactPaginate from "react-paginate";
import PostRequestDetailsDialog from "./PostRequestDetailsDialog";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import PostLimitDialog from "./PostLimitDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { showError } from "../../../toastify";

const PostsDistributionREB = (props) => {
  useEffect(() => {
    document.title = "TMIS | Post Management - Post request";
  }, []);

  const {
    user,
    loading,
    ddePostDistributions,
    getDDEPostDistributions,
    ddePostLimits,
    getDDEPostLimits,
    saveSchoolPostLimits,
    confirmDDEPostDistributions,
    selectedUnit,
  } = props;
  const [searchTeam, setSearchTeam] = useState("");

  useEffect(() => {
    // getDDEPostDistributions();
    getDDEPostLimits();
  }, [user?.selectedEntity, user?.selectedAcademicYear]);

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(ddePostDistributions);
  }, [itemOffset, itemsPerPage, ddePostDistributions]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % ddePostDistributions.length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  const [showPostLimitDialog, setShowPostLimitDialog] = useState(false);
  const [showConfirmDistributions, setShowConfirmDistributions] =
    useState(false);

  // console.log(user?.selectedAcademicYear);

  return (
    <div className="px-4 pt-2">
      <div className="border rounded">
        <AppBar position="static" elevation={0} className="app-bar w-100">
          <Toolbar
            style={{
              minHeight: "0px",
              borderBottom: "3px solid #e9ecef",
            }}
            className=" my-0 py-2"
          >
            <Typography
              variant="p"
              noWrap
              component="div"
              className="d-flex  align-items-center text-uppercase text-primary font-weight-bold"
            >
              <span className="mr-2 text-dark">Post Distributions</span>
              <span className="material-icons" style={{ color: "#cecece" }}>
                chevron_right
              </span>
              <span
                className="d-block text-truncate"
                style={{ maxWidth: "70%" }}
              >
                <span>
                  {user.selectedEntity?.name}{" "}
                  {!!user.selectedEntity?.type &&
                  !(user.selectedEntity?.name)
                    .toUpperCase()
                    .includes(user.selectedEntity?.type)
                    ? user.selectedEntity?.type
                    : ""}
                </span>
              </span>
              {/* <span className="d-flex">
                <span className="text-dark mr-1">A0:</span>
                <span className="badge badge-secondary d-flex align-items-center">
                  {ddePostLimits.a0Limit}
                </span>
              </span>

              <DragIndicatorIcon style={{ color: "#cecece" }} />

              <span className="d-flex">
                <span className="text-dark mr-1">A1:</span>
                <span className="badge badge-secondary d-flex align-items-center">
                  {ddePostLimits.a1Limit}
                </span>
              </span>

              <DragIndicatorIcon style={{ color: "#cecece" }} />
              <span className="d-flex">
                <span className="text-dark mr-1">A2:</span>
                <span className="badge badge-secondary d-flex align-items-center">
                  {ddePostLimits.a2Limit}
                </span>
              </span> */}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />

            {/* <Box sx={{ display: { xs: "flex", md: "flex" } }}>
              <SearchBox onSearch={onSearch} placeholder="Search…" />
            </Box> */}
          </Toolbar>
        </AppBar>

        <div
          style={{
            backgroundColor: "#f3f4f6",
            borderRadius: "0.5rem",
            boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
            MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            height: "67vh",
            overflowY: "auto",
          }}
          className="px-3 pt-3"
        >
          <div className="mb-1 d-flex align-items-center justify-content-between">
            {/* <div className="d-flex align-items-center">
              <span className="text-primary text-uppercase mr-2">
                Post Distributions
              </span>
              <span className="material-icons text-primary">arrow_right</span>

              <span className="badge d-inline-flex align-items-center">
                A0:{" "}
                <span
                  className={`badge badge-${
                    ddePostLimits.a0Remaining > 0 ? "primary" : "danger"
                  } mx-1`}
                >
                  {ddePostLimits.a0Remaining || 0}
                </span>
                {" - "} A1:{" "}
                <span
                  className={`badge badge-${
                    ddePostLimits.a1Remaining > 0 ? "primary" : "danger"
                  }  mx-1`}
                >
                  {ddePostLimits.a1Remaining || 0}
                </span>
                {" - "} A2:{" "}
                <span
                  className={`badge badge-${
                    ddePostLimits.a2Remaining > 0 ? "primary" : "danger"
                  } ml-1`}
                >
                  {ddePostLimits.a2Remaining || 0}
                </span>
              </span>
            </div> */}

            <span className="d-inline-flex align-items-center">
              {/* {!!user?.selectedEntity[`postDistributionConfirmed${user.selectedScope.id}`] && (
                <Tooltip title="Confirmed">
                  <span
                    style={{ cursor: "default" }}
                    className="material-icons text-success mr-1"
                  >
                    verified
                  </span>
                </Tooltip>
              )} */}
              {/* <Button
                disabled={loading}
                onClick={() => {
                  setShowConfirmDistributions(true);
                }}
                variant="contained"
                size="small"
                disableElevation
                color="primary"
                style={{
                  borderRadius: "8px",
                  height: "32px",
                  alignItems: "center",
                  fontSize: ".75rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "initial",
                }}
              >
                Confirm distributions
              </Button> */}
            </span>
          </div>

          {/* <table className="table table-striped table-hover table-sm  fixTableHead">
            {!!ddePostDistributions.length && (
              <thead>
                <tr>
                  <th scope="col" rowSpan={2}>
                    #
                  </th>

                  <th scope="col" rowSpan={2}>
                    DISTRICT
                  </th>
                  <th
                    colSpan={3}
                    className="text-center"
                    style={{
                      backgroundColor: "#dbeafe",
                      borderLeft: "solid 1px #c6c7ce",
                    }}
                  >
                    A0
                  </th>
                  <th
                    colSpan={3}
                    style={{
                      backgroundColor: "#fef3c7",
                      borderLeft: "solid 1px #c6c7ce",
                    }}
                    className="text-center"
                  >
                    A1
                  </th>
                  <th
                    colSpan={3}
                    style={{
                      backgroundColor: "#e0e7ff",
                      borderLeft: "solid 1px #c6c7ce",
                    }}
                    className="text-center"
                  >
                    A2
                  </th>
                  <th
                    colSpan={4}
                    style={{
                      backgroundColor: "#d1fae5",
                      borderLeft: "solid 1px #c6c7ce",
                      borderRight: "solid 1px #c6c7ce",
                    }}
                    className="text-center"
                  >
                    TOTAL
                  </th>
                </tr>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#edf4fe",
                      borderLeft: "solid 1px #c6c7ce",
                    }}
                  >
                    Requested
                  </th>
                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#edf4fe",
                    }}
                  >
                    <input
                      disabled
                      style={{
                        width: "68px",
                        backgroundColor: "#edf4fe",
                        border: "solid 1px #c6c7ce",
                        fontWeight: "bold",
                      }}
                      type="text"
                      value={"Limit"}
                    />
                  </th>
                  
                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#edf4fe",
                      borderRight: "solid 1px #c6c7ce",
                    }}
                  >
                    Remaining
                  </th>

                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#fffae9",
                      borderLeft: "solid 1px #c6c7ce",
                    }}
                  >
                    Requested
                  </th>

                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#fffae9",
                    }}
                  >
                    <input
                      disabled
                      style={{
                        width: "68px",
                        backgroundColor: "#fffae9",
                        border: "solid 1px #c6c7ce",
                        fontWeight: "bold",
                      }}
                      type="text"
                      value={"Limit"}
                    />
                  </th>
                 
                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#fffae9",
                      borderRight: "solid 1px #c6c7ce",
                    }}
                  >
                    Remaining
                  </th>

                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#f3f5ff",
                    }}
                  >
                    Requested
                  </th>

                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#f3f5ff",
                    }}
                  >
                    <input
                      disabled
                      style={{
                        width: "68px",
                        backgroundColor: "#f3f5ff",
                        border: "solid 1px #c6c7ce",
                        fontWeight: "bold",
                      }}
                      type="text"
                      value={"Limit"}
                    />
                  </th>
                  
                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#f3f5ff",
                      borderRight: "solid 1px #c6c7ce",
                    }}
                  >
                    Remaining
                  </th>
                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#edfdf5",
                    }}
                  >
                    Requested
                  </th>
                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#edfdf5",
                    }}
                  >
                    Limit
                  </th>
                  
                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      borderRight: "solid 1px #c6c7ce",
                      backgroundColor: "#edfdf5",
                    }}
                  >
                    Remaining
                  </th>
                </tr>
              </thead>
            )}

            {!!paginatedItems && (
              <tbody>
                {paginatedItems.map((request) => (
                  <BudgetPostRequestCard
                    key={request.id}
                    index={getNo(ddePostDistributions, "id", request.id)}
                    request={request}
                    selectedEntity={user?.selectedEntity || undefined}
                    saveSchoolPostLimits={saveSchoolPostLimits}
                    selectedAcademicYear={user.selectedAcademicYear}
                    user={user}
                  />
                ))}
              </tbody>
            )}
          </table> */}

          {!paginatedItems?.length && (
            <div className="jumbotron jumbotron-fluid text-center">
              <div className="container py-5 ">
                <div className="">
                  <Typography
                    variant="p"
                    noWrap
                    component="div"
                    className="d-flex mb-4  align-items-center justify-content-center text-uppercase text-primary font-weight-bold"
                  >
                    <span className="mr-2">Limit</span>

                    <span className="d-flex">
                      <span className="text-dark mr-1">A0:</span>
                      <span className="badge badge-secondary d-flex align-items-center">
                        {ddePostLimits.a0Limit}
                      </span>
                    </span>

                    <DragIndicatorIcon style={{ color: "#cecece" }} />

                    <span className="d-flex">
                      <span className="text-dark mr-1">A1:</span>
                      <span className="badge badge-secondary d-flex align-items-center">
                        {ddePostLimits.a1Limit}
                      </span>
                    </span>

                    <DragIndicatorIcon style={{ color: "#cecece" }} />
                    <span className="d-flex">
                      <span className="text-dark mr-1">A2:</span>
                      <span className="badge badge-secondary d-flex align-items-center">
                        {ddePostLimits.a2Limit}
                      </span>
                    </span>
                  </Typography>
                  {/* <p className="text-primary text-uppercase mr-2"></p> */}
                  <p className="mt-3 text-primary text-uppercase mr-2">
                    Distributed
                    <span className="badge d-inline-flex align-items-center">
                      A0:{" "}
                      <span
                        className={`badge p-2 badge-${
                          +ddePostLimits.a0Remaining ===
                            +ddePostLimits.a0Limit &&
                          +ddePostLimits.a0Remaining > 0
                            ? "success"
                            : +ddePostLimits.a0Remaining <
                              +ddePostLimits.a0Limit
                            ? "primary"
                            : "danger"
                        } mx-1`}
                      >
                        {ddePostLimits.a0Remaining || 0}
                      </span>
                      {" - "} A1:{" "}
                      <span
                        className={`badge p-2 badge-${
                          +ddePostLimits.a1Remaining ===
                            +ddePostLimits.a1Limit &&
                          +ddePostLimits.a1Remaining > 0
                            ? "success"
                            : +ddePostLimits.a1Remaining <
                              +ddePostLimits.a1Limit
                            ? "primary"
                            : "danger"
                        } mx-1`}
                      >
                        {ddePostLimits.a1Remaining || 0}
                      </span>
                      {" - "} A2:{" "}
                      <span
                        className={`badge p-2 badge-${
                          +ddePostLimits.a2Remaining ===
                            +ddePostLimits.a2Limit &&
                          +ddePostLimits.a2Remaining > 0
                            ? "success"
                            : +ddePostLimits.a2Remaining <
                              +ddePostLimits.a2Limit
                            ? "primary"
                            : "danger"
                        } mx-1`}
                      >
                        {ddePostLimits.a2Remaining || 0}
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {showPostLimitDialog && (
        <PostLimitDialog
          showDialog={showPostLimitDialog}
          setShowDialog={setShowPostLimitDialog}
        />
      )}

      {showConfirmDistributions && (
        <ConfirmationDialog
          confirmationDialog={showConfirmDistributions}
          message={`Are you sure you want to confirm this post distributions?`}
          setConfirmationDialog={setShowConfirmDistributions}
          onYes={() => {
            confirmDDEPostDistributions(setShowConfirmDistributions);
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({
  user,
  loading,
  ddePostDistributions,
  ddePostLimits,
  selectedUnit,
}) => {
  return { user, loading, ddePostDistributions, ddePostLimits, selectedUnit };
};

export default connect(mapStateToProps, {
  getDDEPostDistributions,
  getDDEPostLimits,
  saveSchoolPostLimits,
  confirmDDEPostDistributions,
})(PostsDistributionREB);

const BudgetPostRequestCard = (props) => {
  const {
    user,
    request,
    index,
    selectedEntity,
    saveSchoolPostLimits,
    selectedAcademicYear,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showPostRequestApprovalDialog, setShowPostRequestApprovalDialog] =
    useState(false);

  const [formData, setFormData] = useState({
    a0Limit: request.a0Limit,
    a1Limit: request.a1Limit,
    a2Limit: request.a2Limit,

    a0Requested: request.a0Requested,
    a1Requested: request.a1Requested,
    a2Requested: request.a2Requested,
  });

  const [isA0Active, setIsA0Active] = useState(false);
  const [isA1Active, setIsA1Active] = useState(false);
  const [isA2Active, setIsA2Active] = useState(false);

  const [saved, setSaved] = useState(false);

  return (
    <>
      <tr className={``}>
        <th scope="row" className="text-left">
          {index + 1}
        </th>

        <td className="text-uppercase">{request.name}</td>
        <td
          className="text-center"
          style={{
            // backgroundColor: "#edf4fe",
            borderLeft: "solid 1px #c6c7ce",
          }}
        >
          {request.a0Requested || 0}
        </td>

        <td
          className="text-center"
          onMouseEnter={() => {
            if (
              selectedAcademicYear.active &&
              !selectedEntity[
                `postDistributionConfirmed${user.selectedScope.id}`
              ]
            )
              setIsA0Active(true);
            setSaved(false);
          }}
          onClick={() => {
            if (
              selectedAcademicYear.active &&
              !selectedEntity[
                `postDistributionConfirmed${user.selectedScope.id}`
              ]
            )
              setIsA0Active(true);
            setSaved(false);
          }}
          onMouseLeave={() => {
            if (formData.a0Limit !== request.a0Limit && !saved)
              setFormData({ ...formData, a0Limit: request.a0Limit });

            setIsA0Active(false);
          }}
        >
          {/* {request.a0Limit || 0} */}

          <input
            autoFocus
            disabled={!isA0Active || saved}
            style={{ width: "68px" }}
            type="number"
            onKeyPress={(e) => {
              if (
                e.key === "Enter" &&
                selectedAcademicYear.active &&
                !selectedEntity[
                  `postDistributionConfirmed${user.selectedScope.id}`
                ]
              ) {
                if (formData.a0Limit > formData.a0Requested)
                  return showError(
                    "Limit number can't be greater than requested number"
                  );
                else
                  saveSchoolPostLimits(
                    {
                      schoolId: request.id,
                      qualificationLevelId: 3,
                      limit: +(formData.a0Limit || 0),
                    },
                    setSaved
                  );
              }
            }}
            value={formData.a0Limit}
            onChange={(e) => {
              setFormData({ ...formData, a0Limit: e.target.value || 0 });
            }}
            onBlur={() => {
              if (formData.a0Limit === "")
                setFormData({
                  ...formData,
                  a0Limit: 0,
                });
            }}
            onKeyDown={() => {
              if (+formData.a0Limit === 0)
                setFormData({
                  ...formData,
                  a0Limit: "",
                });
            }}
          />
        </td>
        {/* <td className="text-center">{request.a0Distributed || 0}</td> */}
        <td
          className="text-center"
          style={{
            // backgroundColor: "#edf4fe",
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {request.a0Remaining || 0}
        </td>
        <td className="text-center">{request.a1Requested || 0}</td>

        <td
          className="text-center"
          onMouseEnter={() => {
            if (
              selectedAcademicYear.active &&
              !selectedEntity[
                `postDistributionConfirmed${user.selectedScope.id}`
              ]
            )
              setIsA1Active(true);
            setSaved(false);
          }}
          onClick={() => {
            if (
              selectedAcademicYear.active &&
              !selectedEntity[
                `postDistributionConfirmed${user.selectedScope.id}`
              ]
            )
              setIsA1Active(true);
            setSaved(false);
          }}
          onMouseLeave={() => {
            if (formData.a1Limit !== request.a1Limit && !saved)
              setFormData({ ...formData, a1Limit: request.a1Limit });

            setIsA1Active(false);
          }}
        >
          <input
            disabled={!isA1Active || saved}
            style={{ width: "68px" }}
            type="number"
            onKeyPress={(e) => {
              if (
                e.key === "Enter" &&
                selectedAcademicYear.active &&
                !selectedEntity[
                  `postDistributionConfirmed${user.selectedScope.id}`
                ]
              ) {
                if (formData.a1Limit > formData.a1Requested)
                  return showError(
                    "Limit number can't be greater than requested number"
                  );
                else
                  saveSchoolPostLimits(
                    {
                      schoolId: request.id,
                      qualificationLevelId: 2,
                      limit: +(formData.a1Limit || 0),
                    },
                    setSaved
                  );
              }
            }}
            value={formData.a1Limit}
            onChange={(e) => {
              setFormData({ ...formData, a1Limit: e.target.value || 0 });
            }}
            onBlur={() => {
              if (formData.a1Limit === "")
                setFormData({
                  ...formData,
                  a1Limit: 0,
                });
            }}
            onKeyDown={() => {
              if (+formData.a1Limit === 0)
                setFormData({
                  ...formData,
                  a1Limit: "",
                });
            }}
          />
        </td>
        {/* <td className="text-center">{request.a1Distributed || 0}</td> */}
        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {request.a1Remaining || 0}
        </td>
        <td className="text-center">{request.a2Requested || 0}</td>

        <td
          className="text-center"
          onMouseEnter={() => {
            if (
              selectedAcademicYear.active &&
              !selectedEntity[
                `postDistributionConfirmed${user.selectedScope.id}`
              ]
            )
              setIsA2Active(true);
            setSaved(false);
          }}
          onClick={() => {
            if (
              selectedAcademicYear.active &&
              !selectedEntity[
                `postDistributionConfirmed${user.selectedScope.id}`
              ]
            )
              setIsA2Active(true);
            setSaved(false);
          }}
          onMouseLeave={() => {
            if (formData.a2Limit !== request.a2Limit && !saved)
              setFormData({ ...formData, a2Limit: request.a2Limit });

            setIsA2Active(false);
          }}
        >
          <input
            disabled={!isA2Active || saved}
            style={{ width: "68px" }}
            type="number"
            onKeyPress={(e) => {
              if (
                e.key === "Enter" &&
                selectedAcademicYear.active &&
                !selectedEntity[
                  `postDistributionConfirmed${user.selectedScope.id}`
                ]
              ) {
                if (formData.a2Limit > formData.a2Requested)
                  return showError(
                    "Limit number can't be greater than requested number"
                  );
                else
                  saveSchoolPostLimits(
                    {
                      schoolId: request.id,
                      qualificationLevelId: 1,
                      limit: +(formData.a2Limit || 0),
                    },
                    setSaved
                  );
              }
            }}
            value={formData.a2Limit}
            onChange={(e) => {
              setFormData({ ...formData, a2Limit: e.target.value || 0 });
            }}
            onBlur={() => {
              if (formData.a2Limit === "")
                setFormData({
                  ...formData,
                  a2Limit: 0,
                });
            }}
            onKeyDown={() => {
              if (+formData.a2Limit === 0)
                setFormData({
                  ...formData,
                  a2Limit: "",
                });
            }}
          />
        </td>
        {/* <td className="text-center">{request.a2Distributed || 0}</td> */}
        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {request.a2Remaining || 0}
        </td>

        <td
          className="text-center"
          style={{
            backgroundColor: "#edfdf5",
          }}
        >
          {request.totalRequested || 0}
        </td>

        <td
          className="text-center"
          style={{
            backgroundColor: "#edfdf5",
          }}
        >
          {request.totalLimit || 0}
        </td>
        {/* <td
          className="text-center"
          style={{
            backgroundColor: "#edfdf5",
          }}
        >
          {request.totalDistributed || 0}
        </td> */}
        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
            backgroundColor: "#edfdf5",
          }}
        >
          {request.totalRemaining || 0}
        </td>

        {/* <td className="text-right">
          <IconButton
            size="small"
            className="p-0"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                }}
                className="text-primary font-weight-bolder"
              >
                <span className="material-icons mr-1">open_in_new</span>
                Open
              </MenuItem>
            </span>
          </Menu>
        </td> */}
      </tr>
    </>
  );
};
