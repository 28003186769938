import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  Button,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveOrUpdateSchoolPosition } from "../../../../store/posts/actions";
import { showError } from "../../../toastify";

const AddOrUpdateSchoolDialog = (props) => {
  const {
    loading,
    isEditing,
    unitTypes,
    units,
    setIsEditing,
    showDialog,
    setShowDialog,
    parentUnit,
    selectedTreeItem,
    saveOrUpdateSchoolPosition,
    expandedNodes,
    setExpandedNodes,
    user,
    formData,
    setFormData,
    cells,
    villages,
    getCells,
    getVillages,
    schoolLevels,
    sectors,
    getSectors,
    jobs,
    selectedUnit,
    qualificationLevels,
    teachingLevels,
  } = props;

  const [errors, setErrors] = useState({
    sdmsCodeHasError: false,
    nameHasError: false,
    schoollevelHasError: false,
    cellHasError: false,
    villageHasError: false,
    ippisUnitIdHasError: false,
  });

  const formValidator = () => {
    const error = {
      positionHasError: false,
      budgetPostsHasError: false,
      hasError: false,
    };

    if (!selectedJob) {
      error.positionHasError = true;
      error.hasError = true;
    }

    if (!formData.budgetPosts) {
      error.budgetPostsHasError = true;
      error.hasError = true;
    }

    if (!selectedTeachingLevel) {
      error.teachingLevelHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onClose = () => {
    setShowDialog(false);
    setIsEditing(false);

    setFormData({
      id: null,
      schoolId: "",
      positionId: "",
      budgetPosts: 0,
      teachingLevelId: "",
    });
  };

  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [selectedTeachingLevel, setSelectedTeachingLevel] = useState(null);

  useEffect(() => {
    if (!!selectedJob)
      setSelectedQualification(
        qualificationLevels.find(
          ({ id }) => id === selectedJob.qualificationLevelId
        )
      );
    else setSelectedQualification(null);
  }, [selectedJob]);

  useEffect(() => {
    if (!!showDialog && !!formData.id) {
      setSelectedJob(jobs.find(({ id }) => id === formData.positionId));
    }

    if (!!showDialog && !!formData.id)
      setSelectedTeachingLevel(
        teachingLevels.find(({ id }) => id === formData.teachingLevelId)
      );
    else {
      if (
        selectedUnit?.schoolLevelId === 1 ||
        user.selectedEntity?.schoolLevelId === 1
      )
        setSelectedTeachingLevel(teachingLevels.find(({ id }) => id === 1));
      else if (
        selectedUnit?.schoolLevelId === 2 ||
        user.selectedEntity?.schoolLevelId === 2 ||
        selectedUnit?.schoolLevelId === 7 ||
        user.selectedEntity?.schoolLevelId === 7
      )
        setSelectedTeachingLevel(teachingLevels.find(({ id }) => id === 2));
      else
        setSelectedTeachingLevel(
          teachingLevels.find(({ id }) => id === 3 || id === 4)
        );
    }
  }, [showDialog]);

  const onSave = () => {
    if (!formValidator()) {
      // console.log(user?.selectedEntity);
      saveOrUpdateSchoolPosition(
        {
          ...formData,
          teachingLevelId: selectedTeachingLevel.id,
          schoolId:
            formData.schoolId || selectedUnit?.id || user?.selectedEntity?.id,
        },
        setFormData,
        setIsEditing,
        setShowDialog,
        isEditing
      );
    }
  };

  const filterJobs = () => {
    if (
      selectedUnit?.schoolLevelId === 7 ||
      user.selectedEntity?.schoolLevelId === 7
    )
      return jobs.filter(({ id }) => id !== "00003" && id !== "00004");
    else if (selectedTeachingLevel?.id === 1)
      return jobs.filter(
        ({ id, teachingLevelId }) => teachingLevelId === 1 || id === "00001"
      );
    else if (selectedTeachingLevel?.id === 2)
      return jobs.filter(
        ({ id, teachingLevelId }) => teachingLevelId === 2 || id === "00001"
      );
    else if (selectedTeachingLevel?.id === 3)
      return jobs.filter(({ teachingLevelId }) => teachingLevelId === 3);

    if (selectedTeachingLevel?.id === 4)
      return jobs.filter(({ teachingLevelId }) => teachingLevelId === 4);
    else return [];
  };

  const filterTeachingLevels = () => {
    if (
      selectedUnit?.schoolLevelId === 1 ||
      user.selectedEntity?.schoolLevelId === 1
    )
      return teachingLevels.filter(({ id }) => id === 1);
    else if (
      selectedUnit?.schoolLevelId === 2 ||
      user.selectedEntity?.schoolLevelId === 2 ||
      selectedUnit?.schoolLevelId === 7 ||
      user.selectedEntity?.schoolLevelId === 7
    )
      return teachingLevels.filter(({ id }) => id === 2 || id === 1);
    else return teachingLevels;
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-primary font-weight-bold">
              {!!formData.id ? "Edit Position" : "Add New Position"}
            </span>{" "}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="row">
            <div className="col-5">
              <Autocomplete
                size="small"
                defaultValue={null}
                value={selectedTeachingLevel || null}
                options={filterTeachingLevels()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, teachingLevel) => {
                  setErrors({ ...errors, teachingLevelHasError: false });
                  setSelectedTeachingLevel(teachingLevel || null);

                  if (!formData.id) setSelectedJob(null);
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, teachingLevel) => (
                  <Box component="li" {...props}>
                    {teachingLevel.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Teaching Level"
                    name="teachingLevel"
                    placeholder="Teaching Level"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              {errors.teachingLevelHasError && (
                <small className="text-danger ">
                  Teaching Level is required
                </small>
              )}
            </div>
            <div className="col-7">
              <Autocomplete
                sx={{ backgroundColor: !!formData.id ? "#eee" : "#fff" }}
                readOnly={!!formData.id}
                size="small"
                defaultValue={null}
                value={selectedJob || null}
                options={filterJobs()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, position) => {
                  setErrors({ ...errors, positionHasError: false });
                  setSelectedJob(position || null);

                  setFormData({
                    ...formData,
                    positionId: position.id || null,
                  });
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, position) => (
                  <Box component="li" {...props}>
                    {position.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Position"
                    name="position"
                    placeholder="Select Position"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              {errors.positionHasError && (
                <small className="text-danger ">Position is required</small>
              )}
            </div>

            <div className="mt-3 col-5">
              <TextField
                // sx={{ backgroundColor: !!formData.id ? "#eee" : "#fff" }}
                // InputProps={{
                //   readOnly: !!formData.id,
                // }}

                // sx={{ backgroundColor: "#eee" }}
                // InputProps={{
                //   readOnly: true,
                // }}
                fullWidth
                type="number"
                size="small"
                name="budgetPosts"
                label="Number of staff"
                variant="outlined"
                placeholder="Number of staff"
                value={formData?.budgetPosts}
                onBlur={() => {
                  if (formData.budgetPosts === "")
                    setFormData({
                      ...formData,
                      budgetPosts: 0,
                    });
                }}
                onKeyDown={() => {
                  if (+formData.budgetPosts === 0)
                    setFormData({
                      ...formData,
                      budgetPosts: "",
                    });
                }}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    budgetPostsHasError: false,
                  });

                  setFormData({
                    ...formData,
                    budgetPosts: e.target.value || 0,
                  });
                }}
              />
              {errors.budgetPostsHasError && (
                <small className="text-danger ">Budget post is required</small>
              )}
            </div>

            <div className="mt-3  col-7">
              <Autocomplete
                style={{ backgroundColor: "#eee" }}
                readOnly
                size="small"
                defaultValue={null}
                value={selectedQualification}
                options={qualificationLevels}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderOption={(props, qualification) => (
                  <Box component="li" {...props}>
                    {qualification.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Qualification"
                    name="qualification"
                    placeholder="Qualification"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
              {/* <TextField
                style={{ backgroundColor: "#eee" }}
                fullWidth
                size="small"
                name="qualificationLevelId"
                label="Qualification"
                variant="outlined"
                placeholder="Qualification"
                value={selectedJob?.qualificationLevelName || ""}
                InputProps={{
                  readOnly: true,
                }}
              /> */}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            variant="contained"
            onClick={onSave}
            type="button"
            className="px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  unitTypes,
  units,
  user,
  sectors,
  cells,
  villages,
  schoolLevels,
  jobs,
  selectedUnit,
  qualificationLevels,
  teachingLevels,
}) => {
  return {
    loading,
    onWait,
    unitTypes,
    units,
    user,
    sectors,
    cells,
    villages,
    schoolLevels,
    jobs,
    selectedUnit,
    qualificationLevels,
    teachingLevels,
  };
};
export default connect(mapStateToProps, {
  saveOrUpdateSchoolPosition,
})(AddOrUpdateSchoolDialog);
