export const defaultState = {
  placementReports: [],
  placementReportDetails: [],
  changeList: [],
  prevPlacementReport: {},
  signatories: [],
  exitReasons: [],
  placementVacantPosts: [],
  banks: [],

  unsubmitted: [],
};
