import React, { useState, useEffect } from "react";

import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

import Header from "./common/components/Header";

import {
  getAcademicYears,
  getUserEntities,
  getUserScopes,
  selectScope,
} from "../../store/common/actions";

import SchoolsLayout from "./posts/Layout";
import PlacementLayout from "./placement/Layout";
import TransfersLayout from "./transfer/Layout";
import MaternityLeaveLayout from "./maternity-leave/Layout";
import CPDLayout from "./cpd/Layout";
import LicensingLayout from "./licensing/Layout";
import SecurityLayout from "./security/Layout";

import Drawer from "./common/components/Drawer";
import Footer from "../home/components/Footer";

import Welcome from "./Welcome";

import Scholarship from "./scholarship";
import { Avatar, Button } from "@mui/material";

import REB_Logo from "./../assets/REB_Logo.png";
import RTB_Logo from "./../assets/RTB_Logo.png";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function Layout(props) {
  const {
    user,
    loading,
    academicYears,
    getAcademicYears,
    userEntities,
    getUserEntities,
    scopes,
    getUserScopes,
    selectScope,
  } = props;

  const [lgDrawerState, setLGDrawerState] = useState(true);
  const [smDrawerState, setSMDrawerState] = useState(false);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!scopes.length) getUserScopes();

    if (!academicYears.length) getAcademicYears();

    if (!userEntities.length) getUserEntities();
  }, []);

  return (
    <>
      {!user?.selectedScope?.id && (
        <div className="px-4 pt-2">
          <div
            className="d-flex border align-items-center justify-content-center flex-column"
            style={{
              height: "93vh",
              backgroundColor: "#f3f4f6",
            }}
          >
            {!!scopes.length && (
              <h2 className="text-primary mb-2 text-uppercase font-weight-light">
                Select scope
              </h2>
            )}
            <div className="lead d-flex align-items-center justify-content-center flex-wrap">
              {scopes.map((scope) => (
                <Button
                  disabled={loading}
                  startIcon={
                    <Avatar
                      alt={scope.id === "REB" ? "REB" : "RTB"}
                      src={scope.id === "REB" ? REB_Logo : RTB_Logo}
                    />
                  }
                  size="large"
                  variant="outlined"
                  className="mr-2 mt-2 px-5"
                  key={scope.id}
                  onClick={() => {
                    selectScope(scope, () => {});
                  }}
                >
                  {scope.name}
                </Button>
              ))}
            </div>
          </div>
        </div>
      )}

      {!!user?.selectedScope?.id && (
        <>
          <Drawer
            lgDrawerState={lgDrawerState}
            smDrawerState={smDrawerState}
            setLGDrawerState={setLGDrawerState}
            setSMDrawerState={setSMDrawerState}
          />

          <div
            id="inner-main"
            className={` ${
              lgDrawerState && windowDimensions.width > 768
                ? "drawer-active"
                : ""
            }`}
            style={{ overflowX: "visible" }}
          >
            <Header
              lgDrawerState={lgDrawerState}
              smDrawerState={smDrawerState}
              setLGDrawerState={setLGDrawerState}
              setSMDrawerState={setSMDrawerState}
            />
            <div>
              <div style={{ backgroundColor: "#dbdcde", height: "6px" }}></div>
            </div>
            {!!user && (
              <div
                style={{
                  backgroundColor: "#f3f4f6",
                }}
              >
                <Switch>
                  <Route path="/in/welcome" component={Welcome} />
                  <Route path="/in/schools" component={SchoolsLayout} />
                  <Route path="/in/placement" component={PlacementLayout} />
                  <Route path="/in/transfers" component={TransfersLayout} />
                  <Route
                    path="/in/maternity-leave"
                    component={MaternityLeaveLayout}
                  />
                  <Route path="/in/cpd" component={CPDLayout} />
                  <Route path="/in/scholarships" component={Scholarship} />
                  <Route path="/in/licenses" component={LicensingLayout} />
                  <Route path="/in/security" component={SecurityLayout} />

                  <Route
                    path="/in"
                    component={() => <Redirect to="/in/welcome" />}
                  />
                </Switch>
              </div>
            )}

            <Footer />
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = ({
  env,
  user,
  loading,
  academicYears,
  userEntities,
  scopes,
}) => {
  return {
    env,
    user,
    loading,
    academicYears,
    userEntities,
    scopes,
  };
};
export default connect(mapStateToProps, {
  getAcademicYears,
  getUserEntities,
  getUserScopes,
  selectScope,
})(Layout);
