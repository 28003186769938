import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import React from "react";
import { useState } from "react";

import { connect } from "react-redux";
import { approveBudgetPostRequest } from "../../../../store/posts/actions";

const ConfirmApprovalPostRequestDialog = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    postRequest,
    isApproved,
    approveBudgetPostRequest,
    approvedNumber,
    onClose,
  } = props;

  const [comment, setComment] = useState("");

  const onSave = () => {
    approveBudgetPostRequest(
      {
        id: postRequest.id,
        isApproved,
        comment,
        approvedNumber,
      },
      (s) => {
        setShowDialog(s);
        onClose();
      }
    );
  };

  return (
    <>
      <Dialog
        maxWidth="xs"
        onClose={() => {
          setShowDialog(false);
        }}
        open={showDialog}
      >
        <DialogTitle className={`text-primary`}>
          <span className="d-flex align-items-center">
            <span className="material-icons mr-1">error</span>
            Confirm
          </span>
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className={`text-center`}>
          <p>
            {" "}
            Are you sure you want to{" "}
            <span
              className={`font-weight-bolder text-${
                isApproved ? "success" : "danger"
              }`}
            >
              {isApproved ? "approve" : "reject"}
            </span>{" "}
            this request?{" "}
          </p>

          {!isApproved && (
            <TextField
              autoFocus
              multiline
              rows={2}
              fullWidth
              type="number"
              size="small"
              name="comment"
              label="Reason For Rejection"
              variant="outlined"
              placeholder="Reason For Rejection"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          {!!isApproved && (
            <Button
              color="primary"
              variant="contained"
              onClick={onSave}
              type="button"
              className="px-4"
              disabled={
                loading ||
                !approvedNumber ||
                +approvedNumber > +postRequest.requestedNumber
              }
            >
              {loading ? "Wait..." : "Confirm"}
            </Button>
          )}
          {!isApproved && (
            <Button
              color="error"
              variant="contained"
              onClick={onSave}
              type="button"
              className="px-4"
              disabled={loading || !comment}
            >
              {loading ? "Wait..." : "Confirm"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return { loading };
};
export default connect(mapStateToProps, { approveBudgetPostRequest })(
  ConfirmApprovalPostRequestDialog
);
