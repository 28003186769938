import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import axios from "axios";
import moment from "moment";
import defaultPofile from "../../../assets/default-profile.jpg";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import MaskedInput from "react-text-mask";
import joi from "joi";

import {
  addOrUpdateReplacementStaff,
  getReplacementStaffProfile,
} from "../../../../store/maternity-leave/actions";

const personalEmailValidator = joi.object({
  email: joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ["com", "fr", "rw"] },
  }),
});

const idNumberPattern = [
  /[1-3]/,
  " ",
  /[1-2]/,
  /[0|9]/,
  /\d/,
  /\d/,
  " ",
  /[7-8]/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
];

const AddNewTeacherDialog = (props) => {
  const {
    user,
    loading,
    onWait,
    teacher,
    showDialog,
    setShowDialog,
    degrees,
    specializations,
    schoolPosition,

    request,
    banks,

    addOrUpdateReplacementStaff,
    getReplacementStaffProfile,
  } = props;

  const onClose = () => {
    setShowDialog(false);
  };

  const [errors, setErrors] = useState({
    nidNumberHasError: false,
    employeeIdHasError: false,
    rssbNumberHasError: false,
    bankAccountNumberHasError: false,
    bankHasError: false,
    phoneNumberHasError: false,
    emailHasError: false,
    degreeHasError: false,
    specializationHasError: false,
    graduationDateHasError: false,
    educationCertficateHasError: false,
    hasError: false,
  });

  const teacherInfoFormValidator = () => {
    const error = {
      nidNumberHasError: false,
      employeeIdHasError: false,
      rssbNumberHasError: false,
      bankAccountNumberHasError: false,
      bankHasError: false,
      phoneNumberHasError: false,
      emailHasError: false,
      degreeHasError: false,
      specializationHasError: false,
      graduationDateHasError: false,
      educationCertficateHasError: false,
      hasError: false,
    };

    if (
      (teacherForm.nidNumber || "").replace(/\s/g, "").length !== 16 ||
      !!idHasError
    ) {
      error.nidNumberHasError = true;
      error.hasError = true;
    }

    if (!teacherForm.employeeId) {
      error.employeeIdHasError = true;
      error.hasError = true;
    }

    if (!teacherForm.rssbNumber) {
      error.rssbNumberHasError = true;
      error.hasError = true;
    }

    if (!teacherForm.accountNumber) {
      error.bankAccountNumberHasError = true;
      error.hasError = true;
    }

    if (!teacherForm.bankId) {
      error.bankHasError = true;
      error.hasError = true;
    }

    if ((teacherForm.phoneNumber || "").replace(/\s/g, "").length !== 10) {
      error.phoneNumberHasError = true;
      error.hasError = true;
    }

    if (
      !teacherForm.email ||
      (!!teacherForm.email &&
        personalEmailValidator.validate({ email: teacherForm.email }).error)
    ) {
      error.emailHasError = true;
      error.hasError = true;
    }

    if (!teacherForm.degreeId) {
      error.degreeHasError = true;
      error.hasError = true;
    }

    if (!teacherForm.specializationId) {
      error.specializationHasError = true;
      error.hasError = true;
    }

    if (teacherForm.hasEducationCertficate === "") {
      error.educationCertficateHasError = true;
      error.hasError = true;
    }

    if (
      (!!teacherForm.graduationDate &&
        new Date(teacherForm.graduationDate) > new Date()) ||
      !teacherForm.graduationDate
    ) {
      error.graduationDateHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const [isVerifing, setIsVerifing] = useState(false);
  const [wasVerified, setWasVerified] = useState(false);
  const [idHasError, setIdHasError] = useState(false);

  const [teacherForm, setTeacherForm] = useState({
    nidNumber: "",
    nin: "",
    email: "",
    phoneNumber: "",
    gender: "",
    genderId: "",
    countryOfBirth: "",
    countryIdOfBirth: "",
    firstName: "",
    lastName: "",
    civilStatus: "",
    civilStatusId: "",
    spouseNames: "",
    fatherNames: "",
    motherNames: "",
    dateOfBirth: "",
    placeOfBirth: "",
    nationalityId: "",
    villageId: "",
    profilePicture: "",
    employeeId: "",
    rssbNumber: "",
    accountNumber: "",
    bankId: "",
    bankName: "",
    degreeId: null,
    specializationId: null,
    hasEducationCertficate: "",
    graduationDate: null,
  });

  useEffect(() => {
    if (!!request.actingStaffId)
      getReplacementStaffProfile(request.actingStaffId, (data) => {
        setTeacherForm({
          ...teacherForm,
          ...data,
        });
        setWasVerified(data.isNIDVerified);
      });
  }, []);

  const getNidDetails = async (nidNumber) => {
    try {
      let staff = {};
      setWasVerified(false);
      setIsVerifing(true);

      try {
        const { data: tmisData } = await axios.get(
          "/api/users/search-staff/" + (nidNumber || "").replace(/\s/g, "")
        );

        staff = tmisData;
      } catch (error) {}

      const { data } = await axios.get(
        "https://ippis.rw/api/search-tmis-employee/" +
          (nidNumber || "").replace(/\s/g, "")
      );

      setTeacherForm({
        ...teacherForm,
        nidNumber: nidNumber,
        nin: data.nin,
        gender: data.gender,
        genderId: data.genderId,
        countryOfBirth: data.countryOfBirth,
        countryIdOfBirth: data.countryIdOfBirth,
        firstName: data.firstName,
        lastName: data.lastName,
        civilStatus: data.civilStatus,
        civilStatusId: data.civilStatusId,
        spouseNames: data.spouseNames,
        fatherNames: data.fatherNames,
        motherNames: data.motherNames,
        dateOfBirth: data.dateOfBirth,
        placeOfBirth: data.placeOfBirth,
        nationalityId: data.nationalityId,
        villageId: data.villageId,
        profilePicture: data.profileImage,

        employeeId: data.employee?.id || "",
        rssbNumber: data.employee?.rssbNumber || "",
        accountNumber: data.employee?.accountNumber || "",
        bankId: data.employee?.bankId || "",
        bankName: data.employee?.bankName || "",
        email: staff?.email?.trim() || data.employee?.email?.trim() || "",
        phoneNumber:
          staff?.phoneNumber?.trim() ||
          data.employee?.phoneNumber?.trim() ||
          "",
      });

      setErrors({
        nidNumberHasError: false,
        employeeIdHasError: false,
        rssbNumberHasError: false,
        bankAccountNumberHasError: false,
        bankHasError: false,
        phoneNumberHasError: false,
        emailHasError: false,
        degreeHasError: false,
        specializationHasError: false,
        graduationDateHasError: false,
        educationCertficateHasError: false,
        hasError: false,
      });

      setIdHasError(false);
      setWasVerified(true);
      setIsVerifing(false);
    } catch (error) {
      setIdHasError(true);
      setWasVerified(false);
      setIsVerifing(false);
    }
  };

  // const getReplacementStaffProfile = async (actingStaffId) => {
  //   try {
  //     const { data } = await axios.get(
  //       "/api/maternity-leave/replacement-staff/" + actingStaffId
  //     );

  //     setTeacherForm({
  //       ...teacherForm,
  //       ...data,
  //     });

  //     setWasVerified(data.isNIDVerified);
  //   } catch (error) {}
  // };

  const onSave = () => {
    if (!teacherInfoFormValidator())
      addOrUpdateReplacementStaff(
        {
          id: teacherForm.employeeId,
          lastName: teacherForm.lastName || null,
          firstName: teacherForm.firstName || null,
          email: teacherForm.email?.trim(),
          phoneNumber: teacherForm.phoneNumber?.trim(),
          nidNumber: (teacherForm.nidNumber || "").replace(/\s/g, ""),
          nin: teacherForm.nin || null,
          gender: teacherForm.genderId || null,
          dateOfBirth: teacherForm.dateOfBirth || null,
          placeOfBirth: teacherForm.placeOfBirth || null,
          fatherNames: teacherForm.fatherNames || null,
          motherNames: teacherForm.motherNames || null,
          civilStatusId: teacherForm.civilStatusId || null,
          spouseNames: teacherForm.spouseNames || null,
          countryOfBirth: teacherForm.countryIdOfBirth || null,
          nationalityId: teacherForm.nationalityId || null,
          residenceVillageId: teacherForm.villageId || null,
          bankId: teacherForm.bankId || null,
          bankAccountNumber: teacherForm.accountNumber || null,
          rssbNumber: teacherForm.rssbNumber || null,
          hasEducationCertficate: teacherForm.hasEducationCertficate,
          degreeId: teacherForm.degreeId || null,
          specializationId: teacherForm.specializationId || null,
          graduationDate: teacherForm.graduationDate || null,
          maternityLeaveRequestId: request.id,
        },
        onClose
      );
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary pb-0 mb-0 text-uppercase border">
          <span className="d-flex align-items-center mb-2">
            <Typography className="d-flex align-items-center text-primary">
              <span className="text-dark mr-1">Add Replacement Staff</span>
            </Typography>
          </span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="px-0">
          <div className="mt-0" style={{ height: "100%" }}>
            <div
              style={{
                display: "table-cell",
                width: "100vw",
                height: "45vh",
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              <div className="row mx-0">
                <div className="col-sm-12">
                  <React.Fragment>
                    <div>
                      <div className="row no-gutters">
                        <div className="col-2 justify-content-center  text-center">
                          <div
                            style={{
                              minHeight: "150px",
                              overflow: "hidden",
                            }}
                          >
                            <>
                              <img
                                className="mt-0 rounded  text-center"
                                src={
                                  !!teacherForm.profilePicture
                                    ? "data:image/jpg;base64," +
                                      teacherForm.profilePicture
                                    : defaultPofile
                                }
                                style={{
                                  border: "1px solid #a7a7a7",
                                  height: "150px",
                                  width: "120px",
                                  overflow: "hidden",
                                  borderRadius: "50%",
                                }}
                              />
                            </>
                          </div>

                          <div className="text-primary">
                            <strong>NID PHOTO</strong>{" "}
                          </div>
                          <label className="text-center text-uppercase  mt-n2">
                            <span style={{ fontSize: "12px" }}>
                              {teacherForm?.lastName} {teacherForm?.firstName}
                            </span>
                          </label>

                          <FormControl
                            className=" d-block border rounded pt-2"
                            style={{ backgroundColor: "#eee" }}
                          >
                            <FormLabel className="text-primary  px-2">
                              Education certficate
                            </FormLabel>
                            <RadioGroup
                              className="mt-0 d-flex justify-content-start px-4"
                              row
                              name="hasEducationCertficate"
                              value={teacherForm?.hasEducationCertficate}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  hasEducationCertficate: e.target.value,
                                });

                                setErrors({
                                  ...errors,
                                  educationCertficateHasError: false,
                                });
                              }}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio disableRipple size="small" />}
                                label="Certfied"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio disableRipple size="small" />}
                                label="Not certfied"
                              />
                            </RadioGroup>
                            {errors.educationCertficateHasError && (
                              <small className="text-danger mb-1">
                                Required
                              </small>
                            )}
                          </FormControl>
                        </div>

                        <div className="col-10 row mx-0 d-flex">
                          <div className="col-4 justify-content-center">
                            {!idHasError && !isVerifing && !wasVerified && (
                              <span className="material-icons loaderIcon text-danger">
                                help
                              </span>
                            )}

                            {!!isVerifing && (
                              <i
                                className="fas fa-spinner spinner loaderIcon text-primary"
                                style={{ fontSize: "22px" }}
                              ></i>
                            )}

                            {!isVerifing && !!idHasError && (
                              <span className="loaderIcon text-danger d-flex align-items-center">
                                <span className="material-icons text-danger">
                                  report_problem
                                </span>
                                <span style={{ fontSize: "10px" }}>Error</span>
                              </span>
                            )}

                            {!idHasError && !isVerifing && !!wasVerified && (
                              <span className="loaderIcon text-success d-flex align-items-center">
                                <span className="material-icons text-success ">
                                  verified
                                </span>
                                {/* <span style={{ fontSize: "10px" }}>
                                  Verified
                                </span> */}
                              </span>
                            )}

                            <MaskedInput
                              mask={idNumberPattern}
                              className="form-control "
                              placeholder="NID Number"
                              guide={false}
                              value={teacherForm.nidNumber}
                              onKeyPress={(e) => {
                                if (
                                  e.key === "Enter" &&
                                  !!e.target.value.length
                                ) {
                                  getNidDetails(e.target.value);
                                }
                              }}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  nidNumber: e.target.value,
                                });

                                if (e.target.value.length === 21) {
                                  getNidDetails(e.target.value);
                                } else {
                                  setIdHasError(false);
                                  setWasVerified(false);
                                  setIsVerifing(false);
                                }
                                setErrors({
                                  ...errors,
                                  nidNumberHasError: false,
                                  emailHasError: false,
                                  phoneNumberHasError: false,
                                  employeeIdHasError: false,
                                  staffCodeHasError: false,
                                  bankAccountNumberHasError: false,
                                  hasError: false,
                                });
                              }}
                            />

                            {errors.nidNumberHasError && (
                              <small className="text-danger mb-1">
                                {!!teacherForm.nidNumber
                                  ? "Invalid NID Number"
                                  : " NID Number is required"}
                              </small>
                            )}
                          </div>

                          <div className="col-4 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="lastName"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Last Name"
                              variant="outlined"
                              className=""
                              value={teacherForm.lastName}
                            />
                          </div>
                          <div className="col-4  text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="firstName"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="First Name"
                              variant="outlined"
                              className=""
                              value={teacherForm.firstName}
                            />
                          </div>

                          <div className="col-2 mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="civilStatus"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Civil Status"
                              variant="outlined"
                              value={teacherForm.civilStatus}
                            />
                          </div>
                          <div className="col-2 mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="gender"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Gender"
                              variant="outlined"
                              className=" font-weight-bold"
                              value={teacherForm.gender}
                            />
                          </div>

                          <div className="col-4  mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="dateOfBirth"
                              value={
                                !!teacherForm.dateOfBirth
                                  ? moment(teacherForm.dateOfBirth).format(
                                      "DD/MM/yyyy"
                                    )
                                  : ""
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Date Of Birth"
                              variant="outlined"
                            />
                          </div>
                          <div className="col-4  mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="countryOfBirth"
                              value={teacherForm.countryOfBirth}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Country of Birth"
                              variant="outlined"
                              className=""
                            />
                          </div>
                          <div className="col-4  mt-2 text-left">
                            <TextField
                              InputProps={{
                                readOnly: true,
                              }}
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="placeOfBirth"
                              value={teacherForm.placeOfBirth}
                              label="Place of Birth"
                              variant="outlined"
                              className=""
                            />
                          </div>

                          <div className="col-4  mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="nationality"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Nationality"
                              placeholder="Nationality"
                              variant="outlined"
                              className=" font-weight-bold"
                              value={teacherForm.nationalityId}
                            />
                          </div>

                          <div className="col-4 mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="employeeId"
                              value={teacherForm?.employeeId || ""}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="IPPIS Staff ID"
                              placeholder="IPPIS Staff ID"
                              variant="outlined"
                            />

                            {errors.employeeIdHasError && (
                              <small className="text-danger mt-1">
                                Staff Id is required
                              </small>
                            )}
                          </div>

                          <div className="col-4 mt-2 text-left">
                            <TextField
                              fullWidth
                              size="small"
                              name="rssbNumber"
                              label="RSSB Number"
                              placeholder="RSSB Number"
                              variant="outlined"
                              value={teacherForm.rssbNumber}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  rssbNumber: e.target.value,
                                });

                                setErrors({
                                  ...errors,
                                  rssbNumberHasError: false,
                                });
                              }}
                            />

                            {!!errors.rssbNumberHasError && (
                              <small className="text-danger">
                                RSSB Number is required
                              </small>
                            )}
                          </div>

                          <div className="col-4 mt-2 text-left">
                            <TextField
                              fullWidth
                              size="small"
                              name="bankAccountNumber"
                              label="Bank Account Number"
                              placeholder="Bank Account Number"
                              variant="outlined"
                              value={teacherForm.accountNumber}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  accountNumber: e.target.value,
                                });

                                setErrors({
                                  ...errors,
                                  bankAccountNumberHasError: false,
                                });
                              }}
                            />

                            {!!errors.bankAccountNumberHasError && (
                              <small className="text-danger">
                                Bank account is required
                              </small>
                            )}
                          </div>
                          <div className="col-4 mt-2 text-left">
                            <Autocomplete
                              size="small"
                              defaultValue={null}
                              value={
                                banks.find(
                                  ({ id }) => id === teacherForm.bankId
                                ) || null
                              }
                              options={banks}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="bank"
                                  label="Bank"
                                  placeholder="Bank"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              onChange={(event, bank) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  bankId: bank?.id || null,
                                });
                                setErrors({
                                  ...errors,
                                  bankHasError: false,
                                });
                              }}
                            />
                            {errors.bankHasError && (
                              <small className="text-danger">
                                Bank is required
                              </small>
                            )}
                          </div>

                          <div className="col-4  mt-2 text-left">
                            <TextField
                              fullWidth
                              size="small"
                              autoComplete="false"
                              name="phone"
                              label="Phone number"
                              placeholder="Phone number"
                              variant="outlined"
                              value={teacherForm.phoneNumber}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  phoneNumber: e.target.value,
                                });

                                setErrors({
                                  ...errors,
                                  phoneNumberHasError: false,
                                });
                              }}
                            />
                            {errors.phoneNumberHasError && (
                              <small className="text-danger mt-1">
                                {!!teacherForm.phoneNumber
                                  ? "Invalid Phone Number"
                                  : " Phone Number is required"}
                              </small>
                            )}
                          </div>

                          <div className="col-4  mt-2 text-left">
                            <TextField
                              fullWidth
                              size="small"
                              autoComplete="false"
                              name="email"
                              label="Personal email"
                              placeholder="Personal email"
                              variant="outlined"
                              value={teacherForm.email}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  email: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  emailHasError: false,
                                });
                              }}
                            />

                            {errors.emailHasError && (
                              <small className="text-danger">
                                {!!teacherForm.email
                                  ? "Invalid Personal email"
                                  : " Personal email is required"}
                              </small>
                            )}
                          </div>

                          <div className="mt-2 col-4">
                            <Autocomplete
                              size="small"
                              defaultValue={null}
                              value={
                                degrees.find(
                                  ({ id }) => id === teacherForm.degreeId
                                ) || null
                              }
                              options={degrees}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Highest Degree"
                                  name="degree"
                                  placeholder="Highest Degree"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              onChange={(event, degree) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  degreeId: degree?.id || null,
                                });

                                setErrors({
                                  ...errors,
                                  degreeHasError: false,
                                });
                              }}
                            />
                            {errors.degreeHasError && (
                              <small className="text-danger">
                                Highest Degree is required
                              </small>
                            )}
                          </div>
                          <div className="mt-2 col-8">
                            <Autocomplete
                              size="small"
                              defaultValue={null}
                              value={
                                specializations.find(
                                  ({ id }) =>
                                    id === teacherForm.specializationId
                                ) || null
                              }
                              options={specializations}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Specialization"
                                  name="specialization"
                                  placeholder="Specialization"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              onChange={(event, specialization) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  specializationId: specialization?.id || null,
                                });

                                setErrors({
                                  ...errors,
                                  specializationHasError: false,
                                });
                              }}
                            />
                            {errors.specializationHasError && (
                              <small className="text-danger">
                                Specialization is required
                              </small>
                            )}
                          </div>
                          <div className="mt-2 col-4">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                label="Graduation date"
                                inputFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                value={teacherForm.graduationDate || null}
                                onChange={(date) => {
                                  setTeacherForm({
                                    ...teacherForm,
                                    graduationDate: date,
                                  });

                                  setErrors({
                                    ...errors,
                                    graduationDateHasError: false,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    onKeyDown={(e) => e.preventDefault()}
                                    size="small"
                                    fullWidth
                                    {...params}
                                  />
                                )}
                              />

                              {errors.graduationDateHasError && (
                                <small className="text-danger mt-1">
                                  {!!teacherForm.graduationDate
                                    ? "Invalid Date"
                                    : "Graduation Date is required"}
                                </small>
                              )}
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className=" d-flex justify-content-center py-4">
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            disableElevation
            size="sm"
            className="px-5"
            onClick={onSave}
          >
            {loading ? "Wait..." : !!request.actingStaffId ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  placementReportDetails,
  changeList,
  prevPlacementReport,
  signatories,
  roles,
  districts,
  sectors,
  degrees,
  specializations,
  schoolPositions,
  qualificationLevels,
  banks,
}) => {
  return {
    user,
    loading,
    onWait,
    placementReportDetails,
    changeList,
    prevPlacementReport,
    signatories,
    roles,
    districts,
    sectors,
    degrees,
    specializations,
    schoolPositions,
    qualificationLevels,
    banks,
  };
};
export default connect(mapStateToProps, {
  addOrUpdateReplacementStaff,
  getReplacementStaffProfile,
})(AddNewTeacherDialog);
