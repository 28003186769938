import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Dashboard from "./pages/Dashboard";

import { isEmpty } from "lodash";
import { connect } from "react-redux";
import UserGuide from "./pages/UserGuide";
import ArchivedPlacementReports from "./pages/ArchivedPlacementReports";
import NewPlacementReports from "./pages/NewPlacementReports";
import { getSpecializations, getDegrees } from "./../../../store/users/actions";

import { getExitReasons } from "./../../../store/placement/actions";
import Unsubmitted from "./pages/Unsubmitted";

const Layout = (props) => {
  useEffect(() => {
    document.title = "TMIS | Placement";
  }, []);

  const { user, getDegrees, getSpecializations, getExitReasons } = props;
  const history = useHistory();

  useEffect(() => {
    getDegrees();
    getSpecializations();
    getExitReasons();
  }, []);

  return (
    <React.Fragment>
      <Switch>
        <Route path="/in/placement/dashboard" component={Dashboard} />
        <Route
          path="/in/placement/new-placement-reports"
          component={NewPlacementReports}
        />
        <Route
          path="/in/placement/archived-placement-reports"
          component={ArchivedPlacementReports}
        />
        <Route path="/in/placement/unsubmitted" component={Unsubmitted} />

        <Route path="/in/placement/user-guide" component={UserGuide} />

        <Route
          path="/in/placement"
          component={() => <Redirect to="/in/placement/dashboard" />}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
export default connect(mapStateToProps, {
  getSpecializations,
  getDegrees,
  getExitReasons,
})(Layout);
