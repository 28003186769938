import { Link } from "react-router-dom";

const Footer = (props) => {
  const { drawerState } = props;
  return (
    <footer
      id="footer"
      className={`text-center  pt-2 ${drawerState ? "drawer-active" : ""}`}
      style={{
        backgroundColor: "#f6f8fa",
        borderTop: "1px solid rgba(0, 0, 0, 0.125)",
      }}
    >
      <span className="text-uppercase" style={{ fontSize: "12px" }}>
        &copy;{new Date().getFullYear()}{" "}
        <a
          href="https://www.reb.gov.rw"
          target="_blank"
          rel="noreferrer"
          // className="text-dark"
        >
          Rwanda Basic Education Board
        </a>
      </span>
    </footer>
  );
};

export default Footer;
