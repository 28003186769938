import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Dashboard from "./pages/Dashboard";

import { isEmpty } from "lodash";
import { connect } from "react-redux";
import UserGuide from "./pages/UserGuide";
import Permutation from "./pages/Permutations";
import TransferRequests from "./pages/TransferRequests";

import { getTransferEligibility } from "../../../store/transfer/actions";

const Layout = (props) => {
  useEffect(() => {
    document.title = "TMIS | Transfers";
  }, []);

  const { user, getTransferEligibility } = props;

  useEffect(() => {
    getTransferEligibility();
  }, []);

  return (
    <React.Fragment>
      <Switch>
        <Route path="/in/transfers/dashboard" component={Dashboard} />
        <Route path="/in/transfers/permutations" component={Permutation} />
        <Route path="/in/transfers/requests" component={TransferRequests} />
        <Route path="/in/transfers/user-guide" component={UserGuide} />

        <Route
          path="/in/transfers"
          component={() => <Redirect to="/in/transfers/dashboard" />}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
export default connect(mapStateToProps, { getTransferEligibility })(Layout);
