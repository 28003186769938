import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import { Typography, Box, Button, Menu, MenuItem } from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import { getCpdData } from "../../../../store/cpd/actions";

const Dashboard = (props) => {
  useEffect(() => {
    document.title = "TMIS | CPD - Dashboard";
  }, []);

  const { user, loading, cpdTestData, getCpdData } = props;

  useEffect(() => {
    getCpdData();
  }, []);

  return <div className="px-4">CPD | Dashboard</div>;
};

const mapStateToProps = ({ user, loading, cpdTestData }) => {
  return { user, loading, cpdTestData };
};
export default connect(mapStateToProps, { getCpdData })(Dashboard);
