import { Button } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import ErrorMessage from "../../inner/common/components/ErrorMessage";
const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const Unauthorized = (props) => {
  const {} = props;
  const history = useHistory();
  const goBack = () => {
    history.push("/");
  };
  return (
    <React.Fragment>
      <div className="container-fluid px-0">
        <div style={{ marginTop: "0px" }}>
          <div style={{ backgroundColor: "#007bff", height: "2px" }}></div>
          <div style={{ backgroundColor: "#199e05", height: "1px" }}></div>
          <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
        </div>

        <div className="d-flex justify-content-center pt-3 mx-0 px-0 text-center mt-5">
          <ErrorMessage
            hasError={true}
            message={<>You are not authorized to view this page.</>}
          />
        </div>
        <div className="d-flex justify-content-center">
          <Button
            onClick={goBack}
            style={{
              ...ActiveButton,
            }}
            size="md"
          >
            Go Back
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Unauthorized;
