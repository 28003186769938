import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { currencyFormat } from "../../common/components/Utils";

import {
  addOrUpdateTeacher,
  getShoolPositions,
} from "../../../../store/posts/actions";
import { getSectors } from "../../../../store/posts/actions";
import { getTeacherProfile } from "../../../../store/users/actions";

import { isEmpty } from "lodash";
import axios from "axios";
import { CSVLink } from "react-csv";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import moment from "moment";
// import { showError } from "../../toastify";
import defaultPofile from "../../../assets/default-profile.jpg";
// import { idNumberPattern } from "../../common/components/Utils";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import MaskedInput from "react-text-mask";
import joi from "joi";

import { Link } from "react-router-dom";
import TeacherProfile from "../components/TeacherProfile";

const personalEmailValidator = joi.object({
  email: joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ["com", "fr", "rw"] },
  }),
});

const idNumberPattern = [
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
];

const MyProfile = (props) => {
  useEffect(() => {
    document.title = "TMIS | Security - TeacherProfile";
  }, []);

  const {
    user,
    loading,
    // onWait,
    // teacher,
    // showDialog,
    // setShowDialog,
    // addOrUpdateTeacher,
    // roles,
    // districts,
    // getSectors,
    // sectors,
    // degrees,
    // specializations,
    // schoolPositions,
    // placementReport,
    // getShoolPositions,

    teacherProfile,
    getTeacherProfile,
  } = props;

  useEffect(() => {
    getTeacherProfile(user.id);
  }, []);

  // const [errors, setErrors] = useState({
  //   nidNumberHasError: false,
  //   emailHasError: false,
  //   phoneNumberHasError: false,
  //   employeeIdHasError: false,
  //   staffCodeHasError: false,
  //   employmentTypeHasError: false,
  //   bankAccountNumberHasError: false,
  //   educationCertficateHasError: false,
  //   employmentDateHasError: false,
  //   positionHasError: false,
  //   passportNumberHasError: false,
  //   hasError: false,
  // });

  // const teacherInfoFormValidator = () => {
  //   const error = {
  //     nidNumberHasError: false,
  //     emailHasError: false,
  //     phoneNumberHasError: false,
  //     employeeIdHasError: false,
  //     staffCodeHasError: false,
  //     employmentTypeHasError: false,
  //     bankAccountNumberHasError: false,
  //     educationCertficateHasError: false,
  //     employmentDateHasError: false,
  //     positionHasError: false,
  //     passportNumberHasError: false,
  //     hasError: false,
  //   };

  //   // console.log(teacherForm.nidNumber.replace(/\s/g, "").length);
  //   if (
  //     ((teacherForm.nidNumber || "").replace(/\s/g, "").length !== 16 &&
  //       (teacherForm.nidNumber || "").replace(/\s/g, "").length !== 10 &&
  //       !teacherForm.passportNumber) ||
  //     !!idHasError
  //   ) {
  //     error.nidNumberHasError = true;
  //     error.hasError = true;
  //   }

  //   if (
  //     !!teacherForm.nidNumber &&
  //     (teacherForm.nidNumber || "").replace(/\s/g, "").length !== 16 &&
  //     !teacherForm.passportNumber
  //   ) {
  //     error.passportNumberHasError = true;
  //     error.hasError = true;
  //   }

  //   if (!teacherForm.employeeId) {
  //     error.employeeIdHasError = true;
  //     error.hasError = true;
  //   }

  //   if (!teacherForm.sdmsStaffCode) {
  //     error.staffCodeHasError = true;
  //     error.hasError = true;
  //   }

  //   if ((teacherForm.phoneNumber || "").replace(/\s/g, "").length !== 10) {
  //     error.phoneNumberHasError = true;
  //     error.hasError = true;
  //   }

  //   if (
  //     !teacherForm.email ||
  //     (!!teacherForm.email &&
  //       personalEmailValidator.validate({ email: teacherForm.email }).error)
  //   ) {
  //     error.emailHasError = true;
  //     error.hasError = true;
  //   }

  //   if (!selectedEmploymentType) {
  //     error.employmentTypeHasError = true;
  //     error.hasError = true;
  //   }

  //   if (!teacherForm.bankId) {
  //     error.bankAccountNumberHasError = true;
  //     error.hasError = true;
  //   }

  //   if (teacherForm.hasEducationCertficate === "") {
  //     error.educationCertficateHasError = true;
  //     error.hasError = true;
  //   }

  //   // if (!selectedDegree) {
  //   //   error.degreeHasError = true;
  //   //   error.hasError = true;
  //   // }

  //   // if (!selectedSpecialization) {
  //   //   error.specializationHasError = true;
  //   //   error.hasError = true;
  //   // }

  //   if (
  //     !position ||
  //     (!!position &&
  //       +position?.budgetPosts <= +position?.numberOfEmployees &&
  //       position.id !== teacher.schoolPositionId)
  //   ) {
  //     error.positionHasError = true;
  //     error.hasError = true;
  //   }

  //   // if (
  //   //   (!!teacherForm.graduationDate &&
  //   //     new Date(teacherForm.graduationDate) > new Date()) ||
  //   //   !teacherForm.graduationDate
  //   // ) {
  //   //   error.graduationDateHasError = true;
  //   //   error.hasError = true;
  //   // }

  //   // if (!teacherForm.employmentDate) {
  //   //   error.employmentDateHasError = true;
  //   //   error.hasError = true;
  //   // }

  //   setErrors(error);

  //   if (error.hasError) {
  //     return true;
  //   }
  //   return false;
  // };

  // const employmentTypes = [
  //   { id: 1, name: "Permanent" },
  //   { id: 2, name: "Contractual" },
  // ];

  // const [isVerifing, setIsVerifing] = useState(false);
  // const [wasVerified, setWasVerified] = useState(teacher.isNIDVerified);
  // const [idHasError, setIdHasError] = useState(false);

  // const [selectedEmploymentType, setSelectedEmploymentType] = useState(
  //   employmentTypes.find(({ id }) => id === teacher.employmentTypeId)
  // );
  // const [selectedDegree, setSelectedDegree] = useState(null);
  // const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  // const [position, setPosition] = useState(null);

  // const [teacherForm, setTeacherForm] = useState({
  //   nidNumber: teacher.nidNumber,
  //   passportNumber: teacher.passportNumber,
  //   nin: teacher.nin,
  //   email: teacher.email,
  //   phoneNumber: teacher.phoneNumber,
  //   gender: teacher.gender,
  //   genderId: teacher.genderId,
  //   countryOfBirth: teacher.countryOfBirth,
  //   countryIdOfBirth: teacher.countryIdOfBirth,
  //   firstName: teacher.firstName,
  //   lastName: teacher.lastName,
  //   civilStatus: teacher.civilStatus,
  //   civilStatusId: teacher.civilStatusId,
  //   spouseNames: teacher.spouseNames,
  //   fatherNames: teacher.fatherNames,
  //   motherNames: teacher.motherNames,
  //   dateOfBirth: teacher.dateOfBirth,
  //   placeOfBirth: teacher.placeOfBirth,
  //   nationalityId: teacher.nationalityId,
  //   villageId: teacher.villageId,
  //   profilePicture: "",
  //   employeeId: teacher.id,
  //   rssbNumber: teacher.rssbNumber,
  //   accountNumber: teacher.accountNumber,
  //   bankId: teacher.bankId,
  //   bankName: teacher.bankName,
  //   sdmsStaffCode: teacher.sdmsStaffCode,
  //   hasEducationCertficate: teacher.hasEducationCertficate,
  //   graduationDate: !!teacher.graduationDate
  //     ? new Date(teacher.graduationDate)
  //     : null,
  //   employmentDate: !!teacher.placementDate
  //     ? new Date(teacher.placementDate)
  //     : null,

  //   appointmentDate: !!teacher.appointmentDate
  //     ? new Date(teacher.appointmentDate)
  //     : null,
  // });

  // const getNidDetails = async (nidNumber) => {
  //   try {
  //     setWasVerified(false);
  //     setIsVerifing(true);

  //     const { data } = await axios.get(
  //       "https://ippis.rw/api/search-tmis-employee/" +
  //         (nidNumber || "").replace(/\s/g, "")
  //     );

  //     setTeacherForm({
  //       ...teacherForm,
  //       nidNumber: nidNumber,
  //       nin: data.nin,
  //       gender: data.gender,
  //       genderId: data.genderId,
  //       countryOfBirth: data.countryOfBirth,
  //       countryIdOfBirth: data.countryIdOfBirth,
  //       firstName: data.firstName,
  //       lastName: data.lastName,
  //       civilStatus: data.civilStatus,
  //       civilStatusId: data.civilStatusId,
  //       spouseNames: data.spouseNames,
  //       fatherNames: data.fatherNames,
  //       motherNames: data.motherNames,
  //       dateOfBirth: data.dateOfBirth,
  //       placeOfBirth: data.placeOfBirth,
  //       nationalityId: data.nationalityId,
  //       villageId: data.villageId,
  //       profilePicture: data.profileImage,

  //       employeeId: data.employee?.id || "",
  //       rssbNumber: data.employee?.rssbNumber || "",
  //       accountNumber: data.employee?.accountNumber || "",
  //       employmentDate: data.employee?.employmentData || null,
  //       bankId: data.employee?.bankId || "",
  //       bankName: data.employee?.bankName || "",
  //     });

  //     setIdHasError(false);
  //     setWasVerified(true);
  //     setIsVerifing(false);
  //   } catch (error) {
  //     setIdHasError(true);
  //     setWasVerified(false);
  //     setIsVerifing(false);
  //   }
  // };

  const onSave = () => {
    // if (!teacherInfoFormValidator())
    //   addOrUpdateTeacher(
    //     {
    //       id: teacherForm.employeeId,
    //       lastName: teacherForm.lastName || null,
    //       firstName: teacherForm.firstName || null,
    //       email: teacherForm.email?.trim(),
    //       phoneNumber: teacherForm.phoneNumber?.trim(),
    //       nidNumber: (teacherForm.nidNumber || "").replace(/\s/g, "") || null,
    //       passportNumber: teacherForm.passportNumber || null,
    //       nin: teacherForm.nin || null,
    //       gender: teacherForm.genderId || null,
    //       dateOfBirth: teacherForm.dateOfBirth || null,
    //       placeOfBirth: teacherForm.placeOfBirth || null,
    //       fatherNames: teacherForm.fatherNames || null,
    //       motherNames: teacherForm.motherNames || null,
    //       civilStatusId: teacherForm.civilStatusId || null,
    //       spouseNames: teacherForm.spouseNames || null,
    //       countryOfBirth: teacherForm.countryIdOfBirth || null,
    //       nationalityId: teacherForm.nationalityId || null,
    //       residenceVillageId: teacherForm.villageId || null,
    //       bankId: teacherForm.bankId || null,
    //       bankAccountNumber: teacherForm.accountNumber || null,
    //       rssbNumber: teacherForm.rssbNumber || null,
    //       sdmsStaffCode: teacherForm.sdmsStaffCode || null,
    //       hasEducationCertficate: teacherForm.hasEducationCertficate,
    //       schoolPositionId: position?.id,
    //       currentSchoolPositionId: teacher?.schoolPositionId || null,
    //       employmentTypeId: selectedEmploymentType.id,
    //       degreeId: selectedDegree?.id || null,
    //       specializationId: selectedSpecialization?.id || null,
    //       graduationDate: teacherForm.graduationDate || null,
    //       employmentDate: teacherForm.employmentDate || null,
    //       appointmentDate: teacherForm.appointmentDate,
    //       isUpdate: true,
    //       placementReportId: placementReport?.id || null,
    //     },
    //     onClose
    //   );
  };

  // useEffect(async () => {
  //   setPosition(
  //     schoolPositions.find(({ id }) => id === teacher.schoolPositionId)
  //   );
  // }, [teacher, schoolPositions]);

  // useEffect(async () => {
  //   setPosition(
  //     schoolPositions.find(({ id }) => id === teacher.schoolPositionId)
  //   );

  //   setSelectedEmploymentType(
  //     employmentTypes.find(({ id }) => id === teacher.employmentTypeId)
  //   );

  //   setSelectedDegree(degrees.find(({ id }) => id === teacher.degreeId));

  //   setSelectedSpecialization(
  //     specializations.find(({ id }) => id === teacher.specializationId)
  //   );

  //   if (!!teacher.nidNumber) {
  //     try {
  //       const { data } = await axios.get(
  //         "https://ippis.rw/api/search-tmis-employee/" +
  //           (teacher.nidNumber || "").replace(/\s/g, "")
  //       );

  //       setTeacherForm({
  //         ...teacherForm,
  //         profilePicture: data.profileImage || "",
  //         employmentDate: data.employee?.employmentData || null,
  //       });
  //     } catch (error) {}
  //   }
  // }, [teacher]);

  // useEffect(() => {
  //   getShoolPositions("SCHOOL", teacher.schoolId);
  // }, []);

  return (
    <div className="px-4 pt-2">
      <div
        className="d-flex border align-items-center justify-content-center flex-column"
        style={{
          // height: "74vh",
          backgroundColor: "#f3f4f6",
          // overflowY: "auto",
        }}
      >
        <TeacherProfile />
      </div>
    </div>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  placementReportDetails,
  changeList,
  prevPlacementReport,
  signatories,
  roles,
  districts,
  sectors,
  degrees,
  specializations,
  schoolPositions,
  teacherProfile,
}) => {
  return {
    user,
    loading,
    onWait,
    placementReportDetails,
    changeList,
    prevPlacementReport,
    signatories,
    roles,
    districts,
    sectors,
    degrees,
    specializations,
    schoolPositions,
    teacherProfile,
  };
};
export default connect(mapStateToProps, {
  addOrUpdateTeacher,
  getSectors,
  getShoolPositions,
  getTeacherProfile,
})(MyProfile);
