import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  Button,
  Checkbox,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  saveOrUpdateSchool,
  getCells,
  getVillages,
  getSectors,
} from "../../../../store/posts/actions";
import { showError } from "../../../../app/toastify";

const schoolCategories = [
  {
    id: 1,
    name: "Public",
  },
  {
    id: 2,
    name: "Private",
  },
  ,
  {
    id: 3,
    name: "Government aided",
  },
];

const AddOrUpdateSchoolDialog = (props) => {
  const {
    loading,
    isEditing,
    unitTypes,
    // units,
    setIsEditing,
    showDialog,
    setShowDialog,
    parentUnit,
    selectedTreeItem,
    saveOrUpdateSchool,
    expandedNodes,
    setExpandedNodes,

    user,
    formData,
    setFormData,
    cells,
    villages,
    getCells,
    getVillages,
    schoolLevels,
    sectors,
    getSectors,
  } = props;

  const [unitType, setUnitType] = useState(null);
  const [unit, setUnit] = useState(null);

  const [errors, setErrors] = useState({
    sdmsCodeHasError: false,
    nameHasError: false,
    schoolLevelHasError: false,
    schoolCategoryHasError: false,
    cellHasError: false,
    villageHasError: false,
    ippisUnitIdHasError: false,
  });

  const formValidator = () => {
    const error = {
      sdmsCodeHasError: false,
      nameHasError: false,
      schoolLevelHasError: false,
      schoolCategoryHasError: false,
      cellHasError: false,
      villageHasError: false,
      ippisUnitIdHasError: false,
      hasError: false,
    };

    if (!formData.sdmsSchoolCode) {
      error.sdmsCodeHasError = true;
      error.hasError = true;
    }

    if (!formData.name) {
      error.nameHasError = true;
      error.hasError = true;
    }

    if (!formData.schoolCategoryId) {
      error.schoolCategoryHasError = true;
      error.hasError = true;
    }

    if (!formData.schoolLevelId) {
      error.schoolLevelHasError = true;
      error.hasError = true;
    }

    if (!formData.cellId) {
      error.cellHasError = true;
      error.hasError = true;
    }

    if (!formData.villageId) {
      error.villageHasError = true;
      error.hasError = true;
    }

    // if (!formData.ippisUnitId) {
    //   error.ippisUnitIdHasError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onClose = () => {
    setShowDialog(false);
    setIsEditing(false);

    setFormData({
      unitId: null,
      unitName: "",
      parentUnitId: "",
      schoolLevelId: 0,
      schoolCategoryId: 1,
      // isLikeTTC: false,
    });
  };

  const [schoolLevel, setSchoolLevel] = useState(null);
  const [schoolCategory, setSchoolCategory] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [selectedVillage, setSelectedVillage] = useState(null);

  // useEffect(() => {
  //   if (!!selectedSector?.id) getCells(selectedSector.id);
  // }, [selectedSector]);

  useEffect(() => {
    setSchoolCategory(
      schoolCategories.find((c) => !!c && c.id === formData.schoolCategoryId)
    );
  }, [showDialog]);

  useEffect(() => {
    if (!!formData.cellId && !!cells.length)
      setSelectedCell(cells.find(({ id }) => id === formData.cellId));
  }, [cells]);

  useEffect(() => {
    if (!!formData.villageId && !!villages.length)
      setSelectedVillage(villages.find(({ id }) => id === formData.villageId));
  }, [villages]);

  useEffect(() => {
    if (!!formData.schoolLevelId)
      setSchoolLevel(
        schoolLevels.find(({ id }) => id === formData.schoolLevelId)
      );

    if (!!selectedTreeItem?.id || !!formData.sectorId)
      getCells(isEditing ? formData.sectorId : selectedTreeItem.id);
  }, [showDialog]);

  useEffect(() => {
    if (!!selectedCell?.id) getVillages(selectedCell.id);
  }, [selectedCell]);

  const onSave = () => {
    if (!formValidator()) {
      saveOrUpdateSchool(
        {
          ...formData,
          sectorId: isEditing ? formData?.sectorId : selectedTreeItem?.id,
        },
        setFormData,
        setIsEditing,
        setShowDialog,
        // expandedNodes,
        // setExpandedNodes,
        // units,
        isEditing
      );
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-dark font-weight-bold">
              {isEditing ? "Edit School | " : "Add New School in"}
            </span>{" "}
            {isEditing
              ? formData.name
              : selectedTreeItem?.name + " " + selectedTreeItem?.type}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <FormControl
              size="small"
              fullWidth
              variant="outlined"
              className=" bg-white"
            >
              <InputLabel htmlFor="sdmsSchoolCode">SDMS School Code</InputLabel>
              <OutlinedInput
                placeholder="SDMS School Code"
                name="sdmsSchoolCode"
                type="text"
                value={formData.sdmsSchoolCode}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    sdmsSchoolCode: e.target.value,
                  });
                  setErrors({ ...errors, sdmsCodeHasError: false });
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="primary" onClick={() => {}} edge="end">
                      <span className="material-icons">pageview</span>
                    </IconButton>
                  </InputAdornment>
                }
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <IconButton color="primary" onClick={() => {}} edge="end">
                //         <span className="material-icons">pageview</span>
                //       </IconButton>
                //     </InputAdornment>
                //   ),
                // }}
                label="SDMS School Code"
              />
              {errors.sdmsCodeHasError && (
                <small className="text-danger ">
                  SDMS School Code is required{" "}
                </small>
              )}
            </FormControl>
          </div>
          <div className="mt-3">
            <TextField
              fullWidth
              size="small"
              rows={4}
              name="schoolName"
              label="School name"
              variant="outlined"
              placeholder="School name"
              value={formData.name}
              onChange={(e) => {
                setErrors({ ...errors, nameHasError: false });

                setFormData({ ...formData, name: e.target.value });
              }}
            />
            {errors.nameHasError && (
              <small className="text-danger ">School name is required </small>
            )}
          </div>

          <div className="mt-3">
            <Autocomplete
              size="small"
              defaultValue={null}
              value={schoolCategory || null}
              options={schoolCategories}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, category) => {
                setErrors({ ...errors, schoolCategoryHasError: false });
                setSchoolCategory(category || null);

                setFormData({
                  ...formData,
                  schoolCategoryId: category.id || null,
                });
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, category) => (
                <Box component="li" {...props}>
                  {category.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="School status"
                  name="schoolCategoryId"
                  placeholder="School status"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />

            {errors.schoolCategoryHasError && (
              <small className="text-danger ">School status is required </small>
            )}
          </div>

          <div className="mt-3">
            <Autocomplete
              size="small"
              defaultValue={null}
              value={schoolLevel || null}
              options={schoolLevels}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, level) => {
                setErrors({ ...errors, schoolLevelHasError: false });
                setSchoolLevel(level || null);

                setFormData({
                  ...formData,
                  schoolLevelId: level.id || null,
                });
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, level) => (
                <Box component="li" {...props}>
                  {level.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="School category"
                  name="level"
                  placeholder="School category"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />

            {errors.schoolLevelHasError && (
              <small className="text-danger ">
                School category is required{" "}
              </small>
            )}
          </div>

          <div>
            <Autocomplete
              className="mt-3"
              size="small"
              defaultValue={null}
              value={selectedCell || null}
              options={cells}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, cell) => {
                setErrors({ ...errors, cellHasError: false });
                setSelectedCell(cell || null);
                setSelectedVillage(null);

                setFormData({
                  ...formData,
                  cellId: cell.id || null,
                });
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, cell) => (
                <Box component="li" {...props}>
                  {cell.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cell"
                  name="cell"
                  placeholder="Cell"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />

            {errors.cellHasError && (
              <small className="text-danger ">Cell is required </small>
            )}
          </div>
          <div className="mt-3">
            <Autocomplete
              size="small"
              defaultValue={null}
              value={selectedVillage || null}
              options={villages}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, village) => {
                setErrors({ ...errors, villageHasError: false });
                setSelectedVillage(village || null);

                setFormData({
                  ...formData,
                  villageId: village.id || null,
                });
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, village) => (
                <Box component="li" {...props}>
                  {village.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Village"
                  name="village"
                  placeholder="Village"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />

            {errors.villageHasError && (
              <small className="text-danger ">village is required </small>
            )}
          </div>
          {/* <div className="mt-3">
            <FormControl
              size="small"
              fullWidth
              variant="outlined"
              className="bg-white"
            >
              <InputLabel htmlFor="ippisUnitId">IPPIS Unit ID</InputLabel>
              <OutlinedInput
                placeholder="IPPIS Unit ID"
                id="ippisUnitId"
                name="ippisUnitId"
                type="text"
                value={formData.ippisUnitId}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    ippisUnitId: e.target.value,
                  });
                  setErrors({ ...errors, ippisUnitIdHasError: false });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton color="primary" onClick={() => {}} edge="end">
                        <span className="material-icons ">pageview</span>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="IPPIS Unit ID"
              />
              {errors.ippisUnitIdHasError && (
                <small className="text-danger ">
                  IPPIS Unit ID is required{" "}
                </small>
              )}
            </FormControl>
          </div> */}
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={formData.isLikeTTC}
                onChange={(e) =>
                  setFormData({ ...formData, isLikeTTC: e.target.checked })
                }
              />
            }
            label="Marked As TTC"
          /> */}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            variant="contained"
            onClick={onSave}
            type="button"
            className="px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  unitTypes,
  // units,
  user,
  sectors,
  cells,
  villages,
  schoolLevels,
}) => {
  return {
    loading,
    onWait,
    unitTypes,
    // units,
    user,
    sectors,
    cells,
    villages,
    schoolLevels,
  };
};
export default connect(mapStateToProps, {
  saveOrUpdateSchool,
  getCells,
  getVillages,
  getSectors,
})(AddOrUpdateSchoolDialog);
