import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import supportStaff from "../../assets/support_staff.json";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import SearchBox from "../../inner/common/components/SearchBox";

const SupportStaff = (props) => {
  const { showSupportStaffDialog, setShowSupportStaffDialog } = props;

  const [filteredSupportStaff, setFilteredSupportStaff] = useState(
    supportStaff.filter(({ category }) => category === "SUPPORT")
  );
  const [supervisors, setSupervisors] = useState(
    supportStaff.filter(({ category }) => category === "SUPERVISOR")
  );

  const [searchTerm, setSearchTerm] = useState("");

  const onSearch = (term) => {
    setSearchTerm(term);
  };

  useEffect(() => {
    const filtered = supportStaff
      .filter(({ category }) => category === "SUPPORT")
      .filter(
        ({ names, phone, districts }) =>
          names.toLowerCase().includes(searchTerm.toLowerCase()) ||
          phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
          districts.findIndex((district) =>
            district.toLowerCase().includes(searchTerm.toLowerCase())
          ) >= 0
      );

    setFilteredSupportStaff(filtered);
  }, [searchTerm]);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      onClose={() => {
        setShowSupportStaffDialog(false);
      }}
      open={showSupportStaffDialog}
    >
      <DialogTitle className={`text-primary`}>
        <div className="row pt-3 mt-3 mx-0 pr-2">
          <div className="col-12 ">
            <div
              className="row mx-0 bg-white border elevated-0"
              style={{ borderRadius: "8px" }}
            >
              <div className="col-12  px-3 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <span
                    className="p-1 mr-2"
                    style={{
                      backgroundColor: "#fef0e0",
                      borderRadius: "5px",
                    }}
                  >
                    <span
                      className="material-icons"
                      style={{ fontSize: "42px", color: "#b76103" }}
                    >
                      my_location
                    </span>
                  </span>
                  <div className="py-2">
                    <div
                      style={{ fontSize: "1.5rem", lineHeight: "2rem" }}
                      className="font-weight-bold"
                    >
                      Allocation of TMIS support staff
                    </div>
                    <div>Check for your assigned district support staff</div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-3">
                <SearchBox
                  autoFocus={true}
                  placeholder="Search…"
                  onSearch={onSearch}
                />
              </div>
            </div>
          </div>
        </div>
        <IconButton
          aria-label="close"
          onClick={() => {
            setShowSupportStaffDialog(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="">
          <div className="row pt-3 mx-0">
            {filteredSupportStaff.map((staff, index) => (
              <div className="col-12 mt-3  support-staff" key={index}>
                <div
                  className="row mx-0 bg-white border elevated-0  py-2"
                  style={{ borderRadius: "8px" }}
                >
                  <div className="col-5">
                    {staff.districts.map((district, index) => (
                      <div
                        key={index}
                        className="py-2 px-3 my-1 bg"
                        style={{
                          backgroundColor: "#f5f5f5",
                          borderRadius: "8px",
                          color: "#000",
                          fontSize: "bold",
                        }}
                      >
                        {district}
                      </div>
                    ))}
                  </div>
                  <div className="col-7 d-flex align-items-center">
                    <span
                      className="p-2 d-inline-flex mr-3 bg"
                      style={{
                        backgroundColor: "#f4f4f4",
                        borderRadius: "50%",
                      }}
                    >
                      <span
                        className="material-icons"
                        style={{ fontSize: "42px" }}
                      >
                        support_agent
                      </span>
                    </span>
                    <div>
                      <div
                        className="user"
                        style={{ fontSize: "1.75rem", color: "#4b5563" }}
                      >
                        {staff.names}
                      </div>
                      <div
                        style={{
                          fontWeight: "700",
                          fontSize: "1.125rem",
                          lineHeight: "1.75rem",
                        }}
                      >
                        <a
                          className="contact"
                          href={`tel:+25${staff.phone}`}
                          style={{
                            textDecoration: "none",
                            color: "#4b5563",
                            lineHeight: "1.75rem",
                          }}
                        >
                          Contact: {staff.phone}
                        </a>
                      </div>
                      <div className="mt-2">
                        <a
                          href={`tel:+25${staff.phone}`}
                          style={{
                            textDecoration: "none",
                            color: "gray",
                            fontWeight: "700",
                            backgroundColor: "#f0f2f4",
                            borderRadius: "8px",
                          }}
                          className="py-1 px-3 ico"
                        >
                          Call me now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="col-12 mt-3 mb-0">
              <div
                className="bg-white px-3"
                style={{
                  fontWeight: "800",
                  fontSize: "1.5rem",
                  lineHeight: "2rem",
                  color: "#015198",
                }}
              >
                The supervisor
                <Divider />
              </div>
            </div>

            {supervisors.map((supervisor, index) => (
              <div className="col-12 mt-0" key={index}>
                <div className="bg-white  py-3 px-3 d-flex align-items-center">
                  <span
                    className="p-2 d-inline-flex mr-3"
                    style={{ backgroundColor: "#015198", borderRadius: "50%" }}
                  >
                    <span
                      className="material-icons"
                      style={{ fontSize: "42px", color: "#fff" }}
                    >
                      support_agent
                    </span>
                  </span>
                  <div>
                    <div
                      className="user"
                      style={{ fontSize: "1.75rem", color: "#4b5563" }}
                    >
                      {supervisor.names}
                    </div>
                    <div
                      style={{
                        fontWeight: "700",
                        fontSize: "1.125rem",
                        lineHeight: "1.75rem",
                      }}
                    >
                      <a
                        className="contact"
                        href={`tel:+25${supervisor.phone}`}
                        style={{
                          textDecoration: "none",
                          color: "#4b5563",
                          lineHeight: "1.75rem",
                        }}
                      >
                        Contact: {supervisor.phone}
                      </a>
                    </div>
                    <div className="mt-2 pb-3">
                      <a
                        href={`tel:+25${supervisor.phone}`}
                        style={{
                          textDecoration: "none",
                          color: "#fff",
                          fontWeight: "700",
                          backgroundColor: "#015198",
                          borderRadius: "8px",
                        }}
                        className="py-2 px-3 ico"
                      >
                        Call me now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center py-4"></DialogActions>
    </Dialog>
  );
};

export default SupportStaff;
