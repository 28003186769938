import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  Divider,
  TextField,
  Tooltip,
} from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  getSystemUsers,
  removeSystemUser,
} from "../../../../store/users/actions";
import ReactPaginate from "react-paginate";
// import PostRequestDetailsDialog from "../components/PostRequestDetailsDialog";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import NewUserDialog from "../components/NewUserDialog";
import PasswordRessetDialog from "../components/PasswordRessetDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import UpdateUserDialog from "../components/UpdateUserDialog";
import { CSVLink } from "react-csv";

const SystemUsers = (props) => {
  useEffect(() => {
    document.title = "TMIS | System Users";
  }, []);

  const { user, loading, systemUsers, getSystemUsers, removeSystemUser } =
    props;
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredItems, setFilteredItems] = useState([...systemUsers]);

  useEffect(() => {
    getSystemUsers();
  }, [user?.selectedEntity]);

  const onSearch = (term) => {
    setSearchTerm(term);
  };

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredItems);
  }, [itemOffset, itemsPerPage, filteredItems]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredItems.length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  const [showUserDialog, setShowUserDialog] = useState(false);

  useEffect(() => {
    const filtered = [...systemUsers].filter(
      ({
        names,
        nidNumber,
        phoneNumber,
        email,
        schoolName,
        sectorName,
        positionName,
        role,
      }) =>
        (
          (names || "") +
          (nidNumber || "") +
          (phoneNumber || "") +
          (email || "") +
          (schoolName || "") +
          (sectorName || "") +
          (positionName || "") +
          (role || "")
        )
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(searchTerm.toLowerCase().replace(/\s/g, ""))
    );
    setFilteredItems(filtered);

    paginate(filtered);
  }, [searchTerm, systemUsers]);

  return (
    <div className="px-4 pt-2">
      <div className="border rounded">
        <AppBar position="static" elevation={0} className="app-bar w-100">
          <Toolbar
            style={{
              minHeight: "0px",
              borderBottom: "3px solid #e9ecef",
            }}
            className=" my-0 py-1 bg-light"
          >
            <Typography
              variant="p"
              noWrap
              component="div"
              className="d-flex  align-items-center text-uppercase text-primary font-weight-bold"
            >
              <span className="text-dark ">
                ({systemUsers.length}) System Users
              </span>
              <span className="material-icons" style={{ color: "#cecece" }}>
                chevron_right
              </span>

              <span
                className="d-block text-truncate"
                style={{ maxWidth: "70%" }}
              >
                {user?.selectedEntity?.name}{" "}
                {!!user?.selectedEntity?.type &&
                !(user?.selectedEntity?.name)
                  .toUpperCase()
                  .includes(user?.selectedEntity?.type)
                  ? user?.selectedEntity?.type
                  : ""}
                {user?.selectedEntity?.id === "00000000" && <>S</>}
              </span>
              <CSVLink
                data={systemUsers}
                filename={`Staff list - ${user.selectedEntity?.name}.csv`}
                className="hidden"
                id="downloadBTN"
                target="_blank"
              />
              <Tooltip title="Download">
                <span className="ml-2">
                  <IconButton
                    size="small"
                    disabled={loading || !systemUsers.length}
                    className=" mr-1"
                    color="primary"
                    onClick={() =>
                      document.getElementById("downloadBTN").click()
                    }
                  >
                    <span
                      className="material-icons"
                      // style={{ fontSize: "28px" }}
                    >
                      file_download
                    </span>
                  </IconButton>
                </span>
              </Tooltip>
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              className="mr-2"
              disabled={loading}
              onClick={() => {
                setShowUserDialog(true);
              }}
              variant="contained"
              size="small"
              disableElevation
              color="primary"
              style={{
                borderRadius: "8px",
                height: "32px",
                alignItems: "center",
                fontSize: ".75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "initial",
              }}
            >
              <span className="material-icons">add</span>
              New User
            </Button>
            <Box sx={{ display: { xs: "flex", md: "flex" } }}>
              <SearchBox onSearch={onSearch} placeholder="Search…" />
            </Box>
          </Toolbar>
        </AppBar>

        <div
          style={{
            backgroundColor: "#f3f4f6",
            borderRadius: "0.5rem",
            boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
            MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            height: "59vh",
            overflowY: "auto",
          }}
          className="px-3"
        >
          <table className="table table-striped table-hover fixTableHead">
            {!!filteredItems.length && (
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col">#</th>

                  <th scope="col" className="text-left">
                    EmployeeId
                  </th>

                  <th scope="col" className="text-left">
                    Staff Code
                  </th>

                  <th scope="col">Names</th>

                  <th scope="col" className="text-left">
                    NID Number
                  </th>

                  <th scope="col" className="text-left">
                    Email
                  </th>

                  <th scope="col" className="text-left">
                    Phone Number
                  </th>

                  {user?.selectedEntity?.id === "00000000" && (
                    <th scope="col">DISTRICT</th>
                  )}

                  {user?.selectedEntity?.type === "DISTRICT" &&
                    user?.selectedEntity?.id !== "00000000" && (
                      <th scope="col">Sector</th>
                    )}

                  {user?.selectedEntity?.id !== "00000000" && (
                    <th scope="col">School</th>
                  )}

                  {user?.selectedEntity?.id !== "00000000" && (
                    <th scope="col">School Level</th>
                  )}

                  <th scope="col" className="text-left">
                    Role
                  </th>

                  <th scope="col" className="text-center">
                    Status
                  </th>

                  <th scope="col" className="text-right"></th>
                  {/* <th scope="col" className="text-right"></th> */}
                </tr>
              </thead>
            )}

            {!!paginatedItems && (
              <tbody>
                {paginatedItems.map((systemUser) => (
                  <SystemUserCard
                    key={systemUser.id}
                    index={getNo(filteredItems, "id", systemUser.id)}
                    user={systemUser}
                    selectedEntity={props.user.selectedEntity || undefined}
                    removeSystemUser={removeSystemUser}
                  />
                ))}
              </tbody>
            )}
          </table>

          {!paginatedItems?.length && (
            <div className="jumbotron jumbotron-fluid text-center">
              <div className="container py-5 ">
                <p className="lead">
                  {loading ? "Fetching data..." : "No record found"}{" "}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {!!filteredItems.length && (
        <div className="mt-3">
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <>
                <span className="pr-1 d-none d-md-inline">Next</span>
                <i className="fas fa-angle-double-right"></i>
              </>
            }
            previousLabel={
              <>
                <i className="fas fa-angle-double-left"></i>
                <span className="pl-1  d-none d-md-inline">Previous</span>
              </>
            }
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
            previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
            nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
            activeLinkClassName="active"
          />
        </div>
      )}
      {showUserDialog && (
        <NewUserDialog
          showDialog={showUserDialog}
          setShowDialog={setShowUserDialog}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ user, loading, systemUsers }) => {
  return { user, loading, systemUsers };
};

export default connect(mapStateToProps, {
  getSystemUsers,
  removeSystemUser,
})(SystemUsers);

const SystemUserCard = (props) => {
  const { user, index, selectedEntity, removeSystemUser } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showPasswordRessetDialog, setShowPasswordRessetDialog] =
    useState(false);

  const [showConfirmRemoveUserDialog, setShowConfirmRemoveUserDialog] =
    useState(false);

  const [showUpdateUserDialog, setShowUpdateUserDialog] = useState(false);

  return (
    <>
      <tr className={``}>
        <th scope="row" className="text-left">
          {index + 1}
        </th>

        <td className="text-left">{user.userId}</td>
        <td className="text-left">{user.staffCode}</td>
        <td className="text-left">{user.names}</td>
        <td className="text-left">
          <span className="d-flex align-items-center">
            {user.nidNumber}

            {!!user.nidNumber && !!user.isNIDVerified && (
              <Tooltip title="Verified">
                <span
                  className="material-icons ml-1 text-success"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                >
                  verified
                </span>
              </Tooltip>
            )}

            {!!user.nidNumber && !user.isNIDVerified && (
              <Tooltip title="Not Verfied">
                <span
                  className="material-icons ml-1 text-danger"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                >
                  report_problem
                </span>
              </Tooltip>
            )}
          </span>
        </td>

        <td className="text-left">{user.email}</td>

        <td className="text-left">{user.phoneNumber}</td>

        {selectedEntity?.id === "00000000" && <td>{user.districtName}</td>}
        {selectedEntity?.type === "DISTRICT" &&
          selectedEntity?.id !== "00000000" && <td>{user.sectorName}</td>}
        {selectedEntity?.id !== "00000000" && <td>{user.schoolName}</td>}
        {selectedEntity?.id !== "00000000" && <td>{user.schoollevelName}</td>}

        <td className="text-left">{user.role}</td>

        <td className="text-center">
          <span
            className={`badge badge-${
              user.status === "Active" ? "success" : "danger"
            } `}
          >
            {user.status}
          </span>
        </td>

        <td className="text-right py-1">
          <IconButton
            size="small"
            className="p-0"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {user.role !== "REB" &&
              user.role !== "ADMIN" &&
              !!user.userEntityId && (
                <span>
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      setShowUpdateUserDialog(true);
                    }}
                    className="text-primary font-weight-bolder"
                  >
                    <span className="material-icons mr-1">edit_note</span>
                    Update
                  </MenuItem>
                </span>
              )}

            {
              <span>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setShowPasswordRessetDialog(true);
                  }}
                  className="text-dark"
                >
                  <span className="material-icons mr-1">lock_open</span>
                  Reset password
                </MenuItem>
              </span>
            }
            {user.role !== "REB" &&
              user.role !== "ADMIN" &&
              !!user.userEntityId && (
                <span>
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      setShowConfirmRemoveUserDialog(true);
                    }}
                    className="text-danger"
                  >
                    <span className="material-icons mr-1">delete_forever</span>
                    Remove
                  </MenuItem>
                </span>
              )}
          </Menu>
        </td>
      </tr>

      {showPasswordRessetDialog && (
        <PasswordRessetDialog
          showDialog={showPasswordRessetDialog}
          setShowDialog={setShowPasswordRessetDialog}
          user={user}
        />
      )}

      {showUpdateUserDialog && (
        <UpdateUserDialog
          showDialog={showUpdateUserDialog}
          setShowDialog={setShowUpdateUserDialog}
          userToUpdate={user}
        />
      )}

      {showConfirmRemoveUserDialog && (
        <ConfirmationDialog
          confirmationDialog={showConfirmRemoveUserDialog}
          message={
            <>
              <p className="mb-0 mt-3">
                Are you sure you want to{" "}
                <strong className="text-danger">remove</strong>{" "}
                <strong className="text-primary">{user.names}</strong> from TMIS
                System Users?
              </p>
            </>
          }
          setConfirmationDialog={setShowConfirmRemoveUserDialog}
          onYes={() => {
            removeSystemUser(
              {
                userEntityId: user.userEntityId,
                role: user.role,
              },
              setShowConfirmRemoveUserDialog
            );
          }}
        />
      )}
    </>
  );
};
