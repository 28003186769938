import { defaultState } from "./state";
import cpd from "./action-types";
import commonTypes from "../common/action-types";

const types = { ...commonTypes, ...cpd };

const cpdTestData = (cpdTestDataState = defaultState.cpdTestData, action) => {
  switch (action.type) {
    case types.SET_CPD_DATA_TEST:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.cpdTestData;

    default:
      return cpdTestDataState;
  }
};

export default {
  cpdTestData,
  // ADD OTHER REDUCERS HERE //
};
