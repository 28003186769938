import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import axios from "axios";
import moment from "moment";
import defaultPofile from "../../../assets/default-profile.jpg";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import MaskedInput from "react-text-mask";
import joi from "joi";

import {
  addOrUpdateReplacementStaff,
  getReplacementStaffProfile,
} from "../../../../store/maternity-leave/actions";

const personalEmailValidator = joi.object({
  email: joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ["com", "fr", "rw"] },
  }),
});

const idNumberPattern = [
  /[1-3]/,
  " ",
  /[1-2]/,
  /[0|9]/,
  /\d/,
  /\d/,
  " ",
  /[7-8]/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
];

const AddNewTeacherDialog = (props) => {
  const {
    user,
    loading,
    onWait,
    teacher,
    showDialog,
    setShowDialog,
    degrees,
    specializations,
    schoolPosition,

    request,
    banks,

    addOrUpdateReplacementStaff,

    getReplacementStaffProfile,
  } = props;

  const onClose = () => {
    setShowDialog(false);
  };

  const [wasVerified, setWasVerified] = useState(false);

  const [teacherForm, setTeacherForm] = useState({
    nidNumber: "",
    nin: "",
    email: "",
    phoneNumber: "",
    gender: "",
    genderId: "",
    countryOfBirth: "",
    countryIdOfBirth: "",
    firstName: "",
    lastName: "",
    civilStatus: "",
    civilStatusId: "",
    spouseNames: "",
    fatherNames: "",
    motherNames: "",
    dateOfBirth: "",
    placeOfBirth: "",
    nationalityId: "",
    villageId: "",
    profilePicture: "",
    employeeId: "",
    rssbNumber: "",
    accountNumber: "",
    bankId: "",
    bankName: "",
    degreeId: null,
    specializationId: null,
    hasEducationCertficate: "",
    graduationDate: null,
  });

  useEffect(() => {
    if (!!request.actingStaffId)
      getReplacementStaffProfile(request.actingStaffId, (data) => {
        setTeacherForm({
          ...teacherForm,
          ...data,
        });
        setWasVerified(data.isNIDVerified);
      });
  }, []);

  // const getReplacementStaffProfile = async (actingStaffId) => {
  //   try {
  //     const { data } = await axios.get(
  //       "/api/maternity-leave/replacement-staff/" + actingStaffId
  //     );

  //     setTeacherForm({
  //       ...teacherForm,
  //       ...data,
  //     });

  //     setWasVerified(data.isNIDVerified);
  //   } catch (error) {}
  // };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary pb-0 mb-0 text-uppercase border">
          <span className="d-flex align-items-center mb-2">
            <Typography className="d-flex align-items-center text-primary">
              <span className="text-dark mr-1">Add Replacement Staff</span>
            </Typography>
          </span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="px-0">
          <div className="mt-0" style={{ height: "100%" }}>
            <div
              style={{
                display: "table-cell",
                width: "100vw",
                height: "45vh",
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              <div className="row mx-0">
                <div className="col-sm-12">
                  <React.Fragment>
                    <div>
                      <div className="row no-gutters">
                        <div className="col-2 justify-content-center  text-center">
                          <div
                            style={{
                              minHeight: "150px",
                              overflow: "hidden",
                            }}
                          >
                            <>
                              <img
                                className="mt-0 rounded  text-center"
                                src={
                                  !!teacherForm.profilePicture
                                    ? "data:image/jpg;base64," +
                                      teacherForm.profilePicture
                                    : defaultPofile
                                }
                                style={{
                                  border: "1px solid #a7a7a7",
                                  height: "150px",
                                  width: "120px",
                                  overflow: "hidden",
                                  borderRadius: "50%",
                                }}
                              />
                            </>
                          </div>

                          <div className="text-primary">
                            <strong>NID PHOTO</strong>{" "}
                          </div>
                          <label className="text-center text-uppercase  mt-n2">
                            <span style={{ fontSize: "12px" }}>
                              {teacherForm?.lastName} {teacherForm?.firstName}
                            </span>
                          </label>

                          <FormControl
                            className=" d-block border rounded pt-2"
                            style={{ backgroundColor: "#eee" }}
                          >
                            <FormLabel className="text-primary  px-2">
                              Education certficate
                            </FormLabel>
                            <RadioGroup
                              className="mt-0 d-flex justify-content-start px-4"
                              row
                              name="hasEducationCertficate"
                              value={teacherForm?.hasEducationCertficate}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio disableRipple size="small" />}
                                label="Certfied"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio disableRipple size="small" />}
                                label="Not certfied"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>

                        <div className="col-10 row mx-0 d-flex">
                          <div className="col-4 justify-content-center">
                            {!wasVerified && (
                              <span className="material-icons loaderIcon text-danger">
                                help
                              </span>
                            )}

                            {!!wasVerified && (
                              <span className="loaderIcon text-success d-flex align-items-center">
                                <span className="material-icons text-success ">
                                  verified
                                </span>
                                {/* <span style={{ fontSize: "10px" }}>
                                  Verified
                                </span> */}
                              </span>
                            )}

                            <MaskedInput
                              style={{ backgroundColor: "#e9ecef" }}
                              onKeyDown={(e) => e.preventDefault()}
                              mask={idNumberPattern}
                              className="form-control "
                              placeholder="NID Number"
                              guide={false}
                              value={teacherForm.nidNumber}
                            />
                          </div>

                          <div className="col-4 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="lastName"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Last Name"
                              variant="outlined"
                              className=""
                              value={teacherForm.lastName}
                            />
                          </div>
                          <div className="col-4  text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="firstName"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="First Name"
                              variant="outlined"
                              className=""
                              value={teacherForm.firstName}
                            />
                          </div>

                          <div className="col-2 mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="civilStatus"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Civil Status"
                              variant="outlined"
                              value={teacherForm.civilStatus}
                            />
                          </div>
                          <div className="col-2 mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="gender"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Gender"
                              variant="outlined"
                              className=" font-weight-bold"
                              value={teacherForm.gender}
                            />
                          </div>

                          <div className="col-4  mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="dateOfBirth"
                              value={
                                !!teacherForm.dateOfBirth
                                  ? moment(teacherForm.dateOfBirth).format(
                                      "DD/MM/yyyy"
                                    )
                                  : ""
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Date Of Birth"
                              variant="outlined"
                            />
                          </div>
                          <div className="col-4  mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="countryOfBirth"
                              value={teacherForm.countryOfBirth}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Country of Birth"
                              variant="outlined"
                              className=""
                            />
                          </div>
                          <div className="col-4  mt-2 text-left">
                            <TextField
                              InputProps={{
                                readOnly: true,
                              }}
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="placeOfBirth"
                              value={teacherForm.placeOfBirth}
                              label="Place of Birth"
                              variant="outlined"
                              className=""
                            />
                          </div>

                          <div className="col-4  mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="nationality"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Nationality"
                              placeholder="Nationality"
                              variant="outlined"
                              className=" font-weight-bold"
                              value={teacherForm.nationalityId}
                            />
                          </div>

                          <div className="col-4 mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="employeeId"
                              value={teacherForm?.employeeId || ""}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="IPPIS Staff ID"
                              placeholder="IPPIS Staff ID"
                              variant="outlined"
                            />
                          </div>

                          <div className="col-4 mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              InputProps={{ readOnly: true }}
                              fullWidth
                              size="small"
                              name="rssbNumber"
                              label="RSSB Number"
                              placeholder="RSSB Number"
                              variant="outlined"
                              value={teacherForm.rssbNumber}
                            />
                          </div>

                          <div className="col-4 mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              InputProps={{ readOnly: true }}
                              fullWidth
                              size="small"
                              name="bankAccountNumber"
                              label="Bank Account Number"
                              placeholder="Bank Account Number"
                              variant="outlined"
                              value={teacherForm.accountNumber}
                            />
                          </div>
                          <div className="col-4 mt-2 text-left">
                            <Autocomplete
                              style={{ backgroundColor: "#e9ecef" }}
                              readOnly
                              size="small"
                              defaultValue={null}
                              value={
                                banks.find(
                                  ({ id }) => id === teacherForm.bankId
                                ) || null
                              }
                              options={banks}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="bank"
                                  label="Bank"
                                  placeholder="Bank"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                            />
                          </div>

                          <div className="col-4  mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              InputProps={{ readOnly: true }}
                              fullWidth
                              size="small"
                              autoComplete="false"
                              name="phone"
                              label="Phone number"
                              placeholder="Phone number"
                              variant="outlined"
                              value={teacherForm.phoneNumber}
                            />
                          </div>

                          <div className="col-4  mt-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              InputProps={{ readOnly: true }}
                              fullWidth
                              size="small"
                              autoComplete="false"
                              name="email"
                              label="Personal email"
                              placeholder="Personal email"
                              variant="outlined"
                              value={teacherForm.email}
                            />
                          </div>

                          <div className="mt-2 col-4">
                            <Autocomplete
                              style={{ backgroundColor: "#e9ecef" }}
                              readOnly
                              size="small"
                              defaultValue={null}
                              value={
                                degrees.find(
                                  ({ id }) => id === teacherForm.degreeId
                                ) || null
                              }
                              options={degrees}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Highest Degree"
                                  name="degree"
                                  placeholder="Highest Degree"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="mt-2 col-8">
                            <Autocomplete
                              style={{ backgroundColor: "#e9ecef" }}
                              readOnly
                              size="small"
                              defaultValue={null}
                              value={
                                specializations.find(
                                  ({ id }) =>
                                    id === teacherForm.specializationId
                                ) || null
                              }
                              options={specializations}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Specialization"
                                  name="specialization"
                                  placeholder="Specialization"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="mt-2 col-4">
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              readOnly
                            >
                              <DesktopDatePicker
                                label="Graduation date"
                                inputFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                value={teacherForm.graduationDate || null}
                                renderInput={(params) => (
                                  <TextField
                                    style={{ backgroundColor: "#e9ecef" }}
                                    onKeyDown={(e) => e.preventDefault()}
                                    size="small"
                                    fullWidth
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className=" d-flex justify-content-center py-4"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  placementReportDetails,
  changeList,
  prevPlacementReport,
  signatories,
  roles,
  districts,
  sectors,
  degrees,
  specializations,
  schoolPositions,
  qualificationLevels,
  banks,
}) => {
  return {
    user,
    loading,
    onWait,
    placementReportDetails,
    changeList,
    prevPlacementReport,
    signatories,
    roles,
    districts,
    sectors,
    degrees,
    specializations,
    schoolPositions,
    qualificationLevels,
    banks,
  };
};
export default connect(mapStateToProps, {
  addOrUpdateReplacementStaff,
  getReplacementStaffProfile,
})(AddNewTeacherDialog);
