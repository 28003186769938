import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  Divider,
  TextField,
} from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  getPostDistributions,
  getPostLimits,
  saveDistrictPostLimits,
  confirmPostDistributions,
} from "../../../../store/posts/actions";
import ReactPaginate from "react-paginate";
import PostRequestDetailsDialog from "../components/PostRequestDetailsDialog";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import PostLimitDialog from "../components/PostLimitDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import PostsDistributionREB from "../components/PostsDistributionREB";
import PostsDistributionDDE from "../components/PostsDistributionDDE";

// import PostsDistributionHT from "../components/PostsDistributionHT";

const PostsDistribution = (props) => {
  useEffect(() => {
    document.title = "TMIS | Post Management - Post Distributions";
  }, []);

  const { user } = props;

  return (
    <>
      {user?.selectedEntity.name === "All" && <PostsDistributionREB />}

      {user?.selectedEntity.name !== "All" && <PostsDistributionDDE />}
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, {})(PostsDistribution);
