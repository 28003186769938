import { defaultState } from "./state";
import structure from "./action-types";
import commonTypes from "../common/action-types";

const types = { ...commonTypes, ...structure };

const units = (unitsState = defaultState.units, action) => {
  switch (action.type) {
    case types.SET_UNITS:
      return action.data;

    case types.ADD_NEW_UNIT: {
      const tmpState = [...unitsState];
      tmpState.unshift(action.data);
      return tmpState;
    }

    case types.UPDATE_UNIT: {
      const tmpState = [...unitsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.DELETE_UNIT: {
      const tmpState = [...unitsState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.units;

    default:
      return unitsState;
  }
};

// const treeUnits = (treeUnitsState = defaultState.treeUnits, action) => {
//   switch (action.type) {
//     case types.SET_TREE_UNITS:
//       return action.data;

//     case types.CLEAN_STATE:
//       return defaultState.treeUnits;

//     default:
//       return treeUnitsState;
//   }
// };

const selectedUnit = (
  selectedUnitState = defaultState.selectedUnit,
  action
) => {
  switch (action.type) {
    case types.SET_SELECTED_UNIT:
      return action.data;

    case types.UPDATE_UNIT: {
      const tmpState = { ...selectedUnitState };

      if (tmpState.id === action.data.id) return action.data;
      else return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.selectedUnit;

    default:
      return selectedUnitState;
  }
};

const organigramData = (
  organigramDataState = defaultState.organigramData,
  action
) => {
  switch (action.type) {
    case types.SET_ORGANIGRAM_DATA:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.organigramData;

    default:
      return organigramDataState;
  }
};

const schoolPositions = (
  schoolPositionsState = defaultState.schoolPositions,
  action
) => {
  switch (action.type) {
    case types.SET_SCHOOL_POSITIONS:
      return action.data;

    case types.ADD_NEW_SCHOOL_POSITION: {
      const tmpState = [...schoolPositionsState];
      tmpState.unshift(action.data);
      return tmpState;
    }

    case types.UPDATE_SCHOOL_POSITION: {
      const tmpState = [...schoolPositionsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.DELETE_SCHOOL_POSITION: {
      const tmpState = [...schoolPositionsState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.schoolPositions;

    default:
      return schoolPositionsState;
  }
};

const sectors = (sectorsState = defaultState.sectors, action) => {
  switch (action.type) {
    case types.SET_SECTORS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.sectors;

    default:
      return sectorsState;
  }
};

const cells = (cellsState = defaultState.cells, action) => {
  switch (action.type) {
    case types.SET_CELLS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.cells;

    default:
      return cellsState;
  }
};

const villages = (villagesState = defaultState.villages, action) => {
  switch (action.type) {
    case types.SET_VILLAGES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.villages;

    default:
      return villagesState;
  }
};

const schoolLevels = (
  schoolLevelsState = defaultState.schoolLevels,
  action
) => {
  switch (action.type) {
    case types.SET_SCHOOL_LEVELS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.schoolLevels;

    default:
      return schoolLevelsState;
  }
};

const jobs = (jobsState = defaultState.jobs, action) => {
  switch (action.type) {
    case types.SET_JOBS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.jobs;

    default:
      return jobsState;
  }
};

const qualificationLevels = (
  qualificationLevelsState = defaultState.qualificationLevels,
  action
) => {
  switch (action.type) {
    case types.SET_QUALIFICATION_LEVELS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.qualificationLevels;

    default:
      return qualificationLevelsState;
  }
};

const positionBudgetPosts = (
  positionBudgetPostsState = defaultState.positionBudgetPosts,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_BUDGET_POSTS:
      return action.data;

    case types.DELETE_POSITION_BUDGET_POST: {
      const tmpState = [...positionBudgetPostsState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.positionBudgetPosts;

    default:
      return positionBudgetPostsState;
  }
};

const budgetPostRequests = (
  budgetPostRequestsState = defaultState.budgetPostRequests,
  action
) => {
  switch (action.type) {
    case types.SET_BUDGET_POST_REQUESTS:
      return action.data;

    case types.ADD_OR_UPDATE_BUDGET_POST_REQUEST: {
      const tmpState = [...budgetPostRequestsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;
      else if (!!action.data) tmpState.push(action.data);

      return tmpState;
    }

    case types.DELETE_BUDGET_POST_REQUEST: {
      const tmpState = [...budgetPostRequestsState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.budgetPostRequests;

    default:
      return budgetPostRequestsState;
  }
};

const rebPostDistributions = (
  rebPostDistributionsState = defaultState.rebPostDistributions,
  action
) => {
  switch (action.type) {
    case types.SET_REB_POST_DISTRIBUTIONS:
      return action.data;

    case types.UPDATE_REB_POST_DISTRIBUTION: {
      const tmpState = [...rebPostDistributionsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.rebPostDistributions;

    default:
      return rebPostDistributionsState;
  }
};

const ddePostDistributions = (
  ddePostDistributionsState = defaultState.ddePostDistributions,
  action
) => {
  switch (action.type) {
    case types.SET_DDE_POST_DISTRIBUTIONS:
      return action.data;

    case types.UPDATE_DDE_POST_DISTRIBUTION: {
      const tmpState = [...ddePostDistributionsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.ddePostDistributions;

    default:
      return ddePostDistributionsState;
  }
};

const htPostDistributions = (
  htPostDistributionsState = defaultState.htPostDistributions,
  action
) => {
  switch (action.type) {
    case types.SET_HT_POST_DISTRIBUTIONS:
      return action.data;

    case types.UPDATE_HT_POST_DISTRIBUTION: {
      const tmpState = [...htPostDistributionsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.htPostDistributions;

    default:
      return htPostDistributionsState;
  }
};

const rebPostLimits = (
  rebPostLimitsState = defaultState.rebPostLimits,
  action
) => {
  switch (action.type) {
    case types.SET_REB_POST_LIMITS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.rebPostLimits;

    default:
      return rebPostLimitsState;
  }
};

const ddePostLimits = (
  ddePostLimitsState = defaultState.ddePostLimits,
  action
) => {
  switch (action.type) {
    case types.SET_DDE_POST_LIMITS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.ddePostLimits;

    default:
      return ddePostLimitsState;
  }
};

const htPostLimits = (
  htPostLimitsState = defaultState.htPostLimits,
  action
) => {
  switch (action.type) {
    case types.SET_HT_POST_LIMITS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.htPostLimits;

    default:
      return htPostLimitsState;
  }
};

const districts = (districtsState = defaultState.districts, action) => {
  switch (action.type) {
    case types.SET_DISTRICTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.districts;

    default:
      return districtsState;
  }
};

const schoolTeachers = (
  schoolTeachersState = defaultState.schoolTeachers,
  action
) => {
  switch (action.type) {
    case types.SET_SCHOOL_TEACHERS:
      return action.data;

    case types.ADD_OR_UPDATE_NEW_SCHOOL_TEACHER: {
      const tmpState = [...schoolTeachersState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;
      else tmpState.unshift(action.data);
      return tmpState;
    }

    case types.DELETE_SCHOOL_TEACHER: {
      const tmpState = [...schoolTeachersState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.schoolTeachers;

    default:
      return schoolTeachersState;
  }
};

const systemSettingPositions = (
  systemSettingPositionsState = defaultState.systemSettingPositions,
  action
) => {
  switch (action.type) {
    case types.SET_SYSTEM_SETTING_POSITIONS:
      return action.data;

    case types.ADD_OR_UPDATE_SYSTEM_SETTING_POSITION: {
      const tmpState = [...systemSettingPositionsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);
      if (index >= 0) tmpState[index] = action.data;
      else tmpState.push(action.data);
      return tmpState;
    }

    case types.DELETE_SYSTEM_SETTING_POSITION: {
      const tmpState = [...systemSettingPositionsState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.systemSettingPositions;

    default:
      return systemSettingPositionsState;
  }
};

const positionRoles = (
  positionRolesState = defaultState.positionRoles,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_ROLES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.positionRoles;

    default:
      return positionRolesState;
  }
};

const schools = (schoolsState = defaultState.schools, action) => {
  switch (action.type) {
    case types.SET_SCHOOLS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.schools;

    default:
      return schoolsState;
  }
};

const positions = (positionsState = defaultState.positions, action) => {
  switch (action.type) {
    case types.SET_POSITIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.positions;

    default:
      return positionsState;
  }
};

const pudgetPostRequestPositions = (
  pudgetPostRequestPositionsState = defaultState.pudgetPostRequestPositions,
  action
) => {
  switch (action.type) {
    case types.SET_PUDGETPOST_REQUEST_POSITIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.pudgetPostRequestPositions;

    default:
      return pudgetPostRequestPositionsState;
  }
};

const teachingLevels = (
  teachingLevelsState = defaultState.teachingLevels,
  action
) => {
  switch (action.type) {
    case types.SET_TEACHING_LEVELS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.teachingLevels;

    default:
      return teachingLevelsState;
  }
};

export default {
  positionBudgetPosts,
  units,
  schoolPositions,
  jobs,
  selectedUnit,
  organigramData,
  sectors,
  cells,
  villages,
  schoolLevels,
  qualificationLevels,
  budgetPostRequests,
  rebPostDistributions,
  ddePostDistributions,
  htPostDistributions,
  rebPostLimits,
  ddePostLimits,
  htPostLimits,
  districts,
  schoolTeachers,
  systemSettingPositions,
  positionRoles,
  schools,
  positions,
  pudgetPostRequestPositions,
  teachingLevels,
};
