import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { useState } from "react";

import { connect } from "react-redux";

const TransferCriteriaDialog = (props) => {
  const { user, transferEligibility, academicYears } = props;

  const previousAcademicYears = () => {
    const index = academicYears.findIndex(
      ({ id }) => id === user?.selectedAcademicYear?.id
    );
    return academicYears[index + 1] || user?.selectedAcademicYear;
  };

  // previousAcademicYears();

  return (
    <div>
      <ol>
        <li>
          Teachers must have a verified profile in TMIS.
          {!isEmpty(transferEligibility) &&
          transferEligibility.hasVerifiedProfile ? (
            <i className="fas fa-check-circle text-success"></i>
          ) : (
            <i className="fas fa-times-circle text-danger"></i>
          )}
        </li>
        <li>
          Teachers must have scored at least 70% in performance evaluation in
          the <strong>{previousAcademicYears().name}</strong> school year{" "}
          <i className="fas fa-ellipsis-h text-primary"></i>
        </li>
        <li>
          Teachers must have successfully completed three (3) years of service
          at the same school, probation period included.
          {!isEmpty(transferEligibility) &&
          transferEligibility.hasThreeYearsInService ? (
            <i className="fas fa-check-circle text-success"></i>
          ) : (
            <i className="fas fa-times-circle text-danger"></i>
          )}
        </li>
        <li>
          Upgraded teachers will only be eligible for transfer if they have
          successfully served at least three (3) years at the same school and
          same position.
          {!isEmpty(transferEligibility) &&
          transferEligibility.hasThreeYearsInService ? (
            <i className="fas fa-check-circle text-success"></i>
          ) : (
            <i className="fas fa-times-circle text-danger"></i>
          )}
        </li>
        <li>
          Teachers who were transferred or interchanged (permutation) will only
          be eligible for transfer if they have completed at least three (3)
          years of service after the previous transfer/interchange
          {!isEmpty(transferEligibility) &&
          transferEligibility.hasThreeYearsAfterPreviousTransfer ? (
            <i className="fas fa-check-circle text-success"></i>
          ) : (
            <i className="fas fa-times-circle text-danger"></i>
          )}
        </li>
        <li>
          Teachers whose request for internal transfer or interchange
          (permutation) that has not been successful, are also eligible to apply
          for internal or external transfer
          {!isEmpty(transferEligibility) &&
          transferEligibility.hasThreeYearsAfterPreviousTransfer ? (
            <i className="fas fa-check-circle text-success"></i>
          ) : (
            <i className="fas fa-times-circle text-danger"></i>
          )}
        </li>

        {user?.selectedScope?.id !== "RTB" && (
          <li>
            Teachers interchanging must have the same profile{" "}
            <i className="fas fa-ellipsis-h text-primary"></i>
          </li>
        )}

        {user?.selectedScope?.id !== "RTB" && (
          <li>
            Un-certified teachers shall request for transfer/interchange after
            completion of the ongoing CPD program.{" "}
            {!isEmpty(transferEligibility) &&
            transferEligibility.isCertified ? (
              <i className="fas fa-check-circle text-success"></i>
            ) : (
              <i className="fas fa-times-circle text-danger"></i>
            )}
          </li>
        )}

        {!!transferEligibility.needRecommendation && (
          <li>
            <strong>
              Recommendation letter from the District is required for School
              Accountants and Bursars
            </strong>
            <i className="fas fa-ellipsis-h text-primary"></i>
          </li>
        )}
        <br />
        <br />
        <em>
          <strong style={{ textDecoration: "underline" }}>Note:</strong>{" "}
          Transfer and Interchange (permutation) of Head teachers, Deputy Head
          teachers and Accountant Secretary A2 is not yet open.
          {!isEmpty(transferEligibility) &&
          transferEligibility.isRoleAllowed ? (
            <i className="fas fa-check-circle text-success"></i>
          ) : (
            <i className="fas fa-times-circle text-danger"></i>
          )}
        </em>
      </ol>
    </div>
  );
};

const mapStateToProps = ({ user, transferEligibility, academicYears }) => {
  return { user, transferEligibility, academicYears };
};
export default connect(mapStateToProps, {})(TransferCriteriaDialog);
