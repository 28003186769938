import types from "./action-types";
import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";

const isPasswordStrong = (password) => {
  if (
    password &&
    password.length > 7 &&
    // eslint-disable-next-line no-useless-escape
    /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password) &&
    /\d/.test(password) &&
    /[a-z]/.test(password) &&
    /[A-Z]/.test(password)
  )
    return true;
  return false;
};

export const setUser = (data) => {
  return {
    type: types.SET_USER,
    data,
  };
};

export const getEnv = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_ENV, data: null });

      const { data } = await axios.get("/api/env");

      dispatch({ type: types.SET_ENV, data });
    } catch (error) {
      showError(error);
    }
  };
};

export const login = (credentials, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/auth/logins", credentials);

      try {
        const { data } = await axios.get("/api/auth/users/me");

        dispatch({
          type: types.SET_USER,
          data,
        });

        history.push("/in");
      } catch (error) {}

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const logout = (history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/auth/logins");

      dispatch({ type: types.END_LOADING });

      history.push("/");

      dispatch({ type: types.CLEAN_STATE });
      dispatch({ type: types.LOGOUT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      history.push("/");
    }
  };
};

export const getUser = (history, location) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/auth/users/me");

      dispatch({
        type: types.SET_USER,
        data,
      });

      if (location.pathname === "/") history.push("/in");

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      showError(error);

      if (location.pathname !== "/password-reset") {
        history.push("/");
        await axios.delete("/api/auth/logins");
      }

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.CLEAN_STATE });
      dispatch({ type: types.LOGOUT });
    }
  };
};

export const resetPassword = (email) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post("/api/auth/reset-password", { email });

      showSuccess(data.message);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const changePassword = (credentials, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/auth/change-password",
        credentials
      );

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.SET_STRONG_PASSWORD, data: true });

      showSuccess(data.message);

      history.push("/");
    } catch (error) {
      dispatch({ type: types.SET_STRONG_PASSWORD, data: false });

      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const register = (user, modelDismiss) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post("/api/auth/users", user);

      showSuccess(data.message);

      dispatch({ type: types.END_LOADING });

      modelDismiss.click();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getAcademicYears = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/auth/academic-years");

      dispatch({ type: types.SET_ACADEMIC_YEARS, data });
    } catch (error) {
      showError(error);
    }
  };
};

export const selectAcademicYear = (year, handleCloseAcYearMenu) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      await axios.post("/api/auth/select-academic-year", {
        id: year.id,
      });

      dispatch({
        type: types.SET_SELECTED_ACADEMIC_YEAR,
        data: year,
      });

      handleCloseAcYearMenu();

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      showError(error);
    }
  };
};

export const getUserEntities = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/auth/user-entities");

      dispatch({ type: types.SET_USER_ENTITIES, data });
    } catch (error) {
      showError(error);
    }
  };
};

export const selectUserEntity = (entity, handleCloseEntityMenu) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      await axios.post("/api/auth/select-user-entity", {
        id: entity.id,
      });

      dispatch({
        type: types.SET_SELECTED_USER_ENTITY,
        data: entity,
      });

      handleCloseEntityMenu();

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getUserScopes = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/auth/scopes");

      dispatch({
        type: types.SET_SCOPES,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const selectScope = (scope, handleCloseMenu) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/auth/select-scope", {
        id: scope.id,
      });

      // dispatch({
      //   type: types.SET_SELECTED_SCOPE,
      //   data: scope,
      // });

      handleCloseMenu();

      window.location.reload();

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
