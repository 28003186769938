import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { currencyFormat } from "../../common/components/Utils";

import { ressetPassword } from "../../../../store/users/actions";
import { isEmpty } from "lodash";
import axios from "axios";
import { CSVLink } from "react-csv";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import moment from "moment";
// import { showError } from "../../toastify";
import defaultPofile from "../../../assets/default-profile.jpg";
import { idNumberPattern } from "../../common/components/Utils";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import MaskedInput from "react-text-mask";
import joi from "joi";

const PasswordRessetDialog = (props) => {
  const { user, loading, onWait, showDialog, setShowDialog, ressetPassword } =
    props;

  const [isVerifing, setIsVerifing] = useState(false);
  const [wasVerified, setWasVerified] = useState(false);
  const [idHasError, setIdHasError] = useState(false);

  const onClose = () => {
    setShowDialog(false);
  };

  const [formData, setFormData] = useState({
    nidNumber: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    nidNumberHasError: false,
    emailHasError: false,
    phoneNumberHasError: false,
    hasError: false,
  });

  const formDataValidate = () => {
    const error = {
      nidNumberHasError: false,
      emailHasError: false,
      phoneNumberHasError: false,
      hasError: false,
    };

    if (
      (!formData.nidNumber || !(!idHasError && !isVerifing && !!wasVerified)) &&
      user.id !== "0000000000"
    ) {
      error.nidNumberHasError = true;
      error.hasError = true;
    }

    if (!formData.email && user.id !== "0000000000") {
      error.emailHasError = true;
      error.hasError = true;
    }

    if (!formData.phoneNumber && user.id !== "0000000000") {
      error.phoneNumberHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!!user) {
      setFormData({
        nidNumber: user.nidNumber,
        email: user.email,
        phoneNumber: user.phoneNumber,
        password: "",
      });
    }

    if (!!user.nidNumber) getNidDetails(user.nidNumber);
  }, [user]);

  const onSave = () => {
    if (!formDataValidate())
      ressetPassword(
        {
          ...formData,
          id: user.id,
          nidNumber: (formData.nidNumber || "").replace(/\s/g, "") || null,
        },
        onClose
      );
  };

  const getNidDetails = async (nidNumber) => {
    try {
      setWasVerified(false);
      setIsVerifing(true);

      const { data } = await axios.get(
        "https://ippis.rw/api/external/nid-number-detail/" +
          (nidNumber || "").replace(/\s/g, "")
      );

      setFormData({
        nidNumber: nidNumber,
        email: user.email,
        phoneNumber: user.phoneNumber,
        password: "",
      });

      // setChanged(true);
      setIdHasError(false);
      setWasVerified(true);
      setIsVerifing(false);
    } catch (error) {
      // setChanged(false);
      setIdHasError(true);
      setWasVerified(false);
      setIsVerifing(false);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary pb-0 mb-0 text-uppercase border">
          <span className="d-flex align-items-center mb-2">
            <Typography className="d-flex align-items-center text-primary">
              <span className="text-dark mr-1">Resset Password for</span>
              {user?.names}
            </Typography>
          </span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="px-0">
          <div className="mt-0" style={{ height: "100%" }}>
            <div
              style={{
                display: "table-cell",
                width: "100vw",
                height: "25vh",
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              <div className="row mx-0">
                <div className="col-sm-12">
                  <React.Fragment>
                    <div>
                      <div className="row mx-0">
                        <div className="col-12 mb-3 justify-content-center">
                          {!idHasError && !isVerifing && !wasVerified && (
                            <span className="material-icons loaderIcon text-danger">
                              help
                            </span>
                          )}

                          {!!isVerifing && (
                            <i
                              className="fas fa-spinner spinner loaderIcon text-primary"
                              style={{ fontSize: "22px" }}
                            ></i>
                          )}

                          {!isVerifing && !!idHasError && (
                            <span className="loaderIcon text-danger d-flex align-items-center">
                              <span className="material-icons text-danger">
                                report_problem
                              </span>
                              <span style={{ fontSize: "10px" }}>Error</span>
                            </span>
                          )}

                          {!idHasError && !isVerifing && !!wasVerified && (
                            <span className="loaderIcon text-success d-flex align-items-center">
                              <span className="material-icons text-success ">
                                verified
                              </span>
                              <span style={{ fontSize: "10px" }}>Verified</span>
                            </span>
                          )}

                          <MaskedInput
                            mask={idNumberPattern}
                            className="form-control "
                            placeholder="NID Number"
                            guide={false}
                            value={formData.nidNumber}
                            onKeyPress={(e) => {
                              if (
                                e.key === "Enter" &&
                                e.target.value.length === 21
                              ) {
                                getNidDetails(e.target.value);
                              }
                            }}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                nidNumber: e.target.value,
                              });

                              if (e.target.value.length === 21) {
                                getNidDetails(e.target.value);
                              } else {
                                setIdHasError(false);
                                setWasVerified(false);
                                setIsVerifing(false);
                              }
                              setErrors({
                                ...errors,
                                nidNumberHasError: false,
                              });
                            }}
                          />

                          {errors.nidNumberHasError && (
                            <div className="text-danger mb-1">
                              {!!formData.nidNumber
                                ? "Invalid NID Number"
                                : " NID Number is required"}
                            </div>
                          )}
                        </div>
                        {/* <div className="col-12 mb-3  text-left">
                          <TextField
                            style={{ backgroundColor: "#e9ecef" }}
                            fullWidth
                            size="small"
                            name="nidNumber"
                            label="NID Number"
                            placeholder="NID Number"
                            variant="outlined"
                            className="font-weight-bold"
                            value={formData.nidNumber}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                nidNumber: e.target.value,
                              });

                              setErrors({
                                ...errors,
                                nidNumberHasError: false,
                              });
                            }}
                          />

                          {errors.nidNumberHasError && (
                            <small className="text-danger">
                              NIDNumber is required
                            </small>
                          )}
                        </div> */}
                        <div className="col-6  text-left">
                          <TextField
                            fullWidth
                            size="small"
                            name="email"
                            label="Email"
                            placeholder="Email"
                            variant="outlined"
                            className="font-weight-bold"
                            value={formData.email}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              });

                              setErrors({
                                ...errors,
                                emailHasError: false,
                              });
                            }}
                          />

                          {errors.emailHasError && (
                            <small className="text-danger">
                              Personal email is required
                            </small>
                          )}
                        </div>
                        <div className="col-6  text-left">
                          <TextField
                            fullWidth
                            size="small"
                            name="phoneNumber"
                            label="Phone Number"
                            placeholder="07XXXXXXXX"
                            variant="outlined"
                            className="font-weight-bold"
                            value={formData.phoneNumber}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                phoneNumber: e.target.value,
                              });

                              setErrors({
                                ...errors,
                                phoneNumberHasError: false,
                              });
                            }}
                          />
                          {errors.phoneNumberHasError && (
                            <small className="text-danger mt-1">
                              Phone Number is required
                            </small>
                          )}
                        </div>
                        <div className="col-12 mt-3 text-left">
                          <TextField
                            autoFocus
                            fullWidth
                            size="small"
                            name="password"
                            label="Password"
                            placeholder="Password"
                            variant="outlined"
                            className="font-weight-bold"
                            value={formData.password}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                password: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className=" d-flex justify-content-center py-4">
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            disableElevation
            size="sm"
            className="px-4"
            onClick={onSave}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, onWait }) => {
  return {
    loading,
    onWait,
  };
};
export default connect(mapStateToProps, {
  ressetPassword,
})(PasswordRessetDialog);
