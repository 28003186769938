import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import { connect } from "react-redux";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import {
  publishTransferRequests,
  getTransferSummary,
} from "../../../../store/transfer/actions";
import { useEffect } from "react";
import { currencyFormat } from "../../common/components/Utils";
const TransferSummaryDialog = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    publishTransferRequests,
    getTransferSummary,
    transferSummary,
    transferSchedule,
  } = props;

  useEffect(() => {
    if (!!showDialog) getTransferSummary();
  }, []);

  const [confirmPermutations, setConfirmPermutations] = useState(false);

  return (
    <>
      <Dialog
        onClose={() => {
          setShowDialog(false);
        }}
        fullWidth
        maxWidth="md"
        open={showDialog}
      >
        <DialogTitle>
          {" "}
          <Typography>
            {" "}
            Transfer summary
            {!!transferSchedule && (
              <span className="ml-2">
                {transferSchedule.internal ? (
                  <span className="badge badge-success">Internal</span>
                ) : (
                  <span className="badge badge-primary">External </span>
                )}
              </span>
            )}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="rounded border p-2" style={{ overflow: "hidden" }}>
            <table style={{ fontSize: "12px" }} className="table">
              <tr>
                <th className="text-right text-primary text-uppercase"></th>
                <th className="text-primary text-uppercase  text-right">All</th>
                <th className="text-primary text-uppercase  text-right">
                  Normal
                </th>
                <th className="text-primary text-uppercase  text-right">
                  Specials
                </th>
              </tr>
              <tr>
                <th className="text-right text-dark text-uppercase">Total</th>
                <td className="text-right">
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.total || 0)}
                  </strong>
                </td>
                <td className="text-right">
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.totalNormals || 0)}
                  </strong>
                </td>
                <td className="text-right">
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.totalSpecials || 0)}
                  </strong>
                </td>
              </tr>
              <tr>
                <th className="text-right text-primary text-uppercase">
                  Accepted
                </th>
                <td className="text-right text-primary">
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.totalAccepted || 0)}
                  </strong>
                </td>
                <td className="text-right text-primary">
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.normalAccepted || 0)}
                  </strong>
                </td>
                <td className="text-right text-primary">
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.specialAccepted || 0)}
                  </strong>
                </td>
              </tr>
              <tr>
                <th className="text-right text-danger text-uppercase">
                  Rejected
                </th>
                <td className="text-right text-danger">
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.totalRejected || 0)}
                  </strong>
                </td>
                <td className="text-right text-danger">
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.normalRejected || 0)}
                  </strong>
                </td>
                <td className="text-right text-danger">
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.specialRejected || 0)}
                  </strong>
                </td>
              </tr>
              <tr>
                <th className="text-right text-secondary text-uppercase">
                  Pending
                </th>
                <td className="text-right text-secondary">
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.totalPending || 0)}
                  </strong>
                </td>
                <td className="text-right text-secondary">
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.normalPending || 0)}
                  </strong>
                </td>
                <td className="text-right text-secondary">
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.specialPending || 0)}
                  </strong>
                </td>
              </tr>
              <tr>
                <th
                  className="text-right text-success text-uppercase"
                  style={{ backgroundColor: "#b1e4ff" }}
                >
                  Transfered
                </th>
                <td
                  className="text-right text-success"
                  style={{ backgroundColor: "#b1e4ffc2" }}
                >
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.totalTransfered || 0)}
                  </strong>
                </td>
                <td
                  className="text-right text-success"
                  style={{ backgroundColor: "#b1e4ffc2" }}
                >
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.normalTransfered || 0)}
                  </strong>
                </td>
                <td
                  className="text-right text-success"
                  style={{ backgroundColor: "#b1e4ffc2" }}
                >
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.specialTransfered || 0)}
                  </strong>
                </td>
              </tr>
              <tr>
                <th
                  className="text-right text-secondary text-uppercase"
                  style={{ backgroundColor: "#b1e4ff" }}
                >
                  Not tansfered
                </th>
                <td
                  className="text-right text-secondary"
                  style={{ backgroundColor: "#b1e4ffc2" }}
                >
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.totalNotTransfered || 0)}
                  </strong>
                </td>
                <td
                  className="text-right text-secondary"
                  style={{ backgroundColor: "#b1e4ffc2" }}
                >
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.normalNotTransfered || 0)}
                  </strong>
                </td>
                <td
                  className="text-right text-secondary"
                  style={{ backgroundColor: "#b1e4ffc2" }}
                >
                  <strong style={{ fontSize: "14px" }}>
                    {currencyFormat(transferSummary.specialNotTransfered || 0)}
                  </strong>
                </td>
              </tr>
            </table>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            color="success"
            variant="contained"
            onClick={() => setConfirmPermutations(true)}
            type="button"
            disabled={
              loading || !!transferSummary.published
              //  ||
              // !transferSummary.total ||
              // !transferSchedule ||
              // +transferSummary.totalPending > 0 ||
              // !transferSummary.totalTransfered
            }
            className="mt-n3 px-5"
          >
            <span className="material-icons">publish</span>
            Publish
          </Button>
        </DialogActions>
      </Dialog>

      {confirmPermutations && (
        <ConfirmationDialog
          confirmationDialog={confirmPermutations}
          message={
            <>
              <span>Are you sure you want to publish?</span>
            </>
          }
          setConfirmationDialog={setConfirmPermutations}
          onYes={() => {
            publishTransferRequests(() => {
              setConfirmPermutations(false);
              setShowDialog(false);
            });
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, transferSummary, transferSchedule }) => {
  return { loading, transferSummary, transferSchedule };
};
export default connect(mapStateToProps, {
  publishTransferRequests,
  getTransferSummary,
})(TransferSummaryDialog);
