import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  Divider,
  TextField,
  Tooltip,
} from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";

import ReactPaginate from "react-paginate";
// import PostRequestDetailsDialog from "../components/PostRequestDetailsDialog";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import NewUserDialog from "../components/NewUserDialog";
import PasswordRessetDialog from "../components/PasswordRessetDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import UpdateUserDialog from "../components/UpdateUserDialog";
import { Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../common/components/TabPanel";
import PositionsDropdown from "../components/PositionsDropdown";

const SystemSettings = (props) => {
  useEffect(() => {
    document.title = "TMIS | System Users";
  }, []);

  const { user, loading, systemSettingPositions } = props;
  // const [searchTeam, setSearchTeam] = useState("");

  // useEffect(() => {
  //   getSystemUsers();
  // }, [user?.selectedEntity]);

  // const onSearch = (term) => {
  //   setSearchTeam(term);
  // };

  // //PAGINATION
  // const [paginatedItems, setPaginatedItems] = useState(null);
  // const [pageCount, setPageCount] = useState(0);

  // const [itemOffset, setItemOffset] = useState(0);

  // const itemsPerPage = 20;

  // const paginate = (items) => {
  //   const endOffset = itemOffset + itemsPerPage;
  //   setPaginatedItems(items.slice(itemOffset, endOffset));
  //   setPageCount(Math.ceil(items.length / itemsPerPage));
  // };

  // useEffect(() => {
  //   paginate(systemUsers);
  // }, [itemOffset, itemsPerPage, systemUsers]);

  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % systemUsers.length || 0;

  //   setItemOffset(newOffset);
  // };

  // const getNo = (data, key, idToSearch) =>
  //   data.findIndex((item) => item[key] === idToSearch);

  // const [showUserDialog, setShowUserDialog] = useState(false);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="px-4 pt-2">
      <div className="border rounded">
        <AppBar position="static" elevation={0} className="app-bar w-100">
          <Toolbar
            style={{
              minHeight: "0px",
              borderBottom: "3px solid #e9ecef",
            }}
            className=" my-0 py-1 bg-light"
          >
            <Typography
              variant="p"
              noWrap
              component="div"
              className="d-flex  align-items-center text-uppercase text-primary font-weight-bold"
            >
              <span className="text-dark ">Global Settings</span>
              <span className="material-icons" style={{ color: "#cecece" }}>
                chevron_right
              </span>

              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={value}
                onChange={handleChange}
                aria-label="System Settings"
              >
                <Tab
                  label={
                    <span className="d-inline-flex">
                      <span className="badge badge-secondary mr-1">
                        {systemSettingPositions.length}
                      </span>{" "}
                      Positions
                    </span>
                  }
                  {...a11yProps(0)}
                />
                {/* <Tab
                  className="text-danger"
                  label="Positions"
                  {...a11yProps(1)}
                /> */}
              </Tabs>
            </Typography>
            {/* <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: { xs: "flex", md: "flex" } }}>
              <SearchBox onSearch={onSearch} placeholder="Search…" />
            </Box> */}
          </Toolbar>
        </AppBar>

        <TabPanel value={value} index={0}>
          <PositionsDropdown />
        </TabPanel>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, loading, systemSettingPositions }) => {
  return { user, loading, systemSettingPositions };
};

export default connect(mapStateToProps, {})(SystemSettings);

// const PositionCard = (props) => {
//   const { user, index, selectedEntity, removeSystemUser } = props;

//   const [anchorEl, setAnchorEl] = useState(null);
//   const open = Boolean(anchorEl);

//   const handleOpenMenu = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleCloseMenu = () => {
//     setAnchorEl(null);
//   };

//   const [showPasswordRessetDialog, setShowPasswordRessetDialog] =
//     useState(false);

//   const [showConfirmRemoveUserDialog, setShowConfirmRemoveUserDialog] =
//     useState(false);

//   const [showUpdateUserDialog, setShowUpdateUserDialog] = useState(false);

//   return (
//     <>
//       <tr className={``}>
//         <th scope="row" className="text-left">
//           {index + 1}
//         </th>

//         <td className="text-left">{user.id}</td>
//         <td className="text-left">{user.staffCode}</td>
//         <td className="text-left">{user.names}</td>
//         <td className="text-left">
//           <span className="d-flex align-items-center">
//             {user.nidNumber}

//             {!!user.nidNumber && !!user.isNIDVerified && (
//               <Tooltip title="Verified">
//                 <span
//                   className="material-icons ml-1 text-success"
//                   style={{ fontSize: "14px", cursor: "pointer" }}
//                 >
//                   verified
//                 </span>
//               </Tooltip>
//             )}

//             {!!user.nidNumber && !user.isNIDVerified && (
//               <Tooltip title="Not Verfied">
//                 <span
//                   className="material-icons ml-1 text-danger"
//                   style={{ fontSize: "14px", cursor: "pointer" }}
//                 >
//                   report_problem
//                 </span>
//               </Tooltip>
//             )}
//           </span>
//         </td>

//         <td className="text-left">{user.email}</td>

//         <td className="text-left">{user.phoneNumber}</td>

//         {selectedEntity?.id === "00000000" && <td>{user.districtName}</td>}
//         {selectedEntity?.type === "DISTRICT" &&
//           selectedEntity?.id !== "00000000" && <td>{user.sectorName}</td>}
//         {selectedEntity?.id !== "00000000" && <td>{user.schoolName}</td>}
//         {selectedEntity?.id !== "00000000" && <td>{user.schoollevelName}</td>}

//         <td className="text-left">{user.role}</td>

//         <td className="text-center">
//           <span
//             className={`badge badge-${
//               user.status === "Active" ? "success" : "danger"
//             } `}
//           >
//             {user.status}
//           </span>
//         </td>

//         <td className="text-right py-1">
//           <IconButton
//             size="small"
//             className="p-0"
//             onClick={handleOpenMenu}
//             aria-label="settings"
//           >
//             <Badge color="info">
//               <span className="material-icons">more_vert</span>
//             </Badge>
//           </IconButton>

//           <Menu
//             anchorEl={anchorEl}
//             id="account-menu"
//             open={open}
//             onClose={handleCloseMenu}
//             PaperProps={{
//               elevation: 0,
//               sx: {
//                 overflow: "visible",
//                 filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
//                 mt: 1.5,
//                 "& .MuiAvatar-root": {
//                   width: 32,
//                   height: 32,
//                   ml: -0.5,
//                   mr: 2,
//                 },
//                 "&:before": {
//                   content: '""',
//                   display: "block",
//                   position: "absolute",
//                   top: 0,
//                   right: 15,
//                   width: 10,
//                   height: 10,
//                   bgcolor: "background.paper",
//                   transform: "translateY(-50%) rotate(45deg)",
//                   zIndex: 0,
//                 },
//               },
//             }}
//             transformOrigin={{ horizontal: "right", vertical: "top" }}
//             anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
//           >
//             {user.role !== "REB" &&
//               user.role !== "ADMIN" &&
//               !!user.userEntityId && (
//                 <span>
//                   <MenuItem
//                     onClick={() => {
//                       handleCloseMenu();
//                       setShowUpdateUserDialog(true);
//                     }}
//                     className="text-primary font-weight-bolder"
//                   >
//                     <span className="material-icons mr-1">edit_note</span>
//                     Update
//                   </MenuItem>
//                 </span>
//               )}

//             {
//               <span>
//                 <MenuItem
//                   onClick={() => {
//                     handleCloseMenu();
//                     setShowPasswordRessetDialog(true);
//                   }}
//                   className="text-dark"
//                 >
//                   <span className="material-icons mr-1">lock_open</span>
//                   Reset password
//                 </MenuItem>
//               </span>
//             }
//             {user.role !== "REB" &&
//               user.role !== "ADMIN" &&
//               !!user.userEntityId && (
//                 <span>
//                   <MenuItem
//                     onClick={() => {
//                       handleCloseMenu();
//                       setShowConfirmRemoveUserDialog(true);
//                     }}
//                     className="text-danger"
//                   >
//                     <span className="material-icons mr-1">delete_forever</span>
//                     Remove
//                   </MenuItem>
//                 </span>
//               )}
//           </Menu>
//         </td>
//       </tr>

//       {showPasswordRessetDialog && (
//         <PasswordRessetDialog
//           showDialog={showPasswordRessetDialog}
//           setShowDialog={setShowPasswordRessetDialog}
//           user={user}
//         />
//       )}

//       {showUpdateUserDialog && (
//         <UpdateUserDialog
//           showDialog={showUpdateUserDialog}
//           setShowDialog={setShowUpdateUserDialog}
//           userToUpdate={user}
//         />
//       )}

//       {showConfirmRemoveUserDialog && (
//         <ConfirmationDialog
//           confirmationDialog={showConfirmRemoveUserDialog}
//           message={
//             <>
//               <p className="mb-0 mt-3">
//                 Are you sure you want to{" "}
//                 <strong className="text-danger">remove</strong>{" "}
//                 <strong className="text-primary">{user.names}</strong> from TMIS
//                 System Users?
//               </p>
//             </>
//           }
//           setConfirmationDialog={setShowConfirmRemoveUserDialog}
//           onYes={() => {
//             removeSystemUser(user.userEntityId, setShowConfirmRemoveUserDialog);
//           }}
//         />
//       )}
//     </>
//   );
// };
