import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { currencyFormat } from "../../common/components/Utils";

import { addNewSystemUser } from "../../../../store/users/actions";
import { getSectors } from "../../../../store/posts/actions";

import { isEmpty } from "lodash";
import axios from "axios";
import { CSVLink } from "react-csv";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import moment from "moment";
// import { showError } from "../../toastify";
import defaultPofile from "../../../assets/default-profile.jpg";
import { idNumberPattern } from "../../common/components/Utils";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import MaskedInput from "react-text-mask";
import joi from "joi";

const personalEmailValidator = joi.object({
  email: joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ["com", "fr", "rw"] },
  }),
});

const NewUserDialog = (props) => {
  const {
    user,
    loading,
    onWait,
    teacher,
    placementReport,
    showDialog,
    setShowDialog,
    addNewSystemUser,
    roles,
    districts,
    getSectors,
    sectors,
  } = props;

  const onClose = () => {
    setShowDialog(false);
  };

  const [errors, setErrors] = useState({
    nidNumberHasError: false,
    emailHasError: false,
    phoneNumberHasError: false,
    roleHasError: false,
    entityHasError: false,
    employeeIdHasError: false,
    staffCodeHasError: false,
    hasError: false,
  });

  const teacherInfoFormValidator = () => {
    const error = {
      nidNumberHasError: false,
      emailHasError: false,
      phoneNumberHasError: false,
      roleHasError: false,
      entityHasError: false,
      employeeIdHasError: false,
      staffCodeHasError: false,
      hasError: false,
    };

    // console.log(teacherForm.nidNumber.replace(/\s/g, "").length);
    if (
      (teacherForm.nidNumber || "").replace(/\s/g, "").length !== 16 ||
      !!idHasError
    ) {
      error.nidNumberHasError = true;
      error.hasError = true;
    }

    if (!teacherForm.employeeId) {
      error.employeeIdHasError = true;
      error.hasError = true;
    }

    if (!teacherForm.sdmsStaffCode) {
      error.staffCodeHasError = true;
      error.hasError = true;
    }

    if ((teacherForm.phoneNumber || "").replace(/\s/g, "").length !== 10) {
      error.phoneNumberHasError = true;
      error.hasError = true;
    }

    if (
      !teacherForm.email ||
      (!!teacherForm.email &&
        personalEmailValidator.validate({ email: teacherForm.email }).error)
    ) {
      error.emailHasError = true;
      error.hasError = true;
    }

    if (!selectedRole) {
      error.roleHasError = true;
      error.hasError = true;
    }

    if (!selectedEntity) {
      error.entityHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const [isVerifing, setIsVerifing] = useState(false);
  const [wasVerified, setWasVerified] = useState(false);
  const [idHasError, setIdHasError] = useState(false);

  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const [teacherForm, setTeacherForm] = useState({
    nidNumber: "",
    nin: "",
    email: "",
    phoneNumber: "",
    gender: "",
    genderId: "",
    countryOfBirth: "",
    countryIdOfBirth: "",
    firstName: "",
    lastName: "",
    civilStatus: "",
    civilStatusId: "",
    spouseNames: "",
    fatherNames: "",
    motherNames: "",
    dateOfBirth: "",
    placeOfBirth: "",
    nationalityId: "",
    villageId: "",
    placementDate: null,
    profilePicture: "",

    employeeId: "",
    rssbNumber: "",
    accountNumber: "",
    bankId: "",
    bankName: "",

    sdmsStaffCode: "",
  });

  const getNidDetails = async (nidNumber) => {
    try {
      let staff = {};
      try {
        const { data: tmisData } = await axios.get(
          "/api/users/search-staff/" + (nidNumber || "").replace(/\s/g, "")
        );

        staff = tmisData;
      } catch (error) {}

      setWasVerified(false);
      setIsVerifing(true);

      const { data } = await axios.get(
        "https://ippis.rw/api/search-tmis-employee/" +
          (nidNumber || "").replace(/\s/g, "")
      );

      setTeacherForm({
        ...teacherForm,
        nidNumber: nidNumber,
        nin: data.nin,
        gender: data.gender,
        genderId: data.genderId,
        countryOfBirth: data.countryOfBirth,
        countryIdOfBirth: data.countryIdOfBirth,
        firstName: data.firstName,
        lastName: data.lastName,
        civilStatus: data.civilStatus,
        civilStatusId: data.civilStatusId,
        spouseNames: data.spouseNames,
        fatherNames: data.fatherNames,
        motherNames: data.motherNames,
        dateOfBirth: data.dateOfBirth,
        placeOfBirth: data.placeOfBirth,
        nationalityId: data.nationalityId,
        villageId: data.villageId,
        profilePicture: data.profileImage,

        employeeId: data.employee?.id || "",
        rssbNumber: data.employee?.rssbNumber || "",
        accountNumber: data.employee?.accountNumber || "",
        bankId: data.employee?.bankId || "",
        bankName: data.employee?.bankName || "",

        sdmsStaffCode: staff?.id || data.employee?.id || "",
        email: staff?.email?.trim() || "",
        phoneNumber: staff?.phoneNumber?.trim() || "",
      });

      // setChanged(true);
      setIdHasError(false);
      setWasVerified(true);
      setIsVerifing(false);
    } catch (error) {
      // setChanged(false);
      setIdHasError(true);
      setWasVerified(false);
      setIsVerifing(false);
    }
  };

  const onSave = () => {
    if (!teacherInfoFormValidator())
      addNewSystemUser(
        {
          id: teacherForm.employeeId,
          lastName: teacherForm.lastName || null,
          firstName: teacherForm.firstName || null,
          email: teacherForm.email?.trim(),
          phoneNumber: teacherForm.phoneNumber?.trim(),
          nidNumber: teacherForm.nidNumber.replace(/\s/g, ""),
          passportNumber: teacherForm.passportNumber || null,
          nin: teacherForm.nin || null,
          gender: teacherForm.genderId || null,
          dateOfBirth: teacherForm.dateOfBirth || null,
          placeOfBirth: teacherForm.placeOfBirth || null,
          fatherNames: teacherForm.fatherNames || null,
          motherNames: teacherForm.motherNames || null,
          civilStatusId: teacherForm.civilStatusId || null,
          spouseNames: teacherForm.spouseNames || null,
          countryOfBirth: teacherForm.countryIdOfBirth || null,
          nationalityId: teacherForm.nationalityId || null,
          residenceVillageId: teacherForm.villageId || null,
          bankId: teacherForm.bankId || null,
          bankAccountNumber: teacherForm.bankAccountNumber || null,
          rssbNumber: teacherForm.rssbNumber || null,
          sdmsStaffCode:
            teacherForm.sdmsStaffCode || teacherForm.employeeId || null,

          role: selectedRole?.id || null,
          entityId: selectedEntity?.id || null,
          entityType: selectedRole?.accessLevel || null,
        },
        onClose
      );
  };

  const filterRoles = () => {
    if (props.user.selectedEntity.id === "00000000")
      return roles.filter(
        ({ id }) =>
          id !== "HEAD_TEACHER" &&
          id !== "SEO" &&
          id !== "TEACHER" &&
          id !== "ADMIN" &&
          id !== "REB"
      );
    else if (props.user.selectedEntity.type === "DISTRICT")
      return roles.filter(({ id }) => id === "SEO");
    else return [];
  };

  useEffect(() => {
    //   if (props.user.selectedEntity.id === "00000000")
    //   return roles.filter(
    //     ({ id }) => id === "DDE" || id === "DEO_1" || id === "DEO_1"
    //   );
    // else if (props.user.selectedEntity.type === "DISTRICT")
    //   return roles.filter(({ id }) => id === "SEO");

    if (
      props.user.selectedEntity.type === "DISTRICT" &&
      props.user.selectedEntity.id !== "00000000"
    ) {
      setSelectedRole(roles.find(({ id }) => id === "SEO"));
      getSectors(props.user.selectedEntity.id);
    }
  }, []);

  const filterEntities = () => {
    if (props.user.selectedEntity.id === "00000000")
      return [{ id: "00000000", name: "All" }, ...districts];
    else if (props.user.selectedEntity.type === "DISTRICT") return sectors;
    else return [];
  };

  // const [districts, setDistricts] = useState([]);

  // const getDistricts = async () => {
  //   try {
  //     const { data } = await axios.get("/api/posts/districts");

  //     setDistricts(data);
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   if (!!showDialog) getDistricts();
  // }, []);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary pb-0 mb-0 text-uppercase border">
          <span className="d-flex align-items-center mb-2">
            <Typography className="d-flex align-items-center text-primary">
              <span className="text-dark mr-1">
                {!!teacher ? "Update User Profile" : "Add New User"}{" "}
              </span>
              {!!teacher && (
                <>
                  {teacher?.lastName} {teacher?.firstName}
                </>
              )}
            </Typography>
          </span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="px-0">
          <div className="mt-0" style={{ height: "100%" }}>
            <div
              style={{
                display: "table-cell",
                width: "100vw",
                height: "45vh",
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              <div className="row mx-0">
                <div className="col-sm-12">
                  <React.Fragment>
                    <div>
                      <div className="row no-gutters">
                        <div className="col-2 justify-content-center  text-center">
                          <div
                            style={{
                              minHeight: "150px",
                              overflow: "hidden",
                            }}
                          >
                            <>
                              <img
                                className="mt-0 rounded  text-center"
                                src={
                                  !!teacherForm.profilePicture
                                    ? "data:image/jpg;base64," +
                                      teacherForm.profilePicture
                                    : defaultPofile
                                }
                                style={{
                                  border: "1px solid #a7a7a7",
                                  height: "150px",
                                  width: "120px",
                                  overflow: "hidden",
                                  borderRadius: "50%",
                                }}
                              />
                            </>
                          </div>

                          <div className="text-primary">
                            <strong>NID PHOTO</strong>{" "}
                          </div>
                          <label className="text-center text-uppercase  mt-n2">
                            <span style={{ fontSize: "12px" }}>
                              {teacher?.lastName} {teacher?.firstName}
                            </span>
                          </label>
                        </div>

                        <div className="col-10 row mx-0">
                          <div className="col-8 justify-content-center">
                            {!idHasError && !isVerifing && !wasVerified && (
                              <span className="material-icons loaderIcon text-danger">
                                help
                              </span>
                            )}

                            {!!isVerifing && (
                              <i
                                className="fas fa-spinner spinner loaderIcon text-primary"
                                style={{ fontSize: "22px" }}
                              ></i>
                            )}

                            {!isVerifing && !!idHasError && (
                              <span className="loaderIcon text-danger d-flex align-items-center">
                                <span className="material-icons text-danger">
                                  report_problem
                                </span>
                                <span style={{ fontSize: "10px" }}>Error</span>
                              </span>
                            )}

                            {!idHasError && !isVerifing && !!wasVerified && (
                              <span className="loaderIcon text-success d-flex align-items-center">
                                <span className="material-icons text-success ">
                                  verified
                                </span>
                                <span style={{ fontSize: "10px" }}>
                                  Verified
                                </span>
                              </span>
                            )}

                            <MaskedInput
                              mask={idNumberPattern}
                              className="form-control "
                              placeholder="NID Number"
                              guide={false}
                              value={teacherForm.nidNumber}
                              onKeyPress={(e) => {
                                if (
                                  e.key === "Enter" &&
                                  e.target.value.length === 21
                                ) {
                                  getNidDetails(e.target.value);
                                }
                              }}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  nidNumber: e.target.value,
                                });

                                if (e.target.value.length === 21) {
                                  getNidDetails(e.target.value);
                                } else {
                                  setIdHasError(false);
                                  setWasVerified(false);
                                  setIsVerifing(false);
                                }
                                setErrors({
                                  ...errors,
                                  nidNumberHasError: false,
                                  emailHasError: false,
                                  phoneNumberHasError: false,
                                  employeeIdHasError: false,
                                  staffCodeHasError: false,
                                  hasError: false,
                                });
                              }}
                            />

                            {errors.nidNumberHasError && (
                              <div className="text-danger mb-1">
                                {!!teacherForm.nidNumber
                                  ? "Invalid NID Number"
                                  : " NID Number is required"}
                              </div>
                            )}
                          </div>

                          <div className="col-4  text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="gender"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Gender"
                              variant="outlined"
                              className=" font-weight-bold"
                              value={teacherForm.gender}
                            />
                          </div>

                          <div className="col-4  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="lastName"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Last Name"
                              variant="outlined"
                              className=""
                              value={teacherForm.lastName}
                            />
                          </div>
                          <div className="col-4  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="firstName"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="First Name"
                              variant="outlined"
                              className=""
                              value={teacherForm.firstName}
                            />
                          </div>
                          <div className="col-4  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="civilStatus"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Civil Status"
                              variant="outlined"
                              value={teacherForm.civilStatus}
                            />
                          </div>

                          <div className="col-4  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="dateOfBirth"
                              value={
                                !!teacherForm.dateOfBirth
                                  ? moment(teacherForm.dateOfBirth).format(
                                      "DD/MM/yyyy"
                                    )
                                  : ""
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Date Of Birth"
                              variant="outlined"
                            />
                          </div>
                          <div className="col-4  mt-3 text-left">
                            <TextField
                              InputProps={{
                                readOnly: true,
                              }}
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="placeOfBirth"
                              value={teacherForm.placeOfBirth}
                              label="Place of Birth"
                              variant="outlined"
                              className=""
                            />
                          </div>
                          <div className="col-4  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="countryOfBirth"
                              value={teacherForm.countryOfBirth}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Country of Birth"
                              variant="outlined"
                              className=""
                            />
                          </div>

                          <div className="col-8 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="employeeId"
                              value={teacherForm?.employeeId || ""}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="IPPIS Employee ID"
                              variant="outlined"
                              className=""
                            />

                            {errors.employeeIdHasError && (
                              <small className="text-danger mt-1">
                                Employee Id is required
                              </small>
                            )}
                          </div>
                          <div className="col-4 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="sdmsStaffCode"
                              value={teacherForm?.sdmsStaffCode || ""}
                              label="Staff Code"
                              placeholder="Staff Code"
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                              // onChange={(e) => {
                              //   setTeacherForm({
                              //     ...teacherForm,
                              //     sdmsStaffCode: e.target.value,
                              //   });

                              //   setErrors({
                              //     ...errors,
                              //     sdmsStaffCodeHasError: false,
                              //   });
                              // }}
                            />

                            {errors.staffCodeHasError && (
                              <small className="text-danger mt-1">
                                Staff Code is required
                              </small>
                            )}
                          </div>

                          {/* <div className="col-8 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="position"
                              value={teacher?.positionName}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Position"
                              variant="outlined"
                              className=""
                            />
                          </div> */}
                          {/* 
                          <div className="col-4 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="qualification"
                              value={teacher?.qualificationLevelName}
                              label="Qualification"
                              variant="outlined"
                              className=""
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </div> */}
                          <div className="col-6  mt-3 text-left">
                            <TextField
                              fullWidth
                              size="small"
                              autoComplete="false"
                              name="phone"
                              label="Phone number"
                              placeholder="Phone number"
                              variant="outlined"
                              value={teacherForm.phoneNumber}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  phoneNumber: e.target.value,
                                });

                                setErrors({
                                  ...errors,
                                  phoneNumberHasError: false,
                                });
                              }}
                            />
                            {errors.phoneNumberHasError && (
                              <small className="text-danger mt-1">
                                {!!teacherForm.phoneNumber
                                  ? "Invalid Phone Number"
                                  : " Phone Number is required"}
                              </small>
                            )}
                          </div>
                          <div className="col-6  mt-3 text-left">
                            <TextField
                              fullWidth
                              size="small"
                              autoComplete="false"
                              name="email"
                              label="Personal email"
                              placeholder="Personal email"
                              variant="outlined"
                              value={teacherForm.email}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  email: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  emailHasError: false,
                                });
                              }}
                            />

                            {errors.emailHasError && (
                              <small className="text-danger">
                                {!!teacherForm.email
                                  ? "Invalid Personal email"
                                  : " Personal email is required"}
                              </small>
                            )}
                          </div>
                          <div className="mt-3 col-6">
                            <Autocomplete
                              size="small"
                              defaultValue={null}
                              value={selectedRole}
                              options={filterRoles()}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.id}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.id}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Role"
                                  name="role"
                                  placeholder="Role"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              onChange={(event, role) => {
                                setSelectedRole(role || null);

                                setErrors({ ...errors, roleHasError: false });
                              }}
                            />
                            {errors.roleHasError && (
                              <small className="text-danger">
                                Role is required
                              </small>
                            )}
                          </div>

                          <div className="mt-3 col-6">
                            <Autocomplete
                              size="small"
                              defaultValue={null}
                              value={selectedEntity}
                              options={filterEntities()}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Access Level"
                                  name="entity"
                                  placeholder="Access Level"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              onChange={(event, entity) => {
                                setSelectedEntity(entity || null);

                                setErrors({
                                  ...errors,
                                  entityHasError: false,
                                });
                              }}
                            />
                            {errors.entityHasError && (
                              <small className="text-danger">
                                Access Level is required
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className=" d-flex justify-content-center py-4">
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            disableElevation
            size="sm"
            className="px-4"
            onClick={onSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  placementReportDetails,
  changeList,
  prevPlacementReport,
  signatories,
  roles,
  districts,
  sectors,
}) => {
  return {
    user,
    loading,
    onWait,
    placementReportDetails,
    changeList,
    prevPlacementReport,
    signatories,
    roles,
    districts,
    sectors,
  };
};
export default connect(mapStateToProps, {
  addNewSystemUser,
  getSectors,
})(NewUserDialog);
