import cpdTypes from "./action-types";
import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";
import commonTypes from "../common/action-types";
import download from "downloadjs";

const types = { ...commonTypes, ...cpdTypes };

export const getCpdData = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/cpd/test");

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_CPD_DATA_TEST,
        data: data,
      });

      // showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
